/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access */
import {
  loadCompaniesWise,
  loadCompanyActivitySetup,
  loadJobTitlesWise,
  deleteCompanyActivitySetupJobtitle, addCompanyActivitySetup
} from "../features/Company";

import ErrorPage from "./ErrorPage";

import * as React from "react";
import { connect, Provider } from "react-redux";

import { confirmAlert } from "react-confirm-alert";
import InvoiceMenu from "../components/InvoiceMenu";
import type ReducersState from "../types/reducers";
import type { Dispatch } from "redux";
import type { CompanyName } from "@mhc/utils/types/nav";
import api from "../features/Api";
import { typed } from "@mhc/utils/src/typing";
import InvoiceUsersActivitiesCheckboxes from "../components/InvoiceUsersActivitiesCheckboxes";

const mapStateToProps = (state: ReducersState) => {
  return {
    company: state.company,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // @ts-ignore
    loadCompaniesWise: () => dispatch(loadCompaniesWise()),
    // @ts-ignore
    loadJobTitlesWise: () => dispatch(loadJobTitlesWise()),
    // @ts-ignore
    loadCompanyActivitySetup: (companyName: CompanyName) => dispatch(loadCompanyActivitySetup(companyName)),
    // @ts-ignore
    deleteCompanyActivitySetupJobtitle: (payload: { companyName: CompanyName, jobTitle: string }) => dispatch(deleteCompanyActivitySetupJobtitle(payload)),
    // @ts-ignore
    addCompanyActivitySetup: (payload: { companyName: CompanyName, jobTitle: string, activity: string }) => dispatch(addCompanyActivitySetup(payload)),
  };
};
export const views = {
  Table: 0,
  Form: 1
};

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {
    isAdmin: "" | "True" | "False",
};

function makeInitialState(props: Props) {
    return {
        madeFirstCall: false,
        currentPage: 1,
        employeesPerPage: 10,
        isAdmin: props.isAdmin,
        viewUpdateFormName: typed<CompanyName | "">(""),
        SelectedActivities: [],
        SelectedCompany: typed<"" | CompanyName>(""),
        SelectedJobTitle: "",
        sortBy: {
            column: "",
            order: typed<boolean | "asc" | "desc">("asc"),
        },
        loading: false,
        companyActivitySetup: typed<string[] | undefined>(undefined),
        view: typed<undefined | "All">(undefined),
    };
}

type State = ReturnType<typeof makeInitialState>;

class InvoicesUsersActivities extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    require("./InvoicesUsersActivities.css");
    this.state = makeInitialState(props);
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (nextState.loading) {
      return false;
    }
    return true;
  };

  componentDidMount() {
    this.props.loadJobTitlesWise().unwrap()
      .then(() => {
        this.setState({ loading: false });
      });
    this.props.loadCompaniesWise().unwrap()
      .then(() => {
        this.setState({ loading: false });
      });
    if (!this.state.madeFirstCall)
      this.setState({
        madeFirstCall: true,
        view: "All",
      });
  };

  createSelectItems() {
    const items = [];
    if (this.props.company.companiesWise != null) {

      items.push(<option key={-1} value="">Choose Client</option>);
      for (let i = 0; i < this.props.company.companiesWise.length; i++) {
        const comp = this.props.company.companiesWise[i];
        items.push(<option key={comp.Code} value={comp.Code}>{comp.DisplayName}</option>);
      }
    }
    return items;
  };

  createSelectJobTitleItems() {
    const items = [];
    items.push(<option key="-1" value="">Choose Jobtitle</option>);
    this.props.company.jobTitlesWise.map((e) => {
      if (e.Code !== "")
        items.push(<option key={e.Code}>{e.Name}</option>);
    });

    return items;
  };

  showActivityForm(e: React.MouseEvent<HTMLElement>) {
    const company = e.currentTarget.attributes["data-companyname"].value as CompanyName;
    let job = "";
    if (e.currentTarget.attributes["data-jobtitle"] !== undefined)
      job = e.currentTarget.attributes["data-jobtitle"].value;
    if (!this.state.loading) {
      this.setState({
        viewUpdateFormName: company,
        SelectedJobTitle: job,
      });
    }
    api.Company.GetCompanyActivitySetupByCompanyAndJobtitle({
        company: company,
        jobtitle: job,
    }).then((originalPromiseResult) => {
        this.setState({ loading: false, companyActivitySetup: originalPromiseResult });
    });
  };

  _ChangeCompany(e: React.ChangeEvent<HTMLSelectElement>) {
    const companyName = e.currentTarget.value as CompanyName;
    if (!this.state.loading) {
      this.setState({
        SelectedCompany: companyName,
      });
    }
    this.props.loadCompanyActivitySetup(companyName).unwrap();
  };

  loadCompanyActivitySetup(company: CompanyName) {
    this.props.loadCompanyActivitySetup(company).unwrap()
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => {
      });
  }

  _generateCompanyList() {
    return (
      <div className="col-xs-6"
           style={{ paddingLeft: "0", display: "table-cell" }}>
        <select onChange={(e) => this._ChangeCompany(e)} value={this.state.SelectedCompany}
                style={{ fontSize: "20px", width: "100%" }}>
          {this.createSelectItems()}
        </select>
      </div>
    );
  };

  _generateJobTitleList() {
    return (
      <div className="row" style={{ display: "table-cell" }}>
        <select onChange={(e) => this._ChangeJobtitle(e)} value={this.state.SelectedJobTitle}
                style={{ fontSize: "20px", minWidth: "250px", width: "auto" }}>
          {this.createSelectJobTitleItems()}
        </select>
      </div>
    );
  };

  _ChangeJobtitle(e: React.ChangeEvent<HTMLSelectElement>) {
    if (!this.state.loading) {
      this.setState({
        SelectedJobTitle: e.currentTarget.value,
      });
    }
  };

  _getHeader() {
    return (<div className="invoice-header">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1>Activities - Setup</h1>
          </div>
        </div>
      </div>
    </div>);
  };

  _createInvoiceMenu() {
    return (<InvoiceMenu selected="Activity"/>);
  };

  showTable() {
    if (!this.state.loading) {
      this.setState({
          SelectedJobTitle: "",
          viewUpdateFormName: "",
      });
    }
  };

  deleteActivity(e: React.MouseEvent) {
    const comp = e.currentTarget.attributes["data-company"].value as CompanyName;
    const job = e.currentTarget.attributes["data-jobtitle"].value;
    const payload = {
      companyName: comp,
      jobTitle: job
    };
    confirmAlert({
      title: "Delete",
      message: "Are you sure to delete: " + e.currentTarget.attributes["data-jobtitle"].value + " from company: " + e.currentTarget.attributes["data-company"].value,
      buttons: [
        {
          label: "Delete",
          onClick: () => {

            this.props.deleteCompanyActivitySetupJobtitle(payload).unwrap()
              .then(() => {
                this.loadCompanyActivitySetup(comp);
              })
              .catch(() => {
              });
          }
        },
        {
          label: "Cancel",
          onClick: () => alert("Click No")
        }
      ]
    });
    this.setState({
        SelectedJobTitle: "",
        viewUpdateFormName: "",
    });
  };

  _saveActivities() {
    let activities = "";
    if (!this.state.viewUpdateFormName || !this.state.SelectedCompany) {
        throw new Error("Company not selected");
    }
    if (!this.state.companyActivitySetup) {
        throw new Error("Company Activity Setup not loaded yet!");
    }
    for (let i = 0; i < this.state.companyActivitySetup.length; i++) {
      if (this.state.companyActivitySetup[i] !== "")
        activities = activities + this.state.companyActivitySetup[i] + "|";
    }
    if (activities === "")
      activities = "null";
    const payload = {
      companyName: this.state.viewUpdateFormName,
      jobTitle: this.state.SelectedJobTitle,
      activity: activities
    };
    this.props.addCompanyActivitySetup(payload).unwrap()
      .then(() => {
      })
      .catch(() => {
      });

    if (!this.state.loading) {
      this.setState({
        SelectedActivities: [],
        SelectedJobTitle: "",
        viewUpdateFormName: "",
      });
    }
    this.loadCompanyActivitySetup(this.state.SelectedCompany);
  };

  _createActivityTable() {
    const self = this;
    const records = self.props.company.companyActivitySetup && self.props.company.companyActivitySetup.length > 0 ? self.props.company.companyActivitySetup.map((r) => {
      return (<tbody key={r.Id}>
      <tr>
        <td>{r.JobTitle}</td>
        <td><a data-companyname={r.CompanyName} data-jobtitle={r.JobTitle} onClick={(e) => this.showActivityForm(e)}
               href="#">Edit</a></td>
        <td><a style={{ display: "inline-block" }}
               data-company={r.CompanyName} data-jobtitle={r.JobTitle} onClick={(e) => this.deleteActivity(e)}
               href="#">Delete</a>
        </td>
      </tr>
      {/* <tr><td colSpan="3">{r.Activites.split('|').map((k) => <span className="fieldList">{k}</span> )}</td></tr> */}
      </tbody>);
    }) : this.state.SelectedCompany == "" ? <tbody>
    <tr>
      <td>Select Client</td>
    </tr>
    </tbody> : <tbody>
    <tr>
      <td>No Department assigned</td>
    </tr>
    </tbody>;
    return (<div>
      <div className="col-xs-12">
        <div className="col-xs-9">

          <div className="row">
            <h3>Client</h3>
          </div>
          <div className="row">
            {this._generateCompanyList()}
          </div>
        </div>
        <div className="col-xs-3">
          <span className="btn btn-warning"
                style={{ marginTop: "30px", display: this.state.SelectedCompany == "" ? "none" : "inline-block" }}
                data-companyname={this.state.SelectedCompany}
                onClick={(e) => this.showActivityForm(e)}>Assign new Department</span>

        </div>
      </div>
      <div className="col-xs-12">
        <div className="table-responsive admin">
          <div className="header">
            <h3>
              Departments
            </h3>
          </div>
          <table className="invoices invoice-months table table-striped table-condensed">
            {records}
          </table>
        </div>
      </div>
    </div>);
  };

  _createActivityForm() {
    return (
      <div className="row col-xs-12">
        <div className="col-xs-12">
          <h3>Select Department for company: {this.state.viewUpdateFormName}</h3>
          <hr/>
          {this._generateJobTitleList()}
          <br/><br/>
        </div>

        <div className="col-xs-12">
            {this.state.loading
                ? <div className="status-loading-animated-ellipsis">Data Loading</div>
            : !this.state.SelectedCompany
                ? <div>Select Company to view activities list</div>
            : !this.state.companyActivitySetup
                ? <div>Loading Company Activities State</div>
            : <InvoiceUsersActivitiesCheckboxes
                key={JSON.stringify([this.state.SelectedCompany, this.state.SelectedJobTitle])}
                SelectedCompany={this.state.SelectedCompany}
                value={this.state.companyActivitySetup}
                onChange={companyActivitySetup => this.setState({ companyActivitySetup })}
            />}
        </div>
        <div className="row col-md-12 action-buttons" style={{ marginTop: "40px" }}>
          <a className="btn btn-warning" href="#"
             style={{ display: this.state.SelectedJobTitle == "" ? "none" : "inline-block" }}
             onClick={(e) => this._saveActivities()}>Save</a>
          <a className="btn btn-warning" href="#" onClick={(e) => this.showTable()}>Cancel</a>
        </div>
        <div className="row col-md-12">

        </div>
      </div>);
  };

  hasAdminPrivileges() {
    const cleanIsAdmin = this.state.isAdmin === "True";
    return cleanIsAdmin;
  };

  render() {
    if (!this.hasAdminPrivileges()) {
      return <ErrorPage/>;
    }

    let content = null;
    if (this.state.viewUpdateFormName) {
        content = this._createActivityForm();
    } else {
        content = this._createActivityTable();
    }

    if (!this.state.madeFirstCall || this.props.company.companiesWise == undefined) {
      return (<div className="invoice-wrapper">
        {this._getHeader()}
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h1
                className="loading">{!this.state.madeFirstCall ? "Loading list of Activities..." : "No Activities found"}</h1>
            </div>
          </div>
        </div>
      </div>);
    } else {
      return (
        <div className="invoice-wrapper">
          {this._getHeader()}
          <div className="container">
            <div className="row">
              <div className="col-xs-3 invocie-menu">
                {this._createInvoiceMenu()}

              </div>

              <div className="content-wrapper">
                <div className="col-xs-9">
                  {content}
                </div>
              </div>
            </div>
          </div>

        </div>
      );
    }
  }
}

const wrapper = (props: Props) => {
  return <Provider store={window.store}>{<InvoicesUsersActivities {...props}/>}</Provider>;
};
module.exports = connect(
  mapStateToProps,
  mapDispatchToProps
)(wrapper);
