import * as React from "react";
import type { BeneficiaryBankAccount, NavCompanyData, Payslip } from "../types/NavisionPayslip";
import type EmployeeInfo from "../types/EmployeeInfo";
import type {
    CompanyToDepartmentToName,
    PayrollLocatorWithDepartment
} from "../features/Api";
import {
    getAllCompaniesDepartmentsMapping
} from "../features/Api";
import api from "../features/Api";
import type {
    INavInvoiceConfirmation,
    INavPayrollFinalization,
    NavInvoiceMessageWithLocatorModel,StoredFinalization
} from "../types/Api/Invoice";


import CustomFields from "../utils/CompanySpecificFields";
import ActionsQueue from "../utils/ActionsQueue";
import { matchesPayroll, transferStatementUnlockTime } from "../features/payrollUtils";
import { YearAndMonthFilter } from "../components/YearAndMonthFilter";
import DepartmentFilterSelect from "../components/DepartmentFilterSelect";
import { getAmount,getConfirmationTotal, getPartTitle, getStatusLabel, rankedStatuses } from "../utils/invoiceListingUtils";
import CompanyFilterSelect from "../components/CompanyFilterSelect";
import _ from "lodash";
import { brand, fromEntries,neverNull } from "../utils/typing";
import { deadlinePassed } from "../utils/feeStatementInvoiceUtils";

type Props = {
    employeeInfo: EmployeeInfo,
};

type State = {
    submitting: boolean,
    resultsLoading: boolean,
    records: Payslip[],
    employeeCodeToConfirmations: null | Record<string, INavInvoiceConfirmation[]>,
    employeeCodeToConfirmationMessages: null | Record<string, NavInvoiceMessageWithLocatorModel[]>,
    employeeCodeToStoppedBankAccounts: null | Record<string, BeneficiaryBankAccount[]>,
    payrollFinalizations: null | (StoredFinalization | INavPayrollFinalization)[],
    filters: PayrollLocatorWithDepartment,
    navCompanyData: null | NavCompanyData,
    companyToDepartmentToName: null | CompanyToDepartmentToName,
};

const EMPTY_STATE = {
    records: [],
    employeeCodeToConfirmations: null,
    employeeCodeToConfirmationMessages: null,
    employeeCodeToStoppedBankAccounts: null,
    navCompanyData: null,
    resultsLoading: true,
};

export default class FeeStatementInvoicesListForOfficer extends React.Component<
    Props, State
> {
    private readonly actionsQueue = ActionsQueue();

    constructor(props: Props) {
        super(props);
        require("./FeeStatementInvoicesListForOfficer.css");
        require("./InvoiceStatuses.css");
        const pastMonth = new Date();
        pastMonth.setUTCMonth(pastMonth.getUTCMonth() - 1);
        this.state = {
            submitting: false,
            payrollFinalizations: null,
            companyToDepartmentToName: null,
            filters: {
                department: 0,
                // "MHC Aviation" does not exist in Navision
                company: this.props.employeeInfo.CompanyName.toUpperCase() === "MHC AVIATION"
                    ? brand("AIRBORNE - MALTA")
                    : this.props.employeeInfo.CompanyName,
                year: pastMonth.getUTCFullYear(),
                month: pastMonth.getUTCMonth() + 1,
            },
            ...EMPTY_STATE,
        };
        api.Invoice.GetNavPayrollFinalizationsList()
            .then(finalizations => this.setState({
                payrollFinalizations: finalizations,
            }));
        getAllCompaniesDepartmentsMapping()
            .then(companyToDepartmentToName => this.setState({
                companyToDepartmentToName: companyToDepartmentToName,
            }));
        this.loadFilteredData();
    }

    private async loadFilteredData() {
        await Promise.all([
            api.Invoice.GetFeeStatementInvoicesListForOfficer(this.state.filters)
                .then((records) => this.setState({ records: records }))
                .finally(() => this.setState({ resultsLoading: false })),
            api.Invoice.GetNavInvoiceConfirmationsListForOfficer(this.state.filters)
                .then(async records => this.setState({
                    employeeCodeToConfirmations: _.groupBy(records, r => r.employee_code.toUpperCase()),
                })),
            api.Invoice.GetStoppedBankAccounts(this.state.filters)
                .then(records => {
                    const employeeCodeToStoppedBankAccounts: Record<string, BeneficiaryBankAccount[]> = {};
                    for (const record of records) {
                        employeeCodeToStoppedBankAccounts[record.No_.toUpperCase()] = employeeCodeToStoppedBankAccounts[record.No_.toUpperCase()] || [];
                        employeeCodeToStoppedBankAccounts[record.No_.toUpperCase()].push(record);
                    }
                    this.setState({ employeeCodeToStoppedBankAccounts });
                }),
            api.Invoice.GetNavPayrollInvoicesMessagesHistory(this.state.filters)
                .then(async records => {
                    const employeeCodeToConfirmationMessages: Record<string, NavInvoiceMessageWithLocatorModel[]> = {};
                    for (const record of records) {
                        const code = record.employee_code.toUpperCase();
                        employeeCodeToConfirmationMessages[code] = employeeCodeToConfirmationMessages[code] ?? [];
                        employeeCodeToConfirmationMessages[code].push(record);
                    }
                    this.setState({ employeeCodeToConfirmationMessages });
                }),
            api.Company.GetNavCompanyData({ company: this.state.filters.company })
                .then(navCompanyData => this.setState({
                    navCompanyData: navCompanyData,
                })),
        ]);
    }

    private async reloadFilteredData() {
        // preventing race condition in situation when you submit new
        // change to filters before previous one is done updating the data
        await this.actionsQueue.enqueue(async () => {
            this.setState(EMPTY_STATE);
            await this.loadFilteredData();
        });
    }

    private getCustomFields(record: Payslip) {
        if (!this.state.navCompanyData) {
            return null;
        }
        return new CustomFields(this.state.navCompanyData, record);
    }

    private getDepartmentCode(record: Payslip) {
        return Number(this.getCustomFields(record)?.departmentCode ?? 0);
    }

    private getStatus(record: Payslip, confirmation: INavInvoiceConfirmation) {
        if (confirmation) {
            const difference = getAmount(record) - confirmation.confirmation_total;
            if (Math.abs(difference) >= 0.01) {
                if (confirmation.status === "DISCREPANCY_REPORTED") {
                    return "CHANGED_AFTER_DISCREPANCY_REPORT";
                } else if (confirmation.status === "CONFIRMED") {
                    return difference > 0
                        ? "INCREASED_AFTER_CONFIRMATION"
                        : "REDUCED_AFTER_CONFIRMATION";
                }
            }
            return confirmation.status;
        }
        if (!this.state.payrollFinalizations) {
            return "DATA_LOADING";
        }
        const finalization = this.findFinalization(this.getDepartmentCode(record));
        if (finalization) {
            return "SENT_TO_CONTRACTOR_FOR_CONFIRMATION";
        } else {
            return "NOT_FINALIZED";
        }
    }

    private getStatusRank(record: Payslip): number {
        const ranks = fromEntries(rankedStatuses.map((s,i) => [s, i]));
        if (!this.state.employeeCodeToConfirmations) {
            return ranks["DATA_LOADING"];
        }
        const confirmations = this.state.employeeCodeToConfirmations[record.Kennitala.toUpperCase()] ?? [];
        if (confirmations.length === 0) {
            return ranks["NOT_FINALIZED"];
        }
        const confirmationRanks = confirmations
            .map(conf => this.getStatus(record, conf))
            .map(status => ranks[status]);
        return Math.min(...confirmationRanks);
    }

    private findFinalization(department: number) {
        return (this.state.payrollFinalizations ?? []).find(f => {
            return matchesPayroll(f, {
                company: this.state.filters.company,
                month: this.state.filters.month,
                year: this.state.filters.year,
                department: department,
            });
        });
    }

    private get payrollFinalization() {
        return this.findFinalization(this.state.filters.department);
    }


    private get canFinalize() {
        return this.state.payrollFinalizations
            && !this.payrollFinalization;
    }

    /** ORDER BY no email, status, department */
    private get sortedRecords() {
        const filtered = this.state.records.filter(r => {
            return !this.state.filters.department ? true :
                this.getDepartmentCode(r) == this.state.filters.department;
        });
        const sortedByDepartment = filtered.sort((a,b) => {
            const aDprt = this.getDepartmentCode(a);
            const bDprt = this.getDepartmentCode(b);
            return +(bDprt ?? "0") - +(aDprt ?? "0");
        });
        let sortedByStatus;
        if (!this.state.employeeCodeToConfirmations) {
            sortedByStatus = sortedByDepartment;
        } else {
            sortedByStatus = sortedByDepartment.sort((a,b) => {
                return this.getStatusRank(a) - this.getStatusRank(b);
            });
        }
        const sortedByEmailAbsence = sortedByStatus.sort((a,b) => {
            return (this.getEmail(a) ? 1 : 0) - (this.getEmail(b) ? 1 : 0);
        });
        const sortedByStoppedBankAccount = sortedByEmailAbsence.sort((a,b) => {
            const aStopped = this.getStoppedBankAccountInfo(a);
            const bStopped = this.getStoppedBankAccountInfo(b);
            return (aStopped ? 0 : 1) - (bStopped ? 0 : 1);
        });
        return sortedByStoppedBankAccount;
    }

    private get actionWarningMessageBase() {
        return "Are you sure that all " + this.sortedRecords.length + " " +
            this.state.filters.company + " invoices for " + this.state.filters.year + "-" + this.state.filters.month +
            (this.state.filters.department ? " department " + this.state.filters.department : " ALL departments") +
            " are ready to ";
    }

    private async finalizePayroll() {
        const confirmMessage = this.actionWarningMessageBase + "be shown to contractors?";
        if (!confirm(confirmMessage)) {
            return;
        }
        this.setState({ submitting: true });
        try {
            await api.Invoice.FinalizeNavPayroll(this.state.filters);
        } finally {
            this.setState({ submitting: false });
        }
        this.setState({
            payrollFinalizations: [
                ...this.state.payrollFinalizations  ?? neverNull(),
                {
                    year: this.state.filters.year,
                    month: this.state.filters.month,
                    company_name: this.state.filters.company,
                    department: this.state.filters.department,
                    finalization_time: new Date().toISOString(),
                }
            ],
        });
    }

    private get transferStatementUnlockTime(): Date {
        return transferStatementUnlockTime(this.state.filters);
    }

    private get readyForTransferStatement() {
        return Date.now() >= this.transferStatementUnlockTime.getTime();
    }

    private async sendTransferStatement() {
        const confirmMessage = this.actionWarningMessageBase + "be paid the amount in invoices?";
        if (!confirm(confirmMessage)) {
            return;
        }
        this.setState({ submitting: true });
        try {
            await api.Invoice.SendTransferStatement(this.state.filters);
        } finally {
            this.setState({ submitting: false });
        }
    }

    private getEmail(record: Payslip) {
        return record.VendorName && !record.VendorIsUmbrella ? record.VendorEmail : record["E-Mail"];
    }

    private getStoppedBankAccountInfo(record: Payslip) {
        const accounts = this.state.employeeCodeToStoppedBankAccounts?.[record.Kennitala.toUpperCase()] ?? [];
        return accounts.map(acc => acc.LineSheetNumber + " " + acc.BankCountry).join(" | ");
    }

    private handleFilterChange(value: string | number, propertyName: keyof PayrollLocatorWithDepartment) {
        const update: Partial<PayrollLocatorWithDepartment> = {
            [propertyName]: value,
        };
        if (propertyName === "company") {
            update.department = 0;
        }
        this.setState({ filters: { ...this.state.filters, ...update } });
        this.reloadFilteredData();
    };

    private getStatusLabels(record: Payslip) {
        const loadingIndicator = <div className="colored-nav-invoice-status-holder" data-status="DATA_LOADING">
            <span className="statusField status-loading-animated-ellipsis">Loading</span>
        </div>;
        if (!this.state.employeeCodeToConfirmations) {
            return loadingIndicator;
        }
        const confirmations = this.state.employeeCodeToConfirmations[record.Kennitala.toUpperCase()] ?? [];
        if (confirmations.length === 0) {
            if (!this.state.payrollFinalizations) {
                return loadingIndicator;
            }
            const finalization = this.findFinalization(this.getDepartmentCode(record));
            if (finalization) {
                let deadlinePassedFlag = false;
                if ("ConfirmationDeadline" in finalization) {
                    if (deadlinePassed(new Date(finalization.ConfirmationDeadline))) {
                        deadlinePassedFlag = true;
                    }
                }
                const statusLabel = deadlinePassedFlag ? "Auto-Confirmed due to Timeout" : "Awaiting Confirmation";
                return <div className="colored-nav-invoice-status-holder" data-status="SENT_TO_CONTRACTOR_FOR_CONFIRMATION">
                    <span className="statusField">{statusLabel}</span>
                </div>;
            } else {
                return <div className="colored-nav-invoice-status-holder" data-status="NOT_FINALIZED">
                    <span className="statusField">Not Sent</span>
                </div>;
            }
        }
        return <>
            {confirmations.map(conf => <div className="colored-nav-invoice-status-holder" key={conf.confirming_party} data-status={this.getStatus(record, conf)}>
                {getPartTitle(conf.confirming_party)} <span className="statusField">{getStatusLabel(this.getStatus(record, conf))}</span>
            </div>)}
        </>;
    }

    render() {
        return (
            <div className="FeeStatementInvoicesListForOfficer">
                <h1>Invoices from Navision for Payroll #{this.state.records.map(r => r.Utborgunarnumer)[0]}</h1>
                <div className="actionPanel">
                    <form>
                        <fieldset className="payrollFilters" disabled={this.state.submitting}>
                            <CompanyFilterSelect
                                allowNull={false}
                                company={this.state.filters.company}
                                onChange={(value) => this.handleFilterChange(value, "company")}
                            />
                            {this.state.navCompanyData ? <DepartmentFilterSelect
                                company={this.state.filters.company}
                                department={this.state.filters.department}
                                onChange={(department) => {
                                    this.handleFilterChange(department, "department");
                                }}
                                records={this.state.records.map(payslip => ({
                                    payslip, allCompany: this.state.navCompanyData ?? neverNull(),
                                }))}
                            /> : "Loading..."}
                            <YearAndMonthFilter
                                month={this.state.filters.month}
                                year={this.state.filters.year}
                                onYearChange={(year) => {
                                    this.handleFilterChange(year, "year");
                                }}
                                onMonthChange={(month) => {
                                    this.handleFilterChange(month, "month");
                                }}
                            />
                        </fieldset>
                    </form>
                    {this.state.resultsLoading ? <div className="status-loading-animated-ellipsis">Loading data</div> : <div>{this.sortedRecords.length} records</div>}
                    {this.canFinalize ? <button onClick={() => this.finalizePayroll()} disabled={this.state.submitting} className={this.state.submitting ? "status-loading-animated-ellipsis" : ""}>
                        {this.state.submitting ? "Submitting" : "Send For Confirmation"}
                    </button> : undefined}
                    {this.payrollFinalization ? <span>Sent to Contractors at {this.payrollFinalization.finalization_time}</span> : undefined}
                    <button
                        onClick={() => this.sendTransferStatement()}
                        disabled={this.state.submitting || !this.readyForTransferStatement}
                        className={this.state.submitting ? "status-loading-animated-ellipsis" : ""}
                        title={"Unlocked on " + this.transferStatementUnlockTime.toISOString().slice(0, 10)}
                    >
                        {!this.readyForTransferStatement
                            ? "Final Invoice on " + this.transferStatementUnlockTime.toISOString().slice(0, 10)
                            : this.state.submitting ? "Submitting" : "Send Final Invoice"}
                    </button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th className="number-field">#</th>
                            <th>Crew Code</th>
                            {this.state.filters.department ? undefined : <th>Dprt. Code</th>}
                            <th className="plainTextField">Name</th>
                            <th></th>
                            <th className="number-field">Amount</th>
                            <th>Link</th>
                            <th>E-Mail</th>
                            <th>Status</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.sortedRecords.map((record, i) => {
                            let commentsPreview = <span>?</span>;
                            if (this.state.employeeCodeToConfirmationMessages) {
                                const messages = this.state.employeeCodeToConfirmationMessages[record.Kennitala.toUpperCase()] ?? [];
                                let lastMessageText = messages.filter(m => m.message_text)[0]?.message_text.slice() ?? "";
                                if (lastMessageText.length > 100) {
                                    lastMessageText = lastMessageText.slice(0, 100) + "...";
                                }
                                commentsPreview = <span
                                    title={messages.map(m => m.author_name + ": " + m.message_text).join("\n")}
                                >{!lastMessageText ? "" : "(" + messages.length + ")"} {lastMessageText}</span>;
                            }
                            return <tr key={record.Numer}>
                                <td className="number-field">{i + 1}</td>
                                <td>{record.Kennitala}</td>
                                {this.state.filters.department ? undefined : <td>{!this.state.navCompanyData ? "Loading..." : this.getDepartmentCode(record)}</td>}
                                <td className="plainTextField">{record.Nafn}</td>
                                <th className="stopped-bank-account-info">{this.getStoppedBankAccountInfo(record)}</th>
                                <td className="number-field">{getConfirmationTotal(record)}</td>
                                <td>
                                    <a href={"/Home/FeeStatementInvoiceConfirmation?" + new URLSearchParams([
                                        ...Object.entries(this.state.filters)
                                            .map(([k,v]) => [k, String(v)]),
                                        ["employeeCode", record.Kennitala],
                                    ])} target="_blank">
                                        <button>View Invoice</button>
                                    </a>
                                </td>
                                <td
                                    className={record.VendorIsUmbrella ? "vendor-is-umbrella" : record.VendorName ? "vendor-email" : undefined}
                                    title={record.VendorIsUmbrella ? "Personal E-Mail: Vendor is Umbrella" : record.VendorName ? "Company E-Mail" : undefined}
                                >{this.getEmail(record)}</td>
                                <td>
                                    {this.getStatusLabels(record)}
                                </td>
                                <td className="plainTextField">{commentsPreview}</td>
                            </tr>;
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}
