import type { Payslip } from "../types/NavisionPayslip";
import type { ConfirmingParty, NavInvoiceConfirmationStatusServer } from "../types/Api/Invoice";

const rankedStatuses = [
    "DISCREPANCY_REPORTED_BY_CLIENT",
    "CHANGED_AFTER_DISCREPANCY_REPORT",
    "DISCREPANCY_REPORTED",
    "REDUCED_AFTER_CONFIRMATION",
    "NOT_FINALIZED",
    "DISCREPANCY_FIXED",
    "SENT_TO_CONTRACTOR_FOR_CONFIRMATION",
    "INCREASED_AFTER_CONFIRMATION",
    "CONFIRMED",
    "CONFIRMED_BY_CLIENT",
    "DATA_LOADING",
    "SUBMITTING",
] as const;

export type RankedStatus = (typeof rankedStatuses)[number];

const getAmount = (record: Payslip) => {
    return +(record["Amount to Pay I"] || 0) + +(record["Amount to Pay II"] || 0);
};

const getConfirmationTotal = (record: Payslip) => {
    return getAmount(record).toFixed(2); // f*ck dinars
};

export const NAV_INVOICE_STATUS_DB_LABELS: Record<NavInvoiceConfirmationStatusServer, string> = {
    "CONFIRMED": "Crew Submitted",
    "CONFIRMED_BY_CLIENT": "Client Confirmed",
    "DISCREPANCY_REPORTED_BY_CLIENT": "Discrepancy Reported by Client",
    "DISCREPANCY_REPORTED": "Discrepancy Reported by Crew",
    "DISCREPANCY_FIXED": "Discrepancy Resolved",
    "SENT_TO_CONTRACTOR_FOR_CONFIRMATION": "Sent to Crew for Confirmation",
};

const getStatusLabel = (status: string) => {
    return {
        ...NAV_INVOICE_STATUS_DB_LABELS,
        "DATA_LOADING": "Loading...",
        "NOT_FINALIZED": "Not Sent",
        "CHANGED_AFTER_DISCREPANCY_REPORT": "Not Sent Back",
        "REDUCED_AFTER_CONFIRMATION": "Reduced After Confirmation",
        "INCREASED_AFTER_CONFIRMATION": "Increased After Confirmation",
        "SUBMITTING": "Submitting...",
    }[status] ?? status;
};

export const getPartTitle = (confirmingParty: ConfirmingParty) => {
    return confirmingParty === "CLIENT" ? "Client" : confirmingParty === "CONTRACTOR" ? "Crew" : "";
};

export const STATUS_FILTERS = [
    {
        filterType: "PENDING",
        buttonText: "Pending approval",
        statusCheck: (status: RankedStatus | undefined) =>
            status !== "CONFIRMED_BY_CLIENT" && status !== "DISCREPANCY_REPORTED_BY_CLIENT",
    } as const,
    {
        filterType: "REJECTED",
        buttonText: "Commented",
        statusCheck: (status: RankedStatus | undefined) =>
            status === "DISCREPANCY_REPORTED_BY_CLIENT",
    } as const,
    {
        filterType: "APPROVED",
        buttonText: "Approved",
        statusCheck: (status: RankedStatus | undefined) =>
            status === "CONFIRMED_BY_CLIENT",
    } as const,
];

export type FilterStatus = (typeof STATUS_FILTERS)[number]["filterType"];


export { getAmount, getConfirmationTotal, getStatusLabel, rankedStatuses };