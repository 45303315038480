import type { AbsoluteMonth } from "@mhc/utils/src/dates";
import { formatDuration } from "../../utils/dates";
import { getMonthDate, getMonthFullName, getMonthShortName, getNumberOfDays } from "@mhc/utils/src/dates";
import type { CellValue, Workbook, Worksheet } from "exceljs";
import type { ManualInvoiceSheetDataIn } from "../Api";
import type { FlyingHoursStatus, ManualInvoiceDayExcelRow, AaiMtxManualInvoiceDayFromSheet } from "../../types/Api/Invoice";
import type { EmployeeInfoWise } from "../../types/EmployeeInfo";

function getDuration(hoursStr: CellValue, minutesStr: CellValue) {
    const totalMinutes =
        Number(hoursStr || "0") * 60 +
        Number(minutesStr || "0");
    if (totalMinutes === 0) {
        return "";
    } else if (Number.isNaN(totalMinutes)) {
        // keep malformed strings as is
        return (hoursStr || "0") + ":" + (minutesStr || "00");
    }
    return formatDuration(totalMinutes);
}

function interpretTime(value: CellValue) {
    if (value instanceof Date) {
        return value.getUTCHours() + ":" + String(value.getUTCMinutes()).padStart(2, "0");
    } else {
        return String(value || "").replace(".", ":");
    }
}

function isMtx1TableFormat(worksheet: Worksheet) {
    const sanityCheckValue = worksheet.getRow(26).getCell("K").value;
    return sanityCheckValue === "12.5 HOURS";
}

function isNormalTableFormat(worksheet: Worksheet) {
    const sanityCheckValue = worksheet.getRow(31).getCell("Q").value;
    return sanityCheckValue === "12 HOURS";
}

function parseMtx1WorkInvoiceXlsx(worksheet: Worksheet, yearMonth: AbsoluteMonth): ManualInvoiceSheetDataIn<AaiMtxManualInvoiceDayFromSheet> {
    const sanityCheckValue = worksheet.getRow(26).getCell("K").value;
    if (sanityCheckValue !== "12.5 HOURS") {
        let message;
        if (isNormalTableFormat(worksheet)) {
            message = "The file you submitted uses 12-HOUR overtime format, but your MTX1 contract requires the 12.5-HOUR overtime format. Please, make sure to submit the template file produced by \"Download Template File\"";
        } else {
            message = "Unexpected MTX1 table format: expected '12.5 HOURS' at cell K26, but got '" + sanityCheckValue + "'. Please, make sure to submit the template file produced by \"Download Template File\"";
        }
        throw new Error(message);
    }
    const CommentBox = String(worksheet.getRow(12).getCell("O").value || "");
    const InvoiceDays: AaiMtxManualInvoiceDayFromSheet[] = [];
    for (let i = 0; i < getNumberOfDays(yearMonth); ++i) {
        const row = worksheet.getRow(27 + i);
        const FlyingHoursStatus: FlyingHoursStatus =
            !!row.getCell("O").value ? "HAS_HOURS" :
            !!row.getCell("Q").value ? "OVER_10_HOURS" :
            "NO_HOURS";
        const baseRowData: ManualInvoiceDayExcelRow = {
            Base: String(row.getCell("B").value || "").slice(0, 20),
            TimeIn1: interpretTime(row.getCell("G").value).replace(/h/i, ":"),
            TimeOut1: interpretTime(row.getCell("H").value).replace(/h/i, ":"),
            TimeIn2: "",
            TimeOut2: "",
            DailyHours: getDuration(
                row.getCell("I").value,
                row.getCell("J").value
            ),
            OverTime: getDuration(
                row.getCell("K").value,
                row.getCell("L").value
            ),
            AdditionalDay: !!row.getCell("M").value,
            Flying: FlyingHoursStatus !== "NO_HOURS",
            PaymentOnOff: !!row.getCell("T").value,
        };
        InvoiceDays.push({
            ...baseRowData,
            ActivityDate: getMonthDate(yearMonth, i + 1),
            FlyingHoursStatus: FlyingHoursStatus,
        });
    }
    return { CommentBox, InvoiceDays };
}

function parseNormalWorkInvoiceXlsx(worksheet: Worksheet, yearMonth: AbsoluteMonth): ManualInvoiceSheetDataIn<AaiMtxManualInvoiceDayFromSheet> {
    const sanityCheckValue = worksheet.getRow(31).getCell("Q").value;
    if (sanityCheckValue !== "12 HOURS") {
        let message;
        if (isMtx1TableFormat(worksheet)) {
            message = "The file you submitted uses 12.5-HOUR overtime format specific to MTX1 contracts, but, since your contract is not MTX1, the table must use 12-HOUR overtime format. Please, make sure to submit the template file produced by \"Download Template File\"";
        } else {
            message = "Unexpected MTX table format: expected '12 HOURS' at cell Q31, but got '" + sanityCheckValue + "'. Please, make sure to submit the template file produced by \"Download Template File\"";
        }
        throw new Error(message);
    }
    const CommentBox = String(worksheet.getRow(12).getCell("Q").value || "");
    const InvoiceDays: AaiMtxManualInvoiceDayFromSheet[] = [];
    for (let i = 0; i < getNumberOfDays(yearMonth); ++i) {
        const row = worksheet.getRow(32 + i);
        const baseRowData: ManualInvoiceDayExcelRow = {
            Base: String(row.getCell("B").value || "").slice(0, 20),
            TimeIn1: interpretTime(row.getCell("G").value).replace(/h/i, ":"),
            TimeOut1: interpretTime(row.getCell("I").value).replace(/h/i, ":"),
            TimeIn2: interpretTime(row.getCell("K").value).replace(/h/i, ":"),
            TimeOut2: interpretTime(row.getCell("M").value).replace(/h/i, ":"),
            DailyHours: getDuration(
                row.getCell("O").value,
                row.getCell("P").value
            ),
            OverTime: getDuration(
                row.getCell("Q").value,
                row.getCell("R").value
            ),
            AdditionalDay: !!row.getCell("S").value,
            Flying: !!row.getCell("V").value,
            PaymentOnOff: !!row.getCell("Y").value,
        };
        InvoiceDays.push({
            ...baseRowData,
            ActivityDate: getMonthDate(yearMonth, i + 1),
            FlyingHoursStatus: baseRowData.Flying ? "HAS_HOURS" : "NO_HOURS",
        });
    }
    return { CommentBox, InvoiceDays };
}

export function parseWorkInvoiceXlsx(workbook: Workbook, yearMonth: AbsoluteMonth, profileNav: EmployeeInfoWise): ManualInvoiceSheetDataIn<AaiMtxManualInvoiceDayFromSheet> {
    const worksheet = workbook.worksheets[0];
    const expectedMonthName = getMonthFullName(yearMonth.month).toUpperCase();
    const actualMonthName = worksheet.getRow(16).getCell("F").value;
    const actualMonthNameStr = String(actualMonthName).toUpperCase().trim();
    if (actualMonthName &&
        actualMonthNameStr !== expectedMonthName &&
        actualMonthNameStr !== getMonthShortName(yearMonth.month).toUpperCase()
    ) {
        throw new Error("Unexpected invoice month value at cell F16: expected '" + expectedMonthName + "', but got '" + actualMonthNameStr + "'");
    }
    if (profileNav.WageContract?.startsWith("MTX1")) {
        return parseMtx1WorkInvoiceXlsx(worksheet, yearMonth);
    } else {
        return parseNormalWorkInvoiceXlsx(worksheet, yearMonth);
    }
}
