import type { ClaimAction } from "../types/Claim";

export function getSoeClaimStatusClass(status: ClaimAction["Status"]) {
    let claimStatusClass = "label ";
    switch (status) {
        case 0 :
            claimStatusClass += "label-primary";
            break;
        case 1 :
            claimStatusClass += "label-success";
            break;
        case 2 :
            claimStatusClass += "label-danger";
            break;
        case 3 :
            claimStatusClass += "label-info";
            break;
        case 5 :
            claimStatusClass += "label-success";
            break;
        default:
            claimStatusClass += "label-uncategorized";
            break;
    }
    return claimStatusClass;
}