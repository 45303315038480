import type {
    INavPayrollFinalization,
    NavInvoiceConfirmationStatus, StoredFinalizationData,
    StoredFinalizationsMapping
} from "../types/Api/Invoice";
import type { CompanyName, PayrollLocatorWithDepartment } from "./Api";
import api from "./Api";
import { buildUrl, HttpResponseError } from "./httpUtils";
import type { AbsoluteMonth } from "../utils/dates";
import  { decrementMonth,getMonthStr,getPastMonth } from "../utils/dates";
import  { getDatePart } from "../utils/dates";
import { formatZonelessDate } from "../utils/dates";
import { getNthWorkingDay } from "../utils/dates";
import { isAirAtlanta } from "./roster/air_atlanta/FeeCalculatorUtils";
import { getPayrollFinalizationsMapping } from "./StaticData";
import type { IsoDate,IsoLikeDateTime } from "../types/utility";
import type { FeeEntryType } from "./ExternalRostersApi";

export const NAV_EPOCH_START = "1753-01-01 00:00:00";

export function isEmptyNavDate(isoStr: IsoDate | IsoLikeDateTime) {
    const datePart = getDatePart(isoStr);
    return isoStr === NAV_EPOCH_START
        || datePart === NAV_EPOCH_START.slice(0, 10);
}

export function formatNavDate(isoStr: IsoDate | IsoLikeDateTime) {
    const datePart = getDatePart(isoStr);
    if (isEmptyNavDate(isoStr)) {
        return "";
    }
    return formatZonelessDate(datePart);
}

/** @deprecated - use getMatchingPayroll() and StaticData.ts */
export function matchesPayroll(
    f: INavPayrollFinalization,
    locator: PayrollLocatorWithDepartment
) {
    const { company, month, year, department } = locator;
    return f.company_name.toUpperCase() === company.toUpperCase()
        && f.month === month
        && f.year === year
        && (!f.department
        || f.department === department);
}

export function getMatchingPayroll<T extends StoredFinalizationData>(
    mapping: StoredFinalizationsMapping<T>,
    { company, year, month, department }: PayrollLocatorWithDepartment
) {
    const payrollState = mapping[company]?.[year]?.[month];
    if (!payrollState) {
        return null;
    }
    if (payrollState.anyDepartment) {
        return payrollState.anyDepartment;
    } else if (!department) {
        return null;
    } else {
        return payrollState.perDepartment[department] ?? null;
    }
}

export function getStatusLabel(status: NavInvoiceConfirmationStatus) {
    return {
        "DATA_LOADING": "Data Loading...",
        "NOT_FINALIZED": "Not Sent",
        "SENT_TO_CONTRACTOR_FOR_CONFIRMATION": "Awaiting Confirmation",
        "CONFIRMED": "Confirmed",
        "CONFIRMED_BY_CLIENT": "Confirmed",
        "DISCREPANCY_REPORTED": "Discrepancy",
        "DISCREPANCY_REPORTED_BY_CLIENT": "Discrepancy Reported",
        "DISCREPANCY_FIXED": "Discrepancy Fixed",
        "SUBMITTING": "Submitting...",
        "REDUCED_AFTER_CONFIRMATION": "Changed",
    }[status] ?? status;
}

export enum InvoiceStatus {
    NotSubmitted = 0, //User has not registered to the portal
    PendingApproval = 1, //User has registered to the portal but no action has been done on the invoice (either by user or admin)
    Expired = 2, //Invoice has expired for the month (Nth working day in configuration
    Rejected = 3, //New - From the Figma file - Gisli
    Approved = 4, //New - From the Figma file - Gisli
    SubmittedForPayroll = 5,
    AddedToPayroll = 6,
}

export type InvoiceStatusStr = keyof typeof InvoiceStatus;

export enum PayEntryKind {
    PAY = 1,
    CLAIM = 2,
}

export function isSoePayEntry(feeEntryType: FeeEntryType | number) {
    return feeEntryType >= 5000 && feeEntryType <= 5999;
}

async function initializeWorkInvoiceLink() {
    const feeStatementLink = document.getElementsByClassName("fee-statement-invoice-confirmation-link")[0];
    const statusHolder = document.querySelector(".fee-statement-invoice-confirmation-link .status-holder");
    const monthHolder = document.querySelector(".fee-statement-invoice-confirmation-link .month-holder");
    if (!feeStatementLink || !statusHolder || !monthHolder) {
        return; // rendered only for contractors
    }
    const setStatus = (status: NavInvoiceConfirmationStatus | "SHOWING_PAST_MONTH") => {
        feeStatementLink.setAttribute("data-status", status);
        statusHolder.textContent =
            status === "SENT_TO_CONTRACTOR_FOR_CONFIRMATION" ? "Ready" :
            status === "NOT_FINALIZED" ? "Not Sent Out" :
            status === "SHOWING_PAST_MONTH" ? "Past Month" :
            getStatusLabel(status);
    };
    const employee = window.EMPLOYEE_INFO;
    if (!employee) {
        return;
    }
    const setPayrollMonth = (payrollMonth: AbsoluteMonth) => {
        monthHolder.textContent = getMonthStr(payrollMonth);
        feeStatementLink.setAttribute("href", buildUrl("/Home/FeeStatementInvoiceConfirmation", {
            company: employee.CompanyName,
            employeeCode: employee.No_,
            ...payrollMonth,
        }));
    };
    const payrollFinalizationsMapping = await getPayrollFinalizationsMapping();
    const pastMonth = getPastMonth(new Date());
    const payrollLocator = {
        company: employee.CompanyName,
        ...pastMonth,
    };
    const invoiceLocator = {
        employeeCode: employee.No_,
        ...payrollLocator,
    };
    let invoiceData;
    try {
        invoiceData = await api.Invoice.GetFeeStatementInvoice(invoiceLocator);
    } catch (error) {
        if ((error instanceof HttpResponseError) && error.response.status === 404) {
            setPayrollMonth(decrementMonth(pastMonth));
            setStatus("SHOWING_PAST_MONTH");
            return;
        } else {
            throw error;
        }
    }
    setPayrollMonth(pastMonth);
    const finalization = getMatchingPayroll(payrollFinalizationsMapping, {
        ...payrollLocator,
        department: +(employee.Department || 0),
    });
    if (!finalization) {
        setStatus("NOT_FINALIZED");
    } else {
        setStatus("SENT_TO_CONTRACTOR_FOR_CONFIRMATION");
    }
}

export async function initializeHeader() {
    initializeWorkInvoiceLink();
}

export function transferStatementUnlockTime({ year, month }: AbsoluteMonth): Date {
    // we make the payment on 10th working day, so on the 9th it must be finalized
    const startDate = new Date(
        year + "-" +
        String(month).padStart(2, "0") +
        "-01"
    );
    startDate.setUTCMonth(startDate.getUTCMonth() + 1);
    return getNthWorkingDay(startDate, 9);
}

export function parseCOMPANYNAME(COMPANYNAME: CompanyName) {
    if (COMPANYNAME.toUpperCase() === "AIRBORNE - MALTA") {
        return {
            partnerCompany: "APM",
            client: "AAI",
            currency: "USD",
        };
    }
    const match = COMPANYNAME.match(/^(\w+)\s*-\s*(\w+)([A-Z]{3})$/);
    if (!match) {
        return null;
    }
    const [, partnerCompany, client, currency] = match;
    return { partnerCompany, client, currency };
}

export function getPartnerCompanyName(COMPANYNAME: CompanyName) {
    if (isAirAtlanta(COMPANYNAME)) {
        return "Airborne";
    }
    return parseCOMPANYNAME(COMPANYNAME)?.partnerCompany;
}