
import {
  addCompanyFieldSetup,
  deleteCompanyFieldsSetupJobtitle,
  loadCompaniesWise,
  loadCompanyFieldSetup,
  loadCompanyFieldsSetupJobtitle, loadCompanySoeFieldSetup,
  loadJobTitlesWise
} from "../features/Company";

import ErrorPage from "./ErrorPage";

let React = require('react');
import {connect, Provider} from "react-redux";

import {confirmAlert} from 'react-confirm-alert';

let _ = require('lodash');
import InvoiceMenu from '../components/InvoiceMenu';

const mapStateToProps = (state) => {
  return {
    company: state.company
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadProfile: (payload) => dispatch(loadProfile(payload)),
    loadCompaniesWise: () => dispatch(loadCompaniesWise()),
    loadJobTitlesWise: () => dispatch(loadJobTitlesWise()),
    loadCompanyFieldSetup: (payload) => dispatch(loadCompanyFieldSetup(payload)),
    loadCompanyFieldsSetupJobtitle: (payload) => dispatch(loadCompanyFieldsSetupJobtitle(payload)),
    deleteCompanyFieldsSetupJobtitle: (payload) => dispatch(deleteCompanyFieldsSetupJobtitle(payload)),
    addCompanyFieldSetup: (payload) => dispatch(addCompanyFieldSetup(payload)),
    loadCompanySoeFieldSetup: (payload) => dispatch(loadCompanySoeFieldSetup(payload)),
  }
};
export const views = {
  Table: 0,
  Form: 1
}

class InvoicesUsersFields extends React.Component {
  //displayName: 'InvoicesUsersFields',

  constructor(props) {
    super(props);
    this.state = {
      madeFirstCall: false,
      isAdmin: this.props.isAdmin,
      viewForm: views.Table,
      viewNewFormName: "",
      Fields: this.props.Fields,
      SelectedFields: [],
      SelectedSoeFields: [],
      SelectedJobTitle: '',
      SelectedCompany: '',
      SelectedType: '1',
      sortBy: {
        column: "",
        order: ""
      }
    };
  };

  order(employees) {
    if (this.state.sortBy.column != "") {
      return _.orderBy(employees, [this.state.sortBy.column], [this.state.sortBy.order]);
    }

    return employees;
  };

  componentDidMount() {
    this.props.loadCompaniesWise().unwrap()
      .then(() => {
        this.setState({loading: false});
      })
      .catch(() => {
      })

    this.props.loadJobTitlesWise().unwrap()
      .then(() => {
        this.setState({loading: false});
      })
      .catch(() => {
      })
    if (!this.state.madeFirstCall)
      this.setState({
        madeFirstCall: true,
        view: "All",
        ShowAdminsFields: "none"
      });
  };

  createSelectItems() {
    let items = [];
    if (this.props.company.companiesWise != null) {

      items.push(<option key={-1} value="">Choose Client</option>);
      for (let i = 0; i < parseInt(this.props.company.companiesWise.length); i++) {
        let comp = this.props.company.companiesWise[i];
        items.push(<option key={comp.Code} value={comp.Code}>{comp.DisplayName}</option>);
      }
    }
    return items;
  };

  _ChangeCompany(e) {
    if (!this.state.loading) {
      const payload = {
        companyName: e.target.value,
        type: this.state.SelectedType
      }
      this.props.loadCompanyFieldSetup(payload).unwrap()
        .then(() => {
          this.setState({loading: false});
        })
        .catch(() => {
        })

      this.setState({
        viewNewFormName: e.target.value,
        SelectedCompany: e.target.value
      });
    }
  };

  _ChangeJobtitle(e) {
    if (!this.state.loading) {
      this.setState({
        SelectedJobTitle: e.target.value,
        SelFields: undefined
      });
    }
  };

  changeType(e) {
    if (e.target.value == "2") {
      const payload = {
        companyName: this.state.SelectedCompany
      }
      this.props.loadCompanySoeFieldSetup(payload).unwrap()
        .then(() => {
          this.setState({loading: false});
        })
        .catch(() => {
        })
    }

    if (!this.state.loading) {
      this.setState({
        SelectedType: e.target.value,
      });
    }
  };

  _generateCompanyList() {
    return (
      <div className="col-xs-6"
           style={{paddingLeft: "0", display: this.state.ShowAdminsFields == "table-cell" ? "none" : "table-cell"}}>
        <select onChange={(e) => this._ChangeCompany(e)} value={this.state.SelectedCompany}
                style={{fontSize: "20px", width: "100%"}}>
          {this.createSelectItems()}
        </select>
      </div>
    );
  };

  _getHeader() {
    return (<div className="invoice-header">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1>Fields - setup</h1>
          </div>
        </div>
      </div>
    </div>);
  };

  _createInvoiceMenu() {
    return (<InvoiceMenu selected="Fields"/>);
  };

  showFieldsForm(e) {
    if (!this.state.loading) {
      this.setState({
        viewUpdateFormName: e.target.attributes["data-companyname"].value,
        viewUpdateFormJobtitle: e.target.attributes["data-jobtitle"].value,
        SelectedJobTitle: e.target.attributes["data-jobtitle"].value,
        SelectedFields: [],
        SelectedSoeFields: [],
        SelFields: undefined,
        SelSoeFields: undefined,
        ////TODO:Setja inn
        // SelectedCompany: "Airborne - Malta",
        // SelectedJobTitle: "Maintanice",
        viewForm: views.Form
      });
    }
  };

  showFieldsUpdateForm(e) {

    if (!this.state.loading) {
      this.setState({
        viewUpdateFormName: e.target.attributes["data-companyname"].value,
        viewForm: views.Form
      });
    }
  };

  _generateJobTitleList() {
    return (
      <div className="row" style={{display: this.state.ShowAdminsFields == "table-cell" ? "none" : "table-cell"}}>
        <select onChange={(e) => this._ChangeJobtitle(e)} value={this.state.SelectedJobTitle}
                style={{fontSize: "20px", minWidth: "250px", width: "auto"}}>
          {this.createSelectJobTitleItems()}
        </select>
      </div>
    );
  };

  selectSoeOrWorkInvoice() {
    let items = [];
    items.push(<option key="1" value="1">Work Invoice</option>);
    items.push(<option key="2" value="2">SoE</option>);
    return (
      <div className="row" style={{display: this.state.ShowAdminsFields == "table-cell" ? "none" : "table-cell"}}>
        <select onChange={(e) => this.changeType(e)} value={this.state.SelectedType}
                style={{fontSize: "20px", minWidth: "250px", width: "auto"}}>
          {items}
        </select>
      </div>
    );
  };

  createSelectJobTitleItems() {
    let items = [];
    items.push(<option key="-1">Choose Department</option>);
    this.props.company.jobTitlesWise.map((e) => {
      if (e.Code !== "")
        items.push(<option key={e.Code}>{e.Name}</option>);
      // return (<EmployeeLine employee={e} ShowAdminsFields={this.state.ShowAdminsFields} key={index} />);
    });

    return items;
  };

  handleChange(e) {
    if (!this.state.loading) {
      let list = this.state.SelFields;
      if (!this.state.loading) {
        if (e.target.checked) {

          if (e.target.checked)
            if (!list.includes(e.target.name)) {
              this.setState({
                SelFields: this.state.SelFields.concat(e.target.name)
              });
            }
        } else {
          let filteredArray = list.filter(item => item !== e.target.name);
          this.setState({SelFields: filteredArray});
        }
      }
    }
  };

  handleSoeFieldChange(e) {
    if (!this.state.loading) {
      let list = this.state.SelSoeFields;
      if (!this.state.loading) {
        if (e.target.checked) {

          if (e.target.checked)
            if (!list.includes(e.target.name)) {
              this.setState({
                SelSoeFields: this.state.SelSoeFields.concat(e.target.name)
              });
            }
        } else {
          let filteredArray = list.filter(item => item !== e.target.name);
          this.setState({SelSoeFields: filteredArray});
        }
      }
    }
  };

  _generateFieldsList() {
    let items = [];
    if (this.state.SelFields == undefined) {
      const payload = {
        companyName: this.state.SelectedCompany,
        jobTitle: this.state.SelectedJobTitle,
        type: 1
      };
    this.props.loadCompanyFieldsSetupJobtitle(payload).unwrap()
      .then((originalPromiseResult) => {
        this.setState({loading: false, SelFields: originalPromiseResult});
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      })}


    if (this.state.Fields != null)

      for (let i = 0; i < parseInt(this.state.Fields.split(" | ").length); i++) {
        let field = this.state.Fields.split(" | ")[i];
        if (field !== "") {
          let checked = false;
          if (this.state.SelFields !== undefined) {
            checked = this.state.SelFields.includes(field.split('-')[2]);
          }
          if (field.split('-')[3] == 1) {
            items.push(<div className="col-sm-9" style={{fontSize: "20px", marginBottom: "10px"}}><input type="checkbox"
                                                                                                         checked={checked}
                                                                                                         onChange={(e) => this.handleChange(e)}
                                                                                                         name={field.split('-')[2]}
                                                                                                         id={field.split('-')[2]}
                                                                                                         data-id={field.split('-')[2]}
                                                                                                         data-Code={field.split('-')[2]}
                                                                                                         style={{display: "inline-block"}}
                                                                                                         className="form-control"/> {field.split('-')[1]}
            </div>);
          }
        }
      }
    return (
      <div>

        {items}

      </div>
    );
  };

  _generateSoeFieldsList() {
    let items = [];
    if (this.state.SelSoeFields == undefined) {
      const payload = {
        companyName: this.state.SelectedCompany,
        jobTitle: this.state.SelectedJobTitle,
        type: 2
      };
      this.props.loadCompanyFieldsSetupJobtitle(payload).unwrap()
        .then((originalPromiseResult) => {
          this.setState({loading: false, SelSoeFields: originalPromiseResult});
        })
        .catch(() => {
        })
    }
    if (this.state.Fields != null)

      for (let i = 0; i < parseInt(this.state.Fields.split(" | ").length); i++) {
        let field = this.state.Fields.split(" | ")[i];
        if (field !== "") {
          let checked = false;
          if (this.state.SelSoeFields !== undefined) {
            checked = this.state.SelSoeFields.includes(field.split('-')[2]);
          }
          if (field.split('-')[3] == 2) {
            items.push(<div className="col-sm-9" style={{fontSize: "20px", marginBottom: "10px"}}><input type="checkbox"
                                                                                                         checked={checked}
                                                                                                         onChange={(e) => this.handleSoeFieldChange(e)}
                                                                                                         name={field.split('-')[2]}
                                                                                                         id={field.split('-')[2]}
                                                                                                         data-id={field.split('-')[2]}
                                                                                                         data-Code={field.split('-')[2]}
                                                                                                         style={{display: "inline-block"}}
                                                                                                         className="form-control"/> {field.split('-')[1]}
            </div>);
          }
        }
      }
    return (
      <div>

        {items}

      </div>
    );
  };

  showTable() {
    if (!this.state.loading) {
      this.setState({
        viewForm: views.Table
      });
    }
  };

  SaveFields() {
    let fields = '';
    if (this.state.SelectedType === "2") {
      for (let i = 0; i < this.state.SelSoeFields.length; i++) {
        fields = fields + this.state.SelSoeFields[i] + '|';
      }
    } else {
      for (let i = 0; i < this.state.SelFields.length; i++) {
        fields = fields + this.state.SelFields[i] + '|';
      }
    }
    if (fields === '')
      fields = "null";

    const payload = {
      companyName: this.state.SelectedCompany,
      jobTitle: this.state.SelectedJobTitle,
      fields: fields,
      type: this.state.SelectedType
    };

    this.props.addCompanyFieldSetup(payload).unwrap()
      .then(() => {
        this.setState({loading: false});
      })
      .catch(() => {
      })
    const loadFieldPayload = {
      companyName: this.state.SelectedCompany,
      type: 1
    }
    this.props.loadCompanyFieldSetup(loadFieldPayload).unwrap()
      .then(() => {
        this.setState({loading: false});
      })
      .catch(() => {
      })
    const loadSoeFieldPayload = {
      companyName: this.state.SelectedCompany
    }
    this.props.loadCompanySoeFieldSetup(loadSoeFieldPayload).unwrap()
      .then(() => {
        this.setState({loading: false});
      })
      .catch(() => {
      })
    if (!this.state.loading) {
      this.setState({
        SelFields: undefined,
        SelSoeFields: undefined,
        SelectedJobTitle: '',
        viewForm: views.Table
      });
    }
  };

  _createFieldsForm() {



    return (
      <div className="row col-xs-12">
        <div className="col-xs-12">
          <h3>Select Department and fields for company: {this.state.viewUpdateFormName}</h3>
          <hr className="noMargin"/>
          {this._generateJobTitleList()}

          <br/><br/>
        </div>
        <div className="col-xs-12">
          <h3>Apply to</h3>
          <hr/>
          {this.selectSoeOrWorkInvoice()}
          <br/><br/>
        </div>

        <div className="col-xs-12">
          <h3>Select Fields</h3>
          <hr/>
          <div className=" col-md-12">
            <div className="form-group">
              <div className="table-responsive admin ActivityFieldsList">

                {this.state.SelectedType === "2" ? this._generateSoeFieldsList() : this._generateFieldsList()}
              </div>
            </div>
          </div>
        </div>
        <div className="row col-md-12" style={{marginTop: "40px"}}>
          <a className="btn btn-warning" href="#"
             style={{display: this.state.SelectedJobTitle == "" ? "none" : "inline-block"}}
             onClick={(e) => this.SaveFields(e)}>Save</a>
          <a className="btn btn-warning" href="#" onClick={(e) => this.showTable(e)}>Cancel</a>
        </div>
        <div className="row col-md-12">
        </div>
      </div>);
  };

  DeleteField(e) {
    const comp = e.target.attributes["data-company"].value;
    const job = e.target.attributes["data-jobtitle"].value;

    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to delete: ' + e.target.attributes["data-jobtitle"].value + " from company: " + e.target.attributes["data-company"].value,
      buttons: [
        {
          label: 'Delete',
          onClick: () => {
            const payload = {
              companyName: comp,
              jobTitle: job,
            };
            this.props.deleteCompanyFieldsSetupJobtitle(payload).unwrap()
              .then(() => {
                const fieldSetupPayload = {
                  companyName: comp,
                  type: 1
                }
                this.setState({loading: false});
                this.props.loadCompanyFieldSetup(fieldSetupPayload).unwrap()
                  .then(() => {
                    this.setState({loading: false});
                  })
                  .catch(() => {
                  })
                this.props.loadCompanySoeFieldSetup(comp).unwrap()
                  .then(() => {
                    this.setState({loading: false});
                  })
                  .catch(() => {
                  })
                this._createFieldsTable();
              })
              .catch(() => {
              })
          }
        },
        {
          label: 'Cancel',
          onClick: () => {
          }
        }
      ]
    });
  };

  _createFieldsTable() {
    const self = this;
    let records = self.props.company.companyFieldSetup && self.props.company.companyFieldSetup.length > 0 ? self.props.company.companyFieldSetup.map((r) => {
      return (<tbody>
        <tr key={r.id}>

          <td>{r.jobtitle}</td>
          <td><a data-companyname={r.company_name} data-jobtitle={r.jobtitle} onClick={(e) => this.showFieldsForm(e)}
                 href="#">Edit</a></td>
          <td><a style={{display: "inline-block"}}
                 onClick={(e) => this.DeleteField(e)} data-company={r.company_name} data-jobtitle={r.jobtitle} href="#">Delete</a>
          </td>
        </tr>
        {/* <tr><td colSpan="3">{r.fields.split('|').map((k) => <span className="fieldList">{k}</span> )}</td></tr> */}
        </tbody>
      );
    })   : this.state.SelectedCompany == "" ? <tbody>
    <tr>
      <td>Select Client</td>
    </tr>
    </tbody> : <tbody>
    <tr>
      <td>No Department assigned</td>
    </tr>
    </tbody>;
    return (<div>
      <div className="col-xs-12">
        <div className="col-xs-9">

          <div className="row">
            <h3>Select Client</h3>
          </div>
          <div className="row">
            {this._generateCompanyList()}
          </div>
        </div>
        <div className="col-xs-3">
          <span className="btn btn-warning"
                style={{marginTop: "30px", display: this.state.viewNewFormName == "" ? "none" : "inline-block"}}
                data-jobtitle="" data-companyname={this.state.viewNewFormName} onClick={(e) => this.showFieldsForm(e)}>Assign new Department</span>

        </div>
      </div>
      <div className="col-xs-12">
        <div className="table-responsive">
          <div className="header">
            <h3>
              Departments
            </h3>
          </div>
          <table className="invoices invoice-months table table-striped table-condensed">

            {records}
          </table>
        </div>
      </div>
    </div>);
  };

  hasAdminPrivileges() {
    const cleanIsAdmin = this.state.isAdmin === "True" || this.state.isAdmin === "true";
    return cleanIsAdmin;
  };

  render() {
    if (!this.hasAdminPrivileges()) {
      return <ErrorPage/>
    }

    let content = null;
    if (this.state.viewForm == views.Form)
      content = this._createFieldsForm();
    else
      content = this._createFieldsTable();
    if (!this.state.madeFirstCall || this.props.company.companiesWise == undefined) {
      return (<div className="invoice-wrapper">
        {this._getHeader()}
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h1
                className="loading">{!this.state.madeFirstCall ? "Loading list of Clients..." : "No Clients found"}</h1>
            </div>
          </div>
        </div>
      </div>);
    } else
      return ((<div className="invoice-wrapper admin-wrapper">
          {this._getHeader()}
          <div className="container">
            <div className="row">
              <div className="col-xs-3 invocie-menu">
                {this._createInvoiceMenu()}

              </div>
              <div className="col-xs-9">


                <div className="row col-xs-12">
                  {content}
                  <div className="col-xs-3">
                  </div>
                  <div className="col-xs-9 text-right">

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>)
      );
  }
}

const wrapper = (props) => {
  return <Provider store={window.store}>{<InvoicesUsersFields {...props}/>}</Provider>;
}
module.exports = connect(
  mapStateToProps,
  mapDispatchToProps
)(wrapper);
