import api from "../features/Api";
import { isObscuredTransportError } from "../features/httpUtils";
import { neverNull } from "@mhc/utils/src/typing";
import { Simulate } from "react-dom/test-utils";
import error = Simulate.error;

/**
 * many libraries throw objects that do not extend Error, this function attempts
 * to extract the message from any kind of error object using popular conventions
 * like having `toString()` implementation or `message` property
 */
export function stringifyError(error: unknown): string {
    if (!error) {
        return "(empty error)";
    } else if (isObscuredTransportError(error)) {
        return "No connection, try refreshing the page. " + (error as { message: string }).message;
    } else if (error && typeof error === "object"
            && "message" in error
            && typeof (error as { message: unknown }).message === "string"
    ) {
        return (error as { message: string }).message;
    } else if (error && typeof error === "object"
            && "errorMessage" in error
            && typeof (error as { errorMessage: unknown }).errorMessage === "string"
    ) {
        return (error as { errorMessage: string }).errorMessage;
    } else if (typeof error === "string") {
        return error;
    } else if (error + "" !== "[object Object]") {
        return error + "";
    } else {
        return "Unknown format error: " + JSON.stringify(error);
    }
}

function reportJsError(error: Error) {
    if (error.stack?.includes("chrome-extension") ||
        error.message.includes("googletag") ||
        error.message.includes("@webkit-masked-url://hidden/") ||
        error.stack?.includes("@webkit-masked-url://hidden/")
    ) {
        // see https://github.com/getsentry/sentry-javascript/discussions/5875
        return; // stupid browsers
    }
    const isAppError =
        error.stack?.includes("https://userportal.mhcaviation.com/Scripts/") ||
        error.stack?.includes("http://localhost:") ||
        error.message.includes("No connection to server");
    api.System.ReportJsError({
        message: (isAppError ? "APP: " : "3pty: ") + error.message,
        pathname: window.location.pathname + window.location.search,
        stack: error.stack ?? null,
        buildTime: process.env.BUILD_TIME ?? neverNull(),
    }).catch(error => console.error("Failed to report JS error due to", error)); // catch errors inside error handler to avoid deadloops
    if (isAppError) {
        if (window.toastr) {
            window.toastr.error(error.message, "", {
                timeOut: 20000,
            });
        } else {
            alert(error.message);
        }
    }
}

export function reportUntypedJsError(error: unknown) {
    if (error instanceof Error) {
        reportJsError(error);
    } else {
        reportJsError(new Error(stringifyError(error)));
    }
}

export const globalJsErrorHandler = (event: Omit<ErrorEvent, "error"> & { error: unknown }) => {
    const { message, filename, lineno, colno, error } = event;
    console.error("global js error", { message, filename, lineno, colno, error });
    const errorOrigin = filename + ":" + lineno + ":" + colno;
    let errorInstance: Error;
    if (error instanceof Error) {
        errorInstance = error;
        errorInstance.stack = errorOrigin + "\n" + (errorInstance.stack ?? "");
    } else {
        errorInstance = new Error(message);
        errorInstance.stack = errorOrigin;
    }
    errorInstance.message += " at " + filename.replace(/.*\//, "");
    reportJsError(errorInstance);
};

export function globalUnhandledRejectionHandler(event: Omit<PromiseRejectionEvent, "reason"> & { reason: unknown }) {
    const { promise, reason } = event;
    console.info("Got an unhandled promise rejection somewhere in code", reason);
    console.error(reason);
    let errorInstance: Error;
    if (reason instanceof Error) {
        errorInstance = reason;
    } else {
        errorInstance = new Error(stringifyError(event.reason));
        errorInstance.stack = "";
    }
    errorInstance.message += " (UPR)";
    reportJsError(errorInstance);
}
