import * as React from "react";

interface SoeDocumentFilePreviewProps {
  fileName: string,
  viewUrl: string,
}

/**
 * @property {SoeDocumentFilePreviewProps} props
 */
class SoeDocumentFilePreview extends React.Component<SoeDocumentFilePreviewProps, {}> {
  render() {
    const embedViewUrl = this.props.viewUrl + "#scrollbar=0&toolbar=0&navpanes=0";
    const fileViewer = this.props.fileName.toLowerCase().endsWith(".pdf") ?
        <iframe src={embedViewUrl}></iframe> :
        this.props.fileName.toLowerCase().endsWith(".xlsx") || this.props.fileName.toLowerCase().endsWith(".xls") ? <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?${new URLSearchParams({
                    src: `https://userportal.mhcaviation.com${this.props.viewUrl}`
                })}`}
            /> : <img src={this.props.viewUrl} alt="soe file" />;
    return <a className="receiptPreviewViewport" href={this.props.viewUrl} target="_blank">
      {fileViewer}
    </a>;
  }
}

export default SoeDocumentFilePreview;