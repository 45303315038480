import type {
    BeneficiaryBankAccount, BoxedPayslip,
    NavCompanyData,
    PayEntry,
    Payslip, StoredSalaryJournalRecordNav
} from "../types/NavisionPayslip";
import type {
    INavInvoiceConfirmation, InvoiceAction,
    ManualInvoiceDetails, NavInvoiceConfirmationStatusServer,
    NavInvoiceMessageModel, NavInvoiceMessageWithLocatorModel
} from "../types/Api/Invoice";
import type {
    AddApprovedSoeClaimToPayrollPayload, ApproveSoeClaimPayload, ClaimAction, ClaimBaseData, ClaimCategory, ClaimFromServer, ClaimSubmissionData } from "../types/Claim";
import type WiseCodeValue from "../types/WiseCodeValue";
import type { EmployeeCreationInfo, EmployeeInfoWise, EmployeeUpdateInfo,NavContractRecord, NavEmployeeBase } from "../types/EmployeeInfo";
import type EmployeeInfo from "../types/EmployeeInfo";
import type { StoredFinalization } from "../types/Api/Invoice";
import type { FullPayslip } from "../types/NavisionPayslip";
import {
    assertSuccessResponse, buildUrl, fetchOrFail,
    getJson, getOrReuseJson,
    getResponse,
    postForJson,postForSuccess,
    postForSuccessStatus,
    postForSuccessStatusToast, sendForSuccess
} from "./httpUtils";
import type {
    FeeEntryTypeRecord,
    RdbContractRecord,
    SalaryJournalRecord, WageContract, WageContractFeeEntryScale,
    WageContractFixedLine
} from "./ExternalRostersApi";
import type OpenExchangeRates from "../types/OpenExchangeRates";
import type { Company } from "../types/Api/Company";
import type { LeonCrawlPersonMonthRoster, LeonPersonMonthRoster } from "../types/Api/Roster/Leon/LeonPersonMonthRoster";
import type InvoiceComment from "../types/Api/InvoiceComment";
import type { ReportJsErrorRequest } from "../types/Api/System";
import type { OfficerFlightDeckFeesPersonAaiFd,OfficerFlightDeckFeesPersonUntyped, SccmFeeScale, SignedDocumentRow } from "../types/Api/Contract";
import type { AbsoluteMonth } from "@mhc/utils/src/dates";
import { entries,typed } from "@mhc/utils/src/typing";
import type Currency from "../types/Currency";
import type { ManualInvoiceSubmissionData } from "../components/ManualInvoiceDetailsForm";
import type { CrewCode } from "@mhc/utils/types/nav";
import JsonResponseAsyncIterator from "../utils/JsonResponseAsyncIterator";
import type { ActivitySetup } from "../types/reducers";
import type { CrewMember } from "../types/Api/Roster/Leon/CrewMember";
import type { CompanyName } from "@mhc/utils/types/nav";
import type { Entry, IsoDate } from "@mhc/utils/types/utility";
import type { IsoLikeDateTimeWithOffset } from "../types/utility";

export type PayrollLocator = AbsoluteMonth & {
    company: CompanyName,
};

export type PayrollLocatorWithDepartment = PayrollLocator & {
    department: number,
};

export type PayrollLocatorWithDepartmentEntry = Entry<PayrollLocatorWithDepartment>;

type FinalizeNavPayrollPayload = PayrollLocatorWithDepartment & {
    confirmationDeadline: IsoLikeDateTimeWithOffset,
};

type ManualInvoiceLocator = AbsoluteMonth & {
    employeeId: number,
};

export type SubmitManualWorkInvoiceForApprovalPayload = {
    year: number,
    month: number,
    comment: string,
};

type InvoiceChangeInvoiceStatusRequest = {
    invoiceId: number,
    invoiceStatus: number,
    text: string,
};

export type EmployeeLocator = {
    company: CompanyName,
    employeeCode: CrewCode,
};

export type NavInvoiceLocator = EmployeeLocator & {
    year: number,
    month: number,
};

type NavInvoiceConfirmationTotal = {
    confirmationTotal: number | string,
};

export type NavInvoiceScreenParams = NavInvoiceLocator & {
    accessToken?: string,
};

/** @see NavInvoiceConfirmationRequest.cs */
type ConfirmNavInvoiceRequest = NavInvoiceScreenParams & NavInvoiceConfirmationTotal;

/** @see NavInvoiceClientConfirmationRequest.cs */
type ClientConfirmNavInvoiceRequest = NavInvoiceLocator & NavInvoiceConfirmationTotal;

export type Department = (WiseCodeValue & { CompanyName: string });

export type CompanyWise = {
    /** "F2R - EAFEUR" */
    Code: string,
    /** @deprecated - on some companies sometimes it's same as Code, on some same as DisplayName */
    Name: string,
    /** "Electra Airways" */
    DisplayName: string,
    /**
     * 29
     * @deprecated - please, don't rely on this: it is populated manually by payroll
     *     officers - a much more reliable way to get the Portal ID is to map by the `Code` field
     * @see getAllCompanies()
     */
    Id: number,
};

type ReportFixedInconsistencyInNavInvoiceRequestBase = NavInvoiceLocator & {
    discrepancyFixedComment: string,
    onlyFor?: "CLIENT" | null,
};

type ReportFixedInconsistencyInNavInvoiceRequest = ReportFixedInconsistencyInNavInvoiceRequestBase & {
    totalChanged: boolean,
    contractorEmail: string,
    contractorFirstName: string,
    subsidiaryDisplayName: string,
    previousStatus: NavInvoiceConfirmationStatusServer,
};

export type CalculateFeesForSheetResponse = {
    journalRecords: SalaryJournalRecord[],
};

type ReplyToClientReportedDiscrepancyRequest = NavInvoiceLocator & {
    replyComment: string,
    onlyFor: "CLIENT" | null,
};

type AddCommentRequest = NavInvoiceLocator & {
    commentText: string,
};

export type ManualInvoiceSheetDataIn<TInvoiceDay extends Partial<ManualInvoiceDetails>> = {
    CommentBox: string,
    InvoiceDays: TInvoiceDay[],
};

/** @see ManualInvoiceSheetData.cs */
export type ManualInvoiceSheetDataOut = {
    InvoiceId: number,
    CommentBox: string,
    InvoiceDays: ManualInvoiceDetails[],
    CalculatedJournalRecords?: SalaryJournalRecord[],
};

/** @see AddManualInvoiceFromSheetRequest.cs */
export type AddManualInvoiceFromSheetRequest<TInvoiceDay extends Partial<ManualInvoiceDetails>> = ManualInvoiceSheetDataIn<TInvoiceDay> & {
    InvoiceId: number,
};

export type RdbPersonData = {
    rdbPersonId: number,
    rdbLink: string | null,
    rdbNia: "RIX" | "VNO, SFO,JED" | string | null,
};

type UpdateCompanyParams = {
    company: string,
    code: string,
    isSOE: boolean | null,
    isDepartment: boolean | null,
    isActive: boolean | null,
    isTimeLog: boolean | null,
};

export type ClientMappingEntry = {
    rdb_id: number,
    rdb_consultant_id: number,
    navision_code?: CompanyName,
};

export type FileUploadResponse = {
    fileName: string,
    accessToken: string,
    email: string,
};

export type RdbClient = {
    ClientID: 429 | number,
    Company: "Air Atlanta Icelandic" | string,
    /**
     * 27 = Active
     * 28 = Inactive
     * 29 = Non Client
     */
    StatusId: null | 27 | 28 | 29,
};

type FieldsSetupRequestBase = {
    company: CompanyName,
    /**
     * 1 = Work Invoices
     * 2 = SoE
     */
    type: 1 | 2,
};

/** @see Receipt.cs */
export type Receipt = {
  Name: string,
  AccessToken: string,
  Email: string,
};

const api = {
    Employee: {
        GetEmployeeById: (params: { employeeId: number }) => getJson<EmployeeInfo>
            ("/api/employee/GetEmployeeById", params),
        /**
         * you're probably wondering what is the difference between these two functions...
         * LoadProfile() takes data from Navision when possible, whereas GetEmployeeById() will only get locally stored employee data
         */
        LoadProfile: (params: { employeeId: number }) => getJson<EmployeeInfoWise>
            ("/api/employee/LoadProfile", params),
        LoadProfileNav: (params: EmployeeLocator) => {
            const { company, employeeCode } = params;
            const narrowed: EmployeeLocator = { company, employeeCode };
            return getOrReuseJson<EmployeeInfoWise>(
                "/api/employee/LoadProfileNav", narrowed
            );
        },
        employee_company_identities: () => getJson<EmployeeLocator[]>
            ("/api/employee/employee_company_identities"),
        GetRdbData: (params: NavInvoiceLocator) => getJson<RdbPersonData>
            ("/api/employee/GetRdbData", params),
        GetAllActiveRdbContractors: () => getJson<RdbContractRecord[]>("/api/employee/GetAllActiveRdbContractors"),
        GetAllActiveNavContractors: () => getResponse("/api/employee/GetAllActiveNavContractors")
            .then(response => JsonResponseAsyncIterator<NavContractRecord>(response)),
        UpdateEmployee: (params: EmployeeUpdateInfo) => postForSuccessStatus("/api/employee/UpdateEmployee", params),
        RegisterAdmin: (params: EmployeeCreationInfo) => postForSuccessStatus("/api/employee/RegisterAdmin", params),
        GetAllEmployees: () => getJson<EmployeeInfo[]>("/api/employee/GetAllEmployees"),
        GetAllEmployeesWise: (params: { company: string | "" }) => getJson<EmployeeInfo[]>("/api/employee/GetAllEmployeesWise", params),
    },
    Company: {
        GetCompanyFieldSetupByCompany: (params: FieldsSetupRequestBase) => getJson<{}>("/api/company/GetCompanyFieldSetupByCompany", params),
        GetCompanyFieldSetupByCompanyAndJobtitleNew: (params: FieldsSetupRequestBase & { jobtitle: string }) => getJson<string[]>
            ("/api/company/GetCompanyFieldSetupByCompanyAndJobtitleNew", params),
        /** @return - the list of jobs in a company and their activities configuration */
        GetCompanyActivitySetupByCompany: (params: { company: CompanyName }) => getJson<ActivitySetup[]>
            ("/api/company/GetCompanyActivitySetupByCompany", params),
        /** @return - the list of activity codes enabled in this company manual work invoice submission */
        GetCompanyActivitySetupByCompanyAndJobtitle: (params: { company: CompanyName, jobtitle: string }) => getJson<string[]>
            ("/api/company/GetCompanyActivitySetupByCompanyAndJobtitle", params),
        GetNavCompanyData: (params: { company: CompanyName }) => getJson<NavCompanyData>
            ("/api/Company/GetNavCompanyData", params),
        GetAllCompaniesDepartments: () => getJson<Department[]>
            ("/api/Company/GetAllCompaniesDepartments"),
        /** @deprecated - please, consider using `getAllCompanies()` instead for reliable Portal ID mapping */
        GetAllCompaniesWise: () => getJson<CompanyWise[]>
            ("/api/Company/GetAllCompaniesWise"),
        GetBaseWise: () => getJson<WiseCodeValue[]>(`/api/company/GetBaseWise`),
        GetProjectWise: (params: { company: CompanyName }) => getJson<WiseCodeValue[]>(`/api/company/GetProjectWise`, params),
        GetNavCompanyToData: () => getJson<Record<CompanyName, NavCompanyData>>
            ("/api/Company/GetNavCompanyToData"),
        GetCompanies: () => getJson<Company[]>
            ("/api/Company/GetCompanies"),
        get_clients_mapping: () => getJson<ClientMappingEntry[]>
            ("/api/Company/get_clients_mapping"),
        GetRdbClients: () => getJson<RdbClient[]>
            ("/api/Company/GetRdbClients"),
        UpdateCompany: async (params: UpdateCompanyParams) => {
            const url = "/api/Company/UpdateCompany?" + new URLSearchParams(
                entries(params).map(e => [e[0], String(e[1])])
            );
            const response = await fetch(url, { method: "post" });
            const errorSuffix = " - failed to POST /api/Company/UpdateCompany";
            await assertSuccessResponse(response, errorSuffix);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const companyId: number = await response.json();
            return companyId;
        },
    },
    Claim: {
        GetAllCurrencies: () => getJson<Currency[]>("/api/claim/getAllCurrencies"),
        UploadFile: async (payload: File) => {
            const formData = new FormData();
            formData.append("file", payload);
            const response = await fetchOrFail("/api/Claim/UploadFile", {
                method: "POST", body: formData,
            });
            const formRecognizerOperationLocation = response.headers.get("Operation-Location");
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const data: FileUploadResponse = await response.json();
            return { newName: data.fileName, accessToken: data.accessToken, email: data.email, formRecognizerOperationLocation };
        },
        GetFiles: (params: { claimId: number }) => getJson<Receipt[]>("/api/Claim/GetFiles", params),
        DeleteSoe: (params: { soeId: number }) => sendForSuccess(buildUrl
            ("/api/Claim/DeleteSoe", params), "DELETE", {}),
        SubmitClaim: (payload: ClaimSubmissionData<never>) => postForSuccessStatus
            ("/api/Claim/SubmitClaim", payload),
        UpdateClaim: (params: ClaimSubmissionData<never>) => postForSuccessStatusToast
            ("/api/Claim/UpdateClaim", params),
        ApproveSoeClaim: (params: ApproveSoeClaimPayload) => postForSuccessStatusToast("/api/Claim/ApproveSoeClaim", params),
        AddApprovedSoeClaimToPayroll: (params: AddApprovedSoeClaimToPayrollPayload) => postForSuccessStatusToast("/api/Claim/AddApprovedSoeClaimToPayroll", params),
        getCurrencyExchangeRates: () => getJson<OpenExchangeRates>("/api/claim/getCurrencyExchangeRates"),
        GetCurrencyExchangeRatesForDate: (params: { date: IsoDate }) => getJson<OpenExchangeRates>("/api/claim/GetCurrencyExchangeRatesForDate", params),
        GetClaim: (params: { claimId: number }) => getJson<ClaimFromServer>("/api/Claim/GetClaim", params),
        GetClaimCategories: () => getJson<ClaimCategory[]>("/api/claim/getClaimCategories"),
        GetPendingClaimsForNavInvoiceConfirmation: (params: NavInvoiceScreenParams) => getJson<ClaimBaseData[]>("/api/claim/GetPendingClaimsForNavInvoiceConfirmation", params),
        GetClaimsForApproverDashboard: () => getJson<ClaimAction[]>("/api/claim/GetClaimsForApproverDashboard"),
        GetClaimsForDashboard: () => getJson<ClaimAction[]>("/api/Claim/GetClaimsForDashboard"),
    },
    Contract: {
        GetAaiOfficerFlightDeckFeesTable: (params: AbsoluteMonth) => getJson<OfficerFlightDeckFeesPersonAaiFd[]>("/api/Contract/GetAaiOfficerFlightDeckFeesTable", params),
        GetHestonOfficerFeesTable: (params: AbsoluteMonth) => getJson<OfficerFlightDeckFeesPersonUntyped[]>("/api/Contract/GetHestonOfficerFeesTable", params),
        GetOfficerFlightDeckFeesRow: (params: NavInvoiceScreenParams) => getJson<OfficerFlightDeckFeesPersonAaiFd>("/api/Contract/GetOfficerFlightDeckFeesRow", params),
        GetLatestContractData: (params: { rdbPersonId: number }) => getJson<SignedDocumentRow>
            ("/api/Contract/GetLatestContractData", params),
        count_airborne_sccm_fee_scales_by_person: (params: { crewCode: string }) => getJson<SccmFeeScale[]>
            ("/api/Contract/count_airborne_sccm_fee_scales_by_person", params),
        GetFeeEntryTypes: (params: { company: CompanyName }) => getJson<FeeEntryTypeRecord[]>(`/api/Contract/GetFeeEntryTypes`, params),
        GetWageContracts: (params: { company: CompanyName }) => getJson<WageContract[]>(`/api/Contract/GetWageContracts`, params),
        GetWageContractFixedLines: (params: { company: CompanyName }) => getJson<WageContractFixedLine[]>(`/api/Contract/GetWageContractFixedLines`, params),
        GetWageContractFeeEntryScales: (params: { company: CompanyName }) => getJson<WageContractFeeEntryScale[]>(`/api/Contract/GetWageContractFeeEntryScales`, params),
        GetPersonWageContractFees: (params: NavInvoiceScreenParams) => getJson<FeeEntryTypeRecord[]>(`/api/Contract/GetPersonWageContractFees`, params),
    },
    Invoice: {
        GetInvoicesForDashboard: (params: { year: number, month: number }) => getJson<InvoiceAction[]>
            (`/api/Invoice/GetInvoicesForDashboard`, params),
        GetInvoicesForDashboardByCompany: (params: { year: number, month: number, companyId: number }) => getJson<InvoiceAction[]>
            (`/api/Invoice/GetInvoicesForDashboardByCompany`, params),
        GetInvoicesForApproverDashboard: (params: { year: number, month: number }) => getJson<InvoiceAction[]>
            (`/api/invoice/GetInvoicesForApproverDashboard`, params),
        GetApproveDeadline: (params: { year: number, month: number, company: CompanyName }) => getJson<number>
            (`/api/invoice/GetApproveDeadline`, params),
        DeleteManualInvoiceDetails: (params: { mid: number }) => getResponse
            ("/api/Invoice/DeleteManualInvoiceDetails", params),
        /**
         * sends activities to the Navision, assumingly right before status is going to be set to Approved
         */
        SubmitManualInvoiceToNav: (params: ManualInvoiceLocator) => getJson<void>
            ("/api/Invoice/SubmitManualInvoiceToNav", params),
        GetManualInvoiceDetailsById: (params: { invoiceId: number }) => getJson<ManualInvoiceDetails[]>
            ("/api/Invoice/GetManualInvoiceDetailsById", params),
        GetOwnManualInvoice: (params: { year: number, month: number }): Promise<ManualInvoiceSheetDataOut | null> => getResponse
            ("/api/Invoice/GetOwnManualInvoice", params).then(
                response => response.status === 204 ? null : typed<Promise<ManualInvoiceSheetDataOut>>(response.json())
            ),
        /** @return - null if contractor has no manual invoice in database */
        GetManualInvoiceByNavLocator: (params: NavInvoiceScreenParams): Promise<ManualInvoiceSheetDataOut | null> => getResponse
            ("/api/Invoice/GetManualInvoiceByNavLocator", params).then(
                response => response.status === 204 ? null : typed<Promise<ManualInvoiceSheetDataOut>>(response.json())
            ),
        SubmitManualInvoiceForApproval: (params: SubmitManualWorkInvoiceForApprovalPayload) => postForSuccess
            ("/api/Invoice/SubmitManualInvoiceForApproval", params),
        AddSubmittedManualInvoiceToPayroll: (params: { InvoiceId: number }) => postForSuccess
            ("/api/Invoice/AddSubmittedManualInvoiceToPayroll", params),
        ChangeInvoiceStatus: (params: InvoiceChangeInvoiceStatusRequest) => getResponse
            ("/api/Invoice/ChangeInvoiceStatus", params),
        GetInvoiceStatusHistory: (params: { id: number }) => getJson<InvoiceComment[]>(`/api/invoice/GetInvoiceStatusHistory`, params),
        AddUpdateManualInvoiceDetails: (params: ManualInvoiceSubmissionData) => postForSuccessStatus
            (`/api/invoice/AddUpdateManualInvoiceDetails`, params),
        ChangeManualInvoiceLineRejected: (params: { id: number, rejected: boolean }) => getJson<void>
            ("/api/Invoice/ChangeManualInvoiceLineRejected", { id: params.id, rejected: params.rejected ? "true" : "false" }),
        CalculateFeesForSheet: (params: AddManualInvoiceFromSheetRequest<Partial<ManualInvoiceDetails>>) => postForJson<CalculateFeesForSheetResponse>
            ("/api/Invoice/CalculateFeesForSheet", params),
        AddManualInvoiceFromSheet: (params: AddManualInvoiceFromSheetRequest<Partial<ManualInvoiceDetails>>) => postForSuccessStatus
            ("/api/Invoice/AddManualInvoiceFromSheet", params),

        GetFeeStatementInvoicesListForOfficer: (params: PayrollLocator) => getJson<Payslip[]>
            ("/api/Invoice/GetFeeStatementInvoicesListForOfficer", params),
        GetFeeStatementInvoicesListForApprover: (params: PayrollLocator) => getJson<BoxedPayslip[]>
            ("/api/Invoice/GetFeeStatementInvoicesListForApprover", params),
        GetPayrollPayEntriesForApprover: (params: PayrollLocator) => getJson<Record<number, PayEntry[]>>
            ("/api/Invoice/GetPayrollPayEntriesForApprover", params),
        GetStoppedBankAccounts: (params: { company: CompanyName }) => getJson<BeneficiaryBankAccount[]>
            ("/api/Invoice/GetStoppedBankAccounts", params),
        GetNavInvoiceConfirmationsListForOfficer: (params: PayrollLocator) => getJson<INavInvoiceConfirmation[]>
            ("/api/Invoice/GetNavInvoiceConfirmationsListForOfficer", params),
        GetNavInvoiceConfirmationsListForApprover: (params: PayrollLocator) => getJson<INavInvoiceConfirmation[]>
            ("/api/Invoice/GetNavInvoiceConfirmationsListForApprover", params),
        FinalizeNavPayroll: (params: FinalizeNavPayrollPayload): Promise<number> => postForSuccessStatusToast
            ("/api/Invoice/FinalizeNavPayroll", params),
        SendTransferStatement: (params: PayrollLocatorWithDepartment): Promise<number> => postForSuccessStatusToast
            ("/api/Invoice/SendTransferStatement", params),
        SendIndividualNavConfirmationLink: (params: NavInvoiceLocator): Promise<number> => postForSuccessStatusToast
            ("/api/Invoice/SendIndividualNavConfirmationLink", params),
        SendIndividualTransferStatement: (params: NavInvoiceLocator): Promise<number> => postForSuccessStatusToast
            ("/api/Invoice/SendIndividualTransferStatement", params),
        GetNavPayrollFinalizationsList: () => getJson<StoredFinalization[]>
            ("/api/Invoice/GetNavPayrollFinalizationsList"),
        GetFeeStatementInvoice: (params: NavInvoiceLocator) => getJson<FullPayslip>
            ("/api/Invoice/GetFeeStatementInvoice", params),
        GetPersonSalaryJournal: (params: NavInvoiceLocator) => getJson<StoredSalaryJournalRecordNav[]>
            ("/api/Invoice/GetPersonSalaryJournal", params),
        GetNavInvoiceMessagesHistory: (params: NavInvoiceScreenParams) => getJson<NavInvoiceMessageModel[]>
            ("/api/Invoice/GetNavInvoiceMessagesHistory", params),
        GetNavPayrollInvoicesMessagesHistory: (params: PayrollLocator) => getJson<NavInvoiceMessageWithLocatorModel[]>
            ("/api/Invoice/GetNavPayrollInvoicesMessagesHistory", params),
        StopInvoiceBankAccount: (params: AddCommentRequest & { accountIndex: number, lineSheetNumber: string }) => postForSuccessStatusToast
            ("/api/Invoice/StopInvoiceBankAccount", params),
        ConfirmNavInvoice: (params: NavInvoiceScreenParams) => postForSuccessStatusToast
            ("/api/Invoice/ConfirmNavInvoice", params),
        ClientConfirmNavInvoice: (params: ClientConfirmNavInvoiceRequest) => postForSuccessStatusToast
            ("/api/Invoice/ClientConfirmNavInvoice", params),
        ReportInconsistencyInNavInvoice: (params: ConfirmNavInvoiceRequest & { discrepancyDescription: string }) => postForSuccessStatusToast
            ("/api/Invoice/ReportInconsistencyInNavInvoice", params),
        ClientReportInconsistencyInNavInvoice: (params: ConfirmNavInvoiceRequest & { discrepancyDescription: string, onlyFor: "OFFICER" | null }) => postForSuccessStatusToast
            ("/api/Invoice/ClientReportInconsistencyInNavInvoice", params),
        ReportFixedInconsistencyInNavInvoice: (params: ReportFixedInconsistencyInNavInvoiceRequest) => postForSuccessStatusToast
            ("/api/Invoice/ReportFixedInconsistencyInNavInvoice", params),
        ReportFixedClientReportedInconsistencyInNavInvoice: (params: ReportFixedInconsistencyInNavInvoiceRequestBase) => postForSuccessStatusToast
            ("/api/Invoice/ReportFixedClientReportedInconsistencyInNavInvoice", params),
        ReplyToClientReportedDiscrepancy: (params: ReplyToClientReportedDiscrepancyRequest) => postForSuccessStatusToast
            ("/api/Invoice/ReplyToClientReportedDiscrepancy", params),
        AddCommentToNavInvoice: (params: AddCommentRequest) => postForSuccessStatusToast
            ("/api/Invoice/AddCommentToNavInvoice", params),
        GetNavInvoiceConfirmations: (params: NavInvoiceScreenParams) => getJson<INavInvoiceConfirmation[]>
            ("/api/Invoice/GetNavInvoiceConfirmations", params),
        all_invoice_items_in_payroll: (params: PayrollLocatorWithDepartment) => getJson<PayEntry[]>
            ("/api/Invoice/all_invoice_items_in_payroll", params),
        insert_salary_journal_records: (params: { company: CompanyName, records: SalaryJournalRecord[] }) => postForSuccessStatusToast
            ("/api/Invoice/insert_salary_journal_records", params),

        GetNavEmployeesWithNoManualReportSubmitted: (company: CompanyName, year: number, month: number,) =>
            getJson<NavEmployeeBase[]>("/api/Invoice/GetNavEmployeesWithNoManualReportSubmitted", { company, year, month }),

        SendSubmitManualReportReminderNotification: (company: CompanyName, employees: NavEmployeeBase[], month: number, year: number) =>
            postForSuccessStatus("/api/Invoice/SendSubmitManualReportReminderNotification", { company, employees, month, year })
    },
    Roster: {
        getLalRoster: (params: NavInvoiceScreenParams) => getJson<LeonCrawlPersonMonthRoster>("/api/Roster/getLalRoster", params),
        getLeonGraphqlRoster: (params: NavInvoiceScreenParams) => getJson<LeonPersonMonthRoster>("/api/Roster/getLeonGraphqlRoster", params),
        getLeonGraphqlPeople: (params: { company: CompanyName }) => getJson<CrewMember[]>("/api/Roster/getLeonGraphqlPeople", params),
    },
    Activity: {
        GetActivitiesWise: () => getJson<WiseCodeValue[]>
            ("/api/activity/GetActivitiesWise"),
    },
    System: {
        ReportJsError: (params: ReportJsErrorRequest) => postForSuccessStatus("/api/System/ReportJsError", params),
    },
};

export type CompanyToDepartmentToName = Record<string, Record<number, string>>;

export async function getAllCompaniesDepartmentsMapping() {
    const departments = await api.Company.GetAllCompaniesDepartments();
    const companyToDepartmentToName: CompanyToDepartmentToName = {};
    for (const department of departments) {
        if (!department.Code ||
            department.Code.toUpperCase() === "N/A" ||
            !department.Code.match(/^\d+$/)
        ) {
            continue;
        }
        const companyKey = department.CompanyName.toUpperCase();
        companyToDepartmentToName[companyKey] = companyToDepartmentToName[companyKey] ?? {};
        companyToDepartmentToName[companyKey][+department.Code] = department.Name;
    }
    return companyToDepartmentToName;
}

export default api;
