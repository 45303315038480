import * as React from "react";
import type { BoxedPayslipAndAllCompany, BoxedPayslip, PayEntry } from "../types/NavisionPayslip";
import api from "../features/Api";
import { useState } from "react";
import { ReportDiscrepancyModal } from "../components/fee-statement-invoices-list-approver/ReportDiscrepancyModal";
import type {
    NavInvoiceConfirmationStatusServer,
} from "../types/Api/Invoice";
import type { RankedStatus } from "../utils/invoiceListingUtils";
import  { getConfirmationTotal } from "../utils/invoiceListingUtils";
import { STATUS_FILTERS } from "../utils/invoiceListingUtils";
import { getStatusLabel } from "../utils/invoiceListingUtils";
import type { AbsoluteMonth } from "@mhc/utils/src/dates";

type FeeStatementInvoiceListRowProps = {
    yearMonth: AbsoluteMonth,
    showActions: boolean,
    rowData: BoxedPayslipAndAllCompany,
    payEntries: readonly PayEntry[] | undefined,
    index: number,
    status: RankedStatus,
    frequentFeeEntryTypes: readonly { id: number, description: string }[],
    onStatusUpdate: (record: BoxedPayslip, status: NavInvoiceConfirmationStatusServer) => void,
    currencySymbol: string,
};

export function FeeStatementInvoiceListRow(props: FeeStatementInvoiceListRowProps) {
    const { rowData, payEntries, index, status, yearMonth, frequentFeeEntryTypes, showActions, onStatusUpdate } = props;
    const [processingRequest, setProcessingRequest] = useState(false);

    const payslip = rowData.payslip;
    const confirmationTotal = getConfirmationTotal(payslip);
    const payrollLocator = {
        year: yearMonth.year,
        month: yearMonth.month,
        company: rowData.allCompany.COMPANYNAME,
    };

    const getInvoiceUrl = () => {
        return `/Home/FeeStatementInvoiceConfirmation?${new URLSearchParams({
            company: payrollLocator.company,
            year: payrollLocator.year.toString(),
            month: payrollLocator.month.toString(),
            employeeCode: rowData.payslip.Kennitala,
        })}`;
    };

    const confirmInvoiceAsClient = () => {
        setProcessingRequest(true);
        api.Invoice.ClientConfirmNavInvoice({
            ...payrollLocator,
            employeeCode: rowData.payslip.Kennitala,
            confirmationTotal: confirmationTotal,
        }).then(() => {
            onStatusUpdate(rowData, "CONFIRMED_BY_CLIENT");
        }).finally(() => {
            setProcessingRequest(false);
        });
    };

    const onDiscrepancySubmitted = () => {
        onStatusUpdate(rowData, "DISCREPANCY_REPORTED_BY_CLIENT");
    };

    const statusLabel = status === "CONFIRMED"
        ? "Pending Approval"
        : getStatusLabel(status);
    const filterStatus = STATUS_FILTERS.find(sf => sf.statusCheck(status))?.filterType;

    const renderPayEntriesCells = (payEntries: readonly PayEntry[]) => {
        const frequentTypeIdToSummary: Record<number, { units: number, totalFee: number, lastDescription: string }> = {};
        const otherTypeIdToSummary: Record<number, { units: number, totalFee: number, lastDescription: string }> = {};
        for (const payEntry of payEntries) {
            const typeId = payEntry.Faerslutegund;
            const units = +payEntry.Einingar;
            const totalFee = +payEntry.Upphaed;
            const description = payEntry["Texti"] || payEntry["Texti a launasedli"];
            const isFrequent = frequentFeeEntryTypes.some(tr => tr.id === typeId);
            if (isFrequent) {
                frequentTypeIdToSummary[typeId] = frequentTypeIdToSummary[typeId] ?? { units: 0, totalFee: 0, lastDescription: "" };
                frequentTypeIdToSummary[typeId].totalFee += totalFee;
                frequentTypeIdToSummary[typeId].units += units;
                frequentTypeIdToSummary[typeId].lastDescription += description;
            } else {
                otherTypeIdToSummary[typeId] = otherTypeIdToSummary[typeId] ?? { units: 0, totalFee: 0, lastDescription: "" };
                otherTypeIdToSummary[typeId].totalFee += totalFee;
                otherTypeIdToSummary[typeId].units += units;
                otherTypeIdToSummary[typeId].lastDescription += description;
            }
        }
        const otherFeesTooltipText = Object.values(otherTypeIdToSummary)
            .map(summary => {
                return "x" + summary.units + " = " + summary.totalFee + " " +
                    props.currencySymbol + " " + summary.lastDescription;
            })
            .join("\n");
        return <>
            {frequentFeeEntryTypes.map(typeRecord => {
                const summary = frequentTypeIdToSummary[typeRecord.id];
                if (!summary) {
                    return <td key={typeRecord.id}></td>;
                }
                return <td
                    className="number-field"
                    key={typeRecord.id}
                    title={summary.totalFee.toFixed(2) + " " + props.currencySymbol}
                >
                    {frequentTypeIdToSummary[typeRecord.id]?.units
                        .toFixed(2)
                        .replace(/\.0+$/, "")
                        .replace(/^0$/, "")}
                </td>;
            })}
            <td className="number-field" title={otherFeesTooltipText}>
                {Object.values(otherTypeIdToSummary)
                    .map(t => t.totalFee)
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)
                    .replace(/\.0+$/, "")
                    .replace(/^0$/, "")}
            </td>
        </>;
    };

    return <tr data-status={status} data-filter-status={filterStatus} className={"colored-nav-invoice-status-holder" + (processingRequest ? " changing-status" : "")}>
        <td className="number-field">{index + 1}</td>
        <td><span className="crew-code">{payslip.Kennitala}</span></td>
        <td className="plainTextField">
            <a href={getInvoiceUrl()} target="_blank">
                <img src="/img/viewInvoiceIcon.svg" loading="lazy" className="view-invoice-icon"
                     alt="view invoice icon"/>
                <span>{payslip.Nafn}</span>
            </a>
        </td>
        {!payEntries ? <>
            {frequentFeeEntryTypes.map(typeRecord =>
                <td className="status-loading-animated-ellipsis" key={typeRecord.id}></td>
            )}
            <td className="status-loading-animated-ellipsis"></td>
        </> : <>
            {renderPayEntriesCells(payEntries)}
        </>}
        <td className="number-field"><strong>{confirmationTotal}</strong></td>
        <td className="text-centered-column-item">
            <span className="statusField">
                {statusLabel}
            </span>
        </td>
        {showActions && <td className="invoice-actions-cell">
            <div className="actions-field-wrapper">
                {status !== "CONFIRMED_BY_CLIENT" && status !== "DISCREPANCY_REPORTED" && <button
                    type="button"
                    disabled={processingRequest}
                    className="action-button action-button-approve"
                    onClick={() => confirmInvoiceAsClient()}>Approve
                </button>}
                {status !== "DISCREPANCY_REPORTED" && <ReportDiscrepancyModal
                    selectedInvoice={payslip}
                    payrollLocator={payrollLocator}
                    onSubmitted={onDiscrepancySubmitted}
                    setProcessingRequest={setProcessingRequest}
                />}
            </div>
        </td>}
    </tr>;
}