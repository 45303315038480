
import * as React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import * as _ from "lodash";
import { typed } from "@mhc/utils/src/typing";
import type { InvoiceAction } from "../types/Api/Invoice";
import { getMonthFullName, getPastMonth } from "@mhc/utils/src/dates";
import { WorkInvoiceApproveTable } from "./WorkInvoiceApproveTable";
import ApproveWorkInvoice from "./ApproveWorkInvoice";
import { ApproverScreenHeader } from "./ApproverScreenHeader";
import type { Company } from "../types/Api/Company";
import type { CompanyName } from "@mhc/utils/types/nav";
import api from "../features/Api";
import type { InvoiceStatus } from "../features/payrollUtils";

export const views = {
    Table: 0,
    Form: 1
};

const wasSubmitted = (s: InvoiceAction) => s.Status === 1 || s.Status === 3 || s.Status === 4;

type Props = {
    code: string,
    companyName: CompanyName,
    approvalDepartmentList: string,
};

function getInitialState() {
    const { year, month } = getPastMonth(new Date());
    return {
        nameDeptCrewcode: "",
        sSelYear: typed<number | string>(year),
        /** 1-based */
        sSelMonth: typed<number | string>(month),
        viewForm: views.Table, //TODO
        /**
         * -1 - selAll
         * 1 - selPen
         * 3 - selReject
         * 4 - selApproved
         */
        StatusView: typed<-1 | InvoiceStatus>(-1),
        loading: typed<boolean | undefined>(undefined),
        invoiceToView: typed<InvoiceAction | undefined>(undefined),
        companies: typed<Company[] | undefined>(undefined),
        sortBy: {
            column: typed<"" | keyof InvoiceAction>(""),
            order: typed<"asc" | "desc">("asc"),
        },
        invoiceActions: typed<undefined | InvoiceAction[]>(undefined),
        approveDay: typed<undefined | number>(undefined),
    };
}

type State = ReturnType<typeof getInitialState>;

export default class ApproveWorkInvoices extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        require("./ApproveWorkInvoices.css");
        this.state = getInitialState();
        api.Company.GetCompanies().then(companies => this.setState({ companies }));
    }

    componentDidMount() {
        const currentMonth = new Date().getMonth() + 1;
        this.GetInitialApproveDeadline(new Date().getFullYear(), currentMonth);
    };

    GetApproveDeadline(year: number | string, month: string | number) {
        const invoiceActionsQuery = {
            year: Number(year),
            month: Number(month),
            company: this.props.companyName
        };
        api.Invoice.GetApproveDeadline(invoiceActionsQuery)
            .then(approveDay => this.setState({ approveDay }));
    };

    GetInitialApproveDeadline(year: number, currentMonth: number) {
        const invoiceActionsQuery = {
            year: year,
            month: currentMonth,
            company: this.props.companyName
        };
        api.Invoice.GetApproveDeadline(invoiceActionsQuery).then(approveDay => {
            this.setState({ approveDay });
            if (approveDay >= new Date().getDate()) {
                this.LoadInvoices(this.state.sSelYear, this.state.sSelMonth);
            } else {
                this.LoadInvoices(year, currentMonth);
            }
        });
    };

    LoadInvoices(year: number | string, month: number | string) {
        this.setState({
            sSelMonth: month,
            sSelYear: year,
        });
        const invoiceActionsQuery = {
            year: Number(year),
            month: Number(month),
        };
        api.Invoice.GetInvoicesForApproverDashboard(invoiceActionsQuery)
            .then(invoiceActions => this.setState({ invoiceActions }));
    };

    order(invoiceActions: InvoiceAction[]) {
        if (this.state.sortBy.column !== "") {
            return _.orderBy(invoiceActions, [this.state.sortBy.column], [this.state.sortBy.order]);
        }
        return invoiceActions;
    };

    handleSort(e: React.MouseEvent<HTMLTableCellElement>) {
        const headerId = e.currentTarget.id;

        this.setState({
            sortBy: {
                column: headerId.slice(2) as keyof InvoiceAction,
                order: this.state.sortBy.order === "desc" ? "asc" : "desc"
            }
        });

        if (e.currentTarget.parentElement) {
            _.forEach(e.currentTarget.parentElement.children, function (c) {
                c.className = "";
            });
        }

        e.currentTarget.className = this.state.sortBy.order === "desc" ? "asc" : "desc";
    };

    _filterByNameCrewDeptcode(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ nameDeptCrewcode: e.target.value });
    };

    _ShowTimeSheets(invoiceAction: InvoiceAction) {
        this.setState({ invoiceToView: invoiceAction });
    };

    ShowData(status: InvoiceStatus | -1) {
        this.setState({ StatusView: status, invoiceToView: undefined });
    };

    _generateFilters(isDepartment: boolean, invoiceActions: InvoiceAction[]) {
        let orderInvoiceActions = this.order(invoiceActions);
        if (orderInvoiceActions.length === undefined)
            orderInvoiceActions = [];
        if (!window.EMPLOYEE_INFO?.ApproveOwnInvoices) {
            orderInvoiceActions = _.filter(orderInvoiceActions, invoice => invoice.EmployeeCode !== this.props.code);
        }
        if (isDepartment) {
            orderInvoiceActions = _.filter(orderInvoiceActions, d => d.DepartmentCode != null && this.props.approvalDepartmentList.includes(d.DepartmentCode.toUpperCase()));
        }
        const notSubmitted = orderInvoiceActions.filter((ia) => ia.Status === 0);
        orderInvoiceActions = orderInvoiceActions.filter((ia) => wasSubmitted(ia));
        const selNotSubmitted = this.state.StatusView === 0 ? "invoiceStatusFilter Selected" : "invoiceStatusFilter";
        const selAll = this.state.StatusView === -1 ? "invoiceStatusFilter Selected" : "invoiceStatusFilter";
        const selPen = this.state.StatusView === 1 ? "invoiceStatusFilter Selected" : "invoiceStatusFilter";
        const selReject = this.state.StatusView === 3 ? "invoiceStatusFilter Selected" : "invoiceStatusFilter";
        const selApproved = this.state.StatusView === 4 ? "invoiceStatusFilter Selected" : "invoiceStatusFilter";
        return (
            <div className="filter-statuses-list">
                <div className={selNotSubmitted}>
                    <a href="#" onClick={() => this.ShowData(0)}>Not Submitted Yet
                        ({notSubmitted.length})</a>
                </div>
                <div className={selAll}>
                    <a href="#" onClick={() => this.ShowData(-1)}>All Submitted
                        ({orderInvoiceActions != null ? orderInvoiceActions.length : 0})</a>
                </div>
                <div className={selPen}>
                    <a href="#" onClick={() => this.ShowData(1)}>Pending Approval
                        ({orderInvoiceActions.length !== undefined ? orderInvoiceActions.filter(s => s.Status === 1).length : 0})</a>
                </div>
                <div className={selReject}>
                    <a href="#" onClick={() => this.ShowData(3)}>Rejected
                        ({orderInvoiceActions.length !== undefined ? orderInvoiceActions.filter(s => s.Status === 3).length : 0})</a>
                </div>
                <div className={selApproved}>
                    <a href="#" onClick={() => this.ShowData(4)}>Approved
                        ({orderInvoiceActions.length !== undefined ? orderInvoiceActions.filter(s => s.Status === 4).length : 0})</a>
                </div>
            </div>
        );
    };

    onStatusSubmit() {
        this.LoadInvoices(this.state.sSelYear, this.state.sSelMonth);
        this.GetApproveDeadline(this.state.sSelYear, this.state.sSelMonth);
        this.setState({ invoiceToView: undefined });
    }

    _GetInvoiceLast6Months() {
        const arrLast6Months = [];
        //Get the current year.
        const currentYear = new Date().getFullYear(); //E.g. 2021
        //Get current month
        let currentMonth = (new Date().getMonth()); //January has the value 0.

        //Do the past 6 months extend into the last year?
        let iDiff = currentMonth - 11;
        const iYears = iDiff < 0 ? 1 : 0;

        //The years
        for (let iYear = currentYear; iYear >= currentYear - iYears; iYear--) {
            //Get months for each year.
            for (let iMonth = currentMonth; iMonth > iDiff; iMonth--) {
                //Value
                let sMonthKey: string | number = iMonth + 1;
                sMonthKey = sMonthKey.toString().length === 1 ? "0" + sMonthKey.toString() : sMonthKey.toString();
                const sKey = iYear.toString() + sMonthKey.toString();
                const sDisplayText = iYear.toString() + " - " + getMonthFullName(iMonth + 1);
                arrLast6Months.push(<option key={sKey} value={sKey}>{sDisplayText}</option>);

                //End first year.
                if (iMonth === 0) {
                    //For the second year. Start in December.
                    currentMonth = 11;
                    iDiff = iDiff + 11;
                    break;
                }
            }
        }

        return arrLast6Months;
    };

    deadlineNotPassed() {
        return new Date(Number(this.state.sSelYear), Number(this.state.sSelMonth), this.state.approveDay, 23, 59, 59) >= new Date();
    };

    get selectedMonthValue() {
        return String(this.state.sSelYear) + String(this.state.sSelMonth).padStart(2, "0");
    };

    _changeInvoiceMonthSelected(e: React.ChangeEvent<HTMLSelectElement>) {
        const sParameter = e.target.value;
        const sSelMonth = sParameter.slice(4, 6);
        const sSelYear = sParameter.slice(0, 4);
        this.LoadInvoices(sSelYear, sSelMonth);
        this.GetApproveDeadline(sSelYear, sSelMonth);
    };

    createInvoiceApproverDashboard(invoiceActions: InvoiceAction[]) {
        return (<div className="invoice-wrapper">
            <WorkInvoiceApproveTable invoiceActions={invoiceActions} handleSort={this.handleSort.bind(this)}
                                     _ShowTimeSheets={this._ShowTimeSheets.bind(this)}/>
        </div>);
    };

    createInvoiceSetup(monthForLastDay: number | string) {
        return (
            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <select className="form-control" value={this.selectedMonthValue}
                        style={{ maxWidth: "180px" }}
                        onChange={(e) => this._changeInvoiceMonthSelected(e)}>
                    {this._GetInvoiceLast6Months()}
                </select>
                {this.deadlineNotPassed() && <div>
                    <span className="deadlineSpan">
                      <img className="deadline" style={{ display: "inline-block" }} src="/img/deadline.png" alt="deadline"/>
                      Deadline: {this.state.approveDay} {getMonthFullName(Number(monthForLastDay) + 1).toUpperCase()}
                    </span>
                </div>}
                <div className="searchInput">
                    <input type="text" placeholder="Search..."
                           onChange={(e) => this._filterByNameCrewDeptcode(e)}/>
                </div>
            </div>
        );
    };

    render() {
        if (!this.state.invoiceActions) {
            return <div className="col-xs-12 center">
                <ClipLoader loading={true} />
            </div>;
        }

        let monthForLastDay = this.state.sSelMonth;
        if (monthForLastDay === 12) {
            monthForLastDay = 0;
        }

        let orderInvoiceActions = this.order(this.state.invoiceActions);
        if (orderInvoiceActions.length === undefined)
            orderInvoiceActions = [];

        const comp = _.filter(this.state.companies, d => d.Name === this.props.companyName);

        // Filtering by what is chosen
        if (this.state.StatusView !== -1) {
            orderInvoiceActions = _.filter(orderInvoiceActions, d => d.Status === this.state.StatusView);
        } else {
            orderInvoiceActions = _.filter(orderInvoiceActions, wasSubmitted);
        }
        if (!window.EMPLOYEE_INFO?.ApproveOwnInvoices) {
            orderInvoiceActions = _.filter(orderInvoiceActions, invoice => invoice.EmployeeCode !== this.props.code);
        }

        // Search filtering
        orderInvoiceActions = orderInvoiceActions.filter(d => {
            return d.Employee.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase())
                || d.EmployeeCode.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase())
                || (d.DepartmentCode != null && d.DepartmentCode.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase()))
                || (d.DepartmentCode != null && d.JobTitle.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase()));
        });

        //If is department is set to true in settings for this company
        let isDepartment = false;
        if (comp.length > 0) {
            //TODO:
            if (comp[0].IsDepartment) {
                orderInvoiceActions = _.filter(orderInvoiceActions, d => d.DepartmentCode != null && this.props.approvalDepartmentList.includes(d.DepartmentCode.toUpperCase()));
                isDepartment = true;
            }
        }

        return <>
            <ApproverScreenHeader />
            <div className="invoice-wrapper">
                <div className="invoice-header">
                    <div className="container">
                        <div className="row">
                            <div className="currentfilters">
                                {this._generateFilters(isDepartment, this.state.invoiceActions)}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ margin: "18px" }}>
                    { this.state.loading ? <div className="col-xs-12">
                        <div className="col-xs-9">
                            <h1 className="loading">{"Loading list of Work Invoices..."}</h1>
                        </div>
                    </div> : this.state.invoiceToView ? <ApproveWorkInvoice
                        key={this.state.invoiceToView.InvoiceId}
                        onStatusSubmit={this.onStatusSubmit.bind(this)}
                        onRequestClose={() => this.setState({ invoiceToView: undefined })}
                        isDeadlineNotPassed={this.deadlineNotPassed()}
                        invoice={this.state.invoiceToView}
                    /> : <>
                        {this.createInvoiceSetup(monthForLastDay)}
                        {orderInvoiceActions.length === 0 &&
                            <div className="col-xs-12 center">
                                {<h2>No work invoices</h2>}
                            </div>}
                        {this.createInvoiceApproverDashboard(orderInvoiceActions)}
                    </>}
                </div>
            </div>
        </>;
    }
}
