import Users from "./src/views/Users";

require('source-map-support').install();

import {rootReducer} from "./src/features/reducer";

import * as React from "react";
import * as ReactDOM from 'react-dom/client';
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import FeeStatementInvoiceConfirmation from "./src/views/FeeStatementInvoiceConfirmation";
import FeeStatementInvoicesListForOfficer from "./src/views/FeeStatementInvoicesListForOfficer";
import {globalJsErrorHandler, globalUnhandledRejectionHandler} from "./src/utils/errorHandling";
import "./src/views/globals.css";
import {initializeHeader} from "./src/features/payrollUtils";
import InvoiceRosterCalculationAirAtlanta from "./src/views/InvoiceRosterCalculation";
import TestsDashboard from "./src/views/TestsDashboard";
import FeeStatementInvoicesListForApprover from "./src/views/FeeStatementInvoicesListForApprover";
import InvoicesUsersClient from "./src/views/InvoicesUsersClient";
import CurrentTimesheets from "./src/views/CurrentTimesheets";
import WageContractsDashboard from "./src/views/WageContractsDashboard";
import {ManualInvoice} from "./src/views/ManualInvoice";
import InvoicesUsersTimeSheets from "./src/views/InvoicesUsersTimeSheets";
import InvoicesUsersActivities from "./src/views/InvoicesUsersActivities";
import ClaimsUsers from "./src/views/ClaimsUsers";
import Claim from "./src/views/Claim";
import ClaimsDashboard from "./src/views/ClaimsDashboard";
import UpdateClaim from "./src/views/UpdateClaim";

// people still using browsers older than 2018: GUMH REDA
Array.prototype.flatMap = Array.prototype.flatMap ?? function(flatten) {
    const result = [];
    for (let i = 0; i < this.length; ++i) {
        const el = this[i];
        const chunk = flatten(el, i);
        result.push(...chunk);
    }
    return result;
};

// people still using browsers older than 2019: AUA
Object.fromEntries = Object.fromEntries ?? function(entries) {
    const result = {};
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
};

window.Startup = new Promise((fulfill, reject) => {
  const store = configureStore({ reducer: rootReducer })
  let React = require('react');
  const dispatch = store.dispatch;
  window.dispatch = dispatch;
  window.React = React;
  window.Provider = Provider;
  window.ReactDOM = ReactDOM;

  window.addEventListener("error", globalJsErrorHandler);
  window.addEventListener("unhandledrejection", globalUnhandledRejectionHandler);
  window.addEventListener("load", initializeHeader);

  let Views = require('./src/views');
  window.store = store;
  window.ManualInvoice = ManualInvoice;
  window.Profile = Views.Profile;
  window.Claim = Claim;
  window.FeeStatementInvoiceConfirmation = FeeStatementInvoiceConfirmation;
  window.InvoiceRosterCalculationAirAtlanta = InvoiceRosterCalculationAirAtlanta;
  window.TestsDashboard = TestsDashboard;
  window.FeeStatementInvoicesListForOfficer  = FeeStatementInvoicesListForOfficer;
  window.ClaimsDashboard = ClaimsDashboard;
  window.UpdateClaim = UpdateClaim;
  window.Users = Users;
  window.ClaimsUsers = ClaimsUsers;
  window.InvoicesUsersClient = InvoicesUsersClient;
  window.InvoicesUsersActivities = InvoicesUsersActivities;
  window.InvoicesDeadlineDays = Views.InvoicesDeadlineDays;
  window.InvoicesUsersFields = Views.InvoicesUsersFields;
  window.InvoicesUsersTimeSheets = InvoicesUsersTimeSheets;
  window.CurrentTimesheets = CurrentTimesheets;
  window.FeeStatementInvoicesListForApprover = FeeStatementInvoicesListForApprover;
  window.WageContractsDashboard = WageContractsDashboard;

  fulfill();
});

console.info("Currently Running Script Build Time", process.env.BUILD_TIME);
