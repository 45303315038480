export default [
    // off duty
    { "Code":4,"Info":"CORRECTED OFF DAY","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":8,"Info":"REQUESTED OFF DAY","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"ROF","UsedTimes":235 },
    { "Code":9,"Info":"DAY OFF OUTSTATION","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"OFO","UsedTimes":1774 },
    { "Code":13,"Info":"UNPAID LEAVE","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"ULV","UsedTimes":6694 },
    { "Code":19,"Info":"EMERGENCY LEAVE","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"EML","UsedTimes":599 },
    { "Code":22,"Info":"MATERNITY LEAVE","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":98,"Info":"VACATION UNPAID","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"VAU","UsedTimes":17327 },
    { "Code":99,"Info":"UNAPPROVED VACATION REQUEST (UNPAID)","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"UPR","UsedTimes":13 },
    { "Code":108,"Info":"OFF DAGUR EFTIR NÆTURVAKT","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"OFN","UsedTimes":39 },
    { "Code":113,"Info":"VAC DAY FOR CREW OVER 60 Y","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":115,"Info":"4/2 ROTATION","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":118,"Info":"SAUDI VISA RENEWAL REMINDER - OFF DAY","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"SVV","UsedTimes":614 },
    { "Code":122,"Info":"PASSPORT RENEWAL OFF DAY","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":142,"Info":"CABIN VACATION (UNPAID)","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"VCU","UsedTimes":8058 },
    { "Code":143,"Info":"FINAL EXIT (OFF DAY)","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":146,"Info":"OFF DAY (COUNT AS LEAVE - NOT COUNT AS PRODUCTIVE CREW)","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":218,"Info":"WAITING FOR LICENSE","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"WFL","UsedTimes":98 },
    { "Code":381,"Info":"EXTENDED LEAVE REQUEST","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":582,"Info":"3/2 ROTATION","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"VA3","UsedTimes":3461 },
    { "Code":921,"Info":"3/3 ROTATION","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":981,"Info":"At home, No hotel needed","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"NOH","UsedTimes":21 },
    { "Code":1061,"Info":"Fatigue","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"FTG","UsedTimes":11 },
    { "Code":1082,"Info":"OFF PAY ON PERDIEM","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":1084,"Info":"OFF PAY OFF PERDIEM","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":1121,"Info":"LEAVE 20 10","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":1321,"Info":"FLEX DAYS - FIA","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":1441,"Info":"UNPAID LEAVE HOME","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":1602,"Info":"AAI DRIVER OFF DAY","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":1621,"Info":"RESERVE","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":1661,"Info":"COVID-19 - excemption","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":1884,"Info":"OFF DAY","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":1901,"Info":"COVID INFECTED ISOLATION","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":1903,"Info":"COVID QUARANTINE","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":1921,"Info":"8 HRS CBT OFF DAY - CPAT / COMPLY","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"CBO","UsedTimes":86 },
    { "Code":1921,"Info":"8 HRS CBT OFF DAY - CPAT / COMPLY - FIA","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"CBO","UsedTimes":86 },
    { "Code":2101,"Info":"LONG TERM LEAVE","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"LLV","UsedTimes":145 },
    { "Code":2161,"Info":"OFF DAY COVID QUARANTINE","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":2162,"Info":"OFF DAY COVID ISOLATION","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":2521,"Info":"24/12 ROTATION","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"V24","UsedTimes":1321 },
    { "Code":2421,"Info":"PARTY OFF PAY","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":2381,"Info":"UNVACCINATED UNPAID LEAVE","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":2721,"Info":"VACATION UNPAID FIXED CONTRACT","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"VAF","UsedTimes":161 },
    { "Code":2901,"Info":"ROTATION CABIN CREW","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"RTC","UsedTimes":9671 },
    { "Code":2981,"Info":"VACATION UNPAID LOYALTY CONTRACT","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"VLO","UsedTimes":630 },
    { "Code":3001,"Info":"Extension for SV Renewal","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"SVX","UsedTimes":970 },
    { "Code":107,"Info":"END OF CONTRACT","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"EOC","UsedTimes":24 },
    { "Code":1281,"Info":"CPAT / COMPLY - COMPUTER BASED TRAINING","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":"CBT","UsedTimes":448 },

    // illness
    { "Code":641,"Info":"RECURRENT CBT","Category":"OFF_DUTY_UNPAID","GNDACTCODETITLE":null },
    { "Code":20,"Info":"ILLNESS CONTRACTOR","Category":"ILLNESS","GNDACTCODETITLE":"ILL","UsedTimes":1101 },
    { "Code":117,"Info":"ILLNESS FIA DUE TO SICK CHILD","Category":"ILLNESS","GNDACTCODETITLE":null },
    { "Code":2641,"Info":"ILLNESS FLEX CONTRACTORS","Category":"ILLNESS","GNDACTCODETITLE":"ILN","UsedTimes":48 },
    { "Code":21,"Info":"ILLNESS FLEXIBLE (ON PAY)","Category":"ILLNESS","GNDACTCODETITLE":"ILF","UsedTimes":42 },
    { "Code":97,"Info":"ILLNESS FIA ON PAY","Category":"ILLNESS","GNDACTCODETITLE":"IFI","UsedTimes":8 },
    { "Code":102,"Info":"ILLNESS FIA ON PAY WHILE ON VAC","Category":"ILLNESS","GNDACTCODETITLE":null },
    { "Code":1381,"Info":"FIA Illness days during vac paid back","Category":"ILLNESS","GNDACTCODETITLE":null },

    // off duty paid
    { "Code":6,"Info":"DAY OFF HOMEBASE","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":"OFF","UsedTimes":14088 },
    { "Code":12,"Info":"VACATION (PAID)","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":"VAC","UsedTimes":466 },
    { "Code":24,"Info":"WINTER VACATION (FIA PAID)","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":null },
    { "Code":25,"Info":"SUMMER VACATION (FIA PAID)","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":null },
    { "Code":28,"Info":"LICENCE MEDICAL","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":null },
    { "Code":29,"Info":"MEDICAL","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":"MED","UsedTimes":49 },
    { "Code":101,"Info":"PAID EMERGENCY LEAVE (CREW PLANNING)","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":null },
    { "Code":114,"Info":"VAC DAY OWED TO CREW MEMBER (PAID BACK)","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":null },
    { "Code":92,"Info":"UNAPPROVED VACATION REQUEST (PAID)","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":null },
    { "Code":1202,"Info":"PAID LEAVE OFF PD","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":"PLO","UsedTimes":10 },
    { "Code":1461,"Info":"FLEX DAY PAID BACK","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":null },
    { "Code":1481,"Info":"FLEX DAY PAID","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":null },
    { "Code":1761,"Info":"EU DRIVER CAR MAINTENANCE","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":null },

    // on duty
    { "Code":1821,"Info":"COVID days paid back","Category":"OFF_DUTY_PAID","GNDACTCODETITLE":null },
    { "Code":26,"Info":"STBY 7-19 HOMEBASE","Category":"ON_DUTY","GNDACTCODETITLE":"SB7","UsedTimes":48 },
    { "Code":27,"Info":"FLEXIBLE STBY","Category":"ON_DUTY","GNDACTCODETITLE":"SBY","UsedTimes":20 },
    { "Code":93,"Info":"STBY FIA 07-18","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":94,"Info":"STANDBY 1900-0700LT","Category":"ON_DUTY","GNDACTCODETITLE":"SBL","UsedTimes":1050 },
    { "Code":158,"Info":"Standby Callout","Category":"ON_DUTY","GNDACTCODETITLE":"SCO","UsedTimes":75 },
    { "Code":212,"Info":"STANDBY AIRBUS 1900 -0700","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":213,"Info":"STANDBY AIRBUS 0700 - 1900","Category":"ON_DUTY","GNDACTCODETITLE":"SA7","UsedTimes":2 },
    { "Code":421,"Info":"STBY 1300-0100 HOMEBASE","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":481,"Info":"STANDBY ALS DAY 0700-1900","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":482,"Info":"STANDBY ALS LATE 1900-0700","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":701,"Info":"STANDBY 2300-1500 LT","Category":"ON_DUTY","GNDACTCODETITLE":"S23","UsedTimes":97 },
    { "Code":703,"Info":"STANDBY 0800-1400 LT","Category":"ON_DUTY","GNDACTCODETITLE":"S08","UsedTimes":1749 },
    { "Code":706,"Info":"STANDBY 0800-1400","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":707,"Info":"STANDBY 1100-1900 LT","Category":"ON_DUTY","GNDACTCODETITLE":"S11","UsedTimes":35 },
    { "Code":708,"Info":"STANDBY 1700-0100 LT","Category":"ON_DUTY","GNDACTCODETITLE":"S17","UsedTimes":9 },
    { "Code":709,"Info":"STANDBY 1000-1900 LT","Category":"ON_DUTY","GNDACTCODETITLE":"S10","UsedTimes":108 },
    { "Code":710,"Info":"STANDBY 1800-0100 LT","Category":"ON_DUTY","GNDACTCODETITLE":"S18","UsedTimes":698 },
    { "Code":721,"Info":"STANDBY 1300- 2000 LT","Category":"ON_DUTY","GNDACTCODETITLE":"S13","UsedTimes":2497 },
    { "Code":2762,"Info":"LM Flexible Standby","Category":"ON_DUTY","GNDACTCODETITLE":"LFS","UsedTimes":73 },
    { "Code":30,"Info":"12 HRS DAY SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":"DHS","UsedTimes":93 },
    { "Code":31,"Info":"DUTY","Category":"ON_DUTY","GNDACTCODETITLE":"DUT","UsedTimes":1826 },
    { "Code":35,"Info":"LOADMASTER DUTY","Category":"ON_DUTY","GNDACTCODETITLE":"LMD","UsedTimes":718 },
    { "Code":36,"Info":"12 HRS NIGHT SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":"NSH","UsedTimes":14 },
    { "Code":37,"Info":"OFFICE DUTY","Category":"ON_DUTY","GNDACTCODETITLE":"ODU","UsedTimes":1790 },
    { "Code":91,"Info":"WORKED OFF DAY","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":133,"Info":"MX DAY SHIFT (12HRS)","Category":"ON_DUTY","GNDACTCODETITLE":"MDS","UsedTimes":2321 },
    { "Code":134,"Info":"MX NIGHT SHIFT (12HRS)","Category":"ON_DUTY","GNDACTCODETITLE":"MNS","UsedTimes":742 },
    { "Code":135,"Info":"MX SHIFT FLEXIBLE","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":137,"Info":"AZZAM NIGHT SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":138,"Info":"AZZAM DAY SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":139,"Info":"ADMIN OFFICE 8HR","Category":"ON_DUTY","GNDACTCODETITLE":"JD8","UsedTimes":308 },
    { "Code":140,"Info":"AZZAM EXTRA SHIFT (FLEXIBLE)","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":144,"Info":"APT OPS DAY SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":"APD","UsedTimes":259 },
    { "Code":145,"Info":"APT OPS NIGHT SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":"APN","UsedTimes":260 },
    { "Code":148,"Info":"BOM TRAVEL DUTY 12:30lt - 20:30lt","Category":"ON_DUTY","GNDACTCODETITLE":"JT2","UsedTimes":74 },
    { "Code":149,"Info":"JED TRAVEL DUTY 08:00 - 16:00","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":150,"Info":"LIS TRAVEL DUTY  09:00lt - 17:00lt","Category":"ON_DUTY","GNDACTCODETITLE":"JT3","UsedTimes":76 },
    { "Code":151,"Info":"JED SAUDIA CITY / GA DESK DUTY","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":152,"Info":"JED GA VOA 24HRS","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":153,"Info":"LAF DAY SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":154,"Info":"LAF DAY SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":155,"Info":"LAF NIGHT SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":156,"Info":"LAF EXTRA SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":160,"Info":"JED AIRPORT B SHIFT DUTY 19-07","Category":"ON_DUTY","GNDACTCODETITLE":"JRB","UsedTimes":164 },
    { "Code":161,"Info":"JED AIRPORT A SHIFT DUTY 07-19","Category":"ON_DUTY","GNDACTCODETITLE":"JRA","UsedTimes":159 },
    { "Code":162,"Info":"OFFICE","Category":"ON_DUTY","GNDACTCODETITLE":"JOF","UsedTimes":86 },
    { "Code":163,"Info":"MED HOTEL/OFFICE A SHIFT 0800-2000","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":164,"Info":"MED HOTEL/OFFICE B SHIFT 2000-0800","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":165,"Info":"MED SV OCC A SHIFT 0800-2000","Category":"ON_DUTY","GNDACTCODETITLE":"MOA","UsedTimes":110 },
    { "Code":166,"Info":"MED SV OCC B SHIFT 2000-0800","Category":"ON_DUTY","GNDACTCODETITLE":"MOB","UsedTimes":84 },
    { "Code":167,"Info":"MED RAMP A SHIFT 0800-2000","Category":"ON_DUTY","GNDACTCODETITLE":"MRA","UsedTimes":58 },
    { "Code":168,"Info":"MED RAMP B SHIFT 2000-0800","Category":"ON_DUTY","GNDACTCODETITLE":"MRB","UsedTimes":18 },
    { "Code":169,"Info":"RUH HOTEL/OFFICE A SHIFT 0800-2000","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":170,"Info":"RUH HOTEL/OFFICE B SHIFT 2000-0800","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":171,"Info":"RUH SV OCC A SHIFT 0800-2000","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":172,"Info":"RUH SV OCC B SHIFT 2000-0800","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":173,"Info":"RUH RAMP A SHIFT 0800-2000","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":174,"Info":"RUH RAMP B SHIFT 2000-0800","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":175,"Info":"DMM DUTY 0800-2000","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":176,"Info":"DMM DUTY 2000-0800","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":177,"Info":"DMM ON DUTY","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":178,"Info":"MED ON DUTY","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":179,"Info":"RUH ON DUTY","Category":"ON_DUTY","GNDACTCODETITLE":"RUH","UsedTimes":222 },
    { "Code":180,"Info":"JED ON DUTY","Category":"ON_DUTY","GNDACTCODETITLE":"JED","UsedTimes":3 },
    { "Code":181,"Info":"MOTIARA DAY SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":186,"Info":"AZZAM MED NIGHT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":187,"Info":"AZZAM MED DAY","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":188,"Info":"Motiara Night Shift","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":190,"Info":"JED GA VOA NIGHT SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":191,"Info":"JED GA VOA DAY SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":194,"Info":"MX RUH NIGHT SHIFT 0000-1200","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":195,"Info":"MX RUH DAY SHIFT 1300-0000","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":197,"Info":"JED GA AZZAM OFFICE DUTY","Category":"ON_DUTY","GNDACTCODETITLE":"JGA","UsedTimes":262 },
    { "Code":204,"Info":"BLH hours Activity","Category":"ON_DUTY","GNDACTCODETITLE":null },
    // see example in roster /Home/FeeStatementInvoiceConfirmation?company=AIRBORNE+-+MALTA&year=2024&month=1&employeeCode=JEMP
    { "Code":205,"Info":"MEETING","Category":"ON_DUTY","GNDACTCODETITLE":"MEE","UsedTimes":27 },
    { "Code":219,"Info":"MNL TRAVEL DUTY 12:00lt - 20:00lt","Category":"ON_DUTY","GNDACTCODETITLE":"JT4","UsedTimes":93 },
    { "Code":221,"Info":"MX DAY SHIFT 07:00 - 17:00","Category":"ON_DUTY","GNDACTCODETITLE":"MXD","UsedTimes":2356 },
    { "Code":222,"Info":"MX NIGHT SHIFT 23:00 - 09:00","Category":"ON_DUTY","GNDACTCODETITLE":"MXN","UsedTimes":1268 },
    { "Code":223,"Info":"MX AFTERNOON SHIFT 15:00 - 01:00","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":401,"Info":"HQ TRAVEL OFFICE DUTY","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":541,"Info":"AZZAM 0800-1600","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":542,"Info":"AZZAM 0000-0800","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":543,"Info":"AZZAM 1600-0000","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":561,"Info":"APT OPS SHIFT 1 0500 ¿ 1300 UTC","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":563,"Info":"APT OPS SHIFT 2 0900 ¿ 1700 UTC","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":564,"Info":"APT OPS SHIFT 3 1300 ¿ 2100 UTC","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":565,"Info":"APT OPS SHIFT 4 1700 ¿ 0100 UTC","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":566,"Info":"APT OPS SHIFT 5 2100 ¿ 0500 UTC","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":681,"Info":"TRAVEL OFFICE DUTY 0900-1600","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":682,"Info":"TRAVEL OFFICE DUTY 1100-1700","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":683,"Info":"TRAVEL OFFICE DUTY 0900-1700","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":801,"Info":"EXTRA FLIGHT ON OFF DAY","Category":"ON_DUTY","GNDACTCODETITLE":"XCO","UsedTimes":116 },
    { "Code":761,"Info":"HARD DAYS","Category":"ON_DUTY","GNDACTCODETITLE":"HRD","UsedTimes":80 },
    { "Code":842,"Info":"MX duty","Category":"ON_DUTY","GNDACTCODETITLE":"MX","UsedTimes":457 },
    { "Code":861,"Info":"Original Duty Start","Category":"ON_DUTY","GNDACTCODETITLE":"ODS","UsedTimes":1671 },
    { "Code":901,"Info":"APT OPS OFFICE HOURS UTC","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1081,"Info":"ON PAY ON PERDIEM","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1083,"Info":"ON PAY OFF PERDIEM","Category":"ON_DUTY","GNDACTCODETITLE":"PXP","UsedTimes":237 },
    { "Code":1101,"Info":"AZZAM A SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1102,"Info":"AZZAM B SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1362,"Info":"EXTRA CONTRIBUTION DAYS - FIA","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1421,"Info":"PR-EXTRA DAY","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1422,"Info":"PR-OVERTIME","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1522,"Info":"EU DRIVER SHIFTS A","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1601,"Info":"EU DRIVER SHIFT B","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1701,"Info":"AIRPORT OPS 12 NIGHT SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1702,"Info":"AIRPORT OPS 12 DAY SHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1741,"Info":"TRAVEL OFFICE DUTY iOCC","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1841,"Info":"EU DRIVER SHIFT C","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1842,"Info":"EU DRIVER SHIFT D","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1881,"Info":"Shortened workweek","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1882,"Info":"Dayshift","Category":"ON_DUTY","GNDACTCODETITLE":"DAY","UsedTimes":6 },
    { "Code":1883,"Info":"Nightshift","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":1886,"Info":"Flex Dayshift","Category":"ON_DUTY","GNDACTCODETITLE":"FLD","UsedTimes":117 },
    { "Code":1887,"Info":"Flex Nightshift","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":2301,"Info":"9-21 DAYSHIFT TRAVEL","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":2341,"Info":"CGA DUTY","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":2401,"Info":"AFA extra day","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":2561,"Info":"WINTER VACATION NIGHTSHIFT","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":2681,"Info":"8-20 DAYSHIFT TRAVEL","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":2801,"Info":"Afternoon shift","Category":"ON_DUTY","GNDACTCODETITLE":null },
    { "Code":2802,"Info":"Afternoon shift sunday","Category":"ON_DUTY","GNDACTCODETITLE":"AFS","UsedTimes":5 },
    { "Code":32,"Info":"INTERVIEWS","Category":"ON_DUTY","GNDACTCODETITLE":"INT","UsedTimes":52 },

    // training
    { "Code":43,"Info":"FIRST AID","Category":"TRAINING","GNDACTCODETITLE":"AID","UsedTimes":88 },
    { "Code":45,"Info":"DANGEROUS GOODS","Category":"TRAINING","GNDACTCODETITLE":"DGR","UsedTimes":593 },
    { "Code":46,"Info":"JAR COURSE","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":47,"Info":"LVO GROUND TRN","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":48,"Info":"WATER SURVIVAL TRAINING","Category":"TRAINING","GNDACTCODETITLE":"WET","UsedTimes":5 },
    { "Code":49,"Info":"COURSE","Category":"TRAINING","GNDACTCODETITLE":"COU","UsedTimes":383 },
    { "Code":50,"Info":"PLANNED LANDINGS","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":52,"Info":"START TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":58,"Info":"AEROPLANE VISIT B747","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":59,"Info":"EVACUATION SLIDE TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":60,"Info":"CONVERSION B747 TRN","Category":"TRAINING","GNDACTCODETITLE":"CT4","UsedTimes":144 },
    { "Code":61,"Info":"READY FOR LINE TRN","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":62,"Info":"UPGRADE TRN","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":63,"Info":"TRN SIM","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":64,"Info":"DOORS AND EXITS","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":65,"Info":"FAMILIARISATION","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":66,"Info":"FIRE AND SMOKE TRN","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":67,"Info":"MEDICAL ASPECTS/FIRST AID","Category":"TRAINING","GNDACTCODETITLE":"FAT","UsedTimes":483 },
    { "Code":68,"Info":"INITIAL TRAINING GENERAL","Category":"TRAINING","GNDACTCODETITLE":"ITG","UsedTimes":624 },
    { "Code":71,"Info":"SENIOR CABIN TRAINING","Category":"TRAINING","GNDACTCODETITLE":"STC","UsedTimes":32 },
    { "Code":72,"Info":"SENIOR CHECK FLIGHT","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":73,"Info":"RECURRENT SENIOR TRN","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":74,"Info":"EMERGENCY SAFTEY TRN ANNUAL A3/31/600","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":75,"Info":"EMERGENCY SAFTEY TRN TRIENNIAL A3/31/600","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":76,"Info":"GROUND AND REFRESHER TRN","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":77,"Info":"LVO SIM A3/31/600","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":78,"Info":"EMERGENCY SAFTEY TRN ANNUAL B747-2/300/400","Category":"TRAINING","GNDACTCODETITLE":"EA4","UsedTimes":3 },
    { "Code":79,"Info":"EMERGENCY SAFTEY TRN TRIENNIAL B747-2/300/400","Category":"TRAINING","GNDACTCODETITLE":"ET4","UsedTimes":22 },
    { "Code":80,"Info":"GROUND AND REFRESHER TRN B747-2/300","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":81,"Info":"LVO SIM B747-2/300","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":82,"Info":"EMERGENCY SAFTEY TRN ANNUAL B747-400","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":83,"Info":"GROUND AND REFRESHER TRN B747-400","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":84,"Info":"LVO SIM B747-400","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":85,"Info":"EMERGENCY SAFTEY TRN TRIENNIAL B747-400","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":86,"Info":"TRAINING TECHNIQUES","Category":"TRAINING","GNDACTCODETITLE":"TRT","UsedTimes":30 },
    { "Code":87,"Info":"B747-400 SIM","Category":"TRAINING","GNDACTCODETITLE":"S44","UsedTimes":492 },
    { "Code":88,"Info":"B747-2/300 SIM","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":89,"Info":"A310/300-600 SIM","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":90,"Info":"SIM INSTRUCTOR","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":105,"Info":"SECURITY AWARENESS COURSE","Category":"TRAINING","GNDACTCODETITLE":"SEC","UsedTimes":189 },
    { "Code":109,"Info":"EMERGENCY RESPONSE TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":121,"Info":"LICENSE ENGLISH PROFICIENCY TEST","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":130,"Info":"PROFICIENCY CHECK - DISPATCH ROUTE CHECK","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":157,"Info":"GROUND STAFF TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":182,"Info":"UNIFORM FITTING","Category":"TRAINING","GNDACTCODETITLE":"UFI","UsedTimes":17 },
    { "Code":183,"Info":"SERVICE TRAINING","Category":"TRAINING","GNDACTCODETITLE":"SRT","UsedTimes":8 },
    { "Code":184,"Info":"INTRODUCTION TO RNAV/RNP OPERATIONS","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":185,"Info":"Dispatch Training","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":189,"Info":"B747-400 CR SIM","Category":"TRAINING","GNDACTCODETITLE":"S4C","UsedTimes":4 },
    { "Code":192,"Info":"SMS TRAINING","Category":"TRAINING","GNDACTCODETITLE":"SMS","UsedTimes":20 },
    { "Code":196,"Info":"PERFORMANCE TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":198,"Info":"GROUND AND REFRESHER TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":199,"Info":"EMERGENCY AND SAFETY TRN ANNUAL","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":200,"Info":"EMERGENCY AND SAFETY TRN TRIENNIAL","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":202,"Info":"A340 LVO SIMULATOR TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":203,"Info":"A340 SIMULATOR TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":206,"Info":"A330 EMERGENCY AND SAFETY TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":207,"Info":"A330 SIMULATOR TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":208,"Info":"A330 LVO SIMULATOR TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":209,"Info":"A330 GROUND AND REFRESHER TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":210,"Info":"A330 EMERGENCY AND SAFETY TRN ANNUAL","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":211,"Info":"SIM - CROSS CREW QUALIFICATION","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":214,"Info":"MAINTENENCE TRAINING","Category":"TRAINING","GNDACTCODETITLE":"MXT","UsedTimes":156 },
    { "Code":215,"Info":"ACARS TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":216,"Info":"FANS TRAINING FLIGHT","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":241,"Info":"STANDARDISATION","Category":"TRAINING","GNDACTCODETITLE":"STI","UsedTimes":10 },
    { "Code":242,"Info":"SIM INSTRUCTOR EVALUATION","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":261,"Info":"SIM INSTRUCTOR NON LINE QUALIFIED","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":281,"Info":"GROUND INSTRUCTOR STANDARDIZATION","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":301,"Info":"ROUTE INSTRUCTOR STANDARDISATION","Category":"TRAINING","GNDACTCODETITLE":"RIS","UsedTimes":6 },
    { "Code":581,"Info":"SIM EVALUATION","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":661,"Info":"CLASSROOM RECURRENT REVIEW A330","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":662,"Info":"CLASSROOM RECURRENT REVIEW B744","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":663,"Info":"CLASSROOM RECURRENT REVIEW A340","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":821,"Info":"CRM TRAINER REFRESHER","Category":"TRAINING","GNDACTCODETITLE":"CTR","UsedTimes":31 },
    { "Code":822,"Info":"CRM TRAINER INITIAL","Category":"TRAINING","GNDACTCODETITLE":"CTI","UsedTimes":24 },
    { "Code":1301,"Info":"GROUND INSTRUCTOR EVALUATION","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":1561,"Info":"B777 SIMULATOR TRAINING","Category":"TRAINING","GNDACTCODETITLE":"S77","UsedTimes":374 },
    { "Code":1563,"Info":"B777 GROUND AND REFRESHER TRN","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":1564,"Info":"B777 ANNUAL EMERGENCY SAFTEY TRN","Category":"TRAINING","GNDACTCODETITLE":"E77","UsedTimes":9 },
    { "Code":1565,"Info":"B777 LVO SIMULATOR TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":1566,"Info":"B777 CLASSROOM RECURRENT REVIEW","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":1567,"Info":"EMERGENCY SAFTEY TRN TRIENNIAL B777","Category":"TRAINING","GNDACTCODETITLE":"ET7","UsedTimes":38 },
    { "Code":1568,"Info":"B777 EMERGENCY AND SAFETY TRAINING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":1961,"Info":"EMERGENCY ANNUAL B747 (EMR PRACTICAL TRN)","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":1962,"Info":"EMERGENCY ANNUAL A340 (EMR PRACTICAL TRN)","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":2501,"Info":"CABIN LINE TRAINER","Category":"TRAINING","GNDACTCODETITLE":"CLT","UsedTimes":8 },
    { "Code":2541,"Info":"PRE-CREWING","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":2221,"Info":"FIRST AID TRAIN THE TRAINER","Category":"TRAINING","GNDACTCODETITLE":"TTF","UsedTimes":21 },
    { "Code":2441,"Info":"AAE conversion couse","Category":"TRAINING","GNDACTCODETITLE":"AAE","UsedTimes":4 },
    { "Code":2601,"Info":"TRAIN THE TRAINER SECURITY","Category":"TRAINING","GNDACTCODETITLE":"TTS","UsedTimes":1 },
    { "Code":2604,"Info":"TRAIN THE TRAINER FIRE AND SMOKE","Category":"TRAINING","GNDACTCODETITLE":"TFS","UsedTimes":20 },
    { "Code":2606,"Info":"TRAIN THE TRAINER DGR","Category":"TRAINING","GNDACTCODETITLE":"TTD","UsedTimes":9 },
    { "Code":2701,"Info":"Crew ride with instructor","Category":"TRAINING","GNDACTCODETITLE":"RWI","UsedTimes":10 },
    { "Code":2742,"Info":"REFRESHER TRAINING B777","Category":"TRAINING","GNDACTCODETITLE":"RT7","UsedTimes":251 },
    { "Code":2841,"Info":"Aeroplane visit B777","Category":"TRAINING","GNDACTCODETITLE":null },
    { "Code":2881,"Info":"WEIGHT AND BALANCE TRAINING","Category":"TRAINING","GNDACTCODETITLE":"WBB","UsedTimes":30 },
    { "Code":2605,"Info":"FATIGUE RISK MANAGEMENT","Category":"TRAINING","GNDACTCODETITLE":"FRM","UsedTimes":118 },
    { "Code":2741,"Info":"CONVERSION B777 TRN","Category":"TRAINING","GNDACTCODETITLE":"CT7","UsedTimes":144 },
    { "Code":44,"Info":"CREW RESOURCE MANAGEMENT","Category":"TRAINING","GNDACTCODETITLE":"CRM","UsedTimes":650 },
    { "Code":69,"Info":"RECURRENT GENERAL","Category":"TRAINING","GNDACTCODETITLE":"RAN","UsedTimes":254 },
    { "Code":70,"Info":"REFRESHER B747","Category":"TRAINING","GNDACTCODETITLE":"RT4","UsedTimes":252 },
    { "Code":781,"Info":"CONVERSION GENERAL","Category":"TRAINING","GNDACTCODETITLE":"CTG","UsedTimes":458 },

    // positioning
    { "Code":1,"Info":"BASE TRANSFER","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":14,"Info":"POS ON TAXI","Category":"POSITIONING","GNDACTCODETITLE":"PTX","UsedTimes":348 },
    { "Code":15,"Info":"POS ON TRAIN","Category":"POSITIONING","GNDACTCODETITLE":"PTR","UsedTimes":128 },
    { "Code":16,"Info":"POS ON AIRLINE","Category":"POSITIONING","GNDACTCODETITLE":"PAR","UsedTimes":2 },
    { "Code":17,"Info":"POS OWN TIME","Category":"POSITIONING","GNDACTCODETITLE":"POT","UsedTimes":757 },
    { "Code":40,"Info":"HOTEL","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":42,"Info":"PICK UP","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":106,"Info":"TRAVEL EXCLUDING DUTY (EU-OPS)","Category":"POSITIONING","GNDACTCODETITLE":"TRL","UsedTimes":4 },
    { "Code":110,"Info":"HOTEL AAI","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":111,"Info":"Hotel Magma","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":841,"Info":"Car Rental","Category":"POSITIONING","GNDACTCODETITLE":"CAR","UsedTimes":100 },
    { "Code":881,"Info":"Hotel Network","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":941,"Info":"Hotel SV","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":961,"Info":"MST apartments","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":1221,"Info":"AAI apartment-Gullsmári 8","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":1242,"Info":"GSP MX apartment","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":1341,"Info":"LGG apartments","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":1401,"Info":"POSITION REQUEST","Category":"POSITIONING","GNDACTCODETITLE":"PRQ","UsedTimes":5 },
    { "Code":1681,"Info":"ORGINAL TRAVEL DAY","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":1781,"Info":"DSA apartment","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":1861,"Info":"AAI TAXI EMPTY TRIP","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":2241,"Info":"Hotel JetoneX","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":2242,"Info":"Hotel Amazair","Category":"POSITIONING","GNDACTCODETITLE":null },
    { "Code":2361,"Info":"WALKING DISTANCE","Category":"POSITIONING","GNDACTCODETITLE":"WAL","UsedTimes":1003 },
    { "Code":2281,"Info":"Airport to Hotel transport excluding duty","Category":"POSITIONING","GNDACTCODETITLE":"TRA","UsedTimes":17100 },
    { "Code":2282,"Info":"Hotel to Airport transport excluding duty","Category":"POSITIONING","GNDACTCODETITLE":"TRH","UsedTimes":19124 },
    { "Code":2283,"Info":"AAI taxi - Airport to Hotel excluding duty","Category":"POSITIONING","GNDACTCODETITLE":"ATA","UsedTimes":143 },
    { "Code":2284,"Info":"AAI taxi - Hotel to Airport excluding duty","Category":"POSITIONING","GNDACTCODETITLE":"ATH","UsedTimes":142 },
    { "Code":1521,"Info":"AAI TAXI","Category":"POSITIONING","GNDACTCODETITLE":"ATX","UsedTimes":900 },
    { "Code":1541,"Info":"AAI TAXI EXCLUDING DUTY","Category":"POSITIONING","GNDACTCODETITLE":null },

    // fact
    { "Code":2482,"Info":"https://www.etakenya.go.ke/en - For eTA","Category":"FACT","GNDACTCODETITLE":"NBO","UsedTimes":97 },
    { "Code":2482,"Info":"EVISA.GO.KE - Transit visa","Category":"FACT","GNDACTCODETITLE":"NBO","UsedTimes":97 },
    { "Code":2621,"Info":"https://visas.immigration.go.ug/","Category":"FACT","GNDACTCODETITLE":"EBB","UsedTimes":107 },
    { "Code":33,"Info":"LEGAL EXCEPTION END","Category":"FACT","GNDACTCODETITLE":"LEE","UsedTimes":95 },
    { "Code":2581,"Info":"EBOLA FORM MUST BE FILLED OUT ONBOARD","Category":"FACT","GNDACTCODETITLE":"EFO","UsedTimes":41 },
    { "Code":34,"Info":"LEGAL EXCEPTION START","Category":"FACT","GNDACTCODETITLE":"LES","UsedTimes":56 },
    { "Code":1982,"Info":"Mandatory reg SIN:https://eservices.ica.gov.sg/sgarrivalcard","Category":"FACT","GNDACTCODETITLE":"SIN","UsedTimes":32 },
    { "Code":341,"Info":"EMBASSY VISA APPLICATION SV","Category":"FACT","GNDACTCODETITLE":"SVA","UsedTimes":11 },
    { "Code":147,"Info":"VISA ON ARRIVAL MARKUP","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":321,"Info":"CHINA VISA PROCESSING","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":361,"Info":"CABIN EMBASSY VISA APPLICATION","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":1801,"Info":"SV VISA STAY EXTENSION","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":1843,"Info":"COVID TEST","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":1981,"Info":"Mandatory reg. for Germany https://www.einreiseanmeldung.de","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2001,"Info":"REGISTER FOR NETHERLAND ARRIVAL","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2002,"Info":"REGISTER FOR BELGIUM ARRIVAL","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2021,"Info":"Kenya reg https://ears.health.go.ke/airline_registration/","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2121,"Info":"Mand reg. for HKG: https://hdf.chp.gov.hk/dhehd/?lang=en-us","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2142,"Info":"https://sa-covid-19-travel.info or form on LIVE","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2481,"Info":"https://visa.visitsaudi.com/Registration/Verify","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2661,"Info":"PLEASE SUBMIT https://www.ghs-hdf.org/hdfvac/fill.php?l=en","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2821,"Info":"Please submit health declaration","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2861,"Info":"Canada eTA - Please apply","Category":"FACT","GNDACTCODETITLE":"CAN","UsedTimes":18 },
    { "Code":2941,"Info":"Visa required for travel to Indonesia.","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2921,"Info":"K-ETA - Please apply https://www.k-eta.go.kr","Category":"FACT","GNDACTCODETITLE":"KET","UsedTimes":7 },
    { "Code":2461,"Info":"BLANK DAY","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2081,"Info":"COVID VACCINATION","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2141,"Info":"COVID ANTIBODY TEST","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2061,"Info":"Self-testkit COVID - test available at hotel lobby","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2201,"Info":"Antigen test","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":2321,"Info":"SV covid test MST","Category":"FACT","GNDACTCODETITLE":null },
    { "Code":41,"Info":"NOTES","Category":"FACT","GNDACTCODETITLE":"NTS","UsedTimes":230 },

    // unknown
    { "Code":38,"Info":"MEETING WITH OPS","GNDACTCODETITLE":null },
    { "Code":39,"Info":"POSSIBLE EXTRA FLIGHT","GNDACTCODETITLE":null },
    { "Code":55,"Info":"VALIDATION PROCESS","GNDACTCODETITLE":null },
    { "Code":124,"Info":"HAJ ONLY CREW","GNDACTCODETITLE":null },
    { "Code":132,"Info":"P-RNAV","GNDACTCODETITLE":null },
    { "Code":193,"Info":"744 PW4000","GNDACTCODETITLE":null },
    { "Code":441,"Info":"HAJ ONLY CREW","GNDACTCODETITLE":null },
    { "Code":501,"Info":"READY FOR FAM","GNDACTCODETITLE":null },
    { "Code":521,"Info":"REFRESHER A330 / A340","GNDACTCODETITLE":null },
    { "Code":522,"Info":"CONVERSION A330/A340","GNDACTCODETITLE":null },
    { "Code":741,"Info":"2 CALENDAR DAYS MONTH","GNDACTCODETITLE":null },
    { "Code":1001,"Info":"SHUTTLE","GNDACTCODETITLE":"BUS","UsedTimes":64 },
    { "Code":1041,"Info":"Second delay","GNDACTCODETITLE":"SDS","UsedTimes":37 },
    { "Code":1141,"Info":"FIA BOUGHT DAY","GNDACTCODETITLE":"FBD","UsedTimes":2 },
    { "Code":1161,"Info":"ONBOARD PERFORMANCE TOOL","GNDACTCODETITLE":null },
    { "Code":1181,"Info":"OTP","GNDACTCODETITLE":null },
    { "Code":1201,"Info":"DAY FOR DAY","GNDACTCODETITLE":"DFD","UsedTimes":10 },
    { "Code":1261,"Info":"Fire Containment Bag activity","GNDACTCODETITLE":null },
    { "Code":1361,"Info":"HAJJ CLAUSE - FIXED CONTRACT","GNDACTCODETITLE":null },
    { "Code":1501,"Info":"HAJJ CLAUSE PAID BACK","GNDACTCODETITLE":null },
    { "Code":1721,"Info":"FIA +27% COVID EXPEMPTION","GNDACTCODETITLE":null },
    { "Code":1941,"Info":"HIGH RISK AREA LAST 10 DAYS","GNDACTCODETITLE":null },
    { "Code":2181,"Info":"2 DAYS FOR 1","GNDACTCODETITLE":null },
    { "Code":2261,"Info":"HKG paid meal","GNDACTCODETITLE":null },
    { "Code":2761,"Info":"Champ HelpDesk","GNDACTCODETITLE":null },
    { "Code":2781,"Info":"MISSING GROUND TRANSPORT","GNDACTCODETITLE":"MGT","UsedTimes":6 }
] as const;
