import type { ReactElement } from "react";
import React from "react";
import { brand, neverNull, typed,asEmailOrFail,toUpperCase } from "../utils/typing";
import * as _ from "lodash";
import type { CompanyName } from "../features/Api";
import api from "../features/Api";
import type { AllCompanies } from "../features/StaticData";
import { getAllCompanies } from "../features/StaticData";
import { deepCopy } from "../utils/dataHelpers";
import type { CrewCode } from "@mhc/utils/types/nav";

export function getEmptyUserFields() {
    return {
        Name: "",
        Email: "",
        Code: brand<CrewCode>("XXXY"),
        IsAdmin: false,
        IsClient: false,
        IsSOEApproval: false,
        ApproveOwnInvoices: false,
        ApproveOwnSoe: false,
        IsActive: false,
        CompanyName: typed<"" | CompanyName>(""),
        DepartmentList: [],
        /**
         * when false, that means that it's a new user creation, when false - an existing user update
         * probably redundant considering that there is also `newUser` state field
         */
        isEdit: false,
    };
}

export function getInitialUserState() {
    return {
        Id: typed<undefined | string>(undefined),
        ...getEmptyUserFields(),
        isManual: false,
        ApprovalDepartmentList: typed<undefined | string[]>(undefined),
        ApprovalCompaniesList: typed<undefined | number[]>(undefined),
        /** probably unused as departments is an array nowadays */
        Department: undefined,
    };
}

export function createEmptyUser(user: UserState) {
    return {
        ..._.cloneDeep(user),
        ...getEmptyUserFields(),
    };
}

export type UserState = ReturnType<typeof getInitialUserState>;

type Props = {
    User: UserState,
    newUser: boolean,
    close: () => void,
    onSubmitted: (emp: UpdateEmployeePayload) => void,
};

type State = {
    User: UserState,
    SelDepartmentsList: string[],
    ApprovalCompaniesList: number[],
    loading: boolean,
    newUser: boolean,
    DepartmentSelected?: string,
    CompanyToBeAdded?: number,
    allCompanies: null | AllCompanies,
};

function MakeUpdateEmployeePayload(userState: UserState, apprvallist: string, companies: number[]) {
    return {
        LocalName: userState.Name,
        EMail: asEmailOrFail(userState.Email),
        No_: userState.Code,
        CompanyName: userState.CompanyName,
        Department: userState.Department ?? null,
        IsAdmin: userState.IsAdmin,
        IsActive: userState.IsActive,
        Id: userState.Id ? +userState.Id : undefined,
        IsClient: userState.IsClient,
        IsSOEApproval: userState.IsSOEApproval,
        ApproveOwnInvoices: userState.ApproveOwnInvoices,
        ApproveOwnSoe: userState.ApproveOwnSoe,
        ApprovalDepartmentList: apprvallist,
        ApprovalCompaniesList: companies,
    } as const;
}

export type UpdateEmployeePayload = ReturnType<typeof MakeUpdateEmployeePayload>;

export default class UserAdminForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            User: deepCopy(this.props.User),
            SelDepartmentsList: deepCopy(this.props.User.ApprovalDepartmentList ?? []),
            ApprovalCompaniesList: this.props.User.ApprovalCompaniesList ?? [],
            loading: false,
            newUser: this.props.newUser,
            allCompanies: null,
        };
        getAllCompanies().then(allCompanies => this.setState({ allCompanies }));
    }

    createCompanySelectItems() {
        const items = [];
        if (this.state.allCompanies) {
            items.push(<option key={""} data-companyname="" value="">Choose Company</option>);
            for (const allCompany of Object.values(this.state.allCompanies)) {
                if (!allCompany.portalEntry) {
                    continue;
                }
                const displayName = allCompany.navEntry?.DisplayName ?? allCompany.COMPANYNAME;
                items.push(<option
                    key={allCompany.COMPANYNAME}
                    value={allCompany.COMPANYNAME}
                >{displayName}</option>);
            }
        }
        return items;
    };

    createCompanyIdSelectItems() {
        const items = [];
        if (this.state.allCompanies) {
            items.push(<option key={-1} data-companyname="" value="">Choose Company</option>);
            for (const allCompany of Object.values(this.state.allCompanies)) {
                if (!allCompany.portalEntry) {
                    continue;
                }
                const displayName = allCompany.navEntry?.DisplayName ?? allCompany.COMPANYNAME;
                items.push(<option
                    key={allCompany.portalEntry.Id}
                    value={allCompany.portalEntry.Id}
                >{displayName}</option>);
            }
        }
        return items;
    };

    _generateCompanyList() {
        let val = "Choose Company";

        if (this.state.User.CompanyName !== "") val = this.state.User.CompanyName;
        return <select required={true} value={val} onChange={(e) => this._ChangeCompany(e)}>
            {this.createCompanySelectItems()}
        </select>;
    };

    _ChangeCompany(e: React.ChangeEvent<HTMLSelectElement>) {
        const User = _.cloneDeep(this.state.User);
        User.CompanyName = brand<CompanyName>(e.target.value);

        this.setState({ User: User, SelDepartmentsList: [] });
    }

    _deleteFromDepartmentList(departmentCode: string) {
        const list = this.state.SelDepartmentsList;
        const filteredArray = list.filter(item => item !== departmentCode);
        this.setState({ SelDepartmentsList: filteredArray });
    };

    _generateSelDepartmentList() {
        const items: ReactElement[] = [];
        this.state.SelDepartmentsList.map((departmentCode) => {
            if (departmentCode !== "") {
                const item = <div key={departmentCode} style={{ fontSize: "20px" }}>
                    <div className="pillow-value">{departmentCode}</div>
                    <button
                        type="button"
                        className="user-settings-list-entry-delete-button"
                        onClick={(e) => this._deleteFromDepartmentList(departmentCode)}
                    >Delete</button>
                </div>;
                items.push(item);
            }
        });
        return (<div className="user-settings-pillows-list">
            {items}
        </div>);
    };

    _generateApprovalCompaniesList() {
        const items: ReactElement[] = this.state.ApprovalCompaniesList.map((companyId) => {
            return <div key={companyId} style={{ fontSize: "20px" }}>
                <div className="pillow-value">{
                    Object.values(this.state.allCompanies ?? {})
                        .filter(ac => ac.portalEntry?.Id === companyId)
                        .map(ac => ac.navEntry?.DisplayName ?? ac.COMPANYNAME)[0] ?? companyId
                }</div>
                <button
                    type="button"
                    className="user-settings-list-entry-delete-button"
                    onClick={(e) => this.setState({
                        ApprovalCompaniesList: (this.state.ApprovalCompaniesList ?? [])
                            .filter(c => c !== companyId),
                    })}
                >Delete</button>
            </div>;
        });
        return (<div className="user-settings-pillows-list">
            {items}
        </div>);
    };

    _ChangeDepartmentList(e: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ DepartmentSelected: e.target.value });
    };

    addDepartmentID(departmentId: string) {
        if (!this.state.SelDepartmentsList.includes(departmentId)) {
            this.setState({ SelDepartmentsList: this.state.SelDepartmentsList.concat(departmentId) });
        }
    };

    createDepartmentSelectItems() {
        const companies: CompanyName[] = [];
        if (this.state.User.CompanyName) {
            companies.push(this.state.User.CompanyName);
        }
        for (const companyId of this.state.ApprovalCompaniesList) {
            const codename = Object.values(this.state.allCompanies ?? {})
                .filter(ac => ac.portalEntry?.Id === companyId)[0]?.COMPANYNAME;
            if (codename) {
                companies.push(codename);
            }
        }
        const items = [];
        items.push(<option key={-1} value="">Select Department</option>);
        if (!this.state.allCompanies) {
            return items;
        }
        const occurrences = new Set<string>();
        for (const company of companies) {
            for (const department of this.state.allCompanies[toUpperCase(company)]?.departments ?? []) {
                if (department.Code === "N/A") {
                    continue;
                }
                if (!this.state.SelDepartmentsList.includes(department.Code) &&
                    !occurrences.has(department.Code)
                ) {
                    occurrences.add(department.Code);
                    items.push(<option
                        key={department.Code}
                        value={department.Code}
                    >{department.Code} - {department.Name}</option>);
                }
            }
        }
        return items;
    };

    _generateDepartmentList() {
        return (<div>
            {this._generateSelDepartmentList()}
            <div className="add-selected-item-pill">
                <select onChange={(e) => this._ChangeDepartmentList(e)}>
                    {this.createDepartmentSelectItems()}
                </select>
                <button type="button" className="btn-warning"
                        disabled={!this.state.DepartmentSelected}
                        onClick={(e) => {
                            if (this.state.DepartmentSelected) {
                                this.addDepartmentID(this.state.DepartmentSelected);
                            }
                        }}>Add
                </button>
            </div>
        </div>);
    };

    _generateCompaniesList() {
        return (<div>
            {this._generateApprovalCompaniesList()}
            <div className="add-selected-item-pill">
                <select onChange={(e) => this.setState({ CompanyToBeAdded: +e.target.value })}>
                    {this.createCompanyIdSelectItems()}
                </select>
                <button type="button" className="btn-warning"
                        disabled={!this.state.CompanyToBeAdded}
                        onClick={(e) => this.setState({
                            ApprovalCompaniesList: [...new Set([
                                ...(this.state.ApprovalCompaniesList ?? []),
                                this.state.CompanyToBeAdded ?? neverNull(),
                            ])],
                        })}>Add
                </button>
            </div>
        </div>);
    };

    _ChangeNewName(e: React.ChangeEvent<HTMLInputElement>) {
        const User = _.cloneDeep(this.state.User);
        User.Name = e.target.value;
        this.setState({ User: User });
    };

    _ChangeNewEmail(e: React.ChangeEvent<HTMLInputElement>) {
        const User = _.cloneDeep(this.state.User);
        User.Email = e.target.value;
        this.setState({ User: User });
    };

    _ChangeNewCode(e: React.ChangeEvent<HTMLInputElement>) {
        const User = _.cloneDeep(this.state.User);
        User.Code = brand<CrewCode>(e.target.value);
        this.setState({ User: User });
    };

    _ChangeNewIsAdmin(e: React.ChangeEvent<HTMLInputElement>) {
        const User = _.cloneDeep(this.state.User);
        User.IsAdmin = e.target.checked;

        this.setState({ User: User });
    };

    _ChangeNewIsActive(e: React.ChangeEvent<HTMLInputElement>) {
        const User = _.cloneDeep(this.state.User);
        User.IsActive = e.target.checked;
        this.setState({ User: User });
    };

    _changeNewIsClient(e: React.ChangeEvent<HTMLInputElement>) {
        const User = _.cloneDeep(this.state.User);
        User.IsClient = e.target.checked;
        this.setState({ User: User });
    };

    _changeNewSEOApproval(e: React.ChangeEvent<HTMLInputElement>) {
        const User = _.cloneDeep(this.state.User);
        User.IsSOEApproval = e.target.checked;
        this.setState({ User: User });
    };

    _changeNewOwnInvoices(e: React.ChangeEvent<HTMLInputElement>) {
        const User = _.cloneDeep(this.state.User);
        User.ApproveOwnInvoices = e.target.checked;
        this.setState({ User: User });
    };

    _changeNewOwnSoe(e: React.ChangeEvent<HTMLInputElement>) {
        const User = _.cloneDeep(this.state.User);
        User.ApproveOwnSoe = e.target.checked;
        this.setState({ User: User });
    };


    async saveUser(e: React.FormEvent) {
        e.preventDefault();
        let apprvallist = "";
        _.forEach(this.state.SelDepartmentsList, function (c) {
            apprvallist += c + "-";
        });
        const emp = MakeUpdateEmployeePayload(this.state.User, apprvallist, this.state.ApprovalCompaniesList);
        const { Id, EMail, CompanyName } = emp;
        if (!EMail) {
            throw new Error("Tried to submit user without email");
        } else if (!CompanyName) {
            throw new Error("Tried to submit user without CompanyName");
        }
        this.setState({ loading: true });
        try {
            if (this.state.User.isEdit) {
                if (!Id) {
                    throw new Error("Tried to edit a user without ID");
                }
                await api.Employee.UpdateEmployee({ ...emp, CompanyName, EMail, Id });
            } else {
                await api.Employee.RegisterAdmin({ ...emp, CompanyName, EMail });
            }
        } finally {
            this.setState({ loading: false });
        }
        const User = createEmptyUser(this.state.User);
        this.setState({ User: User, newUser: false });
        this.props.onSubmitted(emp);
    }

    render() {
        return (<div className="container manualinvoice admin-wrapper">
            <div className="row">
                <div className="col-xs-12">
                    <h2></h2>
                    <form className="form-horizontal" onSubmit={(e) => this.saveUser(e)}>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Name * </label>
                            <div className={"col-sm-4"}>
                                <input required={true} onChange={(e) => this._ChangeNewName(e)}
                                       value={this.state.User.Name}
                                       className="form-control" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Email *</label>
                            <div className={"col-sm-4"}>
                                <input required={true} type="email"
                                       onChange={(e) => this._ChangeNewEmail(e)} value={this.state.User.Email}
                                       className="form-control" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Code *</label>
                            <div className={"col-sm-4"}>
                                <input
                                    required={true}
                                    pattern="^[a-zA-Z]{3,6}$"
                                    onChange={(e) => this._ChangeNewCode(e)}
                                    value={this.state.User.Code}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className={"col-sm-2 control-label"}>Main Company *</label>
                            <div className={"col-sm-4"}>
                                {this._generateCompanyList()}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Approval Companies: </label>
                            <div className="col-sm-6">
                                {this._generateCompaniesList()}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Approval Departments: </label>
                            <div className="col-sm-6">
                                {this._generateDepartmentList()}
                            </div>
                        </div>

                        <div className={"form-group"}>
                            <p><strong className="Error">Roles *</strong></p>
                            <label className="col-sm-2 control-label">
                                <input type="checkbox"
                                       defaultChecked={this.state.User.IsAdmin}
                                       onChange={(e) => this._ChangeNewIsAdmin(e)} className="form-control"/>
                                Admin
                            </label>
                            <div className="col-sm-2"></div>

                            <label className="col-sm-2 control-label">
                                <input type="checkbox"
                                       defaultChecked={this.state.User.IsClient}
                                       onChange={(e) => this._changeNewIsClient(e)} className="form-control"/>
                                Work Invoice Approval
                            </label>
                            <div className="col-sm-2"></div>
                        </div>

                        <div className="form-group">
                            <label className="col-sm-2 control-label">
                                <input type="checkbox"
                                       defaultChecked={this.state.User.IsSOEApproval}
                                       onChange={(e) => this._changeNewSEOApproval(e)} className="form-control"/>
                                SOE approval
                            </label>
                            <div className="col-sm-2"></div>
                        </div>

                        <div className="form-group">
                            <label className="col-sm-2 control-label">
                                <input type="checkbox"
                                       defaultChecked={this.state.User.IsActive || this.state.newUser}
                                       onChange={(e) => this._ChangeNewIsActive(e)} className="form-control"/>
                                Active user
                            </label>
                            <div className="col-sm-2"></div>
                        </div>

                        <br/>
                        <div className="form-group">
                            <label className="col-sm-2 control-label">
                                <input type="checkbox"
                                       defaultChecked={this.state.User.ApproveOwnInvoices}
                                       onChange={(e) => this._changeNewOwnInvoices(e)} className="form-control"/>
                                Approve own Work invoices
                            </label>
                            <div className="col-sm-2"></div>

                            <label className="col-sm-2 control-label">
                                <input type="checkbox"
                                       defaultChecked={this.state.User.ApproveOwnSoe}
                                       onChange={(e) => this._changeNewOwnSoe(e)} className="form-control"/>
                                Approve own SOE
                            </label>

                            <div className="col-sm-2"></div>
                        </div>

                        <div className="row action-buttons">
                            <button type="button" className="btn btn-default"
                                    onClick={(e) => this.props.close()}>Cancel
                            </button>
                            <button type="submit" className="btn btn-warning" disabled={this.state.loading}>Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>);
    }
}