import { useState } from "react";
import React, { useEffect, useMemo } from "react";
import ReactModal from "react-modal";
import type { CompanyName } from "../features/Api";
import Api from "../features/Api";
import { getAllCompaniesDepartmentsMapping } from "../features/Api";
import type { NavEmployeeBase } from "../types/EmployeeInfo";

type SendSubmitManualWorkInvoiceReminderProps = {
    onRequestClose: () => void,
    navCompanyName: CompanyName,
    invoiceMonth: number,
    invoiceYear: number,
};

export default function SubmitManualWorkInvoiceReminderModal({ onRequestClose, navCompanyName, invoiceYear, invoiceMonth }: SendSubmitManualWorkInvoiceReminderProps) {
    const [loading, setLoading] = useState<boolean>(true);
    const [checkedDepartments, setCheckedDepartments] = useState<Set<number>>(new Set());
    const [companyToDepartmentToName, setCompanyToDepartmentToName] = useState<Record<string, Record<number, string>>>();
    const [employees, setEmployees] = useState<NavEmployeeBase[]>();
    const [requestProcessing, setRequestProcessing] = useState(false);

    useEffect(() => {
        Promise.all([
            getAllCompaniesDepartmentsMapping()
                .then(companyToDepartmentToName => setCompanyToDepartmentToName(companyToDepartmentToName)),
            Api.Invoice.GetNavEmployeesWithNoManualReportSubmitted(navCompanyName, invoiceYear, invoiceMonth).then(employees => {
                setEmployees(employees);
            }),
        ]).finally(() => {
            setLoading(false);
        });
    }, []);

    const handleCheckboxChange = (checked: boolean, code: string) => {
        const checkedId = parseInt(code);
        const newDepartments = new Set(checkedDepartments);
        if (checked) {
            newDepartments.add(checkedId);
        } else {
            newDepartments.delete(checkedId);
        }
        setCheckedDepartments(newDepartments);
    };

    const departments = useMemo(() => {
        if (!companyToDepartmentToName || !navCompanyName || !employees) {
            return [];
        }
        const departmentToName = companyToDepartmentToName[navCompanyName] ?? {};

        return Object.entries(departmentToName).map(([code, name]) => {
            const count = employees.filter(item => item.Department == code).length;
            return { code, name, count };
        });
    }, [navCompanyName, companyToDepartmentToName, employees, checkedDepartments]);

    const filteredEmployees = useMemo(() => {
        if (!employees) {
            return [];
        }
        if (departments.length === 0) {
            return employees;
        } else {
            return employees.filter(employee => checkedDepartments.has(parseInt(employee.Department)));
        }
    }, [departments, employees]);

    const sendSubmitManualWorkReportReminderNotification = async (navCompanyName: CompanyName, employees: NavEmployeeBase[]) => {
        if (window.confirm("Please confirm that you intended to send the reminders.")) {
            setRequestProcessing(true);
            try {
                await Api.Invoice.SendSubmitManualReportReminderNotification(navCompanyName, employees, invoiceMonth, invoiceYear);
            } finally {
                setRequestProcessing(false);
            }
            window.toastr.success("Reminders successfully sent!");
        }
    };

    return <ReactModal
        isOpen={true}
        className="send-submit-manual-invoice-reminder-modal"
        ariaHideApp={false}
        onRequestClose={onRequestClose}
        overlayClassName="send-submit-manual-invoice-reminder-modal-overlay">
        <div className="modal-header-wrapper">
            <button onClick={() => onRequestClose()}>
                <img src="/img/modalClose.svg" alt="close-modal-svg"/>
            </button>
        </div>
        <div className="send-submit-manual-invoice-reminder-modal-content">
            <h1>Send Submit Manual Work Invoice Reminder</h1>
            {loading ? <div className="status-loading-animated-ellipsis">Loading records</div> : <>
                {departments.length !== 0 && <div className="departments-select-list">
                    {departments.map(item => {
                        // do not display flight deck and cabin crew departments which uses automatic invoice workflow
                        return item.count > 0 && <label key={item.code}>
                            <input type="checkbox"
                                   checked={checkedDepartments.has(parseInt(item.code))}
                                   name={item.name}
                                   onChange={(event) => handleCheckboxChange(event.target.checked, item.code)}/>
                            <span>{item.code} ({item.count}) {item.name}</span>
                        </label>;
                    })}
                </div>}

                {filteredEmployees && <table>
                    <thead>
                    <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Department</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredEmployees.length != 0 ? filteredEmployees.map(item => {
                        return <tr key={item.No_}>
                            <td>{item.No_}</td>
                            <td>{item.FirstName} {item.LastName}</td>
                            <td>{item.EMail}</td>
                            <td>{item.Department}</td>
                        </tr>;
                    }) : <tr>
                        <td colSpan={4}>No contractors found</td>
                    </tr>}
                    </tbody>
                </table>}
                {navCompanyName && filteredEmployees && <button
                    disabled={requestProcessing}
                    onClick={() => sendSubmitManualWorkReportReminderNotification(navCompanyName, filteredEmployees)}
                    className="send-reminder-button">
                    {!requestProcessing ? "Send reminder" : "Processing..."}
                </button>}
            </>}
        </div>
    </ReactModal>;
}