

import * as React from "react";
import SoeDocumentFilePreview from "../components/SoeDocumentFilePreview";

import * as _ from "lodash";
import { neverNull, typed } from "../utils/typing";
import type  { AnyFormatDate, ClaimCategory, ClaimSubmissionData } from "../types/Claim";
import type { CompanyName, CurrencyIsoCode } from "../features/Api";
import api from "../features/Api";
import type OpenExchangeRates from "../types/OpenExchangeRates";
import { getEquivalent } from "../utils/currency";
import { getJson } from "../features/httpUtils";
import { getDiffDays } from "../utils/Helper";
import { getAllNavBases, getNavCompanyProjects } from "../features/StaticData";
import type WiseCodeValue from "../types/WiseCodeValue";
import type Currency from "../types/Currency";
import { parseCOMPANYNAME } from "../features/payrollUtils";
import AppEnvironment from "../features/AppEnvironment";
import type { IsoDate } from "../types/utility";
import { getDatePart } from "../utils/dates";

type FormRecognizerAnalyzeResult = {
  /**
   * too lazy to describe, should check types here:
   * @see https://www.npmjs.com/package/@azure/ai-form-recognizer
   * @see https://learn.microsoft.com/en-us/azure/ai-services/document-intelligence/concept-invoice?view=doc-intel-3.1.0#field-extraction
   */
  documents?: {
    fields?: {
      InvoiceTotal?: {
        valueCurrency?: {
          amount: number,
          currencyCode: string,
        },
      },
      InvoiceDate?: {
        valueDate: string,
      },
    },
  }[],
};

type FormRecognizerResponseBase = {
  status: "running" | "succeeded",
  /** ISO datetime like "2023-08-08T08:40:36Z" */
  createdDateTime: string,
  /** ISO datetime like "2023-08-08T08:40:36Z" */
  lastUpdatedDateTime: string,
};

export type ISoeDocumentBaseOcrData = {
  amount: number | null, // 1000.0
  currency: CurrencyIsoCode | null, // "DOP"
  transaction_date: string | null, // "2024-01-07T00:00:00"
};

type FormRecognizerResponse = FormRecognizerResponseBase & ({
  status: "running",
} | {
  status: "succeeded",
  analyzeResult: FormRecognizerAnalyzeResult,
  docRecord: ISoeDocumentBaseOcrData,
});

async function pollForFormRecognizerResult(operationLocation: string): Promise<FormRecognizerResponse & { status: "succeeded" }> {
  let lastResult: FormRecognizerResponse | null = null;
  for (let i = 0; i < 50; ++i) {
    const url = "/api/claim/checkFormRecognizerResult?" +
      new URLSearchParams({ operationLocation });
    lastResult = await getJson<FormRecognizerResponse>(url);
    if (lastResult.status !== "running") {
      break;
    }
    await new Promise(resolve => setTimeout(resolve, 500));
  }
  const result = lastResult ?? neverNull();
  if (result.status !== "succeeded") {
    const message = "Form Recognizer status is not 'succeeded': " + result.status;
    const error: Error & { data?: object } = new Error(message);
    error.data = result;
    throw error;
  }
  return result;
}

function asIsoDate(input: AnyFormatDate | null | undefined): IsoDate | "" {
    if (!input) {
        return "";
    }
    let dateObj;
    try {
        dateObj = new Date(input);
    } catch (error) {
        if (error instanceof Error) {
            error.message = "Bad date format: " + input + " " + error.message;
        }
        throw error;
    }
    return getDatePart(dateObj);
}

const EMPTY_DATE = typed<null | Date>(null);

const ALLOWED_EXTENSIONS = ["jpg", "jpeg", "png", "pdf", "xls", "xlsx"];

const NOW = new Date();

type UploadedFile = {
  inputName: string,
  effectiveName: string,
  accessToken: string,
  email: string,
};

type Props = {
  code: string, // '@(Model.Employee != null ? Model.Employee.No_ : "")',
  employeeId: string, // '@(Model.Employee != null ? Model.Employee.Id.ToString() : "")',
  toCurrency: "", // '',
  companyName: CompanyName, // '@(Model.Employee != null ? Model.Employee.CompanyName : "")'
};

function getInitialState(props: Props) {
  return {
    claim: typed<ClaimSubmissionData<null>>({
      EmployeeId: Number(props.employeeId),
      CategoryId: "",
      SubCategoryId: "",
      Date: EMPTY_DATE,
      TravelDate: EMPTY_DATE,
      Receipts: typed<string[]>([]),
      FromCurrency: null,
      ToCurrency: props.toCurrency,
      FromAmount: typed<number | string>(0),
      ToAmount: 0,
      Category: "",
      Description: typed<string>(""),
      Project: typed<null | string>(null),
      AcCode: typed<null | string>(null),
      Base: typed<null | string>(null),
    }),
    activeUploadFileNames: typed<string[]>([]),
    uploadedFiles: typed<UploadedFile[]>([]),
    deletionFileNames: typed<string[]>([]),
    formRecognizerStatus: "AWAITING_INPUT",
    loading: false,
    fileDragHover: false,
    submitting: false,
    fileUploadError: typed<null | string>(null),
    error: typed<string | null>(null),
    whenCurrencyExchangeRates: api.Claim.getCurrencyExchangeRates(),
    currencyExchangeRates: typed<OpenExchangeRates | undefined>(undefined),
    claimCategories: typed<ClaimCategory[] | undefined>(undefined),
    soeFields: typed<string[] | undefined>(undefined),
    allNavBases: typed<null | WiseCodeValue[]>(null),
    navCompanyProjects: typed<null | WiseCodeValue[]>(null),
    currencyOptions: typed<null | Currency[]>(null),
  };
}

type State = ReturnType<typeof getInitialState>;

export default class Claim extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    require("./FormFields.css");
    require("./Claim.css");
    this.state = getInitialState(props);
    this.state.whenCurrencyExchangeRates.then(currencyExchangeRates => this.setState({ currencyExchangeRates }));
  };

  componentDidMount() {
    const profilePayload = {
      employeeId: Number(this.props.employeeId),
    };
    this.setState({ loading: true });
    api.Employee.LoadProfile(profilePayload).then(async profile => {
        const soeFields = !profile.MainJobTitle ? [] : await api.Company
            .GetCompanyFieldSetupByCompanyAndJobtitleNew({
                company: this.props.companyName,
                jobtitle: profile.MainJobTitle,
                type: 2
            });
        if (soeFields.indexOf("Project") > -1) {
            const navCompanyProjects = await getNavCompanyProjects(this.props.companyName);
            this.setState({ navCompanyProjects });
        }
        if (soeFields.indexOf("Base") > -1) {
            const allNavBases = await getAllNavBases();
            this.setState({ allNavBases });
        }
        this.setState({ soeFields });
    }).finally(() => this.setState({ loading: false }));
    api.Claim.GetClaimCategories().then(claimCategories => this.setState({ claimCategories }));
    api.Claim.GetAllCurrencies().then(currencyOptions => this.setState({ currencyOptions }));
  };

  _selectCategory(e: React.ChangeEvent<HTMLSelectElement>) {
    const Claim = _.cloneDeep(this.state.claim);
    Claim.CategoryId = e.target.value;
    Claim.SubCategoryId = "";
    this.setState({ claim: Claim, error: null });
  };

  _selectSubCategory(e: React.ChangeEvent<HTMLSelectElement>) {
    const Claim = _.cloneDeep(this.state.claim);
    Claim.SubCategoryId = e.target.value;
    this.setState({ claim: Claim, error: null });
  };

  _selectDate(dateStr: string) {
    const Claim = _.cloneDeep(this.state.claim);
    Claim.Date = !dateStr ? null :
        new Date(dateStr);
    this.setState({ claim: Claim, error: null });
  };

  _selectTravelDate(dateStr: string) {
    const Claim = _.cloneDeep(this.state.claim);
    Claim.TravelDate = !dateStr ? undefined :
        new Date(dateStr);
    this.setState({ claim: Claim, error: null });
  };

  _fillFormFromOcr(result: ISoeDocumentBaseOcrData, rates: OpenExchangeRates) {
    const Claim = _.cloneDeep(this.state.claim);
    if (this.state.claim.Date === EMPTY_DATE && result.transaction_date) {
      Claim.Date = new Date(result.transaction_date.slice(0, 10));
    }
    if (!Claim.FromCurrency && result.currency) {
      Claim.FromCurrency = result.currency;
    }
    if (!Claim.FromAmount && result.amount && Claim.FromCurrency) {
      const asUsd = !result.currency || !result.amount ? null : getEquivalent(
          Claim.FromCurrency, result.amount, "USD", rates
      );
      // often OCR can't detect the decimal dot which causes auto-entered number get x100 greater than what it should
      // these cases are rather dangerous, so we better ignore OCR when number appears to be unrealistic, than to risk paying it
      const isSaneAmount = !asUsd || asUsd < 2000;
      Claim.FromAmount = isSaneAmount
        ? Math.abs(result.amount)
        : Math.abs(result.amount / 100);
    }
    this.setState({ claim: Claim, error: null });
  }

  _getEquivalentAmount(claim: ClaimSubmissionData<null>) {
    // 1 = Airborne - Malta
    // 87 = SO Tech (USD)
    // 95 = HEL - AAIUSD
    const targetCurrency = parseCOMPANYNAME(this.props.companyName)?.currency ?? "EUR";
    if (!this.state.currencyExchangeRates ||
        claim.FromCurrency === targetCurrency
    ) {
      return undefined;
    }
    const targetEquivalent = !claim.FromCurrency ? null : getEquivalent(
        claim.FromCurrency, claim.FromAmount,
        targetCurrency, this.state.currencyExchangeRates
    );
    if (!targetEquivalent) {
      return undefined;
    }
    return (
        <div className={"currency-equivalent-section" + (targetEquivalent > 1000 ? " large-amount" : "")}>
          <div>
            <label className="control-label claimAttributeHeading">
              {targetCurrency} Equivalent:{" "}
            </label>
            <span className="currency-equivalent-section field-value">
            {targetEquivalent.toFixed(2)}{" "}
          </span>
          </div>
          <div className="forInformationalPurposesOnlyNote">(for informational purposes only)</div>
        </div>
    );
  }

  _uploadReceipt(event: React.ChangeEvent<HTMLInputElement>) {
      this.setState({ fileDragHover: false });
      const input = event.target;
      if (!input.files || !input.files[0]) {
        return;
      }
      this.setState({ fileUploadError: null });
      for (let i = 0; i < input.files.length; i++) {
        const inputName = input.files[i].name;
        const extension = inputName.toLowerCase().split(".").slice(-1)[0];
        if (!ALLOWED_EXTENSIONS.includes(extension)) {
          window.toastr.error("Unsupported file type: " + extension + ". Must be one of: " + ALLOWED_EXTENSIONS.map(ext => "." + ext).join(" "));
          continue;
        }
        this.setState({
          activeUploadFileNames: [
            ...this.state.activeUploadFileNames ?? [],
            inputName
          ],
        });
        api.Claim.UploadFile(input.files[i])
          .then((payload: { newName: string, accessToken: string, email: string, formRecognizerOperationLocation: string | null }) => {
            if (payload.formRecognizerOperationLocation) {
              this.setState({ formRecognizerStatus: "AWAITING_SERVICE_REPLY" });
              pollForFormRecognizerResult(payload.formRecognizerOperationLocation).then(
                async result => {
                  this.setState({ formRecognizerStatus: "SUCCESS" });
                  const rates = await this.state.whenCurrencyExchangeRates;
                  this._fillFormFromOcr(result.docRecord, rates);
                },
                error => this.setState({ formRecognizerStatus: "FAILURE" })
              );
            } else {
              this.setState({ formRecognizerStatus: "FAILURE" });
            }
            this.setState({
              uploadedFiles: [
                {
                  inputName: inputName,
                  effectiveName: payload.newName,
                  accessToken: payload.accessToken,
                  email: payload.email
                },
                ...this.state.uploadedFiles ?? [],
              ],
            });
            this.setState({
              loading: false,
              error: null
            });
          }, (error) => {
            this.setState({ fileUploadError: String(error) });
          })
          .finally(() => this.setState({
            activeUploadFileNames: this.state
                .activeUploadFileNames.filter(fn => fn !== inputName),
          }));
      }
      // reset input so that if person could upload
      // same file again in he accidentally removes it
      input.value = "";
  };

  _selectCurrency(e: React.ChangeEvent<HTMLSelectElement>) {
    const Claim = _.cloneDeep(this.state.claim);
    Claim.FromCurrency = e.target.value as CurrencyIsoCode;
    this.setState({ claim: Claim, error: null });
  };

  _updateAmount(e: React.ChangeEvent<HTMLInputElement>) {
    const Claim = _.cloneDeep(this.state.claim);
    Claim.FromAmount = e.target.value;
    this.setState({ claim: Claim, error: null });
  };

  _updateDescription(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const Claim = _.cloneDeep(this.state.claim);
    Claim.Description = e.target.value;
    this.setState({ claim: Claim, error: null });
  };

  _updateProject(e: React.ChangeEvent<HTMLSelectElement>) {
    const Claim = _.cloneDeep(this.state.claim);
    Claim.Project = e.target.value;
    this.setState({ claim: Claim, error: null });
  };

  _updateBase(e: React.ChangeEvent<HTMLSelectElement>) {
    const Claim = _.cloneDeep(this.state.claim);
    Claim.Base = e.target.value;
    this.setState({ claim: Claim, error: null });
  };

  _getCategories(claimCategories: ClaimCategory[]) {
    const categories = [];
    categories.push(<option key="C0" value="">Not Specified</option>);
    _.map(_.filter(claimCategories, { "ParentCategoryId": null }), function (category: ClaimCategory) {
      categories.push(<option key={category.CategoryId} value={category.CategoryId}>{category.Category}</option>);
    });

    return categories;
  };

  _getSubCategories(claimCategories: ClaimCategory[]) {
    const subCategories = [];
    subCategories.push(<option key="SC0" value="">Not Specified</option>);

    _.map(_.filter(claimCategories, { "ParentCategoryId": Number(this.state.claim.CategoryId) }), function (subcategory) {
      subCategories.push(<option key={subcategory.CategoryId}
        value={subcategory.CategoryId}>{subcategory.Category}</option>);
    });

    return subCategories;
  };

  // TODO: copypaste, move to reusable helper function
  _getCurrencies() {
    if (!this.state.currencyOptions) {
        return <option disabled={true} className="status-loading-animated-ellipsis">Loading Currencies</option>;
    }
    const currencies = [];
    currencies.push(<option key="CUR00" value="">Not Specified</option>);

    _.sortBy(this.state.currencyOptions, "Name").forEach((c) => {
        currencies.push(<option key={c.Code} value={c.Code}>{c.Name} - {c.Code}</option>);
    });

    return currencies;
  };

  // same code in ManualInvoiceDetailsForm.tsx
  // TODO: same code in Claim.tsx - should move to component
  _getProjects() {
    if (!this.state.navCompanyProjects) {
        return <option disabled={true} className="status-loading-animated-ellipsis">Loading Projects</option>;
    }
    const projects = [];
    projects.push(
      <option key="PRJ00" value="">
        Not Specified
      </option>
    );

    _.sortBy(this.state.navCompanyProjects, "Code").forEach((p) => {
      projects.push(
        <option key={p.Code} value={p.Code}>
          {p.Name}
        </option>
      );
    });

    return projects;
  };

  _getBases() {
      if (!this.state.allNavBases) {
          return <option disabled={true} className="status-loading-animated-ellipsis">Loading Bases</option>;
      }
      return [
          <option key="" value="">Select Base</option>,
          ..._.sortBy(this.state.allNavBases, "Code").map(
              (p) => <option value={p.Code} key={p.Code}>
                  {p.Code} - {p.Name}
              </option>
          ),
      ];
  };

  _getFileViews() {
    const fileNames = [];
    for (const fileName of this.state.activeUploadFileNames) {
      fileNames.push(<div key={fileName} className="uploadedFileEntry">
        <div className="badge status-loading-animated-ellipsis">{fileName}</div>
      </div>);
    }
    _.map(_.sortBy(this.state.uploadedFiles, "name"), (file) => {
      fileNames.push(<div key={file.effectiveName} className="uploadedFileEntry receiptContainer">
        <div className="uploadedFileHeader">
          <div className="badge">{file.inputName}</div>
          <button className="remove-box"
            type="button"
            disabled={this.state.deletionFileNames.includes(file.effectiveName)}
            onClick={() => this._deleteFile(file)}
          >🗑  Remove</button>
        </div>
        <div>
          <SoeDocumentFilePreview
            fileName={file.effectiveName}
            viewUrl={`/api/claim/viewfilebyname?${new URLSearchParams({
                fileName: file.effectiveName,
                accessToken: file.accessToken,
                email: file.email,
            })}`}
          />
        </div>
      </div>);
    });
    return fileNames;
  };

  _createActions() {
    if (this.state.submitting) {
        return <button
            className="btn-submit status-loading-animated-ellipsis"
            type="submit"
            disabled={true}
        ></button>;
    } else {
        return <button
            className="btn-submit"
            type="submit"
        >Submit</button>;
    }
  }

  _createErrors() {
    if (this.state.error != null)
      return <span className="control-label alert alert-danger"><span className="mandatoryFieldMark">*</span>{this.state.error}</span>;
    else
      return null;
  };

  _goBack() {
    location.href = "/Home/claimsdashboard";
  };

  async _deleteFile(file: UploadedFile) {
    this.setState({
      deletionFileNames: [...this.state.deletionFileNames ?? [], file.effectiveName],
    });
    const response = await fetch(AppEnvironment.API_BASE_URL + "/api/claim/deletefile?" + new URLSearchParams({
      fileName: file.effectiveName,
    }), {
      method: "DELETE",
    }).finally(() => this.setState({
      deletionFileNames: this.state.deletionFileNames
        .filter(n => n !== file.effectiveName),
    }));

    if (response.status === 200) {
      this.setState({
        uploadedFiles: (this.state.uploadedFiles ?? [])
          .filter(f => f.effectiveName !== file.effectiveName),
      });
    } else {
      const body = (await response.text().catch(() => "(failed to retrieve response)")) || "(no response)";
      alert("Unsuccessful status code " + response.status + " - " + body);
    }
  }

  _submitClaim(event: React.FormEvent) {
    event.preventDefault(); // prevent page reload on submit
    let list: string[] = [];
    if (this.state.soeFields != undefined) {
      list = this.state.soeFields;
    }
    const { FromCurrency, Date } = this.state.claim;
    if (!this.state.claim.CategoryId) {
      this.setState({ error: "Select Category" });
    } else if (!Date) {
      this.setState({ error: "Select Date Of Receipt" });
    } else if ((this.state.uploadedFiles.length <= 0)) {
      this.setState({ error: "Attach Receipt" });
    } else if ((this.state.activeUploadFileNames.length > 0)) {
      this.setState({ error: "Receipt file is still uploading, please wait few seconds and try again" });
    } else if (!FromCurrency) {
      this.setState({ error: "Select Currency" });
    } else if (this.state.claim.FromAmount == 0) {
      this.setState({ error: "Enter Amount" });
    } else if (!this.state.claim.Project && (list.indexOf("Project") > -1)) {
      this.setState({ error: "Select Project" });
    } else if (!this.state.claim.AcCode && (list.indexOf("AcCode") > -1)) {
      this.setState({ error: "Select AC Code" });
    } else if (!this.state.claim.Base && (list.indexOf("Base") > -1)) {
      this.setState({ error: "Select Base" });
    } else if (getDiffDays(NOW, this.state.claim.Date) > 90 &&
              !this.state.claim.Description
    ) {
      this.setState({ error: "Enter reasons for late submission" });
    } else {
        const claim = _.cloneDeep(this.state.claim);
        claim.Receipts = this.state.uploadedFiles.map(uf => uf.effectiveName);
        this.setState({ submitting: true });
        api.Claim.SubmitClaim({ ...claim, FromCurrency, Date })
          .then(() => {
            location.href = "/Home/ClaimsDashboard";
          }, (error: unknown) => {
            this.setState({ submitting: false });
            this.setState({ error: String(error) });
          });
    }
    return false;
  };

  render() {
    let list: string[] = [];
    if (this.state.soeFields != undefined) {
      list = this.state.soeFields;
    }

    return (
      <div className="invoice-wrapper">
        <link rel="prefetch" href="/img/loading_animation.gif"/>
        <div className="field-container">
          <div className="row">
            <div className="col-xs-12 claimForm">
              <div className="Title-Claim">
                <img className="back-button" src="/img/back.png" onClick={(e) => this._goBack()} alt="Back" />
                <h2>
                  <b>Create SoE</b>
                </h2>

              </div>
              <form className="container-claim" onSubmit={(e) => this._submitClaim(e)}>
                <div className="border-upload outerReceiptContainer">
                  <div className="button-wrapper">
                    <label className={"btn-upload " + (this.state.fileDragHover ? "file-drag-over" : "")}>
                      <span className="or-take-photo-suffix">📷/</span><span>Upload Receipt File<span className="mandatoryFieldMark">*</span></span>
                      <input className="form-control" type="file" onChange={(e) => this._uploadReceipt(e)}
                             onDragEnter={e => this.setState({ fileDragHover: true })}
                             onDragLeave={e => this.setState({ fileDragHover: false })}
                             accept={ALLOWED_EXTENSIONS.map(e => "." + e).join(", ")} multiple />
                    </label>
                    <span className="tooltip-span">Allowed file types: .jpeg, .jpg, .png, .pdf, .xls and .xlsx</span>
                    <span className="error-span">{this.state.fileUploadError}</span>
                    <div className="uploadedFilesList">{this._getFileViews()}</div>
                  </div>
                </div>
                <div className="claimGroup">
                  <div className="blue-box">
                    {!this.state.claimCategories ? <div className="status-loading-animated-ellipsis">Categories Loading</div> : <>
                      <div className="materialField">
                        <label className="dropdown-label" htmlFor="category-dropdown">Select Category<span className="mandatoryFieldMark">*</span></label>
                        <div className="material-dropdown">
                          <select id="category-dropdown" required={true} onChange={(e) => this._selectCategory(e)}
                                  value={this.state.claim.CategoryId}>
                            {this._getCategories(this.state.claimCategories)}
                          </select>
                        </div>
                      </div>

                      <div className="materialField">
                        <label className="dropdown-label" htmlFor="sub-category-dropdown">Select Sub Category</label>
                        <div className="material-dropdown">
                          <select id="sub-category-dropdown" onChange={(e) => this._selectSubCategory(e)}
                                  disabled={this._getSubCategories(this.state.claimCategories).length === 1}
                                  value={this.state.claim.SubCategoryId ?? ""}>
                            {this._getSubCategories(this.state.claimCategories)}
                          </select>
                        </div>
                      </div>
                    </>}

                    {this.state.loading && <div className="status-loading-animated-ellipsis">Loading Configuration</div>}

                    <div className={(list.indexOf("Project") > -1) ? "materialField" : "hideMe"}>
                      <label className="dropdown-label" htmlFor="project-dropdown">Project<span className="mandatoryFieldMark">*</span></label>
                      <div className={(list.indexOf("Project") > -1) ? "material-dropdown" : ""}>
                        <select id="project-dropdown" required={(list.indexOf("Project") > -1)} onChange={(e) => this._updateProject(e)}
                                value={this.state.claim.Project ?? ""}>
                          {this._getProjects()}
                        </select>
                      </div>
                    </div>

                    <div className={(list.indexOf("Base") > -1) ? "materialField" : "hideMe"}>
                      <label className="dropdown-label" htmlFor="base-dropdown">Select Base<span className="mandatoryFieldMark">*</span></label>
                      <div className={(list.indexOf("Base") > -1) ? "material-dropdown" : ""}>
                        <select id="base-dropdown" required={(list.indexOf("Base") > -1)} onChange={(e) => this._updateBase(e)}
                                value={this.state.claim.Base ?? ""}>
                          {this._getBases()}
                        </select>
                      </div>
                    </div>

                    <div className="claimGroup">
                      <div className="materialField">
                        <label className="calendar-field">Date of Travel</label>
                        <div className="calendar-container">
                          <input
                              type="date"
                              min={"2017-01-01"}
                              max={new Date().toISOString().slice(0, 10)}
                              value={asIsoDate(this.state.claim.TravelDate)}
                              onChange={(e) => this._selectTravelDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="blue-box">
                    <div className={"tooltip-span ocr-status-line" + (this.state.formRecognizerStatus === "AWAITING_SERVICE_REPLY" ? " status-loading-animated-ellipsis" : "")}>OCR Status: {this.state.formRecognizerStatus}</div>
                    <div className="claimGroup">
                      <div className="materialField">
                        <label className="calendar-field">Date of Receipt<span className="mandatoryFieldMark">*</span></label>
                        <div className="calendar-container">
                          <input
                              type="date"
                              required={true}
                              min={"2017-01-01"}
                              max={new Date().toISOString().slice(0, 10)}
                              value={asIsoDate(this.state.claim.Date)}
                              onChange={(e) => this._selectDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="materialField">
                      <label className="dropdown-label" htmlFor="currency-dropdown">Select Currency<span className="mandatoryFieldMark">*</span></label>
                      <div className="material-dropdown">
                        <select id="currency-dropdown" required={true} onChange={(e) => this._selectCurrency(e)}
                                value={this.state.claim.FromCurrency ?? ""}>
                          {this._getCurrencies()}
                        </select>
                      </div>
                    </div>

                    <div className="number-field materialField">
                      <label htmlFor="numberInput">Amount<span className="mandatoryFieldMark">*</span></label>
                      <input type="number" step="0.01" required={true} min="0.01" onChange={(e) => this._updateAmount(e)}
                             value={this.state.claim.FromAmount}
                             id="numberInput" placeholder="The value to be reimbursed"/>
                    </div>
                    {this._getEquivalentAmount(this.state.claim)}
                  </div>

                  <div className="text-field">
                    <textarea id="descriptionInput" placeholder=" " onChange={(e) => this._updateDescription(e)}
                              value={this.state.claim.Description ?? ""}>
                    </textarea>
                    <label htmlFor="descriptionInput">Description</label>
                  </div>

                  <div className="submit-button">
                    {this._createErrors()}
                    {this._createActions()}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
