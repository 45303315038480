import * as React from "react";
import ReactModal from "react-modal";
import { getConfirmationTotal } from "../../utils/invoiceListingUtils";
import type { Dispatch } from "react";
import { useState } from "react";
import type { PayrollLocator } from "../../features/Api";
import api from "../../features/Api";
import type { Payslip } from "../../types/NavisionPayslip";
import { getPartnerCompanyName } from "../../features/payrollUtils";

type ReportDiscrepancyModalProps = {
    selectedInvoice: Payslip,
    payrollLocator: PayrollLocator,
    onSubmitted: () => void,
    setProcessingRequest: Dispatch<boolean>,
};

export function ReportDiscrepancyModal(props: ReportDiscrepancyModalProps) {
    const [discrepancyDescription, setDiscrepancyDescription] = useState<string>("");
    const [openModal, setOpenModal] = useState<boolean>(false);

    const reportDiscrepancy = async (event: React.MouseEvent | React.FormEvent, onlyFor: "OFFICER" | null) => {
        event.preventDefault();
        if (!discrepancyDescription.trim()) {
            throw new Error("Please, enter text describing the discrepancy");
        }
        props.setProcessingRequest(true);
        setOpenModal(false);
        try {
            await api.Invoice.ClientReportInconsistencyInNavInvoice({
                ...props.payrollLocator,
                employeeCode: props.selectedInvoice.Kennitala,
                confirmationTotal: getConfirmationTotal(props.selectedInvoice),
                discrepancyDescription: discrepancyDescription,
                onlyFor: onlyFor,
            });
            props.onSubmitted();
        } catch (error) {
            setOpenModal(true);
            throw error;
        } finally {
            props.setProcessingRequest(false);
        }
    };

    function getPartnerCompany() {
        return getPartnerCompanyName(props.payrollLocator.company) ?? "Payroll Officer";
    }

    return <>
        <button
            type="button"
            className="action-button action-button-reject"
            onClick={() => {
                setOpenModal(true);
            }}>Comment
        </button>
        <ReactModal
            isOpen={openModal}
            contentLabel="Change Needed"
            className="report-discrepancy-modal"
            shouldFocusAfterRender={false}
            ariaHideApp={false}
            onRequestClose={() => setOpenModal(false)}
        >
            <div className="modal-header-wrapper">
                <button onClick={() => setOpenModal(false)}>
                    <img src="/img/modalClose.svg" alt="close-modal-svg"/>
                </button>
            </div>
            {props.selectedInvoice && <div className="modal-content-wrapper">
                <h1>Change Needed</h1>
                <p>Crew Code: <strong>{props.selectedInvoice.Kennitala}</strong></p>
                <p>Name: <strong>{props.selectedInvoice.Nafn}</strong></p>
                <p>Total: <strong>{getConfirmationTotal(props.selectedInvoice)}</strong></p>
                <form
                    onSubmit={(e) => reportDiscrepancy(e, "OFFICER")}
                    className="rejectSection"
                >
                    <textarea
                        required={true}
                        rows={5}
                        value={discrepancyDescription}
                        onChange={e => setDiscrepancyDescription(e.target.value)}
                        placeholder="What are the discrepancies in selected invoice?"
                        autoFocus={true}
                    ></textarea>
                    <div>Visible To...</div>
                    <button
                        type="submit"
                        className="rejectBtn safer-option"
                    >Only To {getPartnerCompany()}
                    </button>
                    <button
                        type="button"
                        className="rejectBtn"
                        onClick={(e) => reportDiscrepancy(e, null)}
                    >To Everyone
                    </button>
                </form>
            </div>}
        </ReactModal>
    </>;
}