import * as React from "react";
import ReactModal from "react-modal";
import type { createPdf } from "pdfmake/build/pdfmake";
import type { ManualInvoiceDetails as ManualInvoiceDetailsType } from "../types/Api/Invoice";
import type { Dispatch } from "redux";
import { prepareManualInvoiceForPdfExport } from "../features/manualInvoicePdfGeneration";
import type { AbsoluteMonth } from "@mhc/utils/src/dates";
import  { incrementMonth } from "@mhc/utils/src/dates";
import  { getMonthStartDate } from "@mhc/utils/src/dates";
import  { decrementMonth } from "@mhc/utils/src/dates";
import { getMonthDate, getMonthFullName } from "@mhc/utils/src/dates";
import { getNthWorkingDayInLatvia } from "../utils/dates";
import { neverNull, typed } from "@mhc/utils/src/typing";
import { toCOMPANYNAMEUC, toCrewCodeUc } from "@mhc/utils/types/nav";
import ManualInvoiceDetailsForm from "./ManualInvoiceDetailsForm";
import type { SetupField } from "./ManualInvoiceDetailsTable";
import ManualInvoiceDetailsTable from "./ManualInvoiceDetailsTable";
import type { EmployeeInfoBase, EmployeeInfoWise } from "../types/EmployeeInfo";
import ManualInvoiceAaiMtxSubmissionForm from "./ManualInvoiceAaiMtxSubmissionForm";
import { isAirAtlanta, isMaintenanceDepartment } from "../features/roster/air_atlanta/FeeCalculatorUtils";
import { reload } from "../features/httpUtils";
import InvoiceRosterView from "./InvoiceRosterView";
import type { ManualInvoiceSubmissionFormProps } from "../features/manual-work-invoices/sheetUtils";
import ManualInvoiceFlyJetSubmissionForm from "./ManualInvoiceFlyJetSubmissionForm";
import type { ManualInvoiceSheetDataOut } from "../features/Api";
import api from "../features/Api";
import type { InvoiceStatus } from "../features/payrollUtils";
import NavJournalRecordsTable from "./NavJournalRecordsTable";
import { isSameCompany } from "@mhc/utils/types/nav";

function getYearMonthOfDate(dateCursor: Date) {
    return dateCursor.getUTCFullYear() +
        (dateCursor.getUTCMonth() + 1).toString().padStart(2, "0");
}

function isAaiMtx(profile: EmployeeInfoBase) {
    return isMaintenanceDepartment(profile.Department)
        && isAirAtlanta(profile.CompanyName);
}

export const views = {
    Table: 0,
    Form: 1
};

export function getSheetFormComponent(profile: EmployeeInfoWise): null | ((subProps: ManualInvoiceSubmissionFormProps) => React.JSX.Element) {
    if (isAaiMtx(profile)) {
            return subProps => <ManualInvoiceAaiMtxSubmissionForm {...subProps} />;
    } else if (isSameCompany(profile.CompanyName, "CRM - HIJEUR")) {
            return subProps => <ManualInvoiceFlyJetSubmissionForm {...subProps} />;
    } else {
            return null;
    }
}

export type Props = {
    profile: EmployeeInfoWise,
    code: string,
    monthsAgo: string | number,
    invoiceId: "" | string | number,
    DeadlineDay: number | null,
    ExtraDays: number | null,
    dispatch: Dispatch,
    LastMonthExtraDays: number | null,
    displayActions: boolean,
    year: number,
    month: number,
    store: unknown,
    provider: unknown,
    isOpen: boolean,
    dateSubmitted: string,
    invoiceMessage?: string,
    invoiceStatusInfo: string,
    invoiceStatus: keyof typeof InvoiceStatus,
};

function getInitialState(props: Props) {
    return {
        view: views.Table,
        CommentText: "",
        selectedDetail: typed<ManualInvoiceDetailsType | null>(null),
        loading: true,
        submitting: false,
        error: null,
        showLateSubmissionExplanationModal: false,
        invoiceStatusInfo: props.invoiceStatusInfo,
        invoiceMessage: props.invoiceMessage ?? "",
        justLoggedExtraDays: false,
        ownManualInvoice: typed<null | undefined | ManualInvoiceSheetDataOut>(undefined),
    };
}

type State = ReturnType<typeof getInitialState>;

function getDefaultFinalDeadline(payMonth: AbsoluteMonth): Date {
    const baseDate = new Date(getMonthStartDate(payMonth));
    // 5th working day by default
    return getNthWorkingDayInLatvia(baseDate, 5);
}

function getFinalDeadline(rosterMonth: AbsoluteMonth, configDay: number | null): Date {
    const payMonth = incrementMonth(rosterMonth);
    let deadline;
    if (configDay) {
            deadline = new Date(getMonthDate(payMonth, configDay));
    } else {
            deadline = getDefaultFinalDeadline(payMonth);
    }
    deadline.setUTCHours(23);
    deadline.setUTCMinutes(59);
    deadline.setUTCSeconds(59);
    return deadline;
}

export default class ManualInvoiceDetails extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = getInitialState(props);
        window.addEventListener("beforeunload", event => {
            if (this.state.justLoggedExtraDays) {
                event.preventDefault();
                event.returnValue = "dontcloseplizzzz";
                return "dontcloseplizzzz";
            }
        });
    };

    componentDidMount() {
        this.reloadData();
    };

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>) {
        if (nextState.loading) {
            return false;
        }
        return true;
    };

    showManualInvoiceDetailsForm() {
        if (!this.props.profile.Fields ||
                !this.props.profile.Activities
        ) {
            alert("No activities/fields setup, contact administrator");
            return;
        }
        this.setState({
            view: views.Form,
            selectedDetail: null,
        });
    };

    showManualInvoiceDetailsUpdateTable(selected: ManualInvoiceDetailsType) {
        this.setState({
            selectedDetail: selected,
            view: views.Form,
        });
    };

    private get rosterData(): ManualInvoiceDetailsType[] | undefined {
        return this.state.ownManualInvoice?.InvoiceDays;
    }

    checkForOldTimes() {
        let oldTimes = false;
        for (const invoiceDetail of this.rosterData ?? []) {
            if (Date.parse(invoiceDetail.ActivityDate) <
                new Date(this.props.year, this.props.month - 1, 1).getTime()
            ) {
                oldTimes = true;
            }
        }
        return oldTimes;
    };

    submitInvoice() {
        if (this.checkForOldTimes() && this.state.CommentText === "") {
            this.setState({
                showLateSubmissionExplanationModal: true
            });
            return false;
        }
        const payload = {
            year: this.props.year,
            month: this.props.month,
            comment: this.state.CommentText,
        } as const;
        this.setState({ submitting: true });
        api.Invoice.SubmitManualInvoiceForApproval(payload)
            .then(async () => {
                await new Promise<void>(resolve => this.setState({ justLoggedExtraDays: false }, () => resolve()));
                await reload();
            })
            .finally(() => this.setState({ submitting: false }));
        return false;
    };

    onCommentBoxChanged(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ CommentText: e.target.value });
    };

    handleCloseModal() {
        this.setState({ showLateSubmissionExplanationModal: false });
    }

    _createActions() {
        const customStyles = {
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
            },
        };
        const { rosterData } = this;
        if (this._canEditInvoiceAccordingToStatus()) {
            return (
                <div className="invoiceActionContainer">
                    <ReactModal
                        isOpen={this.state.showLateSubmissionExplanationModal}
                        contentLabel="Late Submission Explanation"
                        shouldCloseOnOverlayClick={true}
                        style={customStyles}
                        onRequestClose={this.handleCloseModal.bind(this)}
                        ariaHideApp={false}
                    >
                        <div className="custom-ui">
                            <div className="paragraphContainer">
                                <div className="innerInvoiceActionContainer">
                                    <img className="alertImage" src="/img/alert.png"/>
                                    <div>
                                        <div className="frontPageStatusInfo">
                                            You are submitting work hours for a previous month.
                                        </div>
                                        <div className="frontPageStatusInfo"> Please explain why</div>
                                    </div>
                                </div>
                            </div>
                            <textarea
                                className="form-control lateSubmissionBox"
                                placeholder={"Late submission explanation..."}
                                onChange={(e) => this.onCommentBoxChanged(e)}
                            />
                            <button
                                className="btn btn-warning"
                                type="button"
                                onClick={() => {
                                    this.handleCloseModal();
                                }}
                            >
                                Cancel
                            </button>
                            {this.state.CommentText !== "" ? (
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    disabled={this.state.submitting}
                                    onClick={() => {
                                        this.submitInvoice();
                                    }}
                                >
                                    Submit
                                </button>
                            ) : (
                                <button className="btn btn-primary" disabled>
                                    Submit
                                </button>
                            )}
                        </div>
                    </ReactModal>
                    <div className="downloadAndSubmitContainer">
                        {rosterData && <button
                                className="btn btn-warning"
                                type="button"
                                onClick={(e) => this.extractInvoiceToPdf(rosterData)}
                        >Download PDF</button>}
                        <button
                            type="button"
                            disabled={this.state.submitting}
                            onClick={() => this.submitInvoice()}
                            className={"btn btn-primary frontPageButton" + (this.state.justLoggedExtraDays ? " just-logged-extra-days" : "")}
                        >{this.usesSheetForm ? "Submit Comment" : this.props.invoiceStatus === "NotSubmitted" ? "Submit Work Invoice" : "Submit Update"}</button>
                    </div>
                </div>
            );
        } else {
            return rosterData && (
                <div className="invoice-submission">
                    <span>
                        <button
                            className="btn btn-warning"
                            type="button"
                            onClick={(e) => this.extractInvoiceToPdf(rosterData)}
                        >Download PDF</button>
                    </span>
                </div>
            );
        }
    };

    //Gisli
    //Checks if the invoice status is in a status that allows the employee to edit.
    //Returns true if employee can edit otherwise false.
    _canEditInvoiceAccordingToStatus() {
        const deadline = getFinalDeadline(this.props, this.props.ExtraDays);
        const isSubmissionOpen = new Date() <= deadline;
        const isInvoiceNotApproved = this.props.invoiceStatus !== "Approved";
        return isSubmissionOpen && isInvoiceNotApproved;
    };

    _updateCommentText(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ CommentText: e.target.value, error: null });
    };

    _changeInvoiceMonthSelected(e: React.ChangeEvent<HTMLSelectElement>) {
        const sParameter = e.target.value;
        const sSelMonth = sParameter.substr(4, 2);
        const sSelYear = sParameter.substr(0, 4);
        const monthsAgo = this._getMonthsAgo(sSelMonth, sSelYear);

        window.location.href = "/Home/ManualInvoice?monthsAgo=" + monthsAgo;
    };

    _getMonthsAgo(month: string | number, year: string | number) {

        const currentMonth = new Date().getMonth() + 1;
        const sResult =
            year == new Date().getFullYear()
                ? currentMonth - +month
                : currentMonth + (12 * (new Date().getFullYear() - +year) - +month);
        return sResult;
    };

    private reloadData() {
        this.setState({
            view: views.Table,
            selectedDetail: null,
            loading: true,
        });
        const payload = {
            year: this.props.year,
            month: this.props.month
        };
        api.Invoice.GetOwnManualInvoice(payload)
            .then(ownManualInvoice => this.setState({ ownManualInvoice }))
            .finally(() => this.setState({ loading: false }));
    }

    private get departmentDeadlineDay() {
            const baseDate = new Date(getMonthDate(this.props, 1));
            baseDate.setUTCMonth(baseDate.getUTCMonth() + 1);
            if (isAaiMtx(this.props.profile)) {
                // 1st working day for AAI FD
                return getNthWorkingDayInLatvia(baseDate, 1).getUTCDate();
            } else if (!this.props.DeadlineDay) {
                // 3rd working day for everyone else
                return getNthWorkingDayInLatvia(baseDate, 3).getUTCDate();
            } else {
                return this.props.DeadlineDay;
            }
    }

    private get displayedFieldsList(): SetupField[] {
        let list: SetupField[] = [];

        if (this.props.profile.Fields != undefined)
            list = this.props.profile.Fields.fields.split("|") as SetupField[];
        return list;
    }

    _createManualInvoiceDetailsForm() {
        const list = this.displayedFieldsList;

        const invoiceId = this.props.invoiceId;
        return <ManualInvoiceDetailsForm
            key={this.state.selectedDetail?.Id ?? ""}
            invoiceId={invoiceId}
            selectedDetail={this.state.selectedDetail}
            displayedFieldsList={list}
            activityOptionsList={this.props.profile.Activities?.split("|") ?? []}
            year={this.props.year}
            month={this.props.month}
            DeadlineDay={this.departmentDeadlineDay}
            goBack={() => this.setState({
                view: views.Table,
                selectedDetail: null,
            })}
            onSubmitted={() => {
                this.setState({ justLoggedExtraDays: true });
                this.reloadData();
            }}
            dispatch={this.props.dispatch}
            COMPANYNAME={this.props.profile.CompanyName}
        />;
    }

    _getMonthName(month: number) {
        return getMonthFullName(month + 1);
    };

    getYearMonthOptions() {
        const dateCursor = new Date();
        const deadline = getFinalDeadline(
            decrementMonth(this.props),
            this.props.LastMonthExtraDays
        );
        const disableFirst = deadline > dateCursor;
        let first = true;
        const options = [];
        while (dateCursor.toISOString() > "2017-01-01") {
            const sKey = getYearMonthOfDate(dateCursor);
            const sDisplayText = dateCursor.getUTCFullYear() + " - " +
                this._getMonthName(dateCursor.getUTCMonth());

            options.push(<option key={sKey} value={sKey} disabled={first && disableFirst}>{sDisplayText}</option>);
            dateCursor.setUTCDate(0);
            first = false;
        }
        return options;
    }

    _getYearMonthAtOffset(monthsago: number | string) {
        const dateCursor = new Date();
        dateCursor.setUTCMonth(dateCursor.getUTCMonth() - +monthsago);
        return getYearMonthOfDate(dateCursor);
    };

    private getSelectedYearMonth() {
        if (this.props.monthsAgo == "") {
            let selMonth: string | number = this.props.month;
            const selYear = this.props.year;

            selMonth =
                selMonth.toString().length == 1
                    ? "0" + selMonth.toString()
                    : selMonth.toString();
            return selYear.toString() + selMonth;
        } else {
            return this._getYearMonthAtOffset(this.props.monthsAgo);
        }
    }

    extractInvoiceToPdf(rosterData: ManualInvoiceDetailsType[]) {
        if (!window.pdfMake) {
            const message = "PDF still loading. Please, wait few seconds and try again";
            if (window.toastr) {
                window.toastr.warning(message);
            } else {
                alert(message);
            }
            return;
        }
        const createPdfTyped: typeof createPdf = window.pdfMake.createPdf;
        const docDefinition = prepareManualInvoiceForPdfExport(this.props, rosterData, null);
        const date = this.getSelectedYearMonth();
        createPdfTyped(docDefinition).download(date + "_work_invoice.pdf");
    };

    private get usesSheetForm() {
        return getSheetFormComponent(this.props.profile) !== null;
    }

    get contractFileLink() {
        return "/api/Contract/GetLatestContractFile?" + new URLSearchParams({
            year: this.props.year.toString(),
            month: this.props.month.toString(),
            employeeCode: this.props.profile.No_,
            company: this.props.profile.CompanyName,
        }) + "#page=14";
    }

    private renderLoadingView() {
        return <div>
            <h1 className="loading status-loading-animated-ellipsis">
                Loading invoice
            </h1>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>;
    }

    private renderHeader() {
        const selYearMonth = this.getSelectedYearMonth();
        const inputActionPanel = !this._canEditInvoiceAccordingToStatus()
            ? <strong className="submission-is-closed-message">Submission is closed</strong>
            : !this.usesSheetForm
            ? <button
                type="button"
                className="btn btn-primary floatRight"
                onClick={() => this.showManualInvoiceDetailsForm()}
            >Log time</button>
            : null;

        let monthForLastDay = this.props.month;
        if (monthForLastDay == 12) monthForLastDay = 0;

        return <div className="manual-invoice-header-section">
            <div className="manual-invoice-header">
                <div>
                    <div className="timeContainer">
                        <select
                            onChange={(e) => this._changeInvoiceMonthSelected(e)}
                            value={selYearMonth}
                        >
                            {this.getYearMonthOptions()}
                        </select>
                    </div>
                </div>
                <div className="form-group">
                    <div className="logTime">
                        <div className="frontPageStatusInfo">
                            Status: <span className="invoice-status-text" data-status={this.props.invoiceStatus}>{this.state.invoiceStatusInfo}</span>
                        </div>
                        <span className="deadlineSpan">
                            <img
                                className="deadline"
                                style={{ display: "inline-block" }}
                                src="/img/deadline.png"
                            />
                            Deadline: {this.departmentDeadlineDay}{" "}
                            {this._getMonthName(monthForLastDay).toUpperCase()}
                        </span>
                        {inputActionPanel}
                    </div>
                </div>
            </div>
            {this.state.invoiceMessage && <div className="invoice-message-panel">
                    <span className="invoice-message-label">Invoice Message Box</span>
                    <span className="invoice-message-value">{this.props.invoiceMessage}</span>
            </div>}
        </div>;
    }

    private renderFooter() {
        const sheetDataSubmittedStatusMessage = "Your Work Invoice has been submitted to the payroll officer. Waiting for approval";
        const canShowSheetForm = this._canEditInvoiceAccordingToStatus() &&
            this.props.invoiceStatus === "NotSubmitted" &&
            this.state.invoiceStatusInfo !== sheetDataSubmittedStatusMessage;

        const sheetFormParams: ManualInvoiceSubmissionFormProps = {
            profile: this.props.profile,
            invoiceId: +this.props.invoiceId,
            year: this.props.year,
            month: this.props.month,
            onSubmitted: (sheetRequestData: { CommentBox: string }) => {
                toastr.success("Your work report was successfully submitted");
                this.reloadData();
                this.setState({
                    invoiceStatusInfo: sheetDataSubmittedStatusMessage,
                    invoiceMessage: sheetRequestData.CommentBox,
                });
            },
        } as const;
        const sheetForm = getSheetFormComponent(this.props.profile);
        return <div className="work-report-submission-section">
            {canShowSheetForm && sheetForm && sheetForm(sheetFormParams)}
            <div className="roster-view-subsection" style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ flex: 1 }}></div>
                <InvoiceRosterView
                    locator={{
                        company: this.props.profile.CompanyName,
                        employeeCode: toCrewCodeUc(this.props.profile.No_),
                        year: this.props.year,
                        month: this.props.month,
                    }}
                    contractFileLink={this.contractFileLink}
                />
            </div>
        </div>;
    }

    render() {
        if (this.state.view == views.Form) {
            return <div>{this._createManualInvoiceDetailsForm()}</div>;
        } else if (this.state.loading) {
            return this.renderLoadingView();
        }

        const header = this.renderHeader();
        const footer = this.renderFooter();

        if (!this.state.ownManualInvoice || this.state.ownManualInvoice.InvoiceDays.length === 0) {
            return <div className="table-responsive">
                {header}
                {!this.usesSheetForm && (
                    this._canEditInvoiceAccordingToStatus()
                        ? <h1 className="loading">Please, click "Log Time" to initiate Work Invoice submission</h1>
                        : <h1 className="loading">Invoice Submission Deadline has Passed. Please, contact your payroll officer if you had duties this month</h1>
                )}
                <div className="work-report-submission-content">
                    <div className="work-report-submission-section-wrapper">
                        {footer}
                    </div>
                </div>
            </div>;
        } else {
            return <div>
                {header}
                {this.renderRosterDataView(this.state.ownManualInvoice)}
                {footer}
            </div>;
        }
    };

    private renderRosterDataView(owmManualInvoice: ManualInvoiceSheetDataOut) {
        const user = window.EMPLOYEE_INFO ?? neverNull("EMPLOYEE_INFO session user");
        return <div className="manual-invoice-logged-time-result-section">
            <div className="table-responsive">
                <ManualInvoiceDetailsTable
                    rosterData={owmManualInvoice.InvoiceDays}
                    editRow={(detail: ManualInvoiceDetailsType) => this.showManualInvoiceDetailsUpdateTable(detail)}
                    canEdit={!this.usesSheetForm && this._canEditInvoiceAccordingToStatus()}
                    displayedFieldsList={this.usesSheetForm ? undefined : this.displayedFieldsList}
                    year={this.props.year}
                    month={this.props.month}
                />
            </div>
            <div className="commentsTimesheet">
                <h4>Comments</h4>
                <div>
                    <textarea
                        className={"form-control "}
                        style={{
                            display: this._canEditInvoiceAccordingToStatus()
                                ? "inline-block"
                                : "none",
                        }}
                        onChange={(e) => this._updateCommentText(e)}
                        value={this.state.CommentText}
                    />
                </div>
            </div>
            <div className="submitInfo">
                {this.props.dateSubmitted !== "" ? (
                    <div className="submitDateInfo">
                        This work invoice was last submitted{" "}
                        {this.props.dateSubmitted}
                    </div>
                ) : null}
                {this._createActions()}
            </div>
            {owmManualInvoice.CalculatedJournalRecords && <div className="preliminary-salary-journal-from-roster">
                <h3>Preliminary Fees Calculation (Informational)</h3>
                <NavJournalRecordsTable calculatedPeople={[{
                    person: {
                        navEntry: {
                            No_: user.No_,
                        },
                    },
                    journalRecords: owmManualInvoice.CalculatedJournalRecords,
                }]} COMPANYNAME={toCOMPANYNAMEUC(user.CompanyName)}/>
            </div>}
        </div>;
    }
}
