import type { IsoAlpha2Country, IsoDate } from "@mhc/utils/types/utility";
import type { AbsoluteMonth } from "@mhc/utils/src/dates";
import  { strcmp } from "../../../utils/dates";
import  { getMonthDate,getNumberOfDays } from "@mhc/utils/src/dates";
import  { pad2 } from "@mhc/utils/src/dates";
import  { getDatePart } from "@mhc/utils/src/dates";
import type { PersonMonthRosterContent } from "../../ExternalRostersApi";
import type     { BlankActivity, RealActivity, RosterItem, RotationActivity, RotationData, RotationItem } from "./Roster";
import type { RosterActivity, RosterCrew } from "@mhc/utils/types/integrations/raido/api";
import aaiRaidoActivityTypes from "./aaiRaidoActivityTypes";

/**
 * Air Atlanta sometimes includes the Z mark and sometimes does not
 * "2024-01-04T00:00:00Z" -> new Date("2024-01-04T00:00:00Z")
 * "2024-01-05T14:45:00"  -> new Date("2024-01-05T14:45:00Z")
 * "2024-06-15 08:53"  -> new Date("2024-06-15 08:53:00Z")
 */
export function asUtc(timeStr: string) {
    if (timeStr.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/)) {
        timeStr += ":00"; // ATA/ATD in Air Atlanta are passed as ISO string without seconds
    }
    const datePart = timeStr.slice(0, 10); // 2024-01-04
    const timePart = timeStr.slice(11);
    if (!timePart) {
        // according to W3C date without time is to be automatically interpreted as UTC 00:00:00
        return new Date(datePart);
    }
    const isoCompliant = datePart + "T" + timePart;
    return new Date(isoCompliant.slice(0, "2024-01-04T00:00:00".length) + "Z");
}

export type DutyKind = "OFF_DUTY" | "TRAVEL" | "DAY_SHIFT_TRAVEL_START" | "DAY_SHIFT_TRAVEL_END" | "NIA_GROUND_DUTY";

export type RotationDay<TActivityExtension = {}> = {
    Date: IsoDate,
    Activities: (RosterItem & TActivityExtension)[],
};

type RaidoRealActivityExtension = {
    FullRaidoActivity: RosterActivity,
};

export type RaidoActivityExtension = (RaidoRealActivityExtension | {
    ActivityType: "BLANK",
});

export type RaidoRosterItem = RosterItem & RaidoActivityExtension;

export type RaidoRotationDay = RotationDay<RaidoActivityExtension>;

export type DutyKindExtension = {
    DutyKind: DutyKind | null,
};

export type DutiedRotationDay = RotationDay & DutyKindExtension;
export type DutiedRaidoRotationDay = RaidoRotationDay & DutyKindExtension;

export function makeBlankActivity(dateStr: IsoDate): BlankActivity {
    return {
        ActivityDate: `${dateStr}T00:00:00`,
        ActivityType: "BLANK",
    } as const;
}

function getLocalStartDate(raidoActivity: RosterActivity): IsoDate {
    const dateObj = asUtc(raidoActivity.Start);
    dateObj.setUTCMinutes(dateObj.getUTCMinutes() + raidoActivity.StartLocalTimeDiff);
    return getDatePart(dateObj);
}

export function getLocalEndDate(raidoActivity: RosterActivity): IsoDate {
    const dateObj = asUtc(raidoActivity.End);
    dateObj.setUTCMinutes(dateObj.getUTCMinutes() + raidoActivity.EndLocalTimeDiff);
    return getDatePart(dateObj);
}

const SABRE_FULL_ACTIVITY_FILL = {
    ATA: "0001-01-01T00:00:00",
    ATD: "0001-01-01T00:00:00",
    ActivePosHours: null,
    ActivePosMinutes: null,
    ActivePosTime: null,
    AirportFromCountry: "XX" as IsoAlpha2Country,
    AirportToCountry: "XX" as IsoAlpha2Country,
    LANDING: "", // System.DateTime
    LANDING_UTC: "",
    PosHours: "",
    PosMinutes: "",
    PosTime: "",
    STA: "", // System.DateTime
    STA_UTC: "",
    STD: "", // System.DateTime
    STD_UTC: "",
    TAKEOFF: "", // System.DateTime
    TAKEOFF_UTC: "",
} as const;

function nightsBetween(baseDate: IsoDate, targetDate: IsoDate): number {
    const baseDateMs = new Date(baseDate).getTime();
    const targetDateMs = new Date(targetDate).getTime();
    return (targetDateMs - baseDateMs) / 1000 / 60 / 60 / 24 + 1;
}

function regroupToTimeFormat(
    yearMonth: AbsoluteMonth,
    utcRotationDays: RaidoRotationDay[],
    baseOnLocalTime: boolean
): RaidoRotationDay[] {
    const dateToActivities = new Map<IsoDate, (RosterItem & RaidoRealActivityExtension)[]>();
    for (const utcDay of utcRotationDays) {
        for (const activity of utcDay.Activities) {
            if (activity.ActivityType === "BLANK") {
                continue;
            }
            const date = baseOnLocalTime
                ? getLocalStartDate(activity.FullRaidoActivity)
                : getDatePart(activity.FullRaidoActivity.Start);
            let dateActivities = dateToActivities.get(date);
            if (!dateActivities) {
                dateActivities = [];
                dateToActivities.set(date, dateActivities);
            }
            dateActivities.push(activity);
        }
    }

    const datesSet = new Set<IsoDate>();
    for (let i = 0; i < getNumberOfDays(yearMonth); ++i) {
        const date = getMonthDate(yearMonth, i + 1);
        datesSet.add(date);
    }
    for (const date of dateToActivities.keys()) {
        datesSet.add(date);
    }
    const dates = [...datesSet].sort(strcmp);

    const ltRotationDays: RaidoRotationDay[] = [];
    for (const date of dates) {
        const blankFallback: RaidoRosterItem = {
            ...SABRE_FULL_ACTIVITY_FILL,
            ...makeBlankActivity(date),
        };
        const sabreActivities: RaidoRosterItem[] = dateToActivities.get(date) ?? [blankFallback];
        ltRotationDays.push({
            Date: date,
            Activities: sabreActivities,
        });
    }
    return ltRotationDays;
}

/**
 * in initial data structure activities are grouped by UTC start time. This function re-groups it by
 * LT start time: even though strictly speaking it's not deterministic approach for organizing data,
 * but in case of Air Atlanta timezone only matters at domicile airport
 */
export function regroupToLt(yearMonth: AbsoluteMonth, utcRotationDays: RaidoRotationDay[]): RaidoRotationDay[] {
    return regroupToTimeFormat(yearMonth, utcRotationDays, true);
}

function groupRotationDaysFromRaido(raidoRosterCrew: RosterCrew, yearMonth: AbsoluteMonth): RotationItem<RaidoActivityExtension>[] {
    const dateToActivities = new Map<IsoDate, RosterActivity[]>();
    for (const raidoActivity of raidoRosterCrew.RosterActivities) {
        const date = getDatePart(raidoActivity.Start);
        let dateActivities = dateToActivities.get(date);
        if (!dateActivities) {
            dateActivities = [];
            dateToActivities.set(date, dateActivities);
        }
        dateActivities.push(raidoActivity);
    }
    const datesSet = new Set<IsoDate>();
    for (let i = 0; i < getNumberOfDays(yearMonth); ++i) {
        const date = getMonthDate(yearMonth, i + 1);
        datesSet.add(date);
    }
    for (const date of dateToActivities.keys()) {
        datesSet.add(date);
    }
    const dates = [...datesSet].sort(strcmp);

    const results: RotationItem<RaidoActivityExtension>[] = [];
    for (const date of dates) {
        const raidoActivities = dateToActivities.get(date) ?? [];
        const blankDateTime = new Date(date).toISOString();
        const blankFallback: RotationActivity = {
            ...SABRE_FULL_ACTIVITY_FILL,
            ATD_UTC: blankDateTime,
            ATA_UTC: blankDateTime,
            ActivityEndDate: blankDateTime,
            DutyDesignators: "",
            ...makeBlankActivity(date),
        };
        const sabreActivities: (RotationActivity & RaidoActivityExtension)[] = raidoActivities.length > 0
            ? raidoActivities.map(raidoToRmFullActivity)
            : [blankFallback];
        results.push({
            ActivePosHours_TotalFromStartOfRotation: 0,
            ActivePosMinutes_TotalFromStartOfRotation: 0,
            Activities: sabreActivities,
            Base: raidoRosterCrew.Base,
            BlockHours_TotalFromStartOfRotation: 0,
            BlockMinutes_TotalFromStartOfRotation: 0,
            Comments: [],
            CrwCat: "",
            Date: raidoActivities.length > 0 ? raidoActivities[0].Start : blankDateTime,
            DayNumberInRotaion: 1,
            DeadheadHours_TotalFromStartOfRotation: 0,
            DeadheadMinutes_TotalFromStartOfRotation: 0,
            Id: 0,
            PayTypes: [],
            PayTypesString: null,
            PosHours_TotalFromStartOfRotation: 0,
            PosMinutes_TotalFromStartOfRotation: 0,
            Project: "",
            Sign: raidoRosterCrew.Number,
            SpecFuncs: "",
            idempno: 0,
        });
    }
    return results;
}

/**
 * We can roughly estimate the start time of the activity by subtracting block hours from
 * the end time for informational purposes, but sometimes such heuristic incorrectly results
 * in day shift, creating an inconsistency with rotation date.
 *
 * Rotation date is not based on imprecise time data, so in such inconsistency situations
 * rotation date should be considered correct and estimated time should be capped to match that.
 *
 * 2024-05-29T23:58:00 + 2024-05-30 -> 2024-05-30T00:00:00
 * 2024-05-29T23:58:00 + 2024-05-29 -> 2024-05-29T23:58:00
 * 2024-05-30T00:07:00 + 2024-05-29 -> 2024-05-29T23:59:59
 * 2024-05-30T00:07:00 + 2024-05-30 -> 2024-05-30T00:07:00
 */
function capAtDay(approximateDt: Date, exactDay: RotationDay): Date {
    const approximateDate = approximateDt.toISOString().slice(0, 10);
    if (approximateDate < exactDay.Date) {
        const correctedDt = new Date(exactDay.Date);
        correctedDt.setUTCHours(0);
        correctedDt.setUTCMinutes(0);
        correctedDt.setUTCSeconds(0);
        return correctedDt;
    } else if (approximateDate > exactDay.Date) {
        const correctedDt = new Date(exactDay.Date);
        correctedDt.setUTCHours(23);
        correctedDt.setUTCMinutes(59);
        correctedDt.setUTCSeconds(59);
        return correctedDt;
    } else {
        return approximateDt;
    }
}

function is24hActivity(record: RosterItem) {
    if (record.ActivityType !== "Ground") {
        return false;
    }
    return record.GNDACTCODETITLE === "OFF" // DAY OFF HOMEBASE
        || record.GNDACTCODETITLE === "VAU" // VACATION UNPAID
        || record.GNDACTCODETITLE === "ULV" // UNPAID LEAVE
        || record.GNDACTCODETITLE === "OFO" // DAY OFF OUTSTATION
        || record.GNDACTCODETITLE === "RTC" // ROTATION CABIN CREW
        || record.GNDACTCODETITLE?.match(/^VA\d+/) // 3/2 ROTATION
        ;
}

function getExactStartTime(record: RosterItem): Date | null {
    const utc = asUtc(record.ActivityDate);
    if (record.ActivityType === "BLANK") {
        return utc;
    } else if (record.FullRotationActivity) {
        return asUtc(record.FullRotationActivity.ActivityDate);
    } else if (is24hActivity(record)) {
        utc.setUTCDate(utc.getUTCDate() - 1);
        return utc;
    }
    return null;
}

export function estimateStartDateTime(record: RosterItem, rotationDay: RotationDay): Date | null {
    const exact = getExactStartTime(record);
    if (exact) {
        return exact;
    }
    const utc = asUtc(record.ActivityDate);
    const rmActivityInfo = record.ActivityType === "Ground" &&
        record.GroundActivityInfo || null;
    let match;
    if (record.ActivityType === "Flight") {
        utc.setUTCHours(utc.getUTCHours() - +record.BlockHours);
        utc.setUTCMinutes(utc.getUTCMinutes() - +record.BlockMinutes);
        return capAtDay(utc, rotationDay);
    } else if (record.ActivityType === "Deadhead") {
        utc.setUTCHours(utc.getUTCHours() - +record.DeadheadHours);
        utc.setUTCMinutes(utc.getUTCMinutes() - +record.DeadHeadMinutes);
        return capAtDay(utc, rotationDay);
    } else if (match = rmActivityInfo?.match(/([0-2]\d):?([0-5]\d|)\s*(?:[Ll][Tt])?\s*(?:-|¿)\s*([0-2]\d):?([0-5]\d|)/)) {
        let [, fromHour, fromMinute, toHour, toMinute] = match;
        fromMinute = fromMinute || "00";
        toMinute = toMinute || "00";
        const toHourNum = +fromHour >= +toHour ? +toHour + 24 : +toHour;
        const fromMinutes = +fromHour * 60 + +fromMinute;
        const toMinutes = toHourNum * 60 + +toMinute;
        const durationMinutes = toMinutes - fromMinutes;
        utc.setUTCMinutes(utc.getUTCMinutes() - durationMinutes);
        return utc;
    } else if (match = rmActivityInfo?.match(/(\d+)\s*(HRS?|NIGHT SHIFT|DAY SHIFT)/i)) {
        const [, hours] = match;
        utc.setUTCHours(utc.getUTCHours() - +hours);
        return utc;
    } else {
        return null;
    }
}

function isSameActivity(incompleteActivity: RosterItem, fullActivity: RotationActivity) {
    if (incompleteActivity.FlightId && fullActivity.FlightId &&
        incompleteActivity.FlightId === fullActivity.FlightId
    ) {
        return true;
    }
    if (fullActivity.ActivityType === "BLANK") {
        return incompleteActivity.ActivityType === "BLANK"
            && incompleteActivity.ActivityDate === fullActivity.ActivityDate;
    } else if (fullActivity.ActivityType === "Ground") {
        return incompleteActivity.ActivityType === "Ground"
            && incompleteActivity.ActivityDate === fullActivity.ActivityEndDate
            && fullActivity.GNDACTCODETITLE === incompleteActivity.GNDACTCODETITLE;
    } else {
        return incompleteActivity.ActivityType === fullActivity.ActivityType
            && incompleteActivity.ActivityDate === fullActivity.ActivityEndDate;
    }
}

function toIncompleteActivity<TActivityExtension = {}>(
    fullActivity: RotationActivity & TActivityExtension
): RosterItem & TActivityExtension {
    if (fullActivity.ActivityType === "BLANK") {
        const blankActivity: BlankActivity & TActivityExtension = {
            ...fullActivity,
            ActivityDate: fullActivity.ActivityDate,
            FullRotationActivity: fullActivity,
        };
        return blankActivity;
    } else {
        const realActivity: RealActivity & TActivityExtension = {
            ...fullActivity,
            // RosterItem does not have start date and the ActivityDate holds the end date
            ActivityDate: fullActivity.ActivityEndDate,
            FullRotationActivity: fullActivity,
        };
        return realActivity;
    }
}

function raidoToRmFullActivity(raidoActivity: RosterActivity): RotationActivity & RaidoActivityExtension {
    const referenceActivity = aaiRaidoActivityTypes.get(raidoActivity.RefUniqueId) ?? null;
    const GroundActivityInfo = referenceActivity?.Name ?? raidoActivity.ActivitySubType;

    const base = {
        ...SABRE_FULL_ACTIVITY_FILL,
        ATA_UTC: raidoActivity.End,
        ATD_UTC: raidoActivity.Start,
        ActivityDate: raidoActivity.Start,
        ActivityEndDate: raidoActivity.End,
        GroundActivityInfo: GroundActivityInfo.toUpperCase(),
        DutyDesignators: raidoActivity.RosterDesignator?.split(",").filter(d => d).map(d => `[${d}]`).join(","),
        FullRaidoActivity: raidoActivity,
    } as const;

    const isDeadhead = raidoActivity.Code === "CFD" ||
        raidoActivity.ActivitySubType === "Transport" &&
        raidoActivity.ActivityCode !== raidoActivity.Code &&
        raidoActivity.ActivityCode.match(/^[A-Z][A-Z0-9]\d+$/); // flight number
    if (raidoActivity.Code === "FLT" ||
        raidoActivity.ActivityType === "FLIGHT" ||
        isDeadhead
    ) {
        const airActivityBase = {
            AircraftType: "",
            AirportFrom: raidoActivity.StartAirportCode,
            AirportTo: raidoActivity.EndAirportCode,
            /** it's actually ID of _activity_, it's not specific to only flights */
            FlightId: raidoActivity.UniqueId,
            FlightNumber: raidoActivity.ActivityCode,
            ATA_UTC: raidoActivity.Times.filter(t => t.Type === "ActualEnd").map(t => t.DateTime)[0] ?? null,
            ATD_UTC: raidoActivity.Times.filter(t => t.Type === "ActualStart").map(t => t.DateTime)[0] ?? null,
            FlightNumberCode: 0,
            FlightNumberPrefix: "",
            GNDACTCODETITLE: null,
            GroundActivityCode: "0",
            GroundActivityInfo: null,
        } as const;
        const blockMs =
            asUtc(airActivityBase.ATA_UTC ?? raidoActivity.End).getTime() -
            asUtc(airActivityBase.ATD_UTC ?? raidoActivity.Start).getTime();
        const blockMinutes = blockMs / 1000 / 60;
        const blockWholeHours = Math.floor(blockMinutes / 60);
        const blockRemainderMinutes = Math.floor(blockMinutes % 60);
        if (raidoActivity.Code === "FLT" ||
            raidoActivity.ActivityType === "FLIGHT"
        ) {
            return {
                ...base,
                ...airActivityBase,
                ActivityType: "Flight",
                AircraftNumber: raidoActivity.EquipmentType,
                BlockHours: `${blockWholeHours}`,
                BlockMinutes: `${blockRemainderMinutes}`,
                BlockTime: `${blockWholeHours}:${pad2(blockRemainderMinutes)}`,
                DeadHeadMinutes: null,
                DeadheadHours: null,
                DeadheadBlockTime: null,
            };
        } else {
            return {
                ...base,
                ...airActivityBase,
                ActivityType: "Deadhead",
                AircraftNumber: null,
                BlockHours: null,
                BlockMinutes: null,
                BlockTime: null,
                DeadHeadMinutes: `${blockRemainderMinutes}`,
                DeadheadHours: `${blockWholeHours}`,
                DeadheadBlockTime: `${blockWholeHours}:${pad2(blockRemainderMinutes)}`,
            };
        }
    } else {
        return {
            ...base,
            ActivityType: "Ground",
            DeadHeadMinutes: null,
            DeadheadHours: null,
            DeadheadBlockTime: null,
            AircraftNumber: null,
            BlockHours: null,
            BlockMinutes: null,
            BlockTime: null,
            AircraftType: null,
            FlightId: raidoActivity.UniqueId,
            FlightNumberCode: 0,
            FlightNumberPrefix: null,
            GroundActivityCode: `${raidoActivity.RefUniqueId}`,
            AirportFrom: raidoActivity.StartAirportCode,
            AirportTo: raidoActivity.EndAirportCode,
            FlightNumber: "",
            GNDACTCODETITLE: raidoActivity.ActivityCode,
            GroundActivityInfo: referenceActivity?.Name ?? null,
        };
    }
}

export function raidoToRm(raidoRosterCrew: RosterCrew, yearMonth: AbsoluteMonth): RotationData<RaidoActivityExtension> {
    return {
        EmployeeBases: [],
        EmployeeCategories: [],
        EmployeeInfo: {
            Base: raidoRosterCrew.Base,
            Birthdate: raidoRosterCrew.DateOfBirth,
            Lettercode: raidoRosterCrew.Number,
            Firstname: raidoRosterCrew.Firstname,
            Lastname: raidoRosterCrew.Lastname,
            CrewCategoryMain: raidoRosterCrew.Rank,
            Empdate: raidoRosterCrew.DateOfEmployment, // ISO
            JobTitle: "",
            Homebase: "",
            Fullname: [
                raidoRosterCrew.Firstname,
                raidoRosterCrew.Middlename,
                raidoRosterCrew.Lastname,
            ].filter(n => n).join(" "),
            ContractCode: "",
            ContractFrom: "", // ISO
            ContractInfo: null,
            ContractTo: "", // ISO
            ContractType: "",
            CrewCategory: "",
            DepartmentCode: "N/A",
            DepartmentName: "",
        },
        EmployeeSpecFuncs: [],
        HajjPeriod: {},
        ResultMessage: "OK",
        ResultStatus: "SUCCESS",
        RotationItems: groupRotationDaysFromRaido(raidoRosterCrew, yearMonth),
        RotationItemsBefore: [],
        RotationPeriodItems: [],

    };
}

export function getRotationDaysFromFullUnfiltered<TActivityExtension = {}>(
    fullRotationData: RotationData<TActivityExtension>,
    incomplete: RosterItem[] | null
): RotationDay<TActivityExtension>[] {
    return fullRotationData.RotationItems.map(ri => {
        const Activities: (RosterItem & TActivityExtension)[] = ri.Activities.map(a => {
            const incompleteActivity = incomplete?.find(ia => isSameActivity(ia, a));
            return toIncompleteActivity(a);
        });
        return {
            Date: getDatePart(ri.Date),
            Activities: Activities,
            FullRotationItem: ri,
        };
    });
}

export function getFilterByMonth<TActivityExtension = {}>(payrollMonth: AbsoluteMonth) {
    const yearMonth = payrollMonth.year + "-" + String(payrollMonth.month).padStart(2, "0");
    return (d: RotationDay<TActivityExtension>) => d.Date.slice(0, 7) === yearMonth;
}

export function filterRotationDaysByMonth<TActivityExtension = {}>(
    payrollMonth: AbsoluteMonth, rotationDays: RotationDay<TActivityExtension>[]
): RotationDay<TActivityExtension>[] {
    return rotationDays.filter(getFilterByMonth(payrollMonth));
}

export function getRotationDaysFromFull<TActivityExtension = {}>(
    payrollMonth: AbsoluteMonth,
    fullRotationData: RotationData<TActivityExtension>,
    incomplete: RosterItem[] | null
) {
    const rotationDays = getRotationDaysFromFullUnfiltered(fullRotationData, incomplete);
    return filterRotationDaysByMonth(payrollMonth, rotationDays);
}

export function getRotationDays(record: AbsoluteMonth & PersonMonthRosterContent): RotationDay[] | null {
    if (record.roster_complete && record.roster_complete.ResultStatus === "SUCCESS") {
        return getRotationDaysFromFull(record, record.roster_complete, record.roster_incomplete);
    } else {
        return null;
    }
}