import type { Brand,EmailAddress, Entries, Keys, Last, Unbrand } from "../types/utility";
import type { CompanyName,COMPANYNAME, CREWCODE, UpperCased } from "../features/Api";
import type { CrewCode } from "@mhc/utils/types/nav";

export const typed = <T,>(v: T) => v;

/** kudos to https://stackoverflow.com/a/60142095/2750743 */
export const entries = <T extends {}>(object: T): Entries<T> => Object.entries(object) as Entries<T>;
export const keys = <T extends {}>(object: T): Keys<T> => Object.keys(object) as Keys<T>;

type FromEntriesObj<
    TEntries extends readonly (readonly [PropertyKey, unknown])[]
> = { [Entry in TEntries[number] as Entry[0]]: Entry[1] };

type FromEntriesDic<
    TEntries extends readonly (readonly [PropertyKey, unknown])[]
> = Partial<Record<TEntries[number][0], TEntries[number][1]>>;

type FromEntries<
    TEntries extends readonly (readonly [PropertyKey, unknown])[]
> = FromEntriesObj<TEntries> & FromEntriesDic<TEntries>;

/** see https://stackoverflow.com/a/76176570/2750743 */
export const fromEntries = <
    const TEntries extends readonly (readonly [PropertyKey, unknown])[]
>(entries: TEntries): FromEntries<TEntries> => {
    return Object.fromEntries(entries) as FromEntries<TEntries>;
};

export const brand = <TBranded extends Brand<unknown, symbol>>(value: Unbrand<TBranded>): TBranded => {
    return value as {} as TBranded;
};

export function keyof<T extends {}>(key: string, obj: T): null | keyof T {
    if (key in obj) {
        return key as keyof T;
    } else {
        return null;
    }
}

export function toUpperCase<T extends string>(value: T): UpperCased<T> {
    return value.toUpperCase() as UpperCased<T>;
}

export function toCrewCodeUc(crewCode: CrewCode): CREWCODE {
    return toUpperCase(crewCode);
}

export function toCOMPANYNAMEUC(companyName: CompanyName): COMPANYNAME {
    return toUpperCase(companyName);
}

export function asEmail(value: string): EmailAddress | null {
    const match = value.match(/^(\S+)@(\S+)\.(\S+)$/);
    if (!match) {
        return null;
    }
    const [, username, hostname, country] = match;
    return `${username}@${hostname}.${country}`;
}

export function asEmailOrFail(value: string): EmailAddress {
    return asEmail(value)
        ?? neverNull("Invalid E-Mail Address format: " + value);
}

/**
 * const nullable: string | null = getSomething();
 * const unsafeAssertion: string = nullable!;
 * const safeAssertion: string = nullable ?? neverNull();
 *
 */
export function neverNull(message?: string): never {
    throw new TypeError("Unexpected null value" + (!message ? "" : " " + message));
}

export function last<TTuple extends [unknown, ...unknown[]]>(tuple: TTuple): Last<TTuple> {
    return tuple[tuple.length - 1] as Last<TTuple>;
}

export function getTypeName(value: unknown) {
    if (value === null) {
        return "null";
    }
    if (typeof value === "object") {
        return String(value.constructor?.name) || "object";
    } else {
        return typeof value;
    }
}