import type { Last, PrefixOf, Tail } from "../types/utility";
import  { last } from "./typing";
import  { getTypeName } from "./typing";

/** kudos to chatgpt */
export type RWDeepMap<TKeys extends unknown[], TValue> =
    TKeys extends [infer First, ...infer Rest]
        ? Map<First, RWDeepMap<Rest, TValue>>
        : TValue;

export type RDeepMap<TKeys extends unknown[], TValue> =
    TKeys extends [infer First, ...infer Rest]
        ? ReadonlyMap<First, RDeepMap<Rest, TValue>>
        : TValue;

/** kudos to chatgpt */
// Conditional type to slice the tuple from the N-th position
type Unprefix<T extends unknown[], R extends unknown[]> =
    T extends [infer _, ...infer Rest]
        ? Unprefix<Rest, R extends [infer _, ...infer RestR] ? RestR : never>
        : [];

export type Root<TKeys extends [unknown, ...unknown[]], TValue> = Map<TKeys[0], RWDeepMap<Tail<TKeys>, TValue>>;

/** a generic N-dimensional data structure implemented through maps of maps of maps... */
export default class Tensor<const TKeys extends [unknown, ...unknown[]], TValue> {
    private readonly root: Root<TKeys, TValue> = new Map();

    public constructor() {}

    getNode<TPrefix extends PrefixOf<TKeys>>(
        keys: TPrefix, createMissingParents: boolean
    ): ReadonlyMap<Unprefix<TKeys, TPrefix>[0], RDeepMap<Tail<Unprefix<TKeys, TPrefix>>, TValue>> {
        const plainKeys: unknown[] = keys;
        type Node = TValue | Map<TKeys[number], Node>;
        let mapNode: Map<TKeys[number], Node> = this.root;
        for (let i = 0; i < plainKeys.length; ++i) {
            const key = keys[i];
            let nextNode = mapNode.get(key);
            if (!nextNode) {
                if (!createMissingParents) {
                    return new Map();
                } else {
                    nextNode = new Map();
                    mapNode.set(key, nextNode);
                }
            }
            if (!(nextNode instanceof Map)) {
                throw new Error(
                    "The internal structure of Tensor does not match the type: the node accessed by #" + i + " " + key +
                    " of " + plainKeys.join(",") + " was expected to be a Map, but was actually " + getTypeName(nextNode)
                );
            }
            mapNode = nextNode;
        }
        return mapNode as ReadonlyMap<Unprefix<TKeys, TPrefix>[0], RDeepMap<Tail<Unprefix<TKeys, TPrefix>>, TValue>>;
    }

    private getLeaf(keys: TKeys, createMissingParents: boolean): [Map<Last<TKeys>, TValue>, Last<TKeys>] {
        const lastKey: Last<TKeys> = last(keys);
        const precedingKeys = keys.slice(0, -1) as PrefixOf<TKeys>;
        const mapNode = this.getNode(precedingKeys, createMissingParents);
        return [mapNode as Map<Last<TKeys>, TValue>, lastKey];
    }

    get(keys: TKeys): [TValue] | [] {
        const [mapNode, lastKey] = this.getLeaf(keys, false);
        const value = mapNode.get(lastKey);
        return !value ? [] : [value as TValue];
    }

    set(keys: TKeys, value: TValue): void {
        const [mapNode, lastKey] = this.getLeaf(keys, true);
        mapNode.set(lastKey, value);
    }

    getRoot(): ReadonlyMap<TKeys[0], RDeepMap<Tail<TKeys>, TValue>> {
        return this.root;
    }
}
