
import type { Workbook, Row } from "exceljs";
import type { RaidoPersonMonthRoster } from "../ExternalRostersApi";




import { getResponse } from "../httpUtils";
import type { AbsoluteMonth } from "@mhc/utils/src/dates";
import { getMonthFullName } from "@mhc/utils/src/dates";
import { unformatDuration } from "../../utils/dates";
import type { EmployeeInfoBase, EmployeeInfoWise } from "../../types/EmployeeInfo";


import type { BaseRowData } from "./aaiMtxRosterToSheetData";
import { prepareDayRowsData } from "./aaiMtxRosterToSheetData";
import type { Worksheet } from "exceljs";

export const BLANK_TEMPLATE_NORMAL_LINK = "/Content/data/AP_Contract-Work_Invoice_MAINTENANCE_AAI.xlsx";
export const BLANK_TEMPLATE_MTX1_LINK = "/Content/data/AP_Contract-Work_Invoice_MAINTENANCE_AAI-MTX1.xlsx";

function fillNormalRow(row: Row, baseRowData: BaseRowData) {
    row.getCell("B").value = baseRowData.Base;
    if (!baseRowData.PaymentOnOff) {
        row.getCell("AA").value = "X"; // OFF PAY checkbox
        return;
    }
    row.getCell("G").value = baseRowData.TimeIn1;
    row.getCell("I").value = baseRowData.TimeOut1;
    row.getCell("K").value = baseRowData.TimeIn2;
    row.getCell("M").value = baseRowData.TimeOut2;
    if (baseRowData.DailyHours) {
        [row.getCell("O").value, row.getCell("P").value] = baseRowData.DailyHours.split(":");
    }
    if (baseRowData.DailyHours) {
        const dutyHours = unformatDuration(baseRowData.DailyHours) / 60;
        if (dutyHours >= 14) {
            const overtimeUnits = dutyHours - 12;
            const overtimeHours = Math.floor(overtimeUnits);
            const overtimeMinutes = Math.round(overtimeUnits % 1 * 60);
            row.getCell("Q").value = overtimeHours;
            row.getCell("R").value = String(overtimeMinutes).padStart(2, "0");
        }
    }
    if (baseRowData.AdditionalDay) {
        row.getCell("S").value = "X";
    }
    if (baseRowData.BlockHours > 0) {
        row.getCell("V").value = "X";
    }
    row.getCell("Y").value = "X"; // ON PAY checkbox
}

function fillMtx1Row(row: Row, baseRowData: BaseRowData) {
    row.getCell("B").value = baseRowData.Base;
    if (!baseRowData.PaymentOnOff) {
        row.getCell("V").value = "X"; // OFF PAY checkbox
        return;
    }
    row.getCell("G").value = baseRowData.TimeIn1;
    row.getCell("H").value = baseRowData.TimeOut1;
    if (baseRowData.DailyHours) {
        [row.getCell("I").value, row.getCell("J").value] = baseRowData.DailyHours.split(":");
    }
    if (baseRowData.DailyHours) {
        const dutyHours = unformatDuration(baseRowData.DailyHours) / 60;
        if (dutyHours >= 12.5) {
            const overtimeUnits = dutyHours - 12.5;
            const overtimeHours = Math.floor(overtimeUnits);
            const overtimeMinutes = Math.round(overtimeUnits % 1 * 60);
            row.getCell("K").value = overtimeHours;
            row.getCell("L").value = String(overtimeMinutes).padStart(2, "0");
        }
    }
    if (baseRowData.AdditionalDay) {
        row.getCell("M").value = "X";
    }
    if (baseRowData.BlockHours > 0) {
        if (baseRowData.BlockHours < 10) {
            row.getCell("O").value = "X";
        } else {
            row.getCell("Q").value = "X";
        }
    }
    row.getCell("T").value = "X"; // ON PAY checkbox
}

export type RosterWithExtraData = {
    personMonthRoster: RaidoPersonMonthRoster,
    profileNav: EmployeeInfoWise,
};

export function getIsMtx1(profileNav: EmployeeInfoBase) {
    return profileNav.WageContract?.startsWith("MTX1") || false;
}

export function getBlankTemplateLink(profileNav: EmployeeInfoBase) {
    return getIsMtx1(profileNav)
        ? BLANK_TEMPLATE_MTX1_LINK
        : BLANK_TEMPLATE_NORMAL_LINK;
}

export async function getBlankTemplate(
    yearMonth: AbsoluteMonth,
    profileNav: EmployeeInfoWise,
    ExcelJS: { Workbook: typeof Workbook }
) {
    const isMtx1 = getIsMtx1(profileNav);
    const blankTemplateLink = getBlankTemplateLink(profileNav);
    const whenBlankTemplateBuff = getResponse(blankTemplateLink)
        .then(rs => rs.arrayBuffer());
    const blankTemplateBuff = await whenBlankTemplateBuff;

    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(blankTemplateBuff);
    const worksheet = workbook.worksheets[0];

    worksheet.getRow(10).getCell("F").value = profileNav.FullName.toUpperCase();
    worksheet.getRow(12).getCell("F").value = profileNav.No_.toUpperCase();
    worksheet.getRow(14).getCell("F").value = profileNav.JobTitle;
    worksheet.getRow(16).getCell("F").value = getMonthFullName(yearMonth.month).toUpperCase();
    worksheet.getRow(16).getCell("M").value = yearMonth.year;
    worksheet.getRow(20).getCell("F").value = profileNav.EMail;
    worksheet.getRow(isMtx1 ? 22 : 24).getCell("F").value = profileNav.MobilePhoneno ?? profileNav.PhoneNo;
    return workbook;
}

export function fillTemplateFromRoster(
    worksheet: Worksheet,
    personMonthRoster: RaidoPersonMonthRoster,
    profileNav: EmployeeInfoWise
) {
    const isMtx1 = getIsMtx1(profileNav);
    const rowsData = prepareDayRowsData({ personMonthRoster, profileNav });
    for (let i = 0; i < rowsData.length; ++i) {
        const baseRowData = rowsData[i];
        if (isMtx1) {
            const row = worksheet.getRow(27 + i);
            fillMtx1Row(row, baseRowData);
        } else {
            const row = worksheet.getRow(32 + i);
            fillNormalRow(row, baseRowData);
        }
    }
}

export async function getTemplateDataLink(workbook: Workbook) {
    const filled = await workbook.xlsx.writeBuffer();
    const blob = new Blob([filled], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return URL.createObjectURL(blob);
}
