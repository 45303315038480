import { useEffect, useState } from "react";
import * as React from "react";
import { getAllCompaniesDepartmentsMapping } from "../features/Api";
import type { NavCompanyData, Payslip } from "../types/NavisionPayslip";
import CompanySpecificFields from "../utils/CompanySpecificFields";

type PayslipAndAllCompany = {
    payslip: Payslip,
    allCompany: NavCompanyData,
};

type DepartmentFilterSelectProps = {
    company: string | null,
    department: number,
    onChange: (department: number) => void,
    records: PayslipAndAllCompany[],
};

export default function DepartmentFilterSelect(props: DepartmentFilterSelectProps) {
    const [companyToDepartmentToName, setCompanyToDepartmentToName] = useState<Record<string, Record<number, string>>>();

    const countDepartmentRecords = (departmentCode: number) => {
        return props.records.filter(r => {
            const recordDepartment = new CompanySpecificFields(r.allCompany, r.payslip).departmentCode;
            return +(recordDepartment || "0") === departmentCode;
        }).length;
    };

    const departmentOptions = () => {
        if (!companyToDepartmentToName) {
            return [];
        }
        let departmentToName: Record<number, string>;
        if (props.company) {
            departmentToName = companyToDepartmentToName[props.company] ?? {};
        } else {
            departmentToName = {};
            for (const company in companyToDepartmentToName) {
                for (const department in companyToDepartmentToName[company]) {
                    departmentToName[department] = department;
                }
            }
        }

        return Object.entries(departmentToName).map(([code, name]) => {
            const count = countDepartmentRecords(+code);
            return { code, name, count };
        }).filter(r => window.EMPLOYEE_INFO && !window.EMPLOYEE_INFO.IsAdmin ?
            window.EMPLOYEE_INFO.ApprovalDepartmentList.includes(r.code) :
            r.count > 0);
    };

    useEffect(() => {
        getAllCompaniesDepartmentsMapping()
            .then(companyToDepartmentToName => setCompanyToDepartmentToName(companyToDepartmentToName));
    }, []);

    return <label>
        <span>Department:</span>
        {departmentOptions().length === 0
            ? <input placeholder="Not Specified" className="form-element" value={props.department} disabled={true}/>
            : <select name="departmentCode" className="form-element" value={props.department} onChange={(e) => {
                props.onChange(+e.target.value);
            }}>
                <option value="0">({props.records.length}) Not Specified</option>
                {departmentOptions().map(({ code, name, count }) => (
                    <option value={code} key={code}>({count}) {code}{name !== String(code) ? " " + name : ""}</option>
                ))}
            </select>}
    </label>;
}