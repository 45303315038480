import type { EmployeeInfoWise } from "../../types/EmployeeInfo";
import type { AddManualInvoiceFromSheetRequest } from "../Api";
import type { CellBase } from "react-spreadsheet";
import { entries, fromEntries } from "../../utils/typing";
import type { AbsoluteMonth } from "../../utils/dates";
import { getNumberOfDays } from "../../utils/dates";
import type { ManualInvoiceDetails } from "../../types/Api/Invoice";
import type { PlainMatrix } from "./aaiMtxIntegratedSheetInput";

export type ManualInvoiceSubmissionFormProps = {
    profile: EmployeeInfoWise,
    invoiceId: number,
    year: number,
    month: number,
    onSubmitted: (sheetRequestData: AddManualInvoiceFromSheetRequest<Partial<ManualInvoiceDetails>>) => void,
};

export const BOOLEAN_FIELDS = [
    "AdditionalDay",
    "PaymentOnOff",
    "Flying",
] as const;

/**
 * "1400" -> "14:00"
 * "750" -> "07:50"
 * "1h 5m" -> "1:5"
 * "4 HRS" -> "4:00"
 * "YES" -> "YES"
 */
export function normalizeTime(overtime: string) {
    let normalized = String(overtime)
        .replace(/(\d+)\D+(\d+)/, "$1:$2")
        .replace(/[^0-9:]+/g, "")
        .trim();
    if (normalized.match(/^\d{1,2}$/)) {
        normalized = normalized + ":00";
    } else if (normalized.endsWith(":")) {
        normalized = normalized + "00";
    } else if (normalized.match(/^\d{3,}$/)) {
        normalized = normalized.slice(0, -2) + ":" + normalized.slice(-2);
    }
    if (!normalized) {
        return overtime;
    } else {
        return normalized;
    }
}

const FIELD_TO_VALIDATION = {
    "Base": (rawValue: string) => rawValue.slice(0, 20),
    "TimeIn1": (rawValue: string) => normalizeTime(rawValue),
    "TimeOut1": (rawValue: string) => normalizeTime(rawValue),
    "TimeIn2": (rawValue: string) => normalizeTime(rawValue),
    "TimeOut2": (rawValue: string) => normalizeTime(rawValue),
    "DailyHours": (rawValue: string) => normalizeTime(rawValue),
    "OverTime": (rawValue: string) => normalizeTime(rawValue),
    "BlockHours": (rawValue: string) => normalizeTime(rawValue),
    "AdditionalDay": (rawValue: string) => !!rawValue,
    "PaymentOnOff": (rawValue: string) => !!rawValue,
    "Flying": (rawValue: string) => !!rawValue,
} as const;

type FIELD_TO_VALIDATION = typeof FIELD_TO_VALIDATION;

export function initializeEmptyRows(fields: readonly string[], yearMonth: AbsoluteMonth) {
    const dayRows: PlainMatrix = [];
    for (let i = 0; i < getNumberOfDays(yearMonth); ++i) {
        dayRows.push(fields.map(field => ({
            value: "", className: "field-name--" + field,
        })));
    }
    return dayRows;
}

export function normalizeRecordValues<
    const TKey extends keyof FIELD_TO_VALIDATION,
    TResult extends {
        readonly [key in TKey]: ReturnType<FIELD_TO_VALIDATION[key]>
    }
>(dict: Record<TKey, string>): TResult {
    return fromEntries(entries(dict).map(([field, rawValue]) => {
        const normalizer = FIELD_TO_VALIDATION[field] ?? ((rawValue: string) => rawValue);
        return [field, normalizer(rawValue)];
    })) as TResult;
}

export function sheetRowToRecord<
    TFields extends readonly (keyof FIELD_TO_VALIDATION)[],
    TResult extends {
        readonly [key in TFields[number]]: ReturnType<FIELD_TO_VALIDATION[key]>
    }
>(
    dayRow: (CellBase | undefined)[],
    fields: TFields
): TResult {
    const fieldToRawValue: Record<TFields[number], string> = fromEntries(
        fields.map((field, i) => [field, String(dayRow[i]?.value ?? "")])
    );
    return normalizeRecordValues<TFields[number], TResult>(fieldToRawValue);
}