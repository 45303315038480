import type { Dispatch, Store } from "redux";


import * as React from "react";
import ClaimsUsers from "./ClaimsUsers";
import ApproveWorkInvoices from "../components/ApproveWorkInvoices";
import type { CompanyName } from "@mhc/utils/types/nav";

type Props = {
  dispatch: Dispatch,
  store: Store,
  approveType: number, // @(Model.ApproveType != null ? Model.ApproveType : 1)
  code: string, // '@(Model.Employee != null ? Model.Employee.No_ : "")'
  companyName: CompanyName, // '@(Model.Employee != null ? Model.Employee.CompanyName : "")'
  approvalDepartmentList: string, // '@(Model.Employee != null ? Model.Employee.ApprovalDepartmentList : "")'
};

class CurrentTimesheets extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    require("./CurrentTimesheets.css");
  };

  render() {
    return (<div>{this.props.approveType === 2 ?
      <ClaimsUsers/> :
      <ApproveWorkInvoices approvalDepartmentList={this.props.approvalDepartmentList}
                           code={this.props.code}
                           companyName={this.props.companyName}/>}</div>);
  }
}

export default CurrentTimesheets;