import React from "react";
import type { NavInvoiceScreenParams, RdbPersonData } from "../features/Api";
import api from "../features/Api";
import type {
    RosterItem, RotationActivity,
    RotationData
} from "../features/roster/air_atlanta/Roster";
import { utcToZonedTime } from "date-fns-tz";

import "./InvoiceRosterViewAirAtlanta.css";
import { getDatePart,getMonthShortName, getMonthStr } from "@mhc/utils/src/dates";
import type {
    CodedSalaryJournalRecord,
    FeeEntryTypeRecord,
    ImpersonalSalaryJournalRecord
} from "../features/ExternalRostersApi";
import externalRostersApi from "../features/ExternalRostersApi";
import NavJournalRecordsTable from "./NavJournalRecordsTable";
import type { ActivityIconProps } from "./ActivityIcon";
import ActivityIcon from "./ActivityIcon";
import type { DutiedRotationDay,DutyKindExtension,RaidoActivityExtension,RaidoRosterItem,RaidoRotationDay, RotationDay } from "../features/roster/air_atlanta/RotationDaysGrouper";
import { getRotationDaysFromFullUnfiltered } from "../features/roster/air_atlanta/RotationDaysGrouper";
import { regroupToLt } from "../features/roster/air_atlanta/RotationDaysGrouper";
import { raidoToRm } from "../features/roster/air_atlanta/RotationDaysGrouper";
import { asUtc } from "../features/roster/air_atlanta/RotationDaysGrouper";
import AirAtlantaDutyDesignator from "./AirAtlantaDutyDesignator";
import type { OfficerFlightDeckFees, OfficerFlightDeckFeesPersonAaiFd, SccmFeeScale, SignedDocumentRow } from "../types/Api/Contract";
import { getIataToIana } from "../features/StaticData";
import {
    WW_ROTATION_PERIOD,
    consideredOffPayNiaStay,
    getGroundActivityCategory,
    isSccmJob,
    parseContract,
    fixManually,
    isCabinCrewDepartment,
    isFlightDeckDepartment,
    getNias,
    getDutyFlights,
    getFlightBlockMinutes,
    getPositioningBlockMinutes,
    parseRotationPeriod,
    isLoadMasterDepartment,
    hasPositioningDesignator,dutifyRotationDays
} from "../features/roster/air_atlanta/FeeCalculatorUtils";
import type { RotationPeriods
} from "../features/roster/air_atlanta/FeeCalculatorFlightDeck";
import {
    getAllRotationBlockHours
} from "../features/roster/air_atlanta/FeeCalculatorFlightDeck";
import {
    calculateFeesForFlightDeckPerson,
    getPrecedingUnterminatedRotationDays
} from "../features/roster/air_atlanta/FeeCalculatorFlightDeck";
import {
    calculateFeesForCabinCrewPerson,
    getPositioningTimeSummary
} from "../features/roster/air_atlanta/FeeCalculatorCabinCrew";
import type { EmployeeInfoWise } from "../types/EmployeeInfo";
import { HttpResponseError } from "../features/httpUtils";
import { brand } from "@mhc/utils/src/typing";
import { toCOMPANYNAMEUC,toCrewCodeUc } from "@mhc/utils/types/nav";
import type { IanaTimezone, IataAirport, IsoDate, IsoDateTime } from "@mhc/utils/types/utility";
import type { IataToIana, IsoLikeDateTime } from "../types/utility";
import type { RosterCrew } from "@mhc/utils/types/integrations/raido/api";
import { stringifyError } from "../utils/errorHandling";
import Err from "../utils/Err";
import { calculateFeesForLoadMasterPerson, countLoadMasterDutyDays } from "../features/roster/air_atlanta/FeeCalculatorLoadmaster";
import { isSameCompany } from "@mhc/utils/types/nav";

function formatDate(datetime: string) {
    const [year, month, rest] = datetime.split("-");
    const day = rest.slice(0, 2);
    return +day + " " + getMonthShortName(+month);
}

function getTimePart(datetime: IsoLikeDateTime) {
    return datetime.slice(11, 16);
}

/** dayNumber is the number of days since AD start */
function isAdDayNumber(dayNumber: number) {
    return dayNumber > 719162;
}

function formatDayNumberInRotation(dayNumber: number) {
    if (isAdDayNumber(dayNumber)) {
        const daySinceUnixEpoch = dayNumber - 719162;
        const date = new Date(0);
        date.setUTCDate(daySinceUnixEpoch);
        return date.getUTCDate();
    } else if (dayNumber === -1) {
        return "";
    } else {
        return dayNumber;
    }
}

function countDutyBlockMinutes(rotationDays: RotationDay[]) {
    const allActivities = rotationDays.flatMap(rd => rd.Activities);
    return getDutyFlights(allActivities).map(getFlightBlockMinutes).reduce((a, b) => a + b, 0);
}

function formatBlockMinutes(allMinutes: number) {
    const clockMinutes = allMinutes % 60;
    const clockHours = Math.floor(allMinutes / 60);
    return clockHours + ":" + String(clockMinutes).padStart(2, "0");
}

type Props = {
    locator: NavInvoiceScreenParams,
    latestContract: null | SignedDocumentRow,
    rdbData: null | RdbPersonData,
    onWarning: (message: string) => void,
};

type State = {
    nocRosterLoading: boolean,
    nocRosterCrew: RosterCrew | null,
    baseOnLocalTime: boolean,

    precedingUnterminatedRotationDays: null | RaidoRotationDay[],
    iataToIana: null | IataToIana,
    sccmFeeScales: null | SccmFeeScale[],
    officerFees: null | OfficerFlightDeckFeesPersonAaiFd,
    employeeNavData: null | EmployeeInfoWise,
    wageContractFees: null | FeeEntryTypeRecord[],
};

function getDayInRotation(rotationDay: DutiedRotationDay, rotations: RotationPeriods): number | null {
    for (const rotation of rotations) {
        if (rotation.startDate <= rotationDay.Date &&
            rotation.endDate >= rotationDay.Date
        ) {
            const utcMsPassed = new Date(rotationDay.Date).getTime() - new Date(rotation.startDate).getTime();
            const daysPassed = utcMsPassed / 1000 / 60 / 60 / 24;
            return daysPassed + 1;
        }
    }
    return null;
}

function getDayTimePercent(date: IsoDate, time: Date) {
    const msSinceMidnight = time.getTime() - new Date(date).getTime();
    const dayFraction = msSinceMidnight / (24 * 60 * 60 * 1000);
    const capped = Math.max(0, Math.min(1, dayFraction));
    const withoutBorders = capped * 0.7 + 0.1;
    const percents = 100 * withoutBorders;
    return percents.toFixed(2) + "%";
}

function removeIrrelevantOfficerFees(officerFees: OfficerFlightDeckFees) {
    const relevantOfficerFees = { ...officerFees };
    for (const key in relevantOfficerFees) {
        if (!relevantOfficerFees[key as keyof typeof relevantOfficerFees] ||
            relevantOfficerFees[key as keyof typeof relevantOfficerFees] === "0" ||
            key === "format" ||
            key === "employeeCode" ||
            key === "comment" ||
            key === "notes" ||
            key === "payrollComments" ||
            key === "fullName" ||
            key === "jobTitle" ||
            key === "email" ||
            key === "overtimeFeePerHour" ||
            key === "perDiemFee" ||
            key === "hardDaysFee" ||
            key === "partnerCompany" ||
            key === "contractDates" ||
            key === "department"
        ) {
            delete relevantOfficerFees[key as keyof typeof relevantOfficerFees];
        }
    }
    return relevantOfficerFees;
}

type DutiedRaidoRotationDay = RaidoRotationDay & DutyKindExtension;

export default class InvoiceRosterViewAirAtlanta extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            nocRosterLoading: true,
            nocRosterCrew: null,
            baseOnLocalTime: true,

            precedingUnterminatedRotationDays: null,
            iataToIana: null,
            sccmFeeScales: null,
            officerFees: null,
            employeeNavData: null,
            wageContractFees: null,
        };

        externalRostersApi.getAirAtlantaRaidoRoster({
            crew_code: toCrewCodeUc(this.props.locator.employeeCode),
            year: this.props.locator.year,
            month: this.props.locator.month,
        }).then(nocRosterCrew => {
            this.setState({ nocRosterCrew });
        }).catch(error => {
            const message = error instanceof HttpResponseError ? error.baseMessage : stringifyError(error);
            this.props.onWarning("Raido N-OC roster full data inaccessible: " + message);
        }).finally(() => this.setState({ nocRosterLoading: false }));

        getIataToIana()
            .then(iataToIana => this.setState({ iataToIana }));
        api.Employee.LoadProfileNav(this.props.locator).then(employeeNavData => {
            this.setState({ employeeNavData });

            if (isSccmJob(employeeNavData.JobTitle)) {
                api.Contract.count_airborne_sccm_fee_scales_by_person({ crewCode: this.props.locator.employeeCode })
                    .then(sccmFeeScales => this.setState({ sccmFeeScales }));
            }
            if (isFlightDeckDepartment(employeeNavData.Department)) {
                getPrecedingUnterminatedRotationDays(this.props.locator)
                    .then(precedingUnterminatedRotationDays => this.setState({ precedingUnterminatedRotationDays }));

                api.Contract.GetOfficerFlightDeckFeesRow(this.props.locator)
                    .then(officerFees => this.setState({ officerFees }))
                    .catch(error => {
                        if (error instanceof HttpResponseError && (
                            error.response.status === 501 ||
                            error.response.status === 412
                        )) {
                            // do not annoy user with errors when he opens future or past month rosters
                        } else {
                            throw error;
                        }
                    });
            } else {
                this.setState({ precedingUnterminatedRotationDays: [] });
            }
        });

        api.Contract.GetPersonWageContractFees(this.props.locator)
            .then(wageContractFees => this.setState({ wageContractFees }));
    }

    private getTimeZone(iata: IataAirport): IanaTimezone | null {
        return !iata
            ? brand<IanaTimezone>("Etc/UTC")
            : this.state.iataToIana?.[iata] ?? null;
    }

    private getTimeTooltipText(utcDateTimeStr: IsoDateTime, airport: IataAirport | null | undefined) {
        let result = formatDate(utcDateTimeStr) + " " + getTimePart(utcDateTimeStr) + " UTC";
        if (!airport) {
            return result;
        }
        const tz = this.getTimeZone(airport);
        if (!tz) {
            return result;
        }
        const utc = asUtc(utcDateTimeStr);
        const local = utcToZonedTime(utc, tz);
        result =
            local.getDate() + " " +
            getMonthShortName(local.getMonth() + 1) + " " +
            [local.getHours(), local.getMinutes()]
                .map(n => String(n)
                .padStart(2, "0")).join(":") + " LT (" + tz + ")\n" +
            result;
        return result;
    }

    private getEndTimeTooltipText(record: RosterItem) {
        const utcPlannedStr = record.ActivityDate;
        const utcActualStr = record.FullRotationActivity?.ATA_UTC || undefined;
        let text = this.getTimeTooltipText(utcPlannedStr, record.AirportTo);
        if (utcActualStr && utcActualStr.slice(0, 16) !== utcPlannedStr?.slice(0, 16)) {
            text += " PLANNED \n" + formatDate(utcActualStr) + " " + getTimePart(utcActualStr) + " UTC ACTUAL";
        }
        return text;
    }

    private getStartTimeTooltipText(
        airport: IataAirport | undefined | null,
        utcPlanned: Date,
        utcActual: Date | undefined
    ): string {
        let text = this.getTimeTooltipText(utcPlanned.toISOString(), airport);
        if (utcActual && utcActual.getTime() !== utcPlanned.getTime()) {
            text += " PLANNED \n" + formatDate(utcActual.toISOString()) + " " + getTimePart(utcActual.toISOString()) + " UTC ACTUAL";
        }
        return text;
    }

    get isCcm() {
        return isCabinCrewDepartment(this.state.employeeNavData?.Department ?? "");
    }

    get isFd() {
        return isFlightDeckDepartment(this.state.employeeNavData?.Department ?? "");
    }

    get isLdm() {
        return isLoadMasterDepartment(this.state.employeeNavData?.Department ?? "");
    }

    get isAdmin() {
        return window.EMPLOYEE_INFO?.IsAdmin;
    }

    private countDutyDays(dutylessRotationDays: RaidoRotationDay[]) {
        if (this.state.baseOnLocalTime) {
            dutylessRotationDays = regroupToLt(this.props.locator, dutylessRotationDays);
        }
        const dutified = this.isLdm
            ? countLoadMasterDutyDays(this.nias, dutylessRotationDays)
            : dutifyRotationDays(this.nias, dutylessRotationDays);
        return dutified;
    }

    private countTravelDays(records: DutiedRaidoRotationDay[]) {
        return records.filter(rd => {
            return rd.DutyKind === "TRAVEL"
                || rd.DutyKind === "DAY_SHIFT_TRAVEL_END"
                || rd.DutyKind === "DAY_SHIFT_TRAVEL_START";
        }).length;
    }

    private countNiaGroundDutyDays(records: DutiedRaidoRotationDay[]) {
        return records.filter(rd => rd.DutyKind === "NIA_GROUND_DUTY").length;
    }

    private get parsedContract() {
        if (this.props.latestContract) {
            return parseContract(this.props.latestContract);
        }
        return null;
    }

    private get nias() {
        return getNias({
            navEntry: this.state.employeeNavData,
            rdbEntry: !this.props.rdbData ? undefined : {
                Nearest_Airport: this.props.rdbData.rdbNia,
            },
            latestContractData: this.props.latestContract,
        });
    }

    private toSelectedTimeFormatDateObj(utc: IsoDateTime, offsetMinutes: number) {
        const dateObj = asUtc(utc);
        if (this.state.baseOnLocalTime) {
            dateObj.setUTCMinutes(dateObj.getUTCMinutes() + offsetMinutes);
        }
        return dateObj;
    }

    private toSelectedTimeFormat(utc: IsoDateTime, offsetMinutes: number) {
        const dateObj = this.toSelectedTimeFormatDateObj(utc, offsetMinutes);
        return getTimePart(dateObj.toISOString());
    }

    private isDayRelevant(date: IsoDate) {
        return date.startsWith(getMonthStr(this.props.locator));
    }

    private makeRosterTable(dutylessRotationDaysUtc: RaidoRotationDay[]) {
        const dirtyRotationDaysInFormat = this.countDutyDays(dutylessRotationDaysUtc);
        const rotationDaysInFormat = dirtyRotationDaysInFormat.filter(d => this.isDayRelevant(d.Date));
        const rotationDaysUtc = dutylessRotationDaysUtc.filter(d => this.isDayRelevant(d.Date));
        let dutyDays = 0;
        const rotationPeriod = this.state.officerFees?.rotationType &&
            parseRotationPeriod(this.state.officerFees.rotationType) ||
            this.parsedContract?.rotationPeriod || WW_ROTATION_PERIOD;
        const positioningBlockHoursSummary = getPositioningTimeSummary(this.nias, rotationDaysUtc);
        const monthDutyBlockHours = countDutyBlockMinutes(rotationDaysUtc);
        const rotations = !this.isFd || !this.state.precedingUnterminatedRotationDays ? null :
            getAllRotationBlockHours(rotationDaysInFormat, this.state.precedingUnterminatedRotationDays, WW_ROTATION_PERIOD);
        const showExactRotationTd = !!rotations && rotationDaysInFormat.length > 0 &&
            getDayInRotation(rotationDaysInFormat[rotationDaysInFormat.length - 1], rotations) !== rotationDaysInFormat.filter(rd => rd.DutyKind !== "OFF_DUTY").length;
        const result = <table className="aai-roster-table generic-roster-table">
            <thead>
                <tr className="roster-column-heading">
                    <th>Date</th>
                    <th>Activity</th>
                    <th colSpan={2}>Departure</th>
                    <th colSpan={2}>Arrival</th>
                    <th title="Block Hours Decimal Units">BL HRS</th>
                    <th>Designators</th>
                    <th>Duty #</th>
                    {showExactRotationTd && <th title="Rotation Day Number">Rot.</th>}
                </tr>
            </thead>
            <tbody>
                {dirtyRotationDaysInFormat.flatMap(rotationDay => {
                    const isDayRelevant = this.isDayRelevant(rotationDay.Date);
                    const exactDayInRotation = !rotations || !isDayRelevant ? null : getDayInRotation(rotationDay, rotations);
                    const rowSpan = rotationDay.Activities.length;
                    const isDuty = rotationDay.DutyKind !== "OFF_DUTY" && isDayRelevant;
                    if (isDuty) {
                        ++dutyDays;
                    }
                    const dutyDaysTd = <td
                        rowSpan={rowSpan}
                        className="alpha-numeric-field duty-days"
                        title={rotationDay.DutyKind ?? undefined}
                        data-duty-kind={rotationDay.DutyKind}
                    >{isDuty ? dutyDays : ""}</td>;

                    const rotationExactTd = <td
                        rowSpan={rowSpan}
                        className={
                            "alpha-numeric-field rotation-day-number" +
                            (exactDayInRotation && exactDayInRotation % rotationPeriod === 0 ? " last-day-of-rotation" : "")
                    }
                    >{exactDayInRotation || ""}</td>;

                    return rotationDay.Activities.map((record: RaidoRosterItem, i) => {
                        const groundActivityCategory = getGroundActivityCategory(record);
                        const startDateTimePlanned = asUtc(record.ActivityType === "BLANK" ? record.ActivityDate : record.FullRaidoActivity.Start);
                        const startTimeInFormat = record.ActivityType === "BLANK"
                            ? ""
                            : this.toSelectedTimeFormat(record.FullRaidoActivity.Start, record.FullRaidoActivity.StartLocalTimeDiff);
                        const endTimeInFormatObj = record.ActivityType === "BLANK" ? null : this.toSelectedTimeFormatDateObj(
                            record.FullRaidoActivity.End, record.FullRaidoActivity.EndLocalTimeDiff
                        );
                        const endTimeInFormat = !endTimeInFormatObj
                            ? ""
                            : getTimePart(endTimeInFormatObj.toISOString());
                        const atHomebase = this.nias.size === 0 ||
                            record.ActivityType !== "BLANK" &&
                            consideredOffPayNiaStay(this.nias, record, rotationDaysInFormat);
                        const fullActivity: RotationActivity | null = record.FullRotationActivity ?? null;

                        const startDateTimeActual = record.FullRotationActivity?.ATD_UTC && asUtc(record.FullRotationActivity.ATD_UTC) || undefined;
                        let atdDifferenceMs = 0;
                        if (startDateTimeActual) {
                            atdDifferenceMs = Math.abs(startDateTimeActual.getTime() - startDateTimePlanned.getTime());
                        }
                        const dateObj = new Date(rotationDay.Date);
                        const shownStartDate = `${dateObj.getUTCDate()} ${getMonthShortName(dateObj.getUTCMonth() + 1)}`;
                        const isNewDay = i === 0;

                        let effectiveActivityType: ActivityIconProps["activityType"];
                        if (record.ActivityType === "Flight" &&
                            hasPositioningDesignator(fullActivity)
                        ) {
                            // rather often there are flights that are not supposed to be considered
                            // duty and therefore are not to be counted towards block hours
                            effectiveActivityType = "Deadhead";
                        } else {
                            effectiveActivityType = record.ActivityType;
                        }
                        const actualBlockTimeMissing =
                            !record.FullRotationActivity?.ATD_UTC ||
                            !record.FullRotationActivity?.ATA_UTC;
                        return <tr
                            key={rotationDay.Date + "_" + i}
                            data-activity-type={effectiveActivityType}
                            data-ground-activity-category={groundActivityCategory ?? undefined}
                            className={""
                                + (atHomebase ? " at-homebase" : "")
                                + (isNewDay ? " new-day" : "")
                                + (!isDayRelevant ? " irrelevant-day" : "")
                            }
                            style={{
                                "--day-time-start-percent": getDayTimePercent(rotationDay.Date, startDateTimePlanned),
                                "--day-time-end-percent": getDayTimePercent(rotationDay.Date, asUtc(record.ActivityDate)),
                            }}
                        >
                            {isNewDay && <td
                                 rowSpan={rowSpan}
                                 className="alpha-numeric-field start-date-utc"
                                 data-field="StartDateUtc">{shownStartDate}</td>}
                            <td className="activity-cell">
                                <div className="activity-wrapper">
                                    <ActivityIcon activityType={effectiveActivityType} category={groundActivityCategory}/>
                                    {record.ActivityType === "Flight" || record.ActivityType === "Deadhead"
                                        ? <div className="aai-flight-specific-details">
                                            <div className="alpha-numeric-field"
                                                 data-field="FlightNumber"
                                                 title={record.ActivityType === "Deadhead" ? "Deadhead flight. Duration " + record.DeadheadHours + ":" + String(record.DeadHeadMinutes).padStart(2, "0") : undefined}
                                            >{record.FlightNumber}</div>
                                        </div>
                                        : <div className="aai-ground-specific-details">
                                            <div data-field="GroundActivityInfo"
                                                 title={record.GroundActivityInfo ?? undefined}>{record.GNDACTCODETITLE}</div>
                                        </div>}
                                </div>
                            </td>
                            <td className={"alpha-numeric-field" + (record.AirportFrom === record.AirportTo ? " unimportant-field" : "") + (record.AirportFrom && this.nias.has(record.AirportFrom) ? " homebase" : "")}
                                title={record.AirportFrom && this.nias.has(record.AirportFrom) ? "Homebase" : undefined}
                            >{record.AirportFrom}</td>
                            <td
                                title={this.getStartTimeTooltipText(record.AirportFrom, startDateTimePlanned, startDateTimeActual)}
                                className={
                                    "alpha-numeric-field" +
                                    (record.ActivityType === "BLANK" ? " unimportant-field" : "") +
                                    (Math.abs(atdDifferenceMs) >= 30 * 60 * 1000 ? " actual-time-differs-from-planned" : "")
                                }
                                data-field="StartTimeUtc"
                            >{startTimeInFormat}</td>
                            <td className={"alpha-numeric-field" + (record.AirportFrom && this.nias.has(record.AirportTo) ? " homebase" : "")}
                                title={record.AirportFrom && this.nias.has(record.AirportTo) ? "Homebase" : undefined}
                                data-field="AirportTo"
                            >{record.AirportTo}</td>
                            <td
                                title={this.getEndTimeTooltipText(record)}
                                className={
                                    "alpha-numeric-field" +
                                    (endTimeInFormatObj && getDatePart(endTimeInFormatObj) > rotationDay.Date && endTimeInFormat !== "00:00" ? " ends-past-midnight" : "")
                                }
                            >{record.ActivityType === "BLANK" ? "" : endTimeInFormat}</td>
                            <td className={"alpha-numeric-field" + (actualBlockTimeMissing ? " actual-block-time-missing" : "")} data-field="BlockTime">
                                {effectiveActivityType === "Flight" && <span title={"Block Time: " + formatBlockMinutes(getFlightBlockMinutes(record)) + (!actualBlockTimeMissing ? "" : " (Actual ATA/ATD Block Time Missing! Displaying the planned time)")}>{(getFlightBlockMinutes(record) / 60).toFixed(3)}</span>}
                                {effectiveActivityType === "Deadhead" && this.isCcm && <span title={"Positioning Block Time: " + formatBlockMinutes(getPositioningBlockMinutes(record))} className="positioning-block-hours">{(getPositioningBlockMinutes(record) / 60).toFixed(3)}</span>}
                            </td>
                            <td className="alpha-numeric-field">
                                <div className="duty-designators-list">{fullActivity?.DutyDesignators?.split(",").map(dd => {
                                    return <AirAtlantaDutyDesignator key={dd} dutyDesignator={dd}/>;
                                })}</div>
                            </td>
                            {isNewDay ? dutyDaysTd : null}
                            {isNewDay && showExactRotationTd && rotationExactTd}
                        </tr>;
                    });
                })}
            </tbody>
            <tfoot className="aai-summary-lines">
                {rotationDaysInFormat.slice(-1)[0]?.Activities.slice(-1)[0]?.PayTypes?.filter(pt => !pt.Description?.startsWith("Total BlockHours")).map(pt => <tr key={pt.Description}>
                    <td colSpan={999}>{pt.Description}</td>
                </tr>)}
            </tfoot>
            <tfoot className="in-house-calculated-summary-lines">
                <tr className="travel-days-summary">
                    <td></td>
                    <td className="total-row-icon">
                        <img src="/img/roster-activity-logos/pick-up.svg" title="Positioning" alt="positioning"/>
                    </td>
                    <td colSpan={6} className="summary-text">
                        Travel Days:
                    </td>
                    <td className="alpha-numeric-field summary-value">
                        {this.countTravelDays(rotationDaysInFormat) ?? "?"}
                    </td>
                </tr>
                <tr className="nia-ground-duty-days-summary">
                    <td></td>
                    <td className="total-row-icon">
                        <img src="/img/roster-activity-logos/on-duty.svg" title="Positioning" alt="positioning"/>
                    </td>
                    <td colSpan={6} className="summary-text">
                        NIA Ground Duty Days:
                    </td>
                    <td className="alpha-numeric-field summary-value">
                        {this.countNiaGroundDutyDays(rotationDaysInFormat) ?? "?"}
                    </td>
                </tr>
                {!this.isCcm ? null : <>
                    <tr className="duty-block-hours-summary">
                        <td></td>
                        <td className="total-row-icon">
                            <img src="/img/roster-activity-logos/flight-duty.svg" title="Flight Duty"/>
                        </td>
                        <td colSpan={4} className="summary-text">
                            Duty Block Hours:
                        </td>
                        <td className="alpha-numeric-field" data-field="BlockTime" title={formatBlockMinutes(monthDutyBlockHours)}>
                            {(monthDutyBlockHours / 60).toFixed(3)}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="total-row-icon">
                            <img src="/img/roster-activity-logos/pick-up.svg" title="Positioning"/>
                        </td>
                        <td colSpan={4} className="summary-text">
                            Deadhead Block Hours:
                        </td>
                        <td className="alpha-numeric-field positioning-block-hours" data-field="BlockTime" title={formatBlockMinutes(positioningBlockHoursSummary.deadheadMinutes)}>
                            {(positioningBlockHoursSummary.deadheadMinutes / 60).toFixed(3)}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="total-row-icon">
                            <img src="/img/roster-activity-logos/pick-up.svg" title="Positioning"/>
                        </td>
                        <td colSpan={4} className="summary-text">
                            Positioning Block Hours:
                        </td>
                        <td className="alpha-numeric-field positioning-block-hours" data-field="BlockTime" title={formatBlockMinutes(positioningBlockHoursSummary.positioningMinutes)}>
                            {(positioningBlockHoursSummary.positioningMinutes / 60).toFixed(3)}
                        </td>
                    </tr>
                </>}
            </tfoot>
        </table>;
        return <div className="invoice-roster-view-table-wrapper">{result}</div>;
    }

    private get nocFullRotationDataOrErr() {
        if (!this.state.nocRosterCrew) {
            return new Err("Raido N-OC Roster could not be retrieved");
        }
        return raidoToRm(this.state.nocRosterCrew, this.props.locator);
    }

    private get fullRotationData(): null | RotationData {
        const fullRotationData = this.nocFullRotationDataOrErr;
        if (fullRotationData instanceof Err) {
            return null;
        } else {
            return fullRotationData;
        }
    }

    private get nocRotationDaysOrErr(): RaidoRotationDay[] | Err {
        const fullRotationData = this.nocFullRotationDataOrErr;
        if (fullRotationData instanceof Err) {
            return fullRotationData;
        }
        return getRotationDaysFromFullUnfiltered<RaidoActivityExtension>(fullRotationData, null);
    }

    private get rotationDaysUtc(): RaidoRotationDay[] | null {
        const rotationDays: RaidoRotationDay[] | Err = this.nocRotationDaysOrErr;
        if (rotationDays instanceof Err) {
            return null;
        } else {
            return rotationDays;
        }
    }

    get journalEntries(): CodedSalaryJournalRecord[] {
        if (!this.state.employeeNavData ||
            !this.state.precedingUnterminatedRotationDays
        ) {
            return [];
        }
        const payrollMonth = {
            year: this.props.locator.year,
            month: this.props.locator.month,
        };
        const crew_code = this.props.locator.employeeCode;

        const rotationDays: RaidoRotationDay[] | Err = this.nocRotationDaysOrErr;
        if (rotationDays instanceof Err) {
            return fixManually(payrollMonth, rotationDays.message)
                .map(jr => ({ ...jr, crew_code }));
        }

        const params = {
            year: this.props.locator.year,
            month: this.props.locator.month,
            rotationDays: rotationDays,
            aircraft: isSameCompany(this.props.locator.company, "D2W - AAIUSD")
                ? this.state.employeeNavData.GlobalDimension2Code
                : this.state.employeeNavData.ShortcutDimension3Code,
            precedingUnterminatedRotationDays: this.state.precedingUnterminatedRotationDays,
            navEntry: this.state.employeeNavData,
            latestContractData: this.props.latestContract,
        };

        let journalEntries: ImpersonalSalaryJournalRecord[];
        if (!this.state.iataToIana) {
            journalEntries = fixManually(params, "Time Zones data loading...");
        } else if (this.isFd) {
            journalEntries = calculateFeesForFlightDeckPerson(params, this.state.officerFees);
        } else if (this.isLdm) {
            journalEntries = calculateFeesForLoadMasterPerson(params, this.state.wageContractFees);
        } else if (this.isCcm) {
            journalEntries = calculateFeesForCabinCrewPerson(params);
        } else {
            return [];
        }
        return journalEntries.map(jr => ({ ...jr, crew_code }));
    }

    private get aaiValidContractInfo() {
        const aaiContractInfo = this.fullRotationData?.EmployeeInfo?.ContractInfo;
        if (!aaiContractInfo) {
            return null;
        }
        if (this.parsedContract &&
            this.parsedContract.rotationPeriod &&
            aaiContractInfo.DurationInDays !== this.parsedContract.rotationPeriod
        ) {
            // for some of the people Air Atlanta sends us WW even though in the roster and in the contract you can clearly see 24/12 or 21/14
            // I see that EmployeeSpecFuncs is usually correct though, maybe should use it instead, ContractInfo is probably not maintained anymore
            return null;
        }
        return aaiContractInfo;
    }

    private get nearestInternationalAirport() {
        return this.state.employeeNavData?.NearestIntAirport;
    }

    private prepareUnitTestCase(journalEntries: CodedSalaryJournalRecord[]) {
        const relevantOfficerFees = !this.state.officerFees ? null :
            removeIrrelevantOfficerFees(this.state.officerFees);
        return [
            ``,
            `    {`,
            `        title: "Untitled Test Case ${this.props.locator.employeeCode} ${getMonthStr(this.props.locator)}",`,
            `        input: Input(${this.props.locator.year}, ${this.props.locator.month}, "${this.props.locator.employeeCode}", "${[...this.nias][0] ?? "XXX"}", ${JSON.stringify(relevantOfficerFees)}),`,
            `        output: rowsToObjects(`,
            `            ["fee_entry_type", "units", "service_start_time", "service_end_time", "payment_per_unit", "description"],`,
            ...journalEntries.map(je => `            ` + JSON.stringify([je.fee_entry_type, je.units, je.service_start_time, je.service_end_time, je.payment_per_unit, je.description]) + ","),
            `        ),`,
            `    },`,
        ].join("\n");
    }

    render() {
        let content;
        if (!(this.nocRotationDaysOrErr instanceof Err)) {
            content = <div className="aai-current-roster">
                <h3>Roster from Raido N-OC</h3>
                <div className="aai-contract-facts-list">
                    {this.nearestInternationalAirport && this.nearestInternationalAirport !== this.parsedContract?.nia &&
                        <div className="airline-agnostic-roster-data">NIA in NAV: {this.nearestInternationalAirport}</div>}
                    {this.props.rdbData?.rdbNia && this.props.rdbData?.rdbNia !== this.parsedContract?.nia && this.props.rdbData?.rdbNia !== this.nearestInternationalAirport &&
                        <div className="airline-agnostic-roster-data">NIA in RDB: {this.props.rdbData?.rdbNia}</div>}
                    {this.fullRotationData?.EmployeeInfo?.Base ? "Roster Base: " + this.fullRotationData.EmployeeInfo.Base : ""}
                    {this.aaiValidContractInfo && <>
                        <div>Contract: {this.aaiValidContractInfo.SpecFuncCode} ({this.aaiValidContractInfo.Title})</div>
                        <div>Rotation Period: {this.aaiValidContractInfo.DurationInDays} days</div>
                    </>}
                    <div className="time-format-options-label">
                        <span>Time Format: </span>
                        <fieldset className="time-format-options">
                            <label>
                                <input
                                    type="radio"
                                    name="timeFormat"
                                    checked={!this.state.baseOnLocalTime}
                                    onChange={() => this.setState({ baseOnLocalTime: false })}
                                />
                                <span title="Coordinated Universal Time">UTC</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="timeFormat"
                                    checked={this.state.baseOnLocalTime}
                                    onChange={() => this.setState({ baseOnLocalTime: true })}
                                />
                                <span title="Local Time">LT</span>
                            </label>
                        </fieldset>
                    </div>
                </div>
                {this.rotationDaysUtc && this.makeRosterTable(this.rotationDaysUtc)}
            </div>;
        } else if (this.state.nocRosterLoading) {
            content = <div className="status-loading-animated-ellipsis" style={{ textAlign: "center" }}>
                Roster Data Loading
            </div>;
        } else {
            content = <div className="content-encountered-errors">
                Encountered errors during Roster data retrieval:
            </div>;
        }
        return <div style={{ textAlign: "center" }}>
            {content}
            {this.journalEntries.length === 0 ? undefined : <div className="preliminary-salary-journal-from-roster">
                <h3>Preliminary Salary Journal batch calculated from Raido N-OC Roster</h3>
                <NavJournalRecordsTable calculatedPeople={[{
                    person: { navEntry: { No_: this.props.locator.employeeCode } },
                    journalRecords: this.journalEntries,
                }]} COMPANYNAME={toCOMPANYNAMEUC(this.props.locator.company)}/>
                {window.EMPLOYEE_INFO?.EMail === "arturklesun@yahoo.com" && <pre style={{ textAlign: "left", maxWidth: "600px" }}>
                    {this.prepareUnitTestCase(this.journalEntries)}
                </pre>}
            </div>}
            {!this.state.sccmFeeScales || this.state.sccmFeeScales.length === 0 ? undefined : <div>
                <h3>SCCM Fee Scales History</h3>
                <table className="sccm-fee-scales-history">
                    <thead>
                        <tr>
                            <th>Pay Runs</th>
                            <th>Fee Days</th>
                            <th>First Day</th>
                            <th>Last Day</th>
                            <th>Daily Fee</th>
                        </tr>
                    </thead>
                    <tbody>{this.state.sccmFeeScales.map(scale => <tr key={scale.DailyFee}>
                        <td>{scale.Months}</td>
                        <td>{scale.Days}</td>
                        <td>{scale.MinDate}</td>
                        <td>{scale.MaxDate}</td>
                        <td>{scale.DailyFee}</td>
                    </tr>)}</tbody>
                </table>
            </div>}
        </div>;
    }
}
