import * as React from "react";
import * as _ from "lodash";
import Pagination from "react-js-pagination";
import ErrorPage from "./ErrorPage";
import type EmployeeInfo from "../types/EmployeeInfo";
import UserAdminForm, { createEmptyUser, getInitialUserState } from "../components/UserAdminForm";
import { typed } from "@mhc/utils/src/typing";
import api from "../features/Api";

export const views = {
  Table: 0, Form: 1
};

type Props = {
  isAdmin: boolean,
};

function getInitialState(props: Props) {
  return {
    User: getInitialUserState(),
    newUser: false,
    nameDeptCrewcodeClient: "",
    adminCheck: false,
    manualCheck: false,
    soeApprovalCheck: false,
    AllEmployees: [],
    isClientCheck: false,
    isActive: true,
    madeFirstCall: false,
    currentPage: 1,
    employeesPerPage: 20,
    isAdmin: props.isAdmin,
    viewForm: views.Table,
    sortBy: {
      column: typed<"" | keyof EmployeeInfo>(""),
      order: typed<"desc" | "asc">("asc"),
    },
    loading: false,
    /** unused it seems: view kind is defined by `viewForm` field */
    view: typed<undefined | "All">(undefined),
    ShowAdminsFields: typed<undefined | "table-cell">(undefined),
    employees: typed<EmployeeInfo[] | undefined>(undefined),
    employeesWise: typed<EmployeeInfo[] | undefined>(undefined),
  };
}

type State = ReturnType<typeof getInitialState>;

export default class Users extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    require("./Users.css");
    this.state = getInitialState(props);
  }

  componentDidMount() {
    if (!this.state.madeFirstCall) this.setState({
      madeFirstCall: true, view: "All", ShowAdminsFields: "table-cell"
    });
    api.Employee.GetAllEmployees().then(employees => this.setState({ employees }));
    api.Employee.GetAllEmployeesWise({ company: "" }).then(employeesWise => this.setState({ employeesWise }));
    if (!this.state.madeFirstCall) this.setState({
      madeFirstCall: true, view: "All", ShowAdminsFields: "table-cell"
    });
  };

  order(employees: EmployeeInfo[]) {
    if (this.state.sortBy.column != "") {
      return _.orderBy(employees, [this.state.sortBy.column], [this.state.sortBy.order]);
    }

    return employees;
  };

  handleSort(e: React.MouseEvent<HTMLTableCellElement>) {
    const headerId = e.currentTarget.id;

    this.setState({
      sortBy: {
        column: headerId.slice(2) as keyof EmployeeInfo,
        order: this.state.sortBy.order == "desc" ? "asc" : "desc"
      }
    });

    if (e.currentTarget.parentElement) {
      _.forEach(e.currentTarget.parentElement.children, function (c) {
        c.className = "";
      });
    }

    e.currentTarget.className = this.state.sortBy.order == "desc" ? "asc" : "desc";
  };

  _getHeader() {
    return (<div className="invoice-header">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1>Users</h1>
          </div>
        </div>
      </div>
    </div>);
  };

  handlePageChange(pageNumber: number) {
    this.setState({ currentPage: pageNumber });
  };

  showUserForm(newUser: boolean) {
    this.setState({ newUser: newUser });
    if (!this.state.loading) {
      this.setState({
        viewForm: views.Form
      });
    }
  };

  async updateEmployee(employee: EmployeeInfo) {
    await api.Employee.UpdateEmployee(employee);
    await api.Employee.GetAllEmployees().then(employees => this.setState({ employees }));
  }

  _filterByNameCrewDeptcode(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ nameDeptCrewcodeClient: e.target.value });
  }

  _filterByAdmins(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ adminCheck: e.target.checked });
  }

  _filterByManualInserted(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ manualCheck: e.target.checked });
  }

  _filterBySoeApproval(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ soeApprovalCheck: e.target.checked });
  }

  _filterByIsActive(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ isActive: e.target.checked });
  }

  _filterByIsClient(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ isClientCheck: e.target.checked });
  }

  goBack() {
    location.href = "/Home/InvoicesUsersClient";
  }

  _generateFilters() {
    return (<div>
      <div className="col-md-8">

        <div className="col-xs-4 select ">
          <input type="checkbox"
                 defaultChecked={this.state.adminCheck == false ? false : true}
                 onChange={(e) => this._filterByAdmins(e)}/>
          <span>Admins </span>
        </div>
        <div className="col-xs-4 select ">
          <input type="checkbox"
                 defaultChecked={this.state.manualCheck == false ? false : true}
                 onChange={(e) => this._filterByManualInserted(e)}/>
          <span>Manually Added</span>
        </div>
        <div className="col-xs-4 select ">
          <input type="checkbox" defaultChecked={true} onChange={(e) => this._filterByIsActive(e)}/>
          <span>Active </span>
        </div>
        <div className="col-xs-4 select ">
          <input type="checkbox"
                 defaultChecked={this.state.isClientCheck == false ? false : true}
                 onChange={(e) => this._filterByIsClient(e)}/>
          <span>Work Invoice Approvers</span>
        </div>
        <div className="col-xs-4 select ">
          <input type="checkbox"
                 defaultChecked={this.state.soeApprovalCheck == false ? false : true}
                 onChange={(e) => this._filterBySoeApproval(e)}/>
          <span>SoE Approvers</span>
        </div>
      </div>
      <div className="col-md-4 text-right">
        <a className="btn btn-warning" style={{ display: "inline-block" }}
           href="#" onClick={() => {
          this.showUserForm(true);
        }}>Add user</a>
      </div>
      <div className="col-md-4 text-right">

        <div className="col-xs-10 search searchBox">
          <input type="text" placeholder="Search in list"
                 onChange={(e) => this._filterByNameCrewDeptcode(e)}/>
        </div>
      </div>
    </div>);
  }
  ;

  hasAdminPrivileges() {
    const cleanIsAdmin = this.state.isAdmin === true;
    return cleanIsAdmin;
  }  ;

  setUser(employee: EmployeeInfo) {
    const User = _.cloneDeep(this.state.User);
    User.Name = employee.LocalName;
    User.Email = employee.EMail;
    User.Code = employee.No_;
    User.IsAdmin = employee.IsAdmin;
    User.IsSOEApproval = employee.IsSOEApproval;
    User.ApproveOwnInvoices = employee.ApproveOwnInvoices;
    User.ApproveOwnSoe = employee.ApproveOwnSoe;
    User.IsClient = employee.IsClient;
    User.IsActive = employee.IsActive;
    User.CompanyName = employee.CompanyName;
    User.Id = String(employee.Id);
    User.ApprovalDepartmentList = employee.ApprovalDepartmentList.split("-");
    User.ApprovalCompaniesList = employee.ApprovalCompaniesList ?? [];
    User.isEdit = true;
    User.isManual = employee.IsManualAdded;

    this.setState({
      User: User,
    });

    this.showUserForm(false);
  }

  returnToTable() {
    const User = createEmptyUser(this.state.User);
    User.isManual = false;

    this.setState({ newUser: false, User: User });
    if (!this.state.loading) {
      this.setState({
        viewForm: views.Table
      });
    }
  };

  render() {
    let employees = this.order(this.state.employees ?? []).filter(d => {
      const query = this.state.nameDeptCrewcodeClient.toUpperCase();
      return d.LocalName.toUpperCase().includes(query)
          || d.No_.toUpperCase().includes(query)
          || d.CompanyDisplayName?.toUpperCase().includes(query)
          || (d.Department != null && d.Department.toUpperCase().includes(query))
          || d.EMail.toUpperCase().includes(query);
    });

    const indexOfLastEmployee = this.state.currentPage * this.state.employeesPerPage;
    const indexOfFirstEmployee = indexOfLastEmployee - this.state.employeesPerPage;
    if (!this.state.isActive) employees = _.filter(employees, d => d.IsActive == false);

    if (this.state.adminCheck) employees = _.filter(employees, d => d.IsAdmin == true);

    if (this.state.isClientCheck) employees = _.filter(employees, d => d.IsClient == true);

    if (this.state.soeApprovalCheck) employees = _.filter(employees, d => d.IsSOEApproval == true);

    if (this.state.manualCheck) employees = _.filter(employees, d => d.IsManualAdded == true);
    const currentEmployees = employees ? employees.slice(indexOfFirstEmployee, indexOfLastEmployee) : null;
    let employeeActions: EmployeeLine[] = [];
    const { employeesWise } = this.state;
    if (employeesWise && employeesWise.length > 0) {
      employeeActions = currentEmployees ? currentEmployees.map((e, index) => {
        return (<EmployeeLine employeeItem={e}
                              AllEmp={employeesWise}
                              openIndividualSettingsScreen={() => this.setUser(e)}
                              ShowAdminsFields={this.state.ShowAdminsFields}
                              updateEmployee={this.updateEmployee.bind(this)}
                              key={index}/>);
      }) : [];
    }

    if (!this.state.madeFirstCall || employees.length == 0) {
      return (<div className="invoice-wrapper admin-wrapper admin-Users">
        {this._getHeader()}
        <div className="container">
          <div className="row">
            <div><a onClick={(e) => this.goBack()}><img className="back" src="/img/back.png" alt={"back"}/></a></div>
            <div className="col-xs-12 filters">
              {this._generateFilters()}
            </div>
            <div className="col-xs-12">
              <h1
                className="loading">{!this.state.madeFirstCall ? "Loading list of registered employees..." : "No user found"}</h1>
            </div>
          </div>
        </div>
      </div>);
    } else if (!this.hasAdminPrivileges()) {
      return <ErrorPage/>;
    } else return (this.state.viewForm == views.Table ? (<div className="invoice-wrapper admin-wrapper admin-Users">
      {this._getHeader()}
      <div className="container">
        <div className="row">
          <div><a onClick={(e) => this.goBack()}><img className="back" src="/img/back.png" alt={"back"}/></a>
          </div>
          <div className="col-xs-12 filters">
            {this._generateFilters()}
          </div>
          <div className="row col-xs-12">
            {/* <div className="col-xs-3 CompanyList" >
                {this._generateCompanyList()}
              </div> */}
            <div className="col-xs-6"></div>
            <div className="col-xs-3">

            </div>
          </div>
          <div className="col-xs-12">
            <div className="table-responsive">
              <table className="invoices invoice-months table table-striped table-condensed">
                <thead>
                <tr>
                  <th className="noSort" id="thLocalName" onClick={(e) => this.handleSort(e)}>Name</th>
                  <th className="noSort" id="thEMail" onClick={(e) => this.handleSort(e)}>Email</th>
                  <th className="noSort" id="thNo_" onClick={(e) => this.handleSort(e)}>Code</th>
                  <th className="noSort" id="thCompanyName" onClick={(e) => this.handleSort(e)}>Client</th>
                  <th id="thIsClient" className="center noSort" style={{ display: this.state.ShowAdminsFields }}
                      onClick={(e) => this.handleSort(e)}>Work Invoice Approver
                  </th>
                  <th id="thIsSOEApproval" className="center noSort"
                      style={{ display: this.state.ShowAdminsFields }}
                      onClick={(e) => this.handleSort(e)}>SOE Approver
                  </th>
                  <th id="thIsAdmin" className="center noSort" style={{ display: this.state.ShowAdminsFields }}
                      onClick={(e) => this.handleSort(e)}>Admin
                  </th>
                  <th className="noSort"/>
                </tr>
                </thead>
                <tbody>
                {employeeActions}
                </tbody>
              </table>
            </div>
            <div className="row col-xs-12">
              <div className="col-xs-12 text-right">
                <Pagination
                  activePage={this.state.currentPage}
                  itemsCountPerPage={this.state.employeesPerPage}
                  totalItemsCount={employees ? employees.length : 0}
                  pageRangeDisplayed={5}
                  onChange={(e) => this.handlePageChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>) : (<div className="invoice-wrapper">
      {this._getHeader()}
      <UserAdminForm
          key={this.state.User.Id || undefined}
          User={this.state.User}
          newUser={this.state.newUser}
          close={() => this.returnToTable()}
          onSubmitted={() => {
            api.Employee.GetAllEmployees().then(employees => this.setState({ employees }));
            this.returnToTable();
          }}
      />
    </div>));
  }
}

type EmployeeLineProps = {
  employeeItem: EmployeeInfo,
  updateEmployee: (payload: EmployeeInfo) => void,
  ShowAdminsFields: undefined | "table-cell",
  AllEmp: EmployeeInfo[],
  openIndividualSettingsScreen: () => void,
};

export const EmployeeLine = (props: EmployeeLineProps) => {
  const handleIsClientChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const employee = _.cloneDeep(props.employeeItem);
    employee.IsClient = event.target.checked;
    updateEmployee(employee);
  };

  const handleIsSoeApprovalChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const employee = _.cloneDeep(props.employeeItem);
    employee.IsSOEApproval = event.target.checked;
    updateEmployee(employee);
  };

  const handleIsAdminChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const employee = _.cloneDeep(props.employeeItem);
    employee.IsAdmin = event.target.checked;

    updateEmployee(employee);
  };

  const updateEmployee = (employee: EmployeeInfo) => {
    props.updateEmployee(employee);
  };

  const e = props.employeeItem;
  let name = e.FirstName;
  if (props.ShowAdminsFields == "table-cell") name = e.LocalName;
  const isClient = e.IsClient !== null ? e.IsClient : false;
  const isSoeApproval = e.IsSOEApproval !== null ? e.IsSOEApproval : false;
  const isAdmin = e.IsAdmin !== null ? e.IsAdmin : false;
  const isManual = e.IsManualAdded !== null ? e.IsManualAdded : false;
  if (isManual == false) {
    if (_.filter(props.AllEmp, d => d.No_.toUpperCase() == e.No_.toUpperCase() && d.CompanyName.toUpperCase() == e.CompanyName.toUpperCase()).length == 0) {
      return null;
    }
  }

  return <tr>
    {/* <td><a href={"/Employee?employeeId=" + e.Id}>{e.LocalName}</a></td> */}
    <td>{name}</td>
    <td>{e.EMail}</td>
    <td>{e.No_.toUpperCase()}</td>
    <td>{e.CompanyDisplayName}</td>
    {/*{e.IsClient ? "Yes" : "No"}*/}
    <td className="center" style={{ display: props.ShowAdminsFields }}><input
      style={{ display: "inline-block" }} type="checkbox" checked={isClient}
      onChange={(e) => handleIsClientChecked(e)}/>
    </td>
    <td className="center" style={{ display: props.ShowAdminsFields }}><input
      style={{ display: "inline-block" }} type="checkbox"
      checked={isSoeApproval}
      onChange={(e) => handleIsSoeApprovalChecked(e)}/>
    </td>
    <td className="center" style={{ display: props.ShowAdminsFields }}><input
      style={{ display: "inline-block" }} type="checkbox" checked={isAdmin}
      onChange={(e) => handleIsAdminChecked(e)}/>
    </td>
    {
      <td><a style={{ display: isManual || isClient || isSoeApproval ? "inline-block" : "none" }}
             className={"btn halfOpacity"}><img onClick={props.openIndividualSettingsScreen} className={"halfOpacity"}
                                                src="/img/edit.svg"/></a>&nbsp;
      </td>}
  </tr>;
};

type EmployeeLine = ReturnType<typeof EmployeeLine>;
