import React from "react";
import type { CodedSalaryJournalRecord,FeeEntryType } from "../features/ExternalRostersApi";

import "./NavJournalRecordsTable.css";
import type { PayEntry } from "../types/NavisionPayslip";
import type { OfficerFlightDeckFeesPerson } from "../types/Api/Contract";
import { isSoePayEntry } from "../features/payrollUtils";
import { keys,toUpperCase } from "../utils/typing";
import type { COMPANYNAME, CREWCODE } from "../features/Api";
import type { CrewCode } from "@mhc/utils/types/nav";
import { isAirAtlanta } from "../features/roster/air_atlanta/FeeCalculatorUtils";

export type PersonBase = {
    navEntry: {
        No_: CrewCode,
    },
};

type CalculatedPerson = {
    person: PersonBase,
    journalRecords: CodedSalaryJournalRecord[],
};

type Props = {
    COMPANYNAME: COMPANYNAME,
    calculatedPeople: CalculatedPerson[],
    codeToNavInvoiceItems?: Record<CREWCODE, PayEntry[]> | null,
    codeToOfficerNotes?: Record<CREWCODE, OfficerFlightDeckFeesPerson> | null,
    makeLink?: (record: { crew_code: CrewCode }) => string,
};

function compareCalculatedItemsToPayroll(COMPANYNAME: COMPANYNAME, journalRecords: CodedSalaryJournalRecord[], payrollItems: PayEntry[]) {
    const typeToPayrollSum: Record<FeeEntryType, number> = {};
    for (const payrollItem of payrollItems) {
        const isPastMonth =  payrollItem.Bokunardagur &&  payrollItem["Timabil til (Dagpeningar)"] &&
            payrollItem["Timabil til (Dagpeningar)"].slice(0, 7) < payrollItem.Bokunardagur.slice(0, 7);
        if (payrollItem.Tegund === 2 ||
            isPastMonth && payrollItem.Texti !== "Over time FD" ||
            COMPANYNAME === "F2R - ETFEUR" && payrollItem.Texti === "Loyalty Bonus" || // ETF one-time thing
            isAirAtlanta(COMPANYNAME) && payrollItem.Faerslutegund === 110 || // deduct admin fee
            isAirAtlanta(COMPANYNAME) && payrollItem.Faerslutegund === 141 || // transfer from airborne
            isAirAtlanta(COMPANYNAME) && payrollItem.Faerslutegund === 167 || // Loan deduction AAI Course
            isAirAtlanta(COMPANYNAME) && payrollItem.Faerslutegund === 400 || // Fee correction
            isAirAtlanta(COMPANYNAME) && payrollItem.Faerslutegund === 1601 || // Business class compensation
            isAirAtlanta(COMPANYNAME) && payrollItem.Faerslutegund === 1698 || // Visa advance payment
            isAirAtlanta(COMPANYNAME) && payrollItem.Faerslutegund === 1699 || // advance payment
            isAirAtlanta(COMPANYNAME) && payrollItem.Faerslutegund === 4000 || // Bankcharge allowance
            isSoePayEntry(payrollItem.Faerslutegund)
        ) {
            continue;
        }
        typeToPayrollSum[payrollItem.Faerslutegund] = typeToPayrollSum[payrollItem.Faerslutegund] ?? 0;
        typeToPayrollSum[payrollItem.Faerslutegund] += +payrollItem.Einingar;
    }
    const typeToCalcSum: Record<FeeEntryType, number> = {};
    for (const leonItem of journalRecords) {
        if (leonItem.fee_entry_type === 9000) { // Fix Manually type
            continue;
        }
        typeToCalcSum[leonItem.fee_entry_type] = typeToCalcSum[leonItem.fee_entry_type] ?? 0;
        typeToCalcSum[leonItem.fee_entry_type] += +leonItem.units;
    }
    const entryTypes = new Set<FeeEntryType>([
        ...keys(typeToPayrollSum),
        ...keys(typeToCalcSum),
    ]);
    const mismatchTypes = new Set<number>();
    for (const entryType of entryTypes) {
        const payrollAmount = typeToPayrollSum[entryType] ?? 0;
        const calcAmount = typeToCalcSum[entryType] ?? 0;
        if (Math.abs(payrollAmount - calcAmount) >= 0.01) {
            mismatchTypes.add(+entryType);
        }
    }
    return mismatchTypes;
}

export function getOfficerNotesText(codeToOfficerNotes: Record<string, OfficerFlightDeckFeesPerson>, person: PersonBase) {
    const officerNotes = codeToOfficerNotes[person.navEntry.No_.trim().toUpperCase()];
    if (officerNotes) {
        const text = [
            officerNotes.comment,
            officerNotes.notes,
            officerNotes.payrollComments,
        ].filter(a => a?.trim()).join(" | ");
        if (text) {
            return officerNotes.rotationType + " " + text;
        }
    }
    return "";
}

export default class NavJournalRecordsTable extends React.Component<Props, {}> {
    render() {
        let i = 1;
        const discrepancyPeople = new Set<string>();
        let totalDiscrepancies = 0;
        const hasBases = this.props.calculatedPeople.some(cp => cp.journalRecords.some(jr => jr.base?.trim()));
        const hasCountries = this.props.calculatedPeople.some(cp => cp.journalRecords.some(jr => jr.country?.trim()));
        return <table className={"nav-journal-records-table" + (hasBases ? " has-bases" : "") + (hasCountries ? " has-countries" : "")}>
            <thead>
                <tr>
                    <th className="alphanumeric-column">#</th>
                    <th data-field-name="crew_code" className="alphanumeric-column">No.</th>
                    <th data-field-name="descripton">Description</th>
                    <th data-field-name="base" className="alphanumeric-column">Base</th>
                    <th data-field-name="country" className="alphanumeric-column">Country</th>
                    <th className="alphanumeric-column">Start</th>
                    <th className="alphanumeric-column">End</th>
                    <th className="alphanumeric-column">Units</th>
                    <th className="alphanumeric-column">Rate</th>
                    <th className="alphanumeric-column">Total</th>
                    {this.props.codeToNavInvoiceItems ? <th className="alphanumeric-column">Invoiced</th> : <th></th>}
                </tr>
            </thead>
            {this.props.calculatedPeople.map(cp => {
                const payrollItems = this.props.codeToNavInvoiceItems?.[toUpperCase(cp.person.navEntry.No_)] ?? [];
                const mismatchTypes = !this.props.codeToNavInvoiceItems ? new Set<number>() :
                    compareCalculatedItemsToPayroll(this.props.COMPANYNAME, cp.journalRecords, payrollItems);
                const journalRecords = cp.journalRecords.length > 0 ? cp.journalRecords : [{
                    fee_entry_type: 9000,
                    description: "No Fees from Calculation",
                    crew_code: cp.person.navEntry.No_,
                    units: 1,
                    payment_per_unit: 0,
                    base: null,
                    country: null,
                    service_start_time: null,
                    service_end_time: null,
                }];
                const trs = journalRecords.map((jr) => {
                    let expectedUnits = "";
                    if (mismatchTypes.has(jr.fee_entry_type)) {
                        discrepancyPeople.add(cp.person.navEntry.No_);
                        ++totalDiscrepancies;
                        mismatchTypes.delete(jr.fee_entry_type);
                        expectedUnits = payrollItems
                            .flatMap(pi => pi.Faerslutegund === jr.fee_entry_type ? [+pi.Einingar] : [])
                            .reduce((a, b) => a + b, 0)
                            .toString();
                    }
                    return <tr
                        key={JSON.stringify(jr)}
                        data-fee-entry-type={jr.fee_entry_type}
                        data-fee-entry-description={jr.description}
                    >
                        <td className="alphanumeric-column">{i++}</td>
                        <td data-field-name="crew_code" className="alphanumeric-column">
                            <a target="_blank" href={this.props.makeLink?.(jr)}>{jr.crew_code}</a>
                        </td>
                        <td data-field-name="descripton">{jr.description}</td>
                        <td className="alphanumeric-column" data-field-name="base">{jr.base}</td>
                        <td className="alphanumeric-column" data-field-name="country">{jr.country}</td>
                        <td className="alphanumeric-column">{jr.service_start_time}</td>
                        <td className="alphanumeric-column">{jr.service_end_time}</td>
                        <td className="alphanumeric-column">{(+jr.units).toFixed(2).replace(/\.0+$/, "")}</td>
                        <td className={"alphanumeric-column" + (+jr.payment_per_unit === 0 ? " zero-payment-per-unit" : "")}>{jr.payment_per_unit}</td>
                        <td className="alphanumeric-column">{(+jr.payment_per_unit * +jr.units).toFixed(2)}</td>
                        <td data-field-name="expectedUnits" className="alphanumeric-column">{expectedUnits}</td>
                    </tr>;
                });
                for (const mismatchType of mismatchTypes) {
                    discrepancyPeople.add(cp.person.navEntry.No_);
                    ++totalDiscrepancies;
                    const items = payrollItems.filter(pi => +pi.Faerslutegund === +mismatchType);
                    trs.push(<tr key={"mismatch_" + mismatchType} data-fee-entry-type={mismatchType}>
                        <td></td>
                        <td data-field-name="crew_code" className="alphanumeric-column">
                            <a target="_blank" href={this.props.makeLink?.({ crew_code: cp.person.navEntry.No_ })}>{cp.person.navEntry.No_}</a>
                        </td>
                        <td data-field-name="descripton">{items.map(pi => pi["Texti"] || pi["Texti a launasedli"])[0]}</td>
                        <td className="alphanumeric-column" data-field-name="base"></td>
                        <td className="alphanumeric-column" data-field-name="country"></td>
                        <td className="alphanumeric-column">{items.map(pi => pi["Timabil fra (Dagpeningar)"])[0]?.slice(0, 10)}</td>
                        <td className="alphanumeric-column">{items.map(pi => pi["Timabil til (Dagpeningar)"]).slice(-1)[0]?.slice(0, 10)}</td>
                        <td className="alphanumeric-column">0</td>
                        <td className="alphanumeric-column"></td>
                        <td className="alphanumeric-column">{items.map(pi => +pi["Upphaed"]).reduce((a, b) => a + b, 0).toFixed(2)}</td>
                        <td data-field-name="expectedUnits" className="alphanumeric-column">
                            {items.map(pi => +pi["Einingar"]).reduce((a, b) => a + b, 0)}
                        </td>
                    </tr>);
                }
                const notesText = getOfficerNotesText(this.props.codeToOfficerNotes ?? {}, cp.person);
                if (notesText) {
                    trs.unshift(<tr className="officer-notes-row" key="officer-notes-row">
                        <td></td>
                        <td data-field-name="crew_code" className="alphanumeric-column">
                            <a target="_blank"
                               href={this.props.makeLink?.({ crew_code: cp.person.navEntry.No_ })}>{cp.person.navEntry.No_}</a>
                        </td>
                        <td colSpan={9999}>{notesText}</td>
                    </tr>);
                }
                if (trs.length > 0) {
                    return <tbody key={cp.person.navEntry.No_} id={cp.person.navEntry.No_}>
                    {trs}
                    </tbody>;
                } else {
                    return null;
                }
            })}
            {this.props.codeToNavInvoiceItems && <tbody>
                <tr>
                    <td colSpan={9999} style={{ maxWidth: "600px" }}>{discrepancyPeople.size} people with discrepancies ({totalDiscrepancies} entries): {[...discrepancyPeople].map(code => <a style={{ display: "inline-block", marginRight: "6px" }} key={code} href={"#" + code}>{code}</a>)}</td>
                </tr>
            </tbody>}
        </table>;
    }
}
