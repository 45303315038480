
import * as React from "react";
import api from "../features/Api";
import type { CompanyName } from "@mhc/utils/types/nav";
import { useEffect,useState } from "react";
import { getAllNavActivities } from "../features/StaticData";
import type WiseCodeValue from "../types/WiseCodeValue";
import type { FeeEntryTypeRecord } from "../features/ExternalRostersApi";

export default function (props: {
    SelectedCompany: CompanyName,
    value: string[],
    onChange: (value: string[]) => void,
}) {
    const [activityOptions, setActivityOptions] = useState<WiseCodeValue[]>();
    const [feeEntryTypes, setFeeEntryTypes] = useState<FeeEntryTypeRecord[]>();

    useEffect(() => {
        getAllNavActivities().then(setActivityOptions);
        api.Contract.GetFeeEntryTypes({ company: props.SelectedCompany }).then(setFeeEntryTypes);
    }, []);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>, code: string) {
        const newState = new Set(props.value);
        if (e.target.checked) {
            newState.add(code);
        } else {
            newState.delete(code);
        }
        props.onChange([...newState]);
    }

    function generateActivityList() {
        if (!activityOptions) {
            return <div className="status-loading-animated-ellipsis">Loading Activity Options</div>;
        }
        const items = [];
        for (const activityOption of activityOptions) {
            if (activityOption.Code === "") {
                continue;
            }
            const feeEntryTypeRecord = feeEntryTypes?.find(fet => fet.portal_activity_type === activityOption.Code);
            items.push(<div
                key={activityOption.Code}
                className="activity-checkbox-option"
                style={{ fontSize: "20px", minWidth: "250px" }}
            >
                <input
                    type="checkbox"
                    checked={props.value.includes(activityOption.Code)}
                    onChange={(e) => handleChange(e, activityOption.Code)}
                    style={{ display: "inline-block" }}
                    className="form-control"
                />
                <span className="activity-name">{activityOption.Name}</span>
                {feeEntryTypeRecord && <span className="fee-entry-type-record-details">
                    | {feeEntryTypeRecord.fee_entry_type}<span> </span>
                    | {feeEntryTypeRecord.payment_per_unit?.toFixed(2)}
                </span>}
            </div>);
        }
        return items;
    }

    return <div>
        <h3>Select Activities</h3>
        <hr/>
        <div className=" col-md-12">
          <div className="form-group">
            {generateActivityList()}
          </div>
        </div>
    </div>;
}