import type {
    ManualInvoiceDetails as ManualInvoiceDetailsType,
    ManualInvoiceDetails,
    ManualInvoiceDetails as ManualInvoiceDetailsModel
} from "../types/Api/Invoice";
import type { Content, TableCell, TDocumentDefinitions } from "pdfmake/interfaces";
import type InvoiceComment from "../types/Api/InvoiceComment";
import type { Props } from "../components/ManualInvoiceDetails";
import type { ContentColumns, ContentText, ContentUnorderedList, UnorderedListElement } from "pdfmake/interfaces";

function createPdfEntry(invoiceEntry: ManualInvoiceDetailsModel, fields: string[]): TableCell[] {
    const tableCells: TableCell[] = [];
    for (let i = 0; i < fields.length; ++i) {
        tableCells.push("");
    }
    if (fields.includes("Date")) {
        tableCells[fields.indexOf("Date")] = invoiceEntry.ActivityDateStr;
    }
    if (fields.includes("Activity")) {
        tableCells[fields.indexOf("Activity")] = invoiceEntry.Activity;
    }
    if (fields.includes("TimeIn")) {
        tableCells[fields.indexOf("TimeIn")] = invoiceEntry.TimeIn1;
    }
    if (fields.includes("TimeOut")) {
        tableCells[fields.indexOf("TimeOut")] = invoiceEntry.TimeOut1;
    }
    if (fields.includes("DailyHours")) {
        tableCells[fields.indexOf("DailyHours")] = invoiceEntry.DailyHours;
    }
    if (fields.includes("PaymentONOFF")) {
        tableCells[fields.indexOf("PaymentONOFF")] = invoiceEntry.PaymentOnOff ? "X" : "";
    }
    if (fields.includes("Base")) {
        tableCells[fields.indexOf("Base")] = invoiceEntry.Base;
    }
    if (fields.includes("Project")) {
        tableCells[fields.indexOf("Project")] = invoiceEntry.Project;
    }
    if (fields.includes("FlightNumber")) {
        tableCells[fields.indexOf("FlightNumber")] = invoiceEntry.FlightNumber;
    }
    if (fields.includes("AircraftReg")) {
        tableCells[fields.indexOf("AircraftReg")] = invoiceEntry.AircraftReg;
    }
    if (fields.includes("BlockHours")) {
        tableCells[fields.indexOf("BlockHours")] = invoiceEntry.BlockHours;
    }
    if (fields.includes("PositioningHours")) {
        tableCells[fields.indexOf("PositioningHours")] = invoiceEntry.PositioningHours;
    }
    if (fields.includes("Flying")) {
        tableCells[fields.indexOf("Flying")] = invoiceEntry.Flying ? "X" : "";
    }
    if (fields.includes("SoldOffDay")) {
        tableCells[fields.indexOf("SoldOffDay")] = invoiceEntry.SoldOffDay ? "X" : "";
    }
    if (fields.includes("TimeIn2")) {
        tableCells[fields.indexOf("TimeIn2")] = invoiceEntry.TimeIn2;
    }
    if (fields.includes("TimeOut2")) {
        tableCells[fields.indexOf("TimeOut2")] = invoiceEntry.TimeOut2;
    }
    if (fields.includes("PerDiem")) {
        tableCells[fields.indexOf("PerDiem")] = invoiceEntry.PerDiem;
    }
    if (fields.includes("AddonFee")) {
        tableCells[fields.indexOf("AddonFee")] = invoiceEntry.AddonFee;
    }
    if (fields.includes("OverTime")) {
        tableCells[fields.indexOf("OverTime")] = invoiceEntry.OverTime;
    }
    return tableCells;
}

function getTotalHours(rosterData: ManualInvoiceDetails[]) {
    let totalHours = 0;
    rosterData.map((invoiceDetail) => {
        totalHours = totalHours + convertToDecimal(invoiceDetail.DailyHours);
    });
    return totalHours;
}

function getStatusName(status: string) {
    let statusName = "";
    switch (status) {
        case "0":
            statusName = "NotSubmitted";
            break;
        case "1":
            statusName = "PendingApproval";
            break;
        case "2":
            statusName = "Expired";
            break;
        case "3":
            statusName = "Rejected";
            break;
        case "4":
            statusName = "Approved";
            break;
        default:
            statusName = "";
            break;
    }
    return statusName;
}

function getComments(history: InvoiceComment[] | null) {
    const comments: UnorderedListElement[][] = [];
    if (history != null) {
        history.map((comment: InvoiceComment) => {
            const commentParts: UnorderedListElement[] = [];
            commentParts[0] = comment.Text;
            commentParts[1] = comment.Date;
            commentParts[2] = getStatusName(comment.Status);
            commentParts[4] = comment.Commenter;
            comments.push(commentParts);
        });
    }
    return comments;
}

function convertToDecimal(value: string) {
    if ((value != null) && (value !== "")) {
        const converter = 100 / 60;
        const hour = parseInt(value.split(":")[0]).toString();
        const minute = (parseFloat(value.split(":")[1]) * converter).toString().replace(",", "");
        const combinedTime = hour + "." + minute;
        return parseFloat(combinedTime);
    }
    return 0;
}

function getAddonFeeSummary(rosterData: ManualInvoiceDetailsType[]) {
    const addonFees: { addonFee: string, hours: number }[] = [];
    if (rosterData != null) {
        rosterData.map((invoiceDetail: ManualInvoiceDetails) => {
            if (invoiceDetail.AddonFee != null) {
                let found = false;
                addonFees.map((addonFee) => {
                        if (addonFee.addonFee === invoiceDetail.AddonFee) {
                            addonFee.hours = addonFee.hours + convertToDecimal(invoiceDetail.DailyHours);
                            found = true;
                        }
                    }
                );
                if (!found) {
                    const addonFeeWithTime = {
                        addonFee: invoiceDetail.AddonFee,
                        hours: convertToDecimal(invoiceDetail.DailyHours)
                    };
                    addonFees.push(addonFeeWithTime);
                }
            }
        });
    }
    addonFees.map((addonFee) => {
        if (addonFee.addonFee === "") {
            addonFee.addonFee = "No Addon Fee";
        }
    });
    return addonFees;
}

function findMostRecentApprove(history: InvoiceComment[] | null) {
    let mostRecentApprove: InvoiceComment | null = null;
    if (history != null) {
        for (const comment of history) {
            if (comment.Status === "4") {
                if (mostRecentApprove == null || comment.Date > mostRecentApprove.Date) {
                    mostRecentApprove = comment;
                }
            }
        }
    }
    return mostRecentApprove;
}

/** I think history is always null */
function createDocument(
    props: Props,
    rosterData: ManualInvoiceDetailsType[],
    body: TableCell[][],
    history: InvoiceComment[] | null
): TDocumentDefinitions {
    const name = props.profile.LocalName ? props.profile.LocalName : props.profile.FullName;
    const comments = getComments(history);
    const addonFees = getAddonFeeSummary(rosterData);
    const mostRecentApprove = findMostRecentApprove(history);

    const addonFeesSubheader: Content | null = addonFees.length > 0 ? {
        text: "Addon fees:",
        style: "subheader",
        margin: [0, 40, 0, 20],
        pageBreak: "before"
    } : null;
    const totals: ContentColumns = {
        columns: [
            { text: getTotalHours(rosterData) + " hrs" },
            { text: rosterData.length + " days" },
        ],
        margin: [0, 0, 0, 20]
    };
    const commentsSubheader: ContentText | null = comments.length > 0 ? {
        text: "Comments:", style: "subheader", margin: [0, 40, 0, 20],
    } : null;
    const commentsList: ContentUnorderedList[] = comments.map((comment) => {
        return {
            ul: comment,
            type: "none",
            style: "unorderedList",
        };
    });
    const approvedBySubheader: Content | null = mostRecentApprove ? {
        text: "Approved by Client on  " + mostRecentApprove.Date,
        margin: [0, 0, 0, 20]
    } : null;
    const content: Content[] = [
        { text: name, style: "header" },
        /** a typo? could it be that the intent was props.profile.profile.Address ? */
        { text: props.profile.Address, margin: [0, 0, 0, 20] },
        {
            table:
                {
                    headerRows: 1,
                    body: body
                },
            layout: "headerLineOnly"
        },
        ...!addonFeesSubheader ? [] : [addonFeesSubheader],
        addonFees.map((addonFee): ContentColumns => {
            return {
                columns: [
                    { text: addonFee.addonFee, margin: [0, 0, 0, 20] },
                    { text: addonFee.hours, margin: [0, 0, 0, 20] },
                ]
            };
        }),
        totals,
        ...!commentsSubheader ? [] : [commentsSubheader],
        commentsList,
        ...!approvedBySubheader ? [] : [approvedBySubheader],
    ];
    return {
        content: content,
        styles: {
            header: {
                fontSize: 18,
                bold: true
            },
            subheader: {
                fontSize: 15,
                bold: true
            },
            quote: {
                italics: true
            },
            small: {
                fontSize: 8
            },
            unorderedList: {
                fontSize: 15,
                margin: [0, 0, 0, 20]
            }
        }
    };
}

function getFields(props: Props) {
    let fields: string[] = [];
    if (props.profile.Fields != null)
        fields = props.profile.Fields.fields.split("|");
    if (fields.includes("Activity")) {
        fields.unshift("Date");
    }

    for (let i = 0; i < fields.length; i++) {
        if (fields[i] === "AdditionalInfomation" || fields[i] === "AddDay" || fields[i] === "Over12Hours") {
            fields.splice(i, 1);
        }
    }
    return fields;
}

export function prepareManualInvoiceForPdfExport(
    props: Props,
    rosterData: ManualInvoiceDetailsType[],
    history: InvoiceComment[] | null
): TDocumentDefinitions {
    const fields = getFields(props);
    const headers = fields.map((field) => {
        return { text: field, bold: true };
    });
    const body: TableCell[][] = [
        headers
    ];
    rosterData.map((invoiceDetail) => {
        const row = createPdfEntry(invoiceDetail, fields);
        body.push(row);
    });
    return createDocument(props, rosterData, body, history);
}