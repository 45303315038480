import React from "react";

export type ActivityIconProps = {
    activityType: "Flight" | "Deadhead" | "Ground" | "BLANK",
    category: null | "ON_DUTY" | "OFF_DUTY_UNPAID" | "OFF_DUTY_PAID" | "TRAINING" | "POSITIONING" | "FACT" | "ILLNESS",
};

export default function ActivityIcon(props: ActivityIconProps) {
    if (props.activityType === "Flight") {
        return <img src="/img/roster-activity-logos/flight-duty.svg" title="Flight Duty" alt="flight duty" />;
    } else if (props.activityType === "Deadhead") {
        return <img src="/img/roster-activity-logos/pick-up.svg" title="Positioning" alt="positioning" />;
    } else if (props.activityType === "BLANK") {
        return <img src="/img/roster-activity-logos/off-day.svg" title="Off Day" alt="off day" />;
    } else {
        switch (props.category) {
            case "OFF_DUTY_UNPAID":
                return <img src="/img/roster-activity-logos/off-day.svg" title="Off Day" alt="off day" />;
            case "OFF_DUTY_PAID":
                return <img src="/img/roster-activity-logos/off-day.svg" title="Off Day" alt="off day" />;
            case "POSITIONING":
                return <img src="/img/roster-activity-logos/pick-up.svg" title="Positioning" alt="positioning" />;
            case "TRAINING":
                return <img src="/img/roster-activity-logos/training-duty.svg" title="Training" alt="training duty" />;
            case "ON_DUTY":
                return <img src="/img/roster-activity-logos/on-duty.svg" title="Ground Duty" alt="on duty" />;
            case "FACT":
                return <img src="/img/roster-activity-logos/fact.svg" title="Fact" alt="fact" />;
            case "ILLNESS":
                return <img src="/img/roster-activity-logos/illness.svg" title="Fact" alt="fact" />;
            default:
                return <div className="blank-square"></div>;
        }
    }
}