import * as React from "react";
import type InvoiceComment from "../types/Api/InvoiceComment";
import { GetInvoiceStatusClass } from "../utils/Helper";

class StatusComment extends React.Component<{
    comment: InvoiceComment,
}> {
    render() {
        const comment = this.props.comment;
        return (<div className={"rejectBox" + (!comment.Text?.trim() ? " status-update-without-comment" : "")}>
            <div>
                <span className="Title">
                    <strong>{comment.Commenter}</strong>
                </span>
            </div>
            <div>
                <span>
                    <span className={GetInvoiceStatusClass(+this.props.comment.Status)}>
                        {this.props.comment.StatusName}
                    </span> {comment.Date} {comment.Time}
                </span>
            </div>
            <div className="borderTop">
                {comment.Text?.trim() ? <span>{comment.Text}</span> : <span className="no-comment-message">(No Comment)</span>}
            </div>
        </div>);
    }
}

export default StatusComment;