
/**
 * intended to be used in Monadic functions to make them return
 * either a result or an error similar to how you would do in Rust
 * @see https://doc.rust-lang.org/std/result/enum.Result.html#variant.Err
 *
 * this is an alternative to returning a `null` for cases when you want
 * to provide an explanation of why the result could not be retrieved
 */
export default class Err extends Error {
    public constructor(message: string) {
        super(message);
    }
}