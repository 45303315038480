import * as React from "react";
import { useRef, useState } from "react";
import { IsolatedReact } from "../../utils/IsolatedReact";
import type { BoxedPayslipAndAllCompany, PayslipAndTransfersAndAllCompany } from "../../types/NavisionPayslip";
import type { PayEntriesMapping } from "../../utils/feeStatementInvoiceUtils";
import { getPayEntries } from "../../utils/feeStatementInvoiceUtils";
import { getDownloadFeeStatementOverviewLink } from "../../utils/feeStatementInvoiceUtils";
import type { Workbook } from "exceljs";
import ActionsQueue from "../../utils/ActionsQueue";
import { isSoePayEntry, PayEntryKind } from "../../features/payrollUtils";

type Props = {
    WHEN_EXCELJS: Promise<{ Workbook: typeof Workbook }>,
    departmentRecords: BoxedPayslipAndAllCompany[] | undefined,
    payEntriesMapping: PayEntriesMapping,
    fileNameSuffix: string,
};

function getFeeEntriesDownloadLink(
    WHEN_EXCELJS: Promise<{ Workbook: typeof Workbook }>,
    recordsMaybe: BoxedPayslipAndAllCompany[] | undefined,
    payEntriesMapping: PayEntriesMapping,
    includeClaims: boolean,
    includeSoe: boolean
): undefined | Promise<string> {
    if (!recordsMaybe) {
        return undefined;
    }
    const fullRecords: PayslipAndTransfersAndAllCompany[] = recordsMaybe.map(rec => ({
        ...rec,
        transfers: (getPayEntries(rec, payEntriesMapping) ?? []).filter(tra => {
            if (!includeClaims && tra.Tegund === PayEntryKind.CLAIM) {
                return false;
            }
            if (!includeSoe && isSoePayEntry(tra.Faerslutegund)) {
                return false;
            }
            return true;
        }),
    }));
    return WHEN_EXCELJS.then(ExcelJS => {
        return getDownloadFeeStatementOverviewLink(ExcelJS, fullRecords);
    });
}

export default function DownloadFeesTableButtonPanel(props: Props) {
    const [feeEntriesDownloadLink, setFeeEntriesDownloadLink] = useState<string>();
    const [includeClaims, setIncludeClaims] = useState(false);
    const [includeSoe, setIncludeSoe] = useState(false);
    const updatesQueue = useRef(ActionsQueue()).current;

    const { WHEN_EXCELJS, departmentRecords, payEntriesMapping } = props;
    IsolatedReact.useEffect((...args) => {
        updatesQueue.enqueue(() => {
            setFeeEntriesDownloadLink(undefined);
            return getFeeEntriesDownloadLink(...args)
                ?.then(setFeeEntriesDownloadLink);
        });
    }, [WHEN_EXCELJS, departmentRecords, payEntriesMapping, includeClaims, includeSoe]);

    return <div className="download-fees-table-button-panel">
        <label>
            <input type="checkbox" checked={includeClaims} onChange={e => setIncludeClaims(e.target.checked)}/>
            <span>Include Claims</span>
        </label>
        <label>
            <input type="checkbox" checked={includeSoe} onChange={e => setIncludeSoe(e.target.checked)}/>
            <span>Include SoE</span>
        </label>
        <a
            href={feeEntriesDownloadLink ?? undefined}
            className="client-fee-statement-download-button"
            download={`Fee Statement Confirmation ${props.fileNameSuffix}`}
        >
            <span>Download</span>
            <img src="/img/download.svg" alt="download icon"/>
        </a>
    </div>;
}