

import ClipLoader from "react-spinners/ClipLoader";
import ErrorPage from "./ErrorPage";
import * as React from "react";
import * as _ from "lodash";
import Pagination from "react-js-pagination";
import type { ClaimAction } from "../types/Claim";
import { neverNull,typed } from "@mhc/utils/src/typing";
import { toUpperCase } from "../utils/typing";
import * as Papa from "papaparse";
import type { EmployeeInfoBase } from "../types/EmployeeInfo";
import { getMonthFullName } from "@mhc/utils/src/dates";
import { type AllCompanies, getAllCompanies } from "../features/StaticData";
import api from "../features/Api";
import type { CompanyName } from "@mhc/utils/types/nav";
import { getLatestComment, getSoeClaimStatusClass, normalizeSoeStatusFor3rdParty } from "../features/soeUtils";
import ClaimActionCard from "../components/ClaimActionCard";

type Props = {
};

function getInitialState() {
    const soeApproverDashboardStatusFilter = sessionStorage.getItem("soeApproverDashboardStatusFilter");
    const sessionStatus = !soeApproverDashboardStatusFilter ? 0 : parseInt(soeApproverDashboardStatusFilter);
    return {
        nameDeptCrewcode: sessionStorage.getItem("soeApproverDashboardNameDeptFilter") ?? "",
        claimStatus: sessionStatus,
        sortColumn: typed<keyof ClaimAction>("Date"),
        sortAscending: false,
        claimCategory: sessionStorage.getItem("soeApproverDashboardCategoryFilter") ?? "",
        client: sessionStorage.getItem("soeApproverDashboardClientFilter") ?? "",
        claimMonth: sessionStorage.getItem("soeApproverDashboardMonthFilter") ?? "99",
        claimCompany: 1,
        currentPage: 1,
        claimsPerPage: 20,
        employee: null,
        allCompanies: typed<null | AllCompanies>(null),
        approverClaimActions: typed<null | ClaimAction[]>(null),
    };
}

type State = ReturnType<typeof getInitialState>;

function makeCsvRecord(claim: ClaimAction) {
    return {
        ApproverName: claim.ApproverName,
        CompanyName: claim.CompanyName,
        EmployeeName: claim.EmployeeName,
        EmployeeCode: claim.EmployeeCode,
        EmployeeEmail: claim.EmployeeEmail,
        ReceiptDate: claim.Date,
        TravelDate: claim.TravelDate,
        DateModified: claim.DateModified,
        DateSubmitted: claim.DateSubmitted,
        Currency: claim.Currency,
        Amount: claim.Amount,
        Category: claim.Category,
        StatusStr: claim.StatusStr,
        Comments: claim.Comments,
        DepartmentCode: claim.DepartmentCode,
        SimilarClaims: claim.SimilarClaims.map(c => c.Id).join(","),
    };
}

export default class ClaimsUsers extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        require("../components/ClaimActionCard.css");
        require("./ClaimsUsers.css");
        this.state = getInitialState();
    };

    get currentUser(): EmployeeInfoBase {
        return window.EMPLOYEE_INFO ?? neverNull();
    }

    componentDidMount() {
        api.Claim.GetClaimsForApproverDashboard().then(approverClaimActions => {
            if (!this.currentUser.IsAdmin) {
                approverClaimActions.forEach(normalizeSoeStatusFor3rdParty);
            }
            this.setState({ approverClaimActions });
        });
        getAllCompanies().then(allCompanies => this.setState({ allCompanies }));
    };

    _getClaimStatuses() {
        if (!this.state.approverClaimActions) {
                return <span className="status-loading-animated-ellipsis">Loading Records</span>;
        }
        const self = this;
        const statuses = _.uniqBy(self.state.approverClaimActions, "Status");
        const data = _.sortBy(statuses, "StatusStr").map(function (status) {
            return <option key={status.Status} value={status.Status}>{status.StatusStr}</option>;
        });

        const option = <option key={99} value={99}>All</option>;
        data.push(option);
        return data;
    };

    _getClaimCategories() {
        const self = this;
        const categories = _.uniqBy(self.state.approverClaimActions, "Category");
        const data = _.map(_.sortBy(categories, "Category"), function (category) {
            return <option key={category.Category} value={category.Category}>{category.Category}</option>;
        });

        const option = <option key="allCategory" value="">All</option>;
        data.push(option);
        return data;
    };

    _getClients() {
        if (!this.state.approverClaimActions) {
            return <span className="status-loading-animated-ellipsis">Loading Records</span>;
        }
        const clientNames = new Set(this.state.approverClaimActions.map(aca => aca.CompanyName));
        const optionRecords = [...clientNames].map(value => {
            const label = this._getCompanyDisplayName(value);
            return { value, label };
        });
        const data = _.sortBy(optionRecords, "label").map((clientRecord) => {
            return <option key={clientRecord.value} value={clientRecord.value}>{clientRecord.label}</option>;
        });

        const option = <option key="allClients" value="">All</option>;
        data.push(option);
        return data;
    };

    _getClaimsPerPage() {
        const data = [];
        for (let i = 10; i <= 100; i += 10) {
            data.push(<option key={i} value={i}>{i}</option>);
        }
        return data;
    };

    _filterByClaimStatus(e: React.ChangeEvent<HTMLSelectElement>) {
        sessionStorage.setItem("soeApproverDashboardStatusFilter", e.target.value);
        this.setState({ claimStatus: parseInt(e.target.value), currentPage: 1 });
    };

    _filterByClaimCategory(e: React.ChangeEvent<HTMLSelectElement>) {
        sessionStorage.setItem("soeApproverDashboardCategoryFilter", e.target.value);
        this.setState({ claimCategory: e.target.value, currentPage: 1 });
    };

    _filterByNameCrewDeptcode(e: React.ChangeEvent<HTMLInputElement>) {
        sessionStorage.setItem("soeApproverDashboardNameDeptFilter", e.target.value);
        this.setState({ nameDeptCrewcode: e.target.value });
    };

    _filterByMonth(e: React.ChangeEvent<HTMLSelectElement>) {
        const monthFilter = e.target.value ? e.target.value :  "99";
        sessionStorage.setItem("soeApproverDashboardMonthFilter", monthFilter);
        this.setState({ claimMonth: e.target.value, currentPage: 1 });
    };

    _filterByClient(e: React.ChangeEvent<HTMLSelectElement>) {
        sessionStorage.setItem("soeApproverDashboardClientFilter", e.target.value);
        this.setState({ client: e.target.value, currentPage: 1 });
    };

    _changeClaimsPerPage(e: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ claimsPerPage: parseInt(e.target.value) });
    };

    _generateFilters() {
        return (
            <div className="filterContainer">
                {this.currentUser.IsAdmin || this.currentUser.IsSOEApproval ?
                        <div>
                            {this._generateFilterByDeptCode()}
                        </div> : null}
                <div>
                    <div>Category</div>
                    <select className="widerSelect" onChange={(e) => this._filterByClaimCategory(e)}
                                    value={this.state.claimCategory}>
                        {this._getClaimCategories()}
                    </select>
                </div>
                {this.isMultiCompany ?
                        <div>
                            <div>Client</div>
                            <select className="widerSelect" value={this.state.client}
                                            onChange={(e) => this._filterByClient(e)}>
                                {this._getClients()}
                            </select>
                        </div> : null}
                <div>
                    <div>Month</div>
                    <select className="widerSelect" value={this.state.claimMonth}
                                    onChange={(e) => this._filterByMonth(e)}>
                        {this._getSoeLast6Months()}
                    </select>
                </div>
                <div>
                    <div>Status</div>
                    <select className="widerSelect" onChange={(e) => this._filterByClaimStatus(e)} value={this.state.claimStatus}>
                        {this._getClaimStatuses()}
                    </select>
                </div>
                <div>
                    <button onClick={() => this._downloadCsv()}>Download CSV</button>
                </div>
            </div>
        );
    };

    private _downloadCsv() {
        if (!this.filteredRecords) {
            window.toastr.error("Please, wait. Data is still loading...");
            return;
        }
        const records = this.filteredRecords;
        if (records.length === 0) {
            window.toastr.error("No records");
            return;
        }
        const csv = Papa.unparse(records.map(makeCsvRecord));
        const blob = new Blob([csv], { type: "text/csv" });
        const dataUrl = URL.createObjectURL(blob);
        window.open(dataUrl, "_blank");
    }

    _getSoeLast6Months() {
        const self = this;
        const arrLast6Months = [];
        //Get the current year.
        const currentYear = new Date().getFullYear(); //E.g. 2021
        //Get current month
        let currentMonth = (new Date().getMonth()); //January has the value 0.

        //Do the past 6 months extend into the last year?
        let iDiff = currentMonth - 11;
        const iYears = iDiff < 0 ? 1 : 0;

        //The years
        for (let iYear = currentYear; iYear >= currentYear - iYears; iYear--) {
            //Get months for each year.
            for (let iMonth = currentMonth; iMonth > iDiff; iMonth--) {
                //Value
                let sMonthKey: number | string = iMonth + 1;
                sMonthKey = sMonthKey.toString().length == 1 ? "0" + sMonthKey.toString() : sMonthKey.toString();
                const sKey = iYear.toString() + sMonthKey.toString();

                const sDisplayText = iYear.toString() + " - " + self._getMonthName(iMonth);
                arrLast6Months.push(<option key={sKey} value={sKey}>{sDisplayText}</option>);

                //End first year.
                if (iMonth == 0) {
                    //For the second year. Start in December.
                    currentMonth = 11;
                    iDiff = iDiff + 11;
                    break;
                }
            }
        }
        const option = <option key="allMonths" value="99">All</option>;
        arrLast6Months.push(option);
        return arrLast6Months;
    };

    _getMonthName(month: number) {
        return getMonthFullName(month + 1);
    };

    _getCompanyDisplayName(companyName: CompanyName) {
        const navCompany = this.state.allCompanies?.[toUpperCase(companyName)];
        return navCompany?.navEntry?.DisplayName ?? companyName;
    };

    _generateFilterByDeptCode() {
        return (
            <div className="search">
                <div>Contractor / Code / Dept Code.</div>
                <input type="text" value={this.state.nameDeptCrewcode} onChange={(e) => this._filterByNameCrewDeptcode(e)}/>
            </div>
        );
    };

    _generateClaimsPerPage(total: number) {
        return (
            <div className="row pagination">
                <select onChange={(e) => this._changeClaimsPerPage(e)} value={this.state.claimsPerPage}>
                    {this._getClaimsPerPage()}
                </select>
                <span> of {total}</span>
            </div>
        );
    };

    handlePageChange(pageNumber: number) {
        this.setState({ currentPage: pageNumber });
    };

    sortBy(e: React.MouseEvent<HTMLTableCellElement>) {
        const sortByValue = e.currentTarget.attributes["data-id"].value as keyof ClaimAction;
        if (this.state.sortColumn === sortByValue) {
            this.setState({ sortAscending: !this.state.sortAscending });
        } else {
            this.setState({ sortAscending: true });
        }
        this.setState({ sortColumn: sortByValue });

        // let sortByDirection = this.state.sortAscending ? 'asc' : 'desc';
        // let tempClaimActions = _.orderBy(this.state.claimActions, [sortByValue], [sortByDirection]);
        // this.setState({claimActions: tempClaimActions});
    };

    getSortClasses(column: keyof ClaimAction) {
        if (this.state.sortColumn !== column) {
            return "";
        }
        return this.state.sortAscending ? "ascending" : "descending";
    };

    hasApproverPrivileges() {
        const cleanIsAdmin = this.currentUser.IsAdmin;
        const cleanIsSoeApproval = this.currentUser.IsSOEApproval;
        return cleanIsAdmin || cleanIsSoeApproval;
    };

    filterByMonth(filteredData: ClaimAction[]) {
        filteredData = filteredData.filter(item => item.DateSubmittedStr.split(".")[1] === this.state.claimMonth.substring(4, 6));
        filteredData = filteredData.filter(item => item.DateSubmittedStr.split(".")[2] === this.state.claimMonth.substring(0, 4));
        return filteredData;
    };

    private filterRecords(approverClaimActions: ClaimAction[]): ClaimAction[] {
        let filteredData: ClaimAction[] = _.orderBy(
            approverClaimActions,
            this.state.sortColumn,
            this.state.sortAscending ? "asc" : "desc"
        );
        if (this.state.claimStatus !== 99)
            filteredData = filteredData.filter(ca => ca.Status === this.state.claimStatus);

        if (this.state.claimCategory !== "")
            filteredData = _.filter(filteredData, { "Category": this.state.claimCategory });
        if (this.state.claimMonth !== "99")
            filteredData = this.filterByMonth(filteredData);
        filteredData = filteredData.filter(d => {
            return d.EmployeeName.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase())
                || d.EmployeeCode.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase())
                || d.CompanyName.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase())
                || (d.DepartmentCode != null && d.DepartmentCode.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase()));
        });
        if (this.state.client !== "") {
            filteredData = filteredData.filter(ca => ca.CompanyName === this.state.client);
        }
        if (filteredData) {
            filteredData = filteredData.map(record => {
                return {
                    ...record,
                    // "Estere Admin:" -> "", removing empty comments by approvers
                    Comments: !record.Comments ? record.Comments : record.Comments.replace(/^[^:]+:\s*\n/, ""),
                };
            });
        }
        return filteredData;
    }

    get filteredRecords(): null | ClaimAction[] {
        return !this.state.approverClaimActions ? null : this.filterRecords(this.state.approverClaimActions);
    }

    private get isMultiCompany() {
        return this.currentUser.IsAdmin
            || this.currentUser.ApprovalCompaniesList.length > 1;
    }

    render() {
        if (!this.state.approverClaimActions) {
            return <ClipLoader loading={true} />;
        }
        const filteredData = this.filterRecords(this.state.approverClaimActions);
        const indexOfLastClaim = this.state.currentPage * this.state.claimsPerPage;
        const indexOfFirstClaim = indexOfLastClaim - this.state.claimsPerPage;
        const currentClaims = filteredData.slice(indexOfFirstClaim, indexOfLastClaim);

        const claimActions = currentClaims.map((claimAction) => {
            return (
                <ClaimActionLine
                    key={claimAction.ClaimId}
                    claimAction={claimAction}
                    companyDisplayName={this._getCompanyDisplayName(claimAction.CompanyName)}
                    isAdmin={this.currentUser.IsAdmin}
                />
            );
        });

        const pageNumbers = [];

        if (filteredData) {
            for (let i = 1; i <= Math.ceil(filteredData.length / this.state.claimsPerPage); i++) {
                pageNumbers.push(i);
            }
        }
        if (!this.hasApproverPrivileges()) {
            return <ErrorPage/>;
        }
        if (claimActions === null) {
            return (
                <div className="invoice-wrapper">
                    <div className="invoice-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h1>All SoE</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <h1>Loading data...
                                    <div className="lds-ring">
                                        <div/>
                                        <div/>
                                        <div/>
                                        <div/>
                                    </div>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (claimActions.length == 0) {
            return (
                <div className="invoice-wrapper">
                    <div className="invoice-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-3 header">
                                    <h1>All SoE</h1>
                                </div>
                                <div className="col-xs-9 filters">
                                    {this._generateFilters()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 claims-spinner-container">
                                <h1>No SoE data found.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="invoice-wrapper">
                    <div className="invoice-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-3 header">
                                    <h1>All SoE</h1>
                                </div>
                                <div className="col-xs-9 filters">
                                    {this._generateFilters()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="soe-container container">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="soe-table table-responsive">
                                    <table className={"soe-actions-list invoices table table-striped table-condensed" + (this.isMultiCompany ? " multi-company" : "")}>
                                        <thead>
                                            <tr>
                                                <th className="noSort"/>
                                                <th className={this.getSortClasses("EmployeeName")} data-id={"EmployeeName"}
                                                    onClick={(e) => this.sortBy(e)}>Contractor
                                                </th>
                                                <th className={this.getSortClasses("EmployeeCode") + " codeColumn"} data-id={"EmployeeCode"}
                                                    onClick={(e) => this.sortBy(e)}>Code
                                                </th>
                                                <th data-field-name="CompanyName" className={this.getSortClasses("CompanyName")} data-id={"CompanyName"}
                                                    onClick={(e) => this.sortBy(e)}>Company
                                                </th>
                                                <th className={this.getSortClasses("DepartmentCode") + " deptCodeColumn"}
                                                    data-id={"DepartmentCode"}
                                                    onClick={(e) => this.sortBy(e)}>Dept. code
                                                </th>
                                                <th className={this.getSortClasses("Currency") + " currencyColumn"} data-id={"Currency"}
                                                    onClick={(e) => this.sortBy(e)}>Currency
                                                </th>
                                                <th className={this.getSortClasses("Amount") + " amountColumn"} data-id={"Amount"}
                                                    onClick={(e) => this.sortBy(e)}>Amount
                                                </th>
                                                <th className={this.getSortClasses("Category")} data-id={"Category"}
                                                    onClick={(e) => this.sortBy(e)}>Category
                                                </th>
                                                <th className={this.getSortClasses("Status")} data-id={"Status"}
                                                    onClick={(e) => this.sortBy(e)}>Status
                                                </th>
                                                <th className={this.getSortClasses("Comments")} data-id={"Comments"}
                                                    onClick={(e) => this.sortBy(e)}>Last comments
                                                </th>
                                                <th className={this.getSortClasses("DateSubmitted")} data-id={"DateSubmitted"}
                                                    onClick={(e) => this.sortBy(e)}>Submitted on
                                                </th>
                                                <th className={this.getSortClasses("DateModified")} data-id={"DateModified"}
                                                    onClick={(e) => this.sortBy(e)}>Last Updated on
                                                </th>
                                                <th className={this.getSortClasses("ApproverName")} data-id={"ApproverName"}
                                                    onClick={(e) => this.sortBy(e)}>Approver
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {claimActions}
                                        </tbody>
                                    </table>
                                    <div className="soe-mobile-container">
                                        {currentClaims.map((claimAction) => {
                                            return (
                                                <ClaimActionCard
                                                    key={claimAction.ClaimId}
                                                    claimAction={claimAction}
                                                    isSubmitter={false}
                                                    companyDisplayName={this._getCompanyDisplayName(claimAction.CompanyName)}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-2">
                                {this._generateClaimsPerPage(filteredData ? filteredData.length : 0)}
                            </div>
                            <div className="col-xs-12 text-right">
                                {filteredData && filteredData.length > this.state.claimsPerPage ? <Pagination
                                    activePage={this.state.currentPage}
                                    itemsCountPerPage={this.state.claimsPerPage}
                                    totalItemsCount={filteredData ? filteredData.length : 0}
                                    pageRangeDisplayed={5}
                                    onChange={(e) => this.handlePageChange(e)}
                                /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

type ClaimActionLineProps = {
    isAdmin: boolean,
    claimAction: ClaimAction,
    companyDisplayName: string,
};

class ClaimActionLine extends React.Component<ClaimActionLineProps> {
    render() {
        const c = this.props.claimAction;
        const claimStatusClass = getSoeClaimStatusClass(this.props.claimAction.Status);

        return (
            <tr
                className={c.SimilarClaims && c.SimilarClaims.length > 0 ? "possibleDuplicate" : ""}
                title={c.SimilarClaims && c.SimilarClaims.length > 0 ? "Possible duplicates:\n" + c.SimilarClaims.map(sc => sc.Id + " " + sc.StatusStr + " " + sc.CreatedOn + " " + (sc.Description ?? "")).join("\n") : ""}
            >
                <td>
                    <a data-id={c.ClaimId} href={"/Home/ApproveClaim?claimId=" + c.ClaimId}>
                        <img data-id={c.ClaimId} className={"halfOpacity"} src="/img/view.png"/>
                    </a>
                </td>
                <td>{c.EmployeeName}</td>
                <td>{c.EmployeeCode}</td>
                <td data-field-name="CompanyName">{this.props.companyDisplayName}</td>
                <td>{c.DepartmentCode}</td>
                <td>{c.Currency}</td>
                <td>{c.Amount}</td>
                <td>{c.Category}</td>
                <td className="text-center"><span className={claimStatusClass}>{c.StatusStr}</span></td>
                <td>{getLatestComment(c)}</td>
                <td>{c.DateSubmittedStr}</td>
                <td>{c.DateModifiedStr}</td>
                <td>{c.ApproverName}</td>
            </tr>);
    }
}
