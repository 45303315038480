import type { IsoDate,IsoDateTime,IsoLikeDateTimeLocal,MonthStr,Pad2 } from "../types/utility";

export function formatLocalDate(dateObj: Date) {
    return [
        pad2(dateObj.getDate()),
        pad2(dateObj.getMonth() + 1),
        (dateObj.getFullYear() % 100),
    ].join("/");
}

export function formatLocalDateTime(dateObj: Date) {
    return formatLocalDate(dateObj) + " " +
        pad2(dateObj.getHours()) + ":" +
        pad2(dateObj.getMinutes());
}

export function formatUtcDate(dateObj: Date) {
    return [
        pad2(dateObj.getUTCDate()),
        pad2(dateObj.getUTCMonth() + 1),
        (dateObj.getUTCFullYear() % 100),
    ].join("/");
}

export function getDatePart(dateTime: Date | `${IsoDate}${string}`): IsoDate {
    if (dateTime instanceof Date) {
        return dateTime.toISOString().slice(0, 10) as IsoDate;
    } else {
        return dateTime.slice(0, 10) as IsoDate;
    }
}

export function formatZonelessDate(dateStr: "2024-01-01" | IsoDate | IsoLikeDateTimeLocal) {
    return formatUtcDate(new Date(getDatePart(dateStr)));
}

export function formatDuration(totalMinutes: number) {
    return Math.floor(totalMinutes / 60) + ":" +
        pad2(Math.floor(totalMinutes % 60));
}

export function unformatDuration(duration: string): number {
    if (!duration.trim()) {
        return 0;
    }
    const [hours, minutes] = duration.split(":");
    return +hours * 60 + Number(minutes ?? "00");
}

function isWeekend(date: Date) {
    return date.getUTCDay() === 0 || date.getUTCDay() === 6;
}

function isOffDayInLatvia(date: Date) {
    const fixedDateHolidays = [
        { "month": 1, "day": 1 },   // New Year's Day
        { "month": 5, "day": 1 },   // Labour Day
        { "month": 5, "day": 4 },   // Restoration of Independence Day
        { "month": 6, "day": 23 },  // Midsummer Eve
        { "month": 6, "day": 24 },  // St. John's Day
        { "month": 11, "day": 18 }, // Proclamation Day of the Republic of Latvia
        { "month": 12, "day": 24 }, // Christmas Eve
        { "month": 12, "day": 25 }, // Christmas Day
        { "month": 12, "day": 26 }, // Second Day of Christmas
        { "month": 12, "day": 31 }  // New Year's Eve
    ];
    return isWeekend(date) || fixedDateHolidays.some(holiday => {
        return date.getUTCMonth() + 1 === holiday.month
            && date.getDate() === holiday.day;
    });
}

function getNthWorkingDayByPredicate(startDate: Date, workingDay: number, isWorkingDay: (date: Date) => boolean): Date {
    let workingDaysPassed = 0;
    for (let i = 0; i < 365; ++i) {
        if (isWorkingDay(startDate)) {
            ++workingDaysPassed;
        }
        if (workingDaysPassed >= workingDay) {
            return startDate;
        } else {
            startDate.setUTCDate(startDate.getUTCDate() + 1);
        }
    }
    throw new Error("Invalid working day predicate was supplied");
}

export function getNthWorkingDay(startDate: Date, workingDay: number): Date {
    return getNthWorkingDayByPredicate(startDate, workingDay, date => !isWeekend(date));
}

/**
 * it only accounts for fixed (no Easter) day holidays and does
 * not account for days when working day was moved to a weekend day
 */
export function getNthWorkingDayInLatvia(startDate: Date, workingDay: number): Date {
    return getNthWorkingDayByPredicate(startDate, workingDay, date => !isOffDayInLatvia(date));
}

export function strcmp(a: string, b: string) {
    return a === b ? 0 : a < b ? -1 : 1;
}

export function min(dates: string[]) {
    return [...dates].sort(strcmp)[0];
}

export function max(dates: string[]) {
    return [...dates].sort(strcmp).slice(-1)[0];
}

export function getMonthName(month: number) {
    return [
        "Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec",
    ][month - 1];
}

export function getMonthFullName(month: number) {
    return [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December",
    ][month - 1];
}

export type AbsoluteMonth = {
    year: number,
    month: number,
};

export function pad2(value: number): Pad2 {
    if (value < 10) {
        return `0${value}`;
    } else {
        return value;
    }
}

export function getMonthStr({ year, month }: AbsoluteMonth): MonthStr {
    return `${year}-${pad2(month)}`;
}

export function getMonthDate(yearMonth: AbsoluteMonth, day: number): IsoDate {
    return `${getMonthStr(yearMonth)}-${pad2(day)}`;
}

export function getMonthStartDate(yearMonth: AbsoluteMonth): IsoDate {
    return getMonthDate(yearMonth, 1);
}

function getMonthStartDateObj({ year, month }: AbsoluteMonth) {
    return new Date(getMonthStartDate({ year, month }));
}

export function getMonthEndDateObj(baseDateStr: MonthStr | IsoDate | IsoDateTime) {
    const [year, month, ...rest] = baseDateStr.split("-");
    const yearMonth = { year: +year, month: +month };
    const daysInMonth = getNumberOfDays(yearMonth);
    return new Date(getMonthDate(yearMonth, daysInMonth));
}

export function getMonthEndDate(yearMonth: AbsoluteMonth): IsoDate {
    const lastDay = getNumberOfDays(yearMonth);
    return getMonthDate(yearMonth, lastDay);
}

export function getNumberOfDays({ year, month }: AbsoluteMonth) {
    const endDateObj = getMonthStartDateObj({ year, month });
    endDateObj.setUTCMonth(endDateObj.getUTCMonth() + 1);
    endDateObj.setUTCDate(endDateObj.getUTCDate() - 1);
    return endDateObj.getUTCDate();
}

export function getMonth(baseDate: Date): AbsoluteMonth {
    const endDateObj = new Date(baseDate.getTime());
    return {
        year: endDateObj.getUTCFullYear(),
        month: endDateObj.getUTCMonth() + 1,
    };
}

export function getPastMonth(baseDate: Date): AbsoluteMonth {
    const endDateObj = new Date(baseDate.getTime());
    endDateObj.setUTCDate(0);
    return {
        year: endDateObj.getUTCFullYear(),
        month: endDateObj.getUTCMonth() + 1,
    };
}

export function decrementMonth({ year, month }: AbsoluteMonth) {
    const dateObj = getMonthStartDateObj({ year, month });
    return getPastMonth(dateObj);
}

export function incrementMonth({ year, month }: AbsoluteMonth) {
    const dateObj = getMonthStartDateObj({ year, month });
    dateObj.setUTCMonth(dateObj.getUTCMonth() + 1);
    return {
        year: dateObj.getUTCFullYear(),
        month: dateObj.getUTCMonth() + 1,
    };
}
