import React from "react";
import type { NavInvoiceScreenParams, RdbPersonData } from "../features/Api";
import api from "../features/Api";
import type {
    RosterItem, RotationActivity,
    RotationData, RotationItem
} from "../features/roster/air_atlanta/Roster";
import { utcToZonedTime } from "date-fns-tz";

import "./InvoiceRosterViewAirAtlanta.css";
import { getMonthName,getMonthStr } from "../utils/dates";
import type {
    CodedSalaryJournalRecord,
    FeeEntryTypeRecord,
    ImpersonalSalaryJournalRecord
} from "../features/ExternalRostersApi";
import externalRostersApi from "../features/ExternalRostersApi";
import NavJournalRecordsTable from "./NavJournalRecordsTable";
import type { ActivityIconProps } from "./ActivityIcon";
import ActivityIcon from "./ActivityIcon";
import type { DutiedRotationDay, RotationDay } from "../features/roster/air_atlanta/RotationDaysGrouper";
import  { getRotationDaysFromFull } from "../features/roster/air_atlanta/RotationDaysGrouper";
import  { raidoToRm } from "../features/roster/air_atlanta/RotationDaysGrouper";
import { estimateStartDateTime } from "../features/roster/air_atlanta/RotationDaysGrouper";
import { asUtc, getRotationDays } from "../features/roster/air_atlanta/RotationDaysGrouper";
import AirAtlantaDutyDesignator from "./AirAtlantaDutyDesignator";
import type { OfficerFlightDeckFeesPerson, SccmFeeScale, SignedDocumentRow } from "../types/Api/Contract";
import { getIataToIana } from "../features/StaticData";
import {
    WW_ROTATION_PERIOD,
    consideredOffPayNiaStay,
    countDutyDays,
    getGroundActivityCategory,
    isSccmJob,
    parseContract,
    fixManually,
    isCabinCrewDepartment,
    isFlightDeckDepartment,
    getNias,
    getDutyFlights,
    getFlightBlockMinutes,
    getPositioningBlockMinutes,
    parseRotationPeriod,
    getBestError,
    isLoadMasterDepartment,
    hasPositioningDesignator
} from "../features/roster/air_atlanta/FeeCalculatorUtils";
import type { RotationPeriods
} from "../features/roster/air_atlanta/FeeCalculatorFlightDeck";
import {
    getAllRotationBlockHours
} from "../features/roster/air_atlanta/FeeCalculatorFlightDeck";
import {
    calculateFeesForFlightDeckPerson,
    getPrecedingUnterminatedRotationDays
} from "../features/roster/air_atlanta/FeeCalculatorFlightDeck";
import {
    calculateFeesForCabinCrewPerson,
    getPositioningTimeSummary
} from "../features/roster/air_atlanta/FeeCalculatorCabinCrew";
import type { EmployeeInfoWise } from "../types/EmployeeInfo";
import { HttpResponseError } from "../features/httpUtils";
import { brand, neverNull,toCrewCodeUc } from "../utils/typing";
import type { IanaTimezone, IataAirport,IataToIana,IsoDate,IsoDateTime } from "../types/utility";
import type { RosterCrew } from "@mhc/utils/types/integrations/raido/api";
import { stringifyError } from "../utils/errorHandling";
import Err from "../utils/Err";
import { calculateFeesForLoadMasterPerson, countLoadMasterDutyDays } from "../features/roster/air_atlanta/FeeCalculatorLoadmaster";

function formatDate(datetime: string) {
    const [year, month, rest] = datetime.split("-");
    const day = rest.slice(0, 2);
    return +day + " " + getMonthName(+month);
}

function formatTime(datetime: string) {
    return datetime.slice(11, 16);
}

/** dayNumber is the number of days since AD start */
function isAdDayNumber(dayNumber: number) {
    return dayNumber > 719162;
}

function formatDayNumberInRotation(dayNumber: number) {
    if (isAdDayNumber(dayNumber)) {
        const daySinceUnixEpoch = dayNumber - 719162;
        const date = new Date(0);
        date.setUTCDate(daySinceUnixEpoch);
        return date.getUTCDate();
    } else if (dayNumber === -1) {
        return "";
    } else {
        return dayNumber;
    }
}

function countDutyBlockMinutes(rotationDays: RotationDay[]) {
    const allActivities = rotationDays.flatMap(rd => rd.Activities);
    return getDutyFlights(allActivities).map(getFlightBlockMinutes).reduce((a, b) => a + b, 0);
}

function formatBlockMinutes(allMinutes: number) {
    const clockMinutes = allMinutes % 60;
    const clockHours = Math.floor(allMinutes / 60);
    return clockHours + ":" + String(clockMinutes).padStart(2, "0");
}

type Props = {
    locator: NavInvoiceScreenParams,
    latestContract: null | SignedDocumentRow,
    rdbData: null | RdbPersonData,
    onWarning: (message: string) => void,
};

type State = {
    rmRosterLoading: boolean,
    rmRosterRecords: null | RosterItem[],
    rmFullRotationData: null | RotationData,

    nocRosterLoading: boolean,
    nocRosterCrew: RosterCrew | null,
    showNocRoster: boolean,

    precedingUnterminatedRotationDays: null | RotationDay[],
    iataToIana: null | IataToIana,
    sccmFeeScales: null | SccmFeeScale[],
    officerFees: null | OfficerFlightDeckFeesPerson,
    employeeNavData: null | EmployeeInfoWise,
    rotationDatesInconsistencyReported: boolean,
    wageContractFees: null | FeeEntryTypeRecord[],
};

function getDayInRotation(rotationDay: DutiedRotationDay, rotations: RotationPeriods): number | null {
    for (const rotation of rotations) {
        if (rotation.startDay.Date <= rotationDay.Date &&
            rotation.endDay.Date >= rotationDay.Date
        ) {
            const utcMsPassed = new Date(rotationDay.Date).getTime() - new Date(rotation.startDay.Date).getTime();
            const daysPassed = utcMsPassed / 1000 / 60 / 60 / 24;
            return daysPassed + 1;
        }
    }
    return null;
}

function getDayTimePercent(date: IsoDate, time: Date | null | undefined) {
    const msSinceMidnight = !time ? 0 : time.getTime() - new Date(date).getTime();
    const dayFraction = msSinceMidnight / (24 * 60 * 60 * 1000);
    const capped = Math.max(0, Math.min(1, dayFraction));
    const withoutBorders = capped * 0.7 + 0.1;
    const percents = 100 * withoutBorders;
    return percents.toFixed(2) + "%";
}

function getActivityInfoStr(record: RosterItem) {
    const activityInfoTimeless =
        record.ActivityType === "BLANK" ? "BLANK" :
        record.ActivityType === "Flight" || record.ActivityType === "Deadhead" ? record.FlightNumber :
        record.GroundActivityInfo;
    return activityInfoTimeless + " " + record.ActivityDate;
}

export default class InvoiceRosterViewAirAtlanta extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            rmRosterLoading: false,
            rmRosterRecords: null,
            rmFullRotationData: null,

            nocRosterLoading: true,
            nocRosterCrew: null,
            showNocRoster: true,

            precedingUnterminatedRotationDays: null,
            iataToIana: null,
            sccmFeeScales: null,
            officerFees: null,
            employeeNavData: null,
            rotationDatesInconsistencyReported: false,
            wageContractFees: null,
        };
        if (!this.rmRosterRelevant) {
            this.setState({
                rmRosterLoading: false,
                rmRosterRecords: [],
             });
        } else {
            this.setState({
                rmRosterLoading: true,
             });
            api.Roster.getAirAtlantaRoster(this.props.locator).then(personRoster => {
                if (!personRoster.roster_incomplete) {
                    this.props.onWarning("roster base data inaccessible");
                } else {
                    this.setState({ rmRosterRecords: personRoster.roster_incomplete });
                }
                if (!personRoster.roster_complete) {
                    this.props.onWarning("roster full data inaccessible");
                } else if (personRoster.roster_complete.ResultStatus !== "SUCCESS") {
                    this.props.onWarning("Failed to fetch full Roster data: " + (personRoster.roster_complete.ResultMessage ?? personRoster.roster_complete.ResultMessage));
                } else {
                    this.setState({ rmFullRotationData: personRoster.roster_complete });
                }
            }).finally(() => this.setState({ rmRosterLoading: false }));
        }

        externalRostersApi.getAirAtlantaRaidoRoster({
            crew_code: toCrewCodeUc(this.props.locator.employeeCode),
            year: this.props.locator.year,
            month: this.props.locator.month,
        }).then(nocRosterCrew => {
            this.setState({ nocRosterCrew });
        }).catch(error => {
            const message = error instanceof HttpResponseError ? error.baseMessage : stringifyError(error);
            this.props.onWarning("Raido N-OC roster full data inaccessible: " + message);
        }).finally(() => this.setState({ nocRosterLoading: false }));

        getIataToIana()
            .then(iataToIana => this.setState({ iataToIana }));
        api.Employee.LoadProfileNav(this.props.locator).then(employeeNavData => {
            this.setState({ employeeNavData });

            if (isSccmJob(employeeNavData.JobTitle)) {
                api.Contract.count_airborne_sccm_fee_scales_by_person({ crewCode: this.props.locator.employeeCode })
                    .then(sccmFeeScales => this.setState({ sccmFeeScales }));
            }
            if (isFlightDeckDepartment(employeeNavData.Department)) {
                getPrecedingUnterminatedRotationDays(this.props.locator)
                    .then(precedingUnterminatedRotationDays => this.setState({ precedingUnterminatedRotationDays }));

                api.Contract.GetOfficerFlightDeckFeesRow(this.props.locator)
                    .then(officerFees => this.setState({ officerFees }))
                    .catch(error => {
                        if (error instanceof HttpResponseError && (
                            error.response.status === 501 ||
                            error.response.status === 412
                        )) {
                            // do not annoy user with errors when he opens future or past month rosters
                        } else {
                            throw error;
                        }
                    });
            } else {
                this.setState({ precedingUnterminatedRotationDays: [] });
            }
        });

        api.Contract.GetPersonWageContractFees(this.props.locator)
            .then(wageContractFees => this.setState({ wageContractFees }));
    }

    private getTimeZone(iata: IataAirport): IanaTimezone | null {
        return !iata
            ? brand<IanaTimezone>("Etc/UTC")
            : this.state.iataToIana?.[iata] ?? null;
    }

    private getTimeTooltipText(utcDateTimeStr: IsoDateTime, airport: IataAirport | null | undefined) {
        let result = formatDate(utcDateTimeStr) + " " + formatTime(utcDateTimeStr) + " UTC";
        if (!airport) {
            return result;
        }
        const tz = this.getTimeZone(airport);
        if (!tz) {
            return result;
        }
        const utc = asUtc(utcDateTimeStr);
        const local = utcToZonedTime(utc, tz);
        result =
            local.getDate() + " " +
            getMonthName(local.getMonth() + 1) + " " +
            [local.getHours(), local.getMinutes()]
                .map(n => String(n)
                .padStart(2, "0")).join(":") + " LT (" + tz + ")\n" +
            result;
        return result;
    }

    private getEndTimeTooltipText(record: RosterItem) {
        const utcPlannedStr = record.ActivityDate;
        const utcActualStr = record.FullRotationActivity?.ATA_UTC || undefined;
        let text = this.getTimeTooltipText(utcPlannedStr, record.AirportTo);
        if (utcActualStr && utcActualStr.slice(0, 16) !== utcPlannedStr?.slice(0, 16)) {
            text += " PLANNED \n" + formatDate(utcActualStr) + " " + formatTime(utcActualStr) + " UTC ACTUAL";
        }
        return text;
    }

    private getStartTimeTooltipText(
        airport: IataAirport | undefined | null,
        utcPlanned: Date | null,
        utcActual: Date | undefined
    ): string {
        if (!utcPlanned) {
            return "";
        }
        let text = this.getTimeTooltipText(utcPlanned.toISOString(), airport);
        if (utcActual && utcActual.getTime() !== utcPlanned.getTime()) {
            text += " PLANNED \n" + formatDate(utcActual.toISOString()) + " " + formatTime(utcActual.toISOString()) + " UTC ACTUAL";
        }
        return text;
    }

    get isCcm() {
        return isCabinCrewDepartment(this.state.employeeNavData?.Department ?? "");
    }

    get isFd() {
        return isFlightDeckDepartment(this.state.employeeNavData?.Department ?? "");
    }

    get isLdm() {
        return isLoadMasterDepartment(this.state.employeeNavData?.Department ?? "");
    }

    get isAdmin() {
        return window.EMPLOYEE_INFO?.IsAdmin;
    }

    private countDutyDays(dutylessRotationDays: RotationDay[], iataToIana: IataToIana) {
        return this.isLdm
            ? countLoadMasterDutyDays(this.nias, dutylessRotationDays, iataToIana)
            : countDutyDays(this.nias, dutylessRotationDays, iataToIana);
    }

    private countDutyDaysOrNull(dutylessRotationDays: RotationDay[]) {
        if (this.nias.size === 0 ||
            !this.state.iataToIana
        ) {
            return null;
        }
        const rotationDays = this.countDutyDays(dutylessRotationDays, this.state.iataToIana);
        return { rotationDays };
    }

    private countTravelDays(records: RotationDay[]) {
        const duties = this.countDutyDaysOrNull(records);
        if (!duties) {
            return null;
        }
        return duties.rotationDays.filter(rd => {
            return rd.DutyKind === "TRAVEL"
                || rd.DutyKind === "DAY_SHIFT_TRAVEL_END"
                || rd.DutyKind === "DAY_SHIFT_TRAVEL_START";
        }).length;
    }

    private countNiaGroundDutyDays(records: RotationDay[]) {
        const duties = this.countDutyDaysOrNull(records);
        if (!duties) {
            return null;
        }
        return duties.rotationDays.filter(rd => rd.DutyKind === "NIA_GROUND_DUTY").length;
    }

    private get parsedContract() {
        if (this.props.latestContract) {
            return parseContract(this.props.latestContract);
        }
        return null;
    }

    private get nias() {
        return getNias({
            navEntry: this.state.employeeNavData,
            rdbEntry: !this.props.rdbData ? undefined : {
                Nearest_Airport: this.props.rdbData.rdbNia,
            },
            latestContractData: this.props.latestContract,
        });
    }

    private get rmRosterRelevant() {
        // transition month from RM to N-OC
        return getMonthStr(this.props.locator) <= "2024-09";
    }

    private get nocRosterRelevant() {
        // transition month from RM to N-OC
        return getMonthStr(this.props.locator) >= "2024-09";
    }

    private makeRosterTable(dutylessRotationDays: RotationDay[]) {
        let rotationDays: DutiedRotationDay[];
        if (this.state.iataToIana) {
            rotationDays = this.countDutyDays(dutylessRotationDays, this.state.iataToIana);
        } else {
            rotationDays = dutylessRotationDays.map(drd => ({ ...drd, DutyKind: null }));
        }
        let dutyDays = 0;
        let rotationDatesInconsistent = false;
        let rotationDatesInconsistencyReported = this.state.rotationDatesInconsistencyReported;
        const rotationPeriod = this.state.officerFees?.rotationType &&
            parseRotationPeriod(this.state.officerFees.rotationType) ||
            this.parsedContract?.rotationPeriod || WW_ROTATION_PERIOD;
        const positioningBlockHoursSummary = getPositioningTimeSummary(this.nias, rotationDays);
        // === 0 because if there are no preceding rotation days, the number will be just same as duty day number
        const rotations = !this.isFd || !this.state.precedingUnterminatedRotationDays ? null :
            getAllRotationBlockHours(rotationDays, this.state.precedingUnterminatedRotationDays, WW_ROTATION_PERIOD);
        const showExactRotationTd = !!rotations && rotationDays.length > 0 &&
            getDayInRotation(rotationDays[rotationDays.length - 1], rotations) !== rotationDays.filter(rd => rd.DutyKind !== "OFF_DUTY").length;
        const result = <table className="aai-roster-table generic-roster-table">
            <thead>
                <tr className="roster-column-heading">
                    <th>Date</th>
                    <th>Activity</th>
                    <th colSpan={2}>Departure</th>
                    <th colSpan={2}>Arrival</th>
                    <th title="Block Hours Decimal Units">BL HRS</th>
                    <th>Designators</th>
                    <th>Duty #</th>
                    {showExactRotationTd && <th title="Rotation Day Number">Rot.</th>}
                </tr>
            </thead>
            <tbody>
                {rotationDays.flatMap(rotationDay => {
                    const exactDayInRotation = !rotations ? null : getDayInRotation(rotationDay, rotations);
                    const rowSpan = rotationDay.Activities.length;
                    const isDuty = rotationDay.DutyKind !== "OFF_DUTY";
                    if (isDuty) {
                        ++dutyDays;
                    }
                    const dutyDaysTd = <td
                        rowSpan={rowSpan}
                        className="alpha-numeric-field duty-days"
                        title={rotationDay.DutyKind ?? undefined}
                        data-duty-kind={rotationDay.DutyKind}
                    >{isDuty ? dutyDays : ""}</td>;

                    let fullRotationItem: RotationItem | null = null;
                    if (this.fullRotationData) {
                        fullRotationItem = rotationDay.FullRotationItem ?? null;
                        // TODO: move error logging to the getRotationDays()
                        if (!fullRotationItem &&
                            !rotationDatesInconsistencyReported &&
                            // when checking current month roster, there are no entries of future days
                            rotationDay.Date < new Date().toISOString().slice(0, 10)
                        ) {
                            rotationDatesInconsistencyReported = true;
                            (async () => {
                                throw new Error(`Failed to match day ${rotationDay.MonthDayIndex + 1} to complete roster`);
                            })();
                        }
                    }

                    const rotationExactTd = <td
                        rowSpan={rowSpan}
                        className={
                            "alpha-numeric-field rotation-day-number" +
                            (exactDayInRotation && exactDayInRotation % rotationPeriod === 0 ? " last-day-of-rotation" : "")
                    }
                    >{exactDayInRotation || ""}</td>;

                    return rotationDay.Activities.map((record, i) => {
                        const activityInfo = getActivityInfoStr(record);
                        const groundActivityCategory = getGroundActivityCategory(record);
                        const endTime = formatTime(record.ActivityDate);
                        const atHomebase = this.nias.size === 0 ||
                            record.ActivityType !== "BLANK" &&
                            consideredOffPayNiaStay(this.nias, record, rotationDays);
                        let fullActivity: RotationActivity | null = null;
                        if (fullRotationItem) {
                            fullActivity = record.FullRotationActivity ?? null;
                            // TODO: move error logging to the getRotationDays()
                            if (!fullActivity &&
                                !rotationDatesInconsistencyReported
                            ) {
                                rotationDatesInconsistencyReported = true;
                                (async () => {
                                    throw new Error(`Failed to match activity ${activityInfo} to complete roster`);
                                })();
                            }
                        }

                        const startDateTimePlanned = estimateStartDateTime(record, rotationDay);
                        const startDateTimeActual = record.FullRotationActivity?.ATD_UTC && asUtc(record.FullRotationActivity.ATD_UTC) || undefined;
                        let atdDifferenceMs = 0;
                        if (startDateTimePlanned && startDateTimeActual) {
                            atdDifferenceMs = Math.abs(startDateTimeActual.getTime() - startDateTimePlanned.getTime());
                        }
                        const rosterStartDate = startDateTimePlanned ? formatDate(startDateTimePlanned.toISOString()) : null;
                        const rotationStartDate = `${rotationDay.MonthDayIndex + 1} ${getMonthName(this.props.locator.month)}`;
                        const isNewDay = i === 0;

                        let shownStartDate;
                        if (rotationDatesInconsistent) {
                            shownStartDate = rosterStartDate;
                        } else if (rosterStartDate && rosterStartDate !== rotationStartDate) {
                            shownStartDate = rosterStartDate;
                            rotationDatesInconsistent = true;
                            // it is common for roster to start with a date other than 1st in unfinallized
                            // current month rosters that we show on Manual Invoice screen
                            if ((startDateTimePlanned ?? neverNull()).getUTCMonth() < new Date().getUTCMonth() &&
                                this.state.iataToIana &&
                                // damn broken DST calculation in the API on this specific date
                                record.AirportFrom && this.getTimeZone(record.AirportFrom) === "Africa/Casablanca" && rotationDay.Date === "2024-03-09" &&
                                !rotationDatesInconsistencyReported
                            ) {
                                rotationDatesInconsistencyReported = true;
                                (async () => {
                                    throw new Error(`Rotation start date (${rotationStartDate}) doesn't match with roster start date (${rosterStartDate}). Activity: ${activityInfo}`);
                                })();
                            }
                        } else {
                            shownStartDate = rotationStartDate;
                        }

                        let effectiveActivityType: ActivityIconProps["activityType"];
                        if (record.ActivityType === "Flight" &&
                            hasPositioningDesignator(fullActivity)
                        ) {
                            // rather often there are flights that are not supposed to be considered
                            // duty and therefore are not to be counted towards block hours
                            effectiveActivityType = "Deadhead";
                        } else {
                            effectiveActivityType = record.ActivityType;
                        }
                        const actualBlockTimeMissing = this.state.showNocRoster && (
                            !record.FullRotationActivity?.ATD_UTC ||
                            !record.FullRotationActivity?.ATA_UTC
                        );
                        return <tr
                            key={rotationDay.Date + "_" + i}
                            data-activity-type={effectiveActivityType}
                            data-ground-activity-category={groundActivityCategory ?? undefined}
                            className={"" + (atHomebase ? " at-homebase" : "") + (isNewDay ? " new-day" : "")}
                            style={{
                                "--day-time-start-percent": getDayTimePercent(rotationDay.Date, startDateTimePlanned),
                                "--day-time-end-percent": getDayTimePercent(rotationDay.Date, asUtc(record.ActivityDate)),
                            }}
                        >
                            {isNewDay && <td
                                 rowSpan={rowSpan}
                                 className="alpha-numeric-field start-date-utc"
                                 data-field="StartDateUtc">{shownStartDate}</td>}
                            <td className="activity-cell">
                                <div className="activity-wrapper">
                                    <ActivityIcon activityType={effectiveActivityType} category={groundActivityCategory}/>
                                    {record.ActivityType === "Flight" || record.ActivityType === "Deadhead"
                                        ? <div className="aai-flight-specific-details">
                                            <div className="alpha-numeric-field"
                                                 data-field="FlightNumber"
                                                 title={record.ActivityType === "Deadhead" ? "Deadhead flight. Duration " + record.DeadheadHours + ":" + String(record.DeadHeadMinutes).padStart(2, "0") : undefined}
                                            >{record.FlightNumber}</div>
                                        </div>
                                        : <div className="aai-ground-specific-details">
                                            <div data-field="GroundActivityInfo"
                                                 title={record.GroundActivityInfo ?? undefined}>{record.GNDACTCODETITLE}</div>
                                        </div>}
                                </div>
                            </td>
                            <td className={"alpha-numeric-field" + (record.AirportFrom === record.AirportTo ? " unimportant-field" : "") + (record.AirportFrom && this.nias.has(record.AirportFrom) ? " homebase" : "")}
                                title={record.AirportFrom && this.nias.has(record.AirportFrom) ? "Homebase" : undefined}
                            >{record.AirportFrom}</td>
                            <td
                                title={this.getStartTimeTooltipText(record.AirportFrom, startDateTimePlanned, startDateTimeActual)}
                                className={
                                    "alpha-numeric-field" +
                                    (record.ActivityType === "BLANK" ? " unimportant-field" : "") +
                                    (Math.abs(atdDifferenceMs) >= 30 * 60 * 1000 ? " actual-time-differs-from-planned" : "")
                                }
                                data-field="StartTimeUtc"
                            >{!startDateTimePlanned ? "" : formatTime(startDateTimePlanned.toISOString())}</td>
                            <td className={"alpha-numeric-field" + (record.AirportFrom && this.nias.has(record.AirportTo) ? " homebase" : "")}
                                title={record.AirportFrom && this.nias.has(record.AirportTo) ? "Homebase" : undefined}
                                data-field="AirportTo"
                            >{record.AirportTo}</td>
                            <td title={this.getEndTimeTooltipText(record)} className="alpha-numeric-field">{record.ActivityType === "BLANK" ? "" : endTime}</td>
                            <td className={"alpha-numeric-field" + (actualBlockTimeMissing ? " actual-block-time-missing" : "")} data-field="BlockTime">
                                {effectiveActivityType === "Flight" && <span title={"Block Time: " + formatBlockMinutes(getFlightBlockMinutes(record)) + (!actualBlockTimeMissing ? "" : " (Actual ATA/ATD Block Time Missing! Displaying the planned time)")}>{(getFlightBlockMinutes(record) / 60).toFixed(3)}</span>}
                                {effectiveActivityType === "Deadhead" && this.isCcm && <span title={"Positioning Block Time: " + formatBlockMinutes(getPositioningBlockMinutes(record))} className="positioning-block-hours">{(getPositioningBlockMinutes(record) / 60).toFixed(3)}</span>}
                            </td>
                            <td className="alpha-numeric-field">
                                <div className="duty-designators-list">{fullActivity?.DutyDesignators?.split(",").map(dd => {
                                    return <AirAtlantaDutyDesignator key={dd} dutyDesignator={dd}/>;
                                })}</div>
                            </td>
                            {isNewDay ? dutyDaysTd : null}
                            {isNewDay && showExactRotationTd && rotationExactTd}
                        </tr>;
                    });
                })}
            </tbody>
            <tfoot className="aai-summary-lines">
                {rotationDays.slice(-1)[0]?.Activities.slice(-1)[0]?.PayTypes?.filter(pt => !pt.Description?.startsWith("Total BlockHours")).map(pt => <tr key={pt.Description}>
                    <td colSpan={999}>{pt.Description}</td>
                </tr>)}
            </tfoot>
            <tfoot className="in-house-calculated-summary-lines">
                <tr className="travel-days-summary">
                    <td></td>
                    <td className="total-row-icon">
                        <img src="/img/roster-activity-logos/pick-up.svg" title="Positioning" alt="positioning"/>
                    </td>
                    <td colSpan={6} className="summary-text">
                        Travel Days:
                    </td>
                    <td className="alpha-numeric-field summary-value">
                        {this.countTravelDays(rotationDays) ?? "?"}
                    </td>
                </tr>
                <tr className="nia-ground-duty-days-summary">
                    <td></td>
                    <td className="total-row-icon">
                        <img src="/img/roster-activity-logos/on-duty.svg" title="Positioning" alt="positioning"/>
                    </td>
                    <td colSpan={6} className="summary-text">
                        NIA Ground Duty Days:
                    </td>
                    <td className="alpha-numeric-field summary-value">
                        {this.countNiaGroundDutyDays(rotationDays) ?? "?"}
                    </td>
                </tr>
                {!this.isCcm ? null : <>
                    <tr className="duty-block-hours-summary">
                        <td></td>
                        <td className="total-row-icon">
                            <img src="/img/roster-activity-logos/flight-duty.svg" title="Flight Duty"/>
                        </td>
                        <td colSpan={4} className="summary-text">
                            Duty Block Hours:
                        </td>
                        <td className="alpha-numeric-field" data-field="BlockTime" title={formatBlockMinutes(countDutyBlockMinutes(rotationDays))}>
                            {(countDutyBlockMinutes(rotationDays) / 60).toFixed(3)}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="total-row-icon">
                            <img src="/img/roster-activity-logos/pick-up.svg" title="Positioning"/>
                        </td>
                        <td colSpan={4} className="summary-text">
                            Deadhead Block Hours:
                        </td>
                        <td className="alpha-numeric-field positioning-block-hours" data-field="BlockTime" title={formatBlockMinutes(positioningBlockHoursSummary.deadheadMinutes)}>
                            {(positioningBlockHoursSummary.deadheadMinutes / 60).toFixed(3)}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="total-row-icon">
                            <img src="/img/roster-activity-logos/pick-up.svg" title="Positioning"/>
                        </td>
                        <td colSpan={4} className="summary-text">
                            Positioning Block Hours:
                        </td>
                        <td className="alpha-numeric-field positioning-block-hours" data-field="BlockTime" title={formatBlockMinutes(positioningBlockHoursSummary.positioningMinutes)}>
                            {(positioningBlockHoursSummary.positioningMinutes / 60).toFixed(3)}
                        </td>
                    </tr>
                </>}
            </tfoot>
        </table>;
        if (rotationDatesInconsistencyReported && !this.state.rotationDatesInconsistencyReported) {
            this.setState({ rotationDatesInconsistencyReported: true });
        }
        return <div className="invoice-roster-view-table-wrapper">{result}</div>;
    }

    private get fullRotationData(): null | RotationData {
        if (this.state.showNocRoster) {
            if (!this.state.nocRosterCrew) {
                return null;
            } else {
                return raidoToRm(this.state.nocRosterCrew, this.props.locator);
            }
        } else {
            return this.state.rmFullRotationData;
        }
    }

    private get nocRotationDaysOrErr(): RotationDay[] | Err {
        if (!this.state.nocRosterCrew) {
            return new Err("Raido N-OC Roster could not be retrieved");
        }
        const fullRotationData = raidoToRm(this.state.nocRosterCrew, this.props.locator);
        return getRotationDaysFromFull(this.props.locator, fullRotationData, null);
    }

    private get rmRotationDaysOrErr(): RotationDay[] | Err {
        const rmRoster = {
            roster_incomplete: this.state.rmRosterRecords,
            roster_complete: this.state.rmFullRotationData,
        };
        const rotationDays = getRotationDays({ ...this.props.locator, ...rmRoster });
        if (!rotationDays) {
            const bestError = getBestError(rmRoster);
            return new Err("Roster - " + bestError);
        }
        return rotationDays;
    }

    private get rotationDaysOrErr(): RotationDay[] | Err {
        if (this.state.showNocRoster) {
            return this.nocRotationDaysOrErr;
        } else {
            return this.rmRotationDaysOrErr;
        }
    }

    private get rotationDays(): RotationDay[] | null {
        const rotationDays: RotationDay[] | Err = this.rotationDaysOrErr;
        if (rotationDays instanceof Err) {
            return null;
        } else {
            return rotationDays;
        }
    }

    get journalEntries(): CodedSalaryJournalRecord[] {
        if (!this.state.employeeNavData ||
            !this.state.precedingUnterminatedRotationDays
        ) {
            return [];
        }
        const payrollMonth = {
            year: this.props.locator.year,
            month: this.props.locator.month,
        };
        const crew_code = this.props.locator.employeeCode;

        const rotationDays: RotationDay[] | Err = this.rotationDaysOrErr;
        if (rotationDays instanceof Err) {
            return fixManually(payrollMonth, rotationDays.message)
                .map(jr => ({ ...jr, crew_code }));
        }

        const params = {
            year: this.props.locator.year,
            month: this.props.locator.month,
            rotationDays: rotationDays,
            aircraft: this.props.locator.company === "D2W - AAIUSD"
                ? this.state.employeeNavData.GlobalDimension2Code
                : this.state.employeeNavData.ShortcutDimension3Code,
            precedingUnterminatedRotationDays: this.state.precedingUnterminatedRotationDays,
            navEntry: this.state.employeeNavData,
            latestContractData: this.props.latestContract,
        };

        let journalEntries: ImpersonalSalaryJournalRecord[];
        if (!this.state.iataToIana) {
            journalEntries = fixManually(params, "Time Zones data loading...");
        } else if (this.isFd) {
            journalEntries = calculateFeesForFlightDeckPerson(params, this.state.iataToIana, this.state.officerFees);
        } else if (this.isLdm) {
            journalEntries = calculateFeesForLoadMasterPerson(params, this.state.wageContractFees, this.state.iataToIana);
        } else if (this.isCcm) {
            journalEntries = calculateFeesForCabinCrewPerson(params, this.state.iataToIana);
        } else {
            return [];
        }
        return journalEntries.map(jr => ({ ...jr, crew_code }));
    }

    private get aaiValidContractInfo() {
        const aaiContractInfo = this.fullRotationData?.EmployeeInfo?.ContractInfo;
        if (!aaiContractInfo) {
            return null;
        }
        if (this.parsedContract &&
            this.parsedContract.rotationPeriod &&
            aaiContractInfo.DurationInDays !== this.parsedContract.rotationPeriod
        ) {
            // for some of the people Air Atlanta sends us WW even though in the roster and in the contract you can clearly see 24/12 or 21/14
            // I see that EmployeeSpecFuncs is usually correct though, maybe should use it instead, ContractInfo is probably not maintained anymore
            return null;
        }
        return aaiContractInfo;
    }

    private get nearestInternationalAirport() {
        return this.state.employeeNavData?.NearestIntAirport;
    }

    render() {
        let content;
        if (!(this.nocRotationDaysOrErr instanceof Err) ||
            !(this.rmRotationDaysOrErr instanceof Err)
        ) {
            content = <div className="aai-current-roster">
                <h3>
                    <span>Roster from </span>
                    {this.rmRosterRelevant && <label className="roster-source-switch-button">
                        <input
                            type="radio"
                            name="rosterFrom"
                            value="Sabre"
                            disabled={this.state.rmRosterLoading || !this.state.rmFullRotationData && !this.state.rmRosterRecords}
                            checked={!this.state.showNocRoster}
                            onChange={() => this.setState({ showNocRoster: false })}
                        />
                        <span>Sabre RM</span>
                    </label>}
                    <label className="roster-source-switch-button">
                        <input
                            type="radio"
                            name="rosterFrom"
                            value="Raido"
                            checked={this.state.showNocRoster}
                            onChange={() => this.setState({ showNocRoster: true })}
                        />
                        <span>Raido N-OC</span>
                    </label>
                </h3>
                <div className="aai-contract-facts-list">
                    {this.nearestInternationalAirport && this.nearestInternationalAirport !== this.parsedContract?.nia &&
                        <div className="airline-agnostic-roster-data">NIA in NAV: {this.nearestInternationalAirport}</div>}
                    {this.props.rdbData?.rdbNia && this.props.rdbData?.rdbNia !== this.parsedContract?.nia && this.props.rdbData?.rdbNia !== this.nearestInternationalAirport &&
                        <div className="airline-agnostic-roster-data">NIA in RDB: {this.props.rdbData?.rdbNia}</div>}
                    {this.fullRotationData?.EmployeeInfo?.Base ? "Roster Base: " + this.fullRotationData.EmployeeInfo.Base : ""}
                    {this.aaiValidContractInfo && <>
                        <div>Contract: {this.aaiValidContractInfo.SpecFuncCode} ({this.aaiValidContractInfo.Title})</div>
                        <div>Rotation Period: {this.aaiValidContractInfo.DurationInDays} days</div>
                    </>}
                </div>
                {this.rotationDays && this.makeRosterTable(this.rotationDays)}
            </div>;
        } else if (this.state.nocRosterLoading) {
            content = <div className="status-loading-animated-ellipsis" style={{ textAlign: "center" }}>
                Roster Data Loading
            </div>;
        } else {
            content = <div className="content-encountered-errors">
                Encountered errors during Roster data retrieval:
            </div>;
        }
        return <div style={{ textAlign: "center" }}>
            {content}
            {this.journalEntries.length === 0 ? undefined : <div className="preliminary-salary-journal-from-roster">
                <h3>Preliminary Salary Journal batch calculated from {this.state.showNocRoster ? "Raido N-OC" : "Sabre RM"} Roster</h3>
                <NavJournalRecordsTable calculatedPeople={[{
                    person: { navEntry: { No_: this.props.locator.employeeCode } },
                    journalRecords: this.journalEntries,
                }]} />
            </div>}
            {!this.state.sccmFeeScales || this.state.sccmFeeScales.length === 0 ? undefined : <div>
                <h3>SCCM Fee Scales History</h3>
                <table className="sccm-fee-scales-history">
                    <thead>
                        <tr>
                            <th>Pay Runs</th>
                            <th>Fee Days</th>
                            <th>First Day</th>
                            <th>Last Day</th>
                            <th>Daily Fee</th>
                        </tr>
                    </thead>
                    <tbody>{this.state.sccmFeeScales.map(scale => <tr key={scale.DailyFee}>
                        <td>{scale.Months}</td>
                        <td>{scale.Days}</td>
                        <td>{scale.MinDate}</td>
                        <td>{scale.MaxDate}</td>
                        <td>{scale.DailyFee}</td>
                    </tr>)}</tbody>
                </table>
            </div>}
        </div>;
    }
}
