import type { JsonValue } from "@mhc/utils/types/utility";
import { neverNull } from "@mhc/utils/src/typing";

const extractFromByteArray = (byteArray: Uint8Array, first: boolean) => {
    const lines = new TextDecoder("utf-8").decode(byteArray).split("\n");
    const remainderStr = lines.pop(); // incomplete or ending "]"
    const items = [];
    for (const line of lines) {
        let itemStr;
        const wasFirst = first;
        if (first) {
            first = false;
            itemStr = line.replace(/^\s*\[\s*/, "");
        } else {
            itemStr = line.replace(/^\s*,\s*/, "");
        }
        if (itemStr.length === line.length) {
            throw new Error("Malformed JSON: missing [ or , at line " + wasFirst + " - " + JSON.stringify(line));
        }
        items.push(JSON.parse(itemStr));
    }
    const remainder = new TextEncoder().encode(remainderStr);
    return { items, remainder };
};

function joinByteChunks(parts: Uint8Array[]): Uint8Array {
    let effectiveLength = 0;
    for (const part of parts) {
        effectiveLength += part.length;
    }
    const joined = new Uint8Array(effectiveLength);
    let offset = 0;
    for (const part of parts) {
        joined.set(part, offset);
        offset += part.length;
    }
    return joined;
}

const extractCompleteItems = function*(parts: Uint8Array[], first: boolean): Iterable<JsonValue> {
    let prefix = new Uint8Array(0);
    let part;
    while (part = parts.shift()) {
        const joined = new Uint8Array(prefix.length + part.length);
        joined.set(prefix);
        joined.set(part, prefix.length);
        const { items, remainder } = extractFromByteArray(joined, first);
        for (const item of items) {
            first = false;
            yield item;
        }
        prefix = remainder;
    }
    if (prefix.length > 0) {
        parts.unshift(prefix);
    }
};

/**
 * works only for a particularly formatted JSON responses: an array with
 * line break after every element, comma and closing ] after the line break
 * [{"foo": "bar"}
 * ,{"foo": "baz"}
 * ,{"foo": "bam"}
 * ]
 * creates an Async iterator over the elements that can be used to continuously
 * process the results without first waiting for the whole response to load
 */
export default async function* JsonResponseAsyncIterator<
    T extends JsonValue = JsonValue
>(rs: Response): AsyncGenerator<T> {
    const reader = (rs.body ?? neverNull()).getReader();
    const parts: Uint8Array[] = [];
    let first = true;
    while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        parts.push(value);
        for (const item of extractCompleteItems(parts, first)) {
            first = false;
            yield item as T;
        }
    }
    const remainderBytes = joinByteChunks(parts);
    const remainderStr = new TextDecoder("utf-8").decode(remainderBytes);
    if (first) {
        // just a normal JSON response without line breaks - just parse it fully
        yield * JSON.parse(remainderStr);
    } else if (remainderStr.trim() !== "]") {
        console.warn(parts);
        throw new Error("Unexpected formatting of the streamed JSON");
    }
    // TODO: handle JSON formatted with line breaks that does not comply
    //  to this streaming protocol, like JSON.stringify(data, null, 4)
};
