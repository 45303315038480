import type { Matrix, Point } from "react-spreadsheet";
import Spreadsheet from "react-spreadsheet";


import { useRef } from "react";
import type { Field } from "./ManualInvoiceDetailsTable";
import { getFieldTitle } from "./ManualInvoiceDetailsTable";
import * as React from "react";
import { getMonthShortName, getNumberOfDays } from "@mhc/utils/src/dates";
import { BOOLEAN_FIELDS } from "../features/manual-work-invoices/sheetUtils";
import { deepCopy } from "@mhc/utils/src/utils/dataHelpers";
import type { PlainCell, PlainMatrix } from "../features/manual-work-invoices/aaiMtxIntegratedSheetInput";

const AAI_MTX_BOOLEAN_FIELDS = new Set([
    ...BOOLEAN_FIELDS,
    "FlyingOver10h",
    "FlyingBelow10h",
]);

function tryIdentifyAsBooleanCell(event: React.MouseEvent) {
    if (!(event.target instanceof HTMLElement)) {
        return;
    }
    let target: HTMLElement | null;
    if (event.target.classList.contains("Spreadsheet__data-viewer")) {
        target = event.target.parentElement;
    } else {
        target = event.target;
    }
    if (!(target instanceof HTMLTableCellElement)) {
        return;
    }
    const td = target;
    const isBoolean = [...AAI_MTX_BOOLEAN_FIELDS].some(field => td.classList.contains("field-name--" + field));
    if (!isBoolean) {
        return;
    }
    const tr = td.parentElement;
    if (!(tr instanceof HTMLTableRowElement)) {
        return;
    }
    const column = [...tr.querySelectorAll(":scope > td")].indexOf(td);
    const tbody = tr.parentElement;
    if (!(tbody instanceof HTMLTableSectionElement)) {
        return;
    }
    const row = [...tbody.querySelectorAll(":scope > tr[row]")].indexOf(tr);
    return { row, column };
}

type SheetField = Field | "FlyingBelow10h" | "FlyingOver10h";

export default function ManualInvoiceAaiMtxSubmissionFormInputTable(props: {
    year: number,
    month: number,
    rowLabels: Partial<Record<SheetField, string>>,
    invoiceDayFields: readonly SheetField[],
    resetDayRowsKey: Symbol,
    resetDayRows: PlainMatrix,
    /**
     * fires when we know for sure that change originated from the user action,
     * though at the moment it does not cover action of entering text in a cell
     */
    onExplicitChange: (rows: PlainMatrix) => void,
    /**
     * onImplicitChange fires on both user action and programmatic updates, so if
     * it's used directly in props, it may cause infinite cyclic re-render
     */
    onImplicitChange: (rows: PlainMatrix) => void,
}) {
    const activeCellRef = useRef<Point | null>(null);
    const historyStackIndexRef = useRef(0);
    const historyStackRef = useRef<PlainMatrix[]>([props.resetDayRows]);
    const latestDayRows = useRef<PlainMatrix>(props.resetDayRows);
    const hasLocalChangesRef = useRef(false);
    const resetDayRowsKeyRef = useRef(props.resetDayRowsKey);

    if (resetDayRowsKeyRef.current !== props.resetDayRowsKey) {
        // to make changes coming from parent keep being recorded,
        // while getting into a loop from changes originating from the tabl
        resetDayRowsKeyRef.current = props.resetDayRowsKey;
        latestDayRows.current = props.resetDayRows;
        pushToHistory();
    }

    function setRowsExplicit(rows: PlainMatrix) {
        latestDayRows.current = rows;
        hasLocalChangesRef.current = false;
        props.onExplicitChange(rows);
    }

    function pushToHistory() {
        if (historyStackIndexRef.current !== historyStackRef.current.length - 1) {
            historyStackRef.current = historyStackRef.current.slice(0, historyStackIndexRef.current + 1);
        }
        historyStackRef.current.push(latestDayRows.current);
        historyStackIndexRef.current = historyStackRef.current.length - 1;
    }

    function getActiveBooleanCell() {
        if (activeCellRef.current) {
            const field = props.invoiceDayFields[activeCellRef.current.column] ?? null;
            if (AAI_MTX_BOOLEAN_FIELDS.has(field)) {
                return activeCellRef.current;
            }
        }
        return null;
    }

    function getColumnLabel(field: SheetField): string {
        const label = props.rowLabels[field];
        if (label) {
            return label;
        }
        return field === "FlyingBelow10h" ? "Flying ≤ 10h" :
            field === "FlyingOver10h" ? "Flying > 10h" :
            getFieldTitle(field);
    }

    function onRootMouseDown(event: React.MouseEvent) {
        if (event.button !== 0) {
            return;
        }
        let point = null;
        if (event.target instanceof HTMLDivElement &&
            event.target.classList.contains("Spreadsheet__active-cell")
        ) {
            if (getActiveBooleanCell()) {
                point = getActiveBooleanCell();
            }
        } else {
            point = tryIdentifyAsBooleanCell(event);
        }
        if (!point) {
            return;
        }
        event.preventDefault();

        const { row, column } = point;
        const newDayRows = deepCopy<Matrix<PlainCell>>(latestDayRows.current);
        newDayRows[row] = newDayRows[row] ?? [];
        const cell: PlainCell = newDayRows[row][column] ?? { value: "" };
        newDayRows[row][column] = cell;
        cell.value = !cell.value ? "X" : "";
        if (cell.value) {
            let counterpartColumn = null;
            if (props.invoiceDayFields[column] === "FlyingBelow10h") {
                counterpartColumn = props.invoiceDayFields.findIndex(field => field === "FlyingOver10h");
            } else if (props.invoiceDayFields[column] === "FlyingOver10h") {
                counterpartColumn = props.invoiceDayFields.findIndex(field => field === "FlyingBelow10h");
            }
            const counterpartCell = counterpartColumn !== null && newDayRows[row][counterpartColumn];
            if (counterpartCell) {
                counterpartCell.value = "";
            }
        }
        setRowsExplicit(newDayRows);
        pushToHistory();
    }

    function onRootKeyDown(event: React.KeyboardEvent) {
        if ((event.ctrlKey || event.metaKey) &&
            (event.key === "z" || event.key === "Z")
        ) {
            event.preventDefault();
            if (event.shiftKey) {
                if (historyStackIndexRef.current < historyStackRef.current.length - 1) {
                    ++historyStackIndexRef.current;
                    setRowsExplicit(historyStackRef.current[historyStackIndexRef.current]);
                }
            } else {
                if (historyStackIndexRef.current > 0) {
                    --historyStackIndexRef.current;
                    setRowsExplicit(historyStackRef.current[historyStackIndexRef.current]);
                }
            }
        }
    }

    const daysInMonth = getNumberOfDays(props);
    const rowLabels = Array(daysInMonth);
    for (let i = 0; i < daysInMonth; ++i) {
        rowLabels[i] = (i + 1) + " " + getMonthShortName(props.month);
    }

    return <div
        className={"work-report-input-table" + (getActiveBooleanCell() ? " active-cell-is-boolean" : "")}
        onMouseDown={onRootMouseDown}
    >
        <Spreadsheet
            columnLabels={props.invoiceDayFields.map((field) => getColumnLabel(field))}
            rowLabels={rowLabels}
            data={props.resetDayRows}
            onKeyDown={onRootKeyDown}
            onChange={dayRows => {
                const newStr = JSON.stringify(dayRows);
                const oldStr = JSON.stringify(latestDayRows.current);
                if (oldStr === newStr) {
                    return;
                }
                // this onChange gets triggered not only when changes are initiated by a user action, but also on the
                // passed props update, so this data can not be a part of state, as that would cause infinite re-render
                hasLocalChangesRef.current = true;
                latestDayRows.current = dayRows;
            }}
            onActivate={active => activeCellRef.current = active}
            onBlur={() => activeCellRef.current = null}
            onCellCommit={(prevCell, nextCell, coords) => {
                if (hasLocalChangesRef.current) {
                    hasLocalChangesRef.current = false;
                    props.onImplicitChange(latestDayRows.current);
                    pushToHistory();
                }
            }}
        />
    </div>;
}