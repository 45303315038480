import { FeeEntryType, type FeeEntryTypeRecord, type ImpersonalSalaryJournalRecord } from "../../ExternalRostersApi";
import type { LastRealActivity } from "./FeeCalculatorUtils";
import { getMeaningfulActivity } from "./FeeCalculatorUtils";
import { consideredOffPayNiaStay } from "./FeeCalculatorUtils";
import { getGroundActivityCategory } from "./FeeCalculatorUtils";
import { getNias, groupSequences, type PersonFeeCalculationParams } from "./FeeCalculatorUtils";
import type { DutyKind,DutyKindExtension,RaidoRotationDay, RotationDay } from "./RotationDaysGrouper";
import  { getLocalEndDate } from "./RotationDaysGrouper";
import { typed } from "@mhc/utils/src/typing";
import type { RosterItem } from "./Roster";

function getPersonWageContractFee(wageContractFees: FeeEntryTypeRecord[], feeEntryType: number) {
    const wageContractFee = wageContractFees.find(contract => contract.fee_entry_type === feeEntryType);
    return wageContractFee?.payment_per_unit ? wageContractFee.payment_per_unit : 0;
}

function isDutyActivity(rotationDay: RotationDay, activity: RosterItem) {
    return rotationDay.Activities.some(act => {
        return act.ActivityType === "Flight"
            || act.GNDACTCODETITLE === "LMD"
            || activity.GNDACTCODETITLE === "ODU";
    });
}

export function endsPastMidnight(rotationDay: RaidoRotationDay): boolean {
    const lastActivity = rotationDay.Activities.filter(a => a.ActivityType !== "BLANK").slice(-1)[0];
    if (!lastActivity) {
        return false;
    }
    if (lastActivity.ActivityType === "BLANK") {
        throw new Error("Assertion failed");
    }
    return getLocalEndDate(lastActivity.FullRaidoActivity) > rotationDay.Date;
}

export function countLoadMasterDutyDays(
    nias: Set<string>,
    inputRotationDays: RaidoRotationDay[]
): (RaidoRotationDay & DutyKindExtension)[] {
    const rotationDays: (RaidoRotationDay & DutyKindExtension)[] = inputRotationDays
        .map(ird => ({ ...ird, DutyKind: null }));
    let lastRealActivity: LastRealActivity = rotationDays
        .flatMap(rd => rd.Activities)
        .flatMap(r => r.ActivityType === "BLANK" ? [] : [r])
        .map(a => ({
            AirportFrom: a.AirportFrom,
            AirportTo: a.AirportFrom,
            GroundActivityCode: null,
            GroundActivityInfo: null,
            GNDACTCODETITLE: null,
            FlightId: a.FlightId,
            FullRaidoActivity: a.FullRaidoActivity,
        }))[0];
    if (!lastRealActivity) {
        for (const rotationDay of rotationDays) {
            rotationDay.DutyKind = "OFF_DUTY";
        }
        return rotationDays;
    }

    let lastDutyKind: DutyKind = "OFF_DUTY";
    for (let i = 0; i < rotationDays.length; ++i) {
        const rotationDay = rotationDays[i];
        for (const activity of rotationDay.Activities) {
            const isHomebaseDeadhead =
                activity.ActivityType === "Deadhead" && (
                    nias.has(activity.AirportTo) ||
                    nias.has(activity.AirportFrom)
                ) &&
                rotationDay.DutyKind != "TRAVEL" && (
                    !endsPastMidnight(rotationDay) ||
                    rotationDay.Activities.some(activity => activity.GNDACTCODETITLE === "VAU")
                );
            if (isHomebaseDeadhead && !isDutyActivity(rotationDay, activity)) {
                rotationDay.DutyKind = "OFF_DUTY";
                break;
            }
            lastRealActivity = getMeaningfulActivity(activity, lastDutyKind, lastRealActivity);
            const groundActivityCategory = getGroundActivityCategory(lastRealActivity);
            if (!consideredOffPayNiaStay(nias, lastRealActivity, rotationDays) &&
                !(groundActivityCategory === "ILLNESS" && lastDutyKind === "OFF_DUTY" && i > 0)
                    || groundActivityCategory === "POSITIONING"
            ) {
                lastDutyKind = "TRAVEL";
                rotationDay.DutyKind = "TRAVEL";
            } else if (groundActivityCategory === "ON_DUTY"
                    || groundActivityCategory === "TRAINING"
            ) {
                lastDutyKind = "NIA_GROUND_DUTY";
                if (rotationDay.DutyKind !== "TRAVEL" &&
                    rotationDay.DutyKind !== "DAY_SHIFT_TRAVEL_END" &&
                    rotationDay.DutyKind !== "DAY_SHIFT_TRAVEL_START"
                ) {
                    rotationDay.DutyKind = "NIA_GROUND_DUTY";
                }
            } else {
               lastDutyKind = "OFF_DUTY";
               if (!rotationDay.DutyKind) {
                    rotationDay.DutyKind = "OFF_DUTY";
               }
            }
        }
    }
    return rotationDays;
}

export function calculateFeesForLoadMasterPerson(
    person: PersonFeeCalculationParams,
    wageContractFees: FeeEntryTypeRecord[] | null
): ImpersonalSalaryJournalRecord[] {
    const journalRecords: ImpersonalSalaryJournalRecord[] = [];
    const nias = getNias(person);
    const rotationDays = countLoadMasterDutyDays(nias, person.rotationDays);
    const normalDutyDays = [];
    const perDiemDutyDays = [];
    for (const rotationDay of rotationDays) {
        if (rotationDay.DutyKind === "OFF_DUTY") {
            continue;
        } else if (rotationDay.DutyKind === "TRAVEL"
                || rotationDay.DutyKind === "DAY_SHIFT_TRAVEL_END"
                || rotationDay.DutyKind === "DAY_SHIFT_TRAVEL_START"
        ) {
            perDiemDutyDays.push(rotationDay);
        } else if (rotationDay.DutyKind === "NIA_GROUND_DUTY") {
            normalDutyDays.push(rotationDay);
        }
    }
    journalRecords.push(
        ...groupSequences(normalDutyDays).map(sequence => typed<ImpersonalSalaryJournalRecord>({
            fee_entry_type: FeeEntryType(200),
            description: "Daily Fee",
            payment_per_unit: `${wageContractFees ? getPersonWageContractFee(wageContractFees, 200).toFixed(2) : 0}`,
            service_start_time: sequence[0].Date,
            service_end_time: sequence.slice(-1)[0].Date,
            units: `${sequence.length}`,
        })),
    );
    journalRecords.push(
        ...groupSequences(perDiemDutyDays).map(sequence => typed<ImpersonalSalaryJournalRecord>({
            fee_entry_type: FeeEntryType(501),
            description: "Daily fee and Per diem",
            payment_per_unit: `${wageContractFees ? getPersonWageContractFee(wageContractFees, 501).toFixed(2) : 0}`,
            service_start_time: sequence[0].Date,
            service_end_time: sequence.slice(-1)[0].Date,
            units: `${sequence.length}`,
        }))
    );
    return journalRecords;
}