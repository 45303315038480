import { useEffect, useState } from "react";

import * as React from "react";
import type { AllCompanies } from "../features/StaticData";
import { getAllCompanies } from "../features/StaticData";
import type { CompanyName } from "@mhc/utils/types/nav";
import { brand } from "@mhc/utils/src/typing";

type CompanyFilterSelectSelectProps = {
    allowNull?: false,
    company: string,
    onChange: (companyName: CompanyName) => void,
} | {
    allowNull: true,
    company: string | null,
    onChange: (companyName: CompanyName | null) => void,
};

export default function CompanyFilterSelect(props: CompanyFilterSelectSelectProps) {
    const [ allCompanies, setAllCompanies ] = useState<AllCompanies>();

    useEffect(() => {
        getAllCompanies().then(setAllCompanies);
    }, []);

    const isAdmin = window.EMPLOYEE_INFO?.IsAdmin;
    const accessibleCompanies = new Set(window.EMPLOYEE_INFO?.ApprovalCompaniesList ?? []);

    return <label>
        <span>Company:</span>
        {allCompanies
            ? <select name="companyName" className="form-element" value={props.company ?? ""} onChange={(e) => {
                if (!props.allowNull) {
                    props.onChange(brand(e.target.value));
                } else {
                    props.onChange(e.target.value ? brand<CompanyName>(e.target.value) : null);
                }
            }}>
                {props.allowNull && <option value="">All</option>}
                {Object.values(allCompanies)
                    .filter(ac => isAdmin || ac.portalEntry && accessibleCompanies.has(ac.portalEntry.Id))
                    .map(ac => <option value={ac.COMPANYNAME} key={ac.COMPANYNAME}>
                        {ac.navEntry?.DisplayName ?? ac.COMPANYNAME}
                    </option>)}
            </select>
            : <input value={!props.allowNull ? props.company : props.company ?? ""} disabled={true} className="form-element" />}
    </label>;
}