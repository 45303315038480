import * as React from "react";
import InvoiceStatuses from "./InvoiceStatuses";
import type { InvoiceAction, ManualInvoiceDetails } from "../types/Api/Invoice";
import type EmployeeInfo from "../types/EmployeeInfo";
import type { EmployeeInfoWise } from "../types/EmployeeInfo";
import { CreateHeader, DisplayEmployeeInfoSmall, GetInvoiceStatusClass } from "../utils/Helper";
import api from "../features/Api";
import { ClipLoader } from "react-spinners";
import InvoiceRosterView from "./InvoiceRosterView";

type Props = {
    invoice: InvoiceAction,
    onCancel: () => void,
};

type State = {
    submitting: boolean,
    rejectClass?: string,
    rosterData?: ManualInvoiceDetails[],
    profileTimesheet?: EmployeeInfo | EmployeeInfoWise,
};

export default class InvoiceUserTimesheet extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            submitting: false,
        };
        api.Invoice.GetManualInvoiceDetailsById({
            invoiceId: this.props.invoice.InvoiceId,
        }).then(rosterData => this.setState({ rosterData }));

        api.Employee.LoadProfile({ employeeId: this.props.invoice.EmployeeId })
            .then(profileTimesheet => this.setState({ profileTimesheet }));
    }

    private renderRow(r: ManualInvoiceDetails, i: number, list: string[]) {
        const id = r.Id;
        let lineStyle = "";
        if (r.Status === 1) {
            lineStyle = "yellowInvoiceDetailLine";
        } else if (r.Rejected == true) {
            lineStyle = "redInvoiceDetailLine";
        } else {
            lineStyle = (Date.parse(r.ActivityDate) < new Date(this.props.invoice.Year, this.props.invoice.Month - 1, 1).getTime()) ? "blueInvoiceDetailLine" : "";
        }
        return <tr className={lineStyle} key={r.Id}>
            <td className="numberColumn">{i + 1}<a className={" row-toggler"} /></td>
            <td>{r.ActivityDateStr ? r.ActivityDateStr : ""}</td>
            <td className={(list.indexOf("Activity") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Activity</span>{r.Activity ? r.Activity : ""}</td>
            <td className={(list.indexOf("AddonFee") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Activity 2</span>{r.AddonFee ? this.formatAddonFee(r.AddonFee) : ""}</td>
            <td className={(list.indexOf("Base") > -1) ? "" : "hideMe"}><span
                className="mobile-only ">Base</span>{r.Base ? r.Base : ""}</td>
            <td className={(list.indexOf("Project") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Project</span>{r.Project ? r.Project : ""}</td>
            <td className={(list.indexOf("TimeIn") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Time In</span>{r.TimeIn1 ? r.TimeIn1 : ""}</td>
            <td className={(list.indexOf("TimeOut") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Time Out</span>{r.TimeOut1 ? r.TimeOut1 : ""}</td>
            <td className={(list.indexOf("TimeIn2") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Time In 2</span>{r.TimeIn2 ? r.TimeIn2 : ""}</td>
            <td className={(list.indexOf("TimeOut2") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Time Out 2</span>{r.TimeOut2 ? r.TimeOut2 : ""}</td>
            <td className={(list.indexOf("FlightNumber") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Flight Number</span>{r.FlightNumber ? r.FlightNumber : ""}</td>
            <td className={(list.indexOf("AircraftReg") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Aircraft Reg</span>{r.AircraftReg ? r.AircraftReg : ""}</td>
            <td className={(list.indexOf("DailyHours") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Daily Hours</span>{r.DailyHours ? r.DailyHours : ""}</td>
            <td className={(list.indexOf("Over12Hours") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Over 12Hours</span>{r.Over12Hours ? r.Over12Hours : ""}</td>
            <td className={(list.indexOf("OverTime") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Over Time</span>{r.OverTime ? r.OverTime : ""}</td>
            <td className={(list.indexOf("BlockHours") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Block Hours</span>{r.BlockHours ? r.BlockHours : ""}</td>
            <td className={(list.indexOf("AddDay") > -1) ? "" : "hideMe"}><span
                className="mobile-only">ADD. Day</span>{r.AdditionalDay ? <img src="/img/check-green.png"></img> : ""}</td>
            <td className={(list.indexOf("PerDiem") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Per diem</span>{r.PerDiem ? <img src="/img/check-green.png"></img> : ""}</td>
            <td className={(list.indexOf("PaymentONOFF") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Payment ON/OFF</span>{r.PaymentOnOff ? <img src="/img/check-green.png"></img> : ""}
            </td>
            <td className={(list.indexOf("PositioningHours") > -1) ? "" : "hideMe"}><span className="mobile-only">Position hours</span>{r.PositioningHours ? r.PositioningHours : ""}
            </td>
            <td className={(list.indexOf("Flying") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Flying</span>{r.Flying ? <img src="/img/check-green.png"></img> : ""}</td>
            <td className={(list.indexOf("SoldOffDay") > -1) ? "" : "hideMe"}><span
                className="mobile-only">Sold off day</span>{r.SoldOffDay ? <img src="/img/check-green.png"></img> : ""}</td>
            <td><input type="checkbox" checked={r.Rejected}
                       disabled={this.state.submitting}
                       onChange={(event) => this.handleReject(event, id)}></input></td>
        </tr>;
    }

    _makeTable(rosterData: ManualInvoiceDetails[]) {
        const list = this.state.profileTimesheet?.Fields?.fields.split("|") ?? [];
        const header = CreateHeader(list);
        const canApprove = this.props.invoice.Status === 1; // 1 = PendingApproval
        const rejectCaption =
            this.props.invoice.Status === 1 ? "Reject" : // 1 = PendingApproval
            this.props.invoice.Status === 4 ? "Reject Approval" : // 4 = Approved
            null;
        const records = rosterData.map((r, i) => this.renderRow(r, i, list));
        const invoice = this.props.invoice;
        const invoiceStatusClass = GetInvoiceStatusClass(invoice.Status);
        return <div className="table-responsive">
            <span className={"InvoiceStatus " + invoiceStatusClass}>{invoice.StatusStr}</span>
            <div>{status}</div>
            <table className="manual invoices table table-striped table-condensed">
                <thead>
                    {header}
                </thead>
                <tbody>

                    {records}
                </tbody>
            </table>

            <div className="user-button">
                {canApprove ?
                    <button className="btn btn-primary approveBtn"
                        disabled={this.state.submitting}
                        onClick={(e) => this.submitApprove(e)}>Approve</button>
                    : null}
                {rejectCaption ?
                    <button className="btn btn-warning rejectBtn"
                        disabled={this.state.submitting}
                        onClick={(e) => this.submitReject(e)}>{rejectCaption}</button>
                    : null}
                {this.props.invoice.StatusStr === "SubmittedForPayroll" && <button
                    type="button"
                    className="btn btn-primary approveBtn"
                    disabled={this.state.submitting}
                    onClick={e => this.addToPayroll()}
                >
                    Add to ROST_MTX Salary Journal
                </button>}
                {/*{ expired ?  <a className="btn btn-primary MarginLeft" href="#" onClick={(e) => this.Reopen}>Reopen</a>*/}
                {/*    :  null}*/}
                <button className="btn btn-primary cancelBtn" onClick={e => this.props.onCancel()}>Cancel</button>
            </div>
        </div>;
    };

    submitApprove(e: React.MouseEvent) {
        const payload = {
            year: this.props.invoice.Year,
            month: this.props.invoice.Month,
            employeeId: this.props.invoice.EmployeeId,
        };
        this.setState({ submitting: true });
        api.Invoice.SubmitManualInvoiceToNav(payload)
            .then((data) => {
                const statusPayload = {
                    invoiceId: this.props.invoice.InvoiceId,
                    invoiceStatus: 4,
                    text: "Approved",
                };
                return api.Invoice.ChangeInvoiceStatus(statusPayload)
                    .then(() => {
                        this.toastApprove();
                        this.goBack();
                    });
            })
            .finally(() => this.setState({ submitting: false }));
    };

    addToPayroll() {
        const payload = {
            InvoiceId: this.props.invoice.InvoiceId,
        };
        this.setState({ submitting: true });
        api.Invoice.AddSubmittedManualInvoiceToPayroll(payload)
            .then(() => {
                toastr.success("NAV Salary Journal records were created successfully");
                this.goBack();
            })
            .finally(() => this.setState({ submitting: false }));
    }

    async handleReject(e: React.ChangeEvent<HTMLInputElement>, id: number) {
        const checkbox = e.target;
        const isChecked = checkbox.checked;
        const payload = { id: id, rejected: isChecked };
        this.setState({ submitting: true });
        try {
            await api.Invoice.ChangeManualInvoiceLineRejected(payload);
            if (this.state.rosterData) {
                this.setState({
                    rosterData: this.state.rosterData
                        .map(invoiceDay => invoiceDay.Id !== id ? invoiceDay : {
                            ...invoiceDay, Rejected: isChecked
                        }),
                });
            }
        } finally {
            this.setState({ submitting: false });
        }
    }

    formatAddonFee(addonFee: string) {
        return addonFee.replaceAll(";", ", ");
    };

    toastApprove() {
        toastr.success("Work invoice approved.", "", {
            timeOut: 5000,
        });
    };

    toastReject() {
        toastr.warning("Work invoice rejected.", "", {
            timeOut: 5000,
        });
    };

    goBack() {
        location.href = "/Home/InvoicesUsersTimeSheets";
    };

    submitReject(e: React.MouseEvent) {
        const statusPayload = {
            invoiceId: this.props.invoice.InvoiceId,
            invoiceStatus: 3,
            text: "reject",
        };

        this.setState({ submitting: true });
        api.Invoice.ChangeInvoiceStatus(statusPayload)
            .then(() => {
                this.toastReject();
                this.goBack();
            })
            .finally(() => this.setState({ submitting: false }));
    };

    render() {
        const profileInfo = DisplayEmployeeInfoSmall({
            JobTitle: this.props.invoice.JobTitle,
            No_: this.props.invoice.EmployeeCode,
            FullName: this.props.invoice.Employee,
        });
        return <div style={{ marginTop: "18px" }}>
            {profileInfo}
            {!this.state.rosterData
                ? <div className="col-xs-12 center">
                    {<ClipLoader
                        loading={true}
                    />}
                </div>
                : <div>
                    <div>
                        {this._makeTable(this.state.rosterData)}
                    </div>
                    <div className="currentfilters">
                        <div className="comment-box">
                            <InvoiceStatuses invoiceId={this.props.invoice.InvoiceId}/>
                        </div>
                    </div>
                    <br style={{ clear: "both" }}/>
                </div>}
            {!this.state.profileTimesheet ? undefined : <InvoiceRosterView
                locator={{
                    company: this.state.profileTimesheet.CompanyName,
                    employeeCode: this.props.invoice.EmployeeCode,
                    year: this.props.invoice.Year,
                    month: this.props.invoice.Month,
                }}
            />}
        </div>;
    }
}
