import React from "react";

type Props = {
    dutyDesignator: "[P]" | "[K]" | "[D]" | string,
};

const CODE_TO_MEANING = {
    "L": "Lower Rank Assignment",
    "H": "Higher Rank Assignment",
    "D": "Positioning",
    "P": "Positioning",
    "U": "Supernumerary",
    "C": "Line Check Instructor",
    "I": "Instructor",
    "T": "Trainee",
    "N": "New crew",
    "X": "Landing pilot",
    "8": "747-400 Line Check",
    "4": "747-Classic Line Check",
    "2": "Airbus Line Check",
    "K": "PILOT IN COMMAND",
    "E": "EXTR PILOT OVER 8 HOURS SV",
    "S": "SAFETY PILOT",
    "3": "AIRBUS A330 Line Check",
    "TA": "Transport from Azzam",
    "TS": "Transport from Saudia City",
    "TN": "No Transport required",
    "LD": "Landing Trainee",
    "ET": "EXTRA SIM TRAINING",
    "A1": "AZZAM Classroom 1",
    "S1": "Simulator Recurrent Training (2013/1)",
    "34": "Cabin A340 Training",
    "M1": "MOTIARA Classroom 1",
    "A2": "AZZAM Classroom 2",
    "A3": "AZZAM GYM Classroom",
    "A4": "AZZAM Meetingroom",
    "9": "AIRBUS A340 Line Check",
    "4A": "2014 SIM Program A",
    "4B": "2014 SIM Program B",
    "5A": "2015 SIM Program A",
    "5B": "2015 SIM Program B",
    "GP": "G2 PROFICIENCY FLIGHT",
    "GT": "G2 CHECK FLIGHT",
    "ST": "SENIOR CHECK FLIGHT",
    "SP": "SENIOR PROFICIENCY FLIGHT",
    "SO": "OBSERVATION FLIGHT FOR SCCM",
    "GO": "OBSERVATION FLIGHT FOR G2",
    "AV": "Aircraft Visit",
    "9A": "2019 SIM PROGRAM A",
    "9B": "2019 SIM PROGRAM B",
    "IO": "Observation Instructor",
    "S2": "Simulator Recurrent Training (2013/2)",
    "FA": "FAM FLIGHT",
    "20": "SIM Program 20",
    "21": "SIM Program 21",
    "FS": "FFS",
    "6A": "2016 SIM PROGRAM A",
    "Y": "Line check trainee",
    "IP": "IPT",
    "LF": "LOFT",
    "ZF": "ZFTT",
    "6B": "2016 SIM PROGRAM B",
    "SI": "SIT IN",
    "AI": "ACCELERATED INITIAL TRAINING",
    "7A": "2017 SIM PROGRAM A",
    "7B": "2017 SIM PROGRAM B",
    "8A": "2018 SIM PROGRAM A",
    "8B": "2018 SIM PROGRAM B",
    "RI": "RI Route Instructor",
    "1S": "B777 SIM A",
    "2S": "B777 SIM B",
    "3S": "B777 SIM C",
    "4S": "B777 SIM D",
    "7": "777-300 Line Check",
    "26": "Recurrent - OPC 26",
    "CP": "CCM PROFICIENCY FLIGHT",
    "AS": "Assessment recruitement",
    "23": "Recurrent - OPC 23",
    "F1": "FFS 1",
    "F2": "FFS 2",
    "F3": "FFS 3",
    "F4": "FFS 4",
    "F5": "FFS 5",
    "F6": "FFS 6",
    "F7": "FFS 7",
    "F8": "FFS 8",
    "F9": "FFS 9",
    "F0": "FFS 10",
    "SK": "SKILL TEST",
    "1F": "FBS 1",
    "4F": "FBS 4",
    "2F": "FBS 2",
    "3F": "FBS 3",
    "DG": "DANGEROUS GOODS",
    "E4": "EA4 COURSE",
    "G4": "GR44 COURSE",
    "SE": "SECURITY",
    "XT": "EXTRA SESSION",
    "J1": "JOC 1",
    "24": "Recurrent - OPC 24",
    "UG": "UPGRADE FD",
    "R": "RECURRENT",
    "IN": "INITIAL TRAINING",
    "XX": "SPECIAL SIM TRAINING",
    "J2": "JOC 2",
    "PT": "Party",
    "25": "Recurrent - OPC 25",
    "22": "Recurrent - OPC 22",
    "AE": "AAE",
    "10": "AAE 747-400 Line Check",
    "RR": "BA sim #4 - Rolls Royce - BA214",
    "27": "Recurrent - OPC 27",
    "P1": "FPT SIM 1",
    "P2": "FPT SIM 2",
    "P3": "FPT SIM 3",
    "P4": "FPT SIM 4",
    "P5": "FPT SIM 5",
    "P6": "FPT SIM 6",
    "TO": "Takeoff Trainee (FO MED sectors)",
};

type DutyDesignatorCode = keyof typeof CODE_TO_MEANING;

export default class AirAtlantaDutyDesignator extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const code = this.props.dutyDesignator.replace(/^\[/, "").replace(/]$/, "");
        const title = CODE_TO_MEANING[code as DutyDesignatorCode] ?? undefined;
        return <span className="duty-designator" title={title}>{code}</span>;
    }
}