import { FeeEntryType, type FeeEntryTypeRecord, type ImpersonalSalaryJournalRecord } from "../../ExternalRostersApi";
import type { LastRealActivity } from "./FeeCalculatorUtils";
import  { getMeaningfulActivity } from "./FeeCalculatorUtils";
import  { consideredOffPayNiaStay } from "./FeeCalculatorUtils";
import  { endsPastMidnight } from "./FeeCalculatorUtils";
import  { getGroundActivityCategory } from "./FeeCalculatorUtils";
import { getNias, groupSequences, type PersonFeeCalculationParams } from "./FeeCalculatorUtils";
import type { DutiedRotationDay,DutyKind, RotationDay } from "./RotationDaysGrouper";
import { typed } from "../../../utils/typing";
import type { IataToIana } from "../../../types/utility";
import type { RosterItem } from "./Roster";

function getPersonWageContractFee(wageContractFees: FeeEntryTypeRecord[], feeEntryType: number) {
    const wageContractFee = wageContractFees.find(contract => contract.fee_entry_type === feeEntryType);
    return wageContractFee?.payment_per_unit ? wageContractFee.payment_per_unit : 0;
}

function isDutyActivity(rotationDay: RotationDay, activity: RosterItem) {
    return rotationDay.Activities.some(act => {
        return act.ActivityType === "Flight"
            || act.GNDACTCODETITLE === "LMD"
            || activity.GNDACTCODETITLE === "ODU";
    });
}

export function countLoadMasterDutyDays(
    nias: Set<string>,
    inputRotationDays: RotationDay[],
    iataToIana: IataToIana,
): DutiedRotationDay[] {
    const rotationDays: DutiedRotationDay[] = inputRotationDays
        .map(ird => ({ ...ird, DutyKind: null }));
    let lastRealActivity: LastRealActivity = rotationDays
        .flatMap(rd => rd.Activities)
        .flatMap(r => r.ActivityType === "BLANK" ? [] : [r])
        .map(a => ({
            AirportFrom: a.AirportFrom,
            AirportTo: a.AirportFrom,
            DayNumberInRotaion: -1,
            GroundActivityCode: null,
            GroundActivityInfo: null,
            GNDACTCODETITLE: null,
            FlightId: a.FlightId,
            FullRaidoActivity: a.FullRaidoActivity,
        }))[0];
    if (!lastRealActivity) {
        for (const rotationDay of rotationDays) {
            rotationDay.DutyKind = "OFF_DUTY";
        }
        return rotationDays;
    }

    let lastDutyKind: DutyKind = "OFF_DUTY";
    for (let i = 0; i < rotationDays.length; ++i) {
        const rotationDay = rotationDays[i];
        for (const activity of rotationDay.Activities) {
            const isHomebaseDeadhead =
                activity.ActivityType === "Deadhead" && (
                    nias.has(activity.AirportTo) ||
                    nias.has(activity.AirportFrom)
                ) &&
                rotationDay.DutyKind != "TRAVEL" && (
                    !endsPastMidnight(rotationDay, iataToIana) ||
                    rotationDay.Activities.some(activity => activity.GNDACTCODETITLE === "VAU")
                );
            if (isHomebaseDeadhead && !isDutyActivity(rotationDay, activity)) {
                rotationDay.DutyKind = "OFF_DUTY";
                break;
            }
            lastRealActivity = getMeaningfulActivity(activity, lastDutyKind, lastRealActivity);
            const groundActivityCategory = getGroundActivityCategory(lastRealActivity);
            if (!consideredOffPayNiaStay(nias, lastRealActivity, rotationDays) &&
                !(groundActivityCategory === "ILLNESS" && lastDutyKind === "OFF_DUTY" && i > 0)
                    || groundActivityCategory === "POSITIONING"
            ) {
                lastDutyKind = "TRAVEL";
                rotationDay.DutyKind = "TRAVEL";
            } else if (groundActivityCategory === "ON_DUTY"
                    || groundActivityCategory === "TRAINING"
            ) {
                lastDutyKind = "NIA_GROUND_DUTY";
                if (rotationDay.DutyKind !== "TRAVEL" &&
                    rotationDay.DutyKind !== "DAY_SHIFT_TRAVEL_END" &&
                    rotationDay.DutyKind !== "DAY_SHIFT_TRAVEL_START"
                ) {
                    rotationDay.DutyKind = "NIA_GROUND_DUTY";
                }
            } else {
               lastDutyKind = "OFF_DUTY";
               if (!rotationDay.DutyKind) {
                    rotationDay.DutyKind = "OFF_DUTY";
               }
            }
        }
    }
    return rotationDays;
}

export function calculateFeesForLoadMasterPerson(
    person: PersonFeeCalculationParams,
    wageContractFees: FeeEntryTypeRecord[] | null,
    iataToIana: IataToIana,
): ImpersonalSalaryJournalRecord[] {
    const journalRecords: ImpersonalSalaryJournalRecord[] = [];
    const nias = getNias(person);
    const rotationDays = countLoadMasterDutyDays(nias, person.rotationDays, iataToIana);
    const normalDutyDays = [];
    const perDiemDutyDays = [];
    for (const rotationDay of rotationDays) {
        if (rotationDay.DutyKind === "OFF_DUTY") {
            continue;
        } else if (rotationDay.DutyKind === "TRAVEL"
                || rotationDay.DutyKind === "DAY_SHIFT_TRAVEL_END"
                || rotationDay.DutyKind === "DAY_SHIFT_TRAVEL_START"
        ) {
            perDiemDutyDays.push(rotationDay);
        } else if (rotationDay.DutyKind === "NIA_GROUND_DUTY") {
            normalDutyDays.push(rotationDay);
        }
    }
    journalRecords.push(
        ...groupSequences(normalDutyDays).map(sequence => typed<ImpersonalSalaryJournalRecord>({
            fee_entry_type: FeeEntryType(200),
            description: "Daily Fee",
            payment_per_unit: `${wageContractFees ? getPersonWageContractFee(wageContractFees, 200).toFixed(2) : 0}`,
            service_start_time: sequence[0].Date,
            service_end_time: sequence.slice(-1)[0].Date,
            units: `${sequence.length}`,
        })),
    );
    journalRecords.push(
        ...groupSequences(perDiemDutyDays).map(sequence => typed<ImpersonalSalaryJournalRecord>({
            fee_entry_type: FeeEntryType(501),
            description: "Daily fee and Per diem",
            payment_per_unit: `${wageContractFees ? getPersonWageContractFee(wageContractFees, 501).toFixed(2) : 0}`,
            service_start_time: sequence[0].Date,
            service_end_time: sequence.slice(-1)[0].Date,
            units: `${sequence.length}`,
        }))
    );
    return journalRecords;
}