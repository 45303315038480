import React from "react";
import type { NavInvoiceScreenParams } from "../features/Api";
import api from "../features/Api";
import type { LeonPersonMonthRoster, LeonSegment } from "../types/Api/Roster/Leon/LeonPersonMonthRoster";
import { getMonthName, getNumberOfDays } from "../utils/dates";
import type { Duty } from "../types/Api/Roster/Leon/Duty";
import type { ActivityIconProps } from "./ActivityIcon";
import ActivityIcon from "./ActivityIcon";
import "./InvoiceRosterViewLeonGraphql.css";
import NavJournalRecordsTable from "./NavJournalRecordsTable";
import { toCOMPANYNAMEUC } from "../utils/typing";

type Day = {
    date: string,
    personDuties: Duty[],
    /** segments starting on this day, including ones that end on the following day */
    segments: LeonSegment[],
};

function formatBlockHours(seconds: number) {
    return seconds === 0 ? "" : (seconds / 3600).toFixed(3);
}

function getGroundActivityCategory(duty: Duty | undefined): ActivityIconProps["category"] {
    if (!duty) {
        return "OFF_DUTY_UNPAID";
    } else if (duty.definition.dutyDefinitionTypeEnum === "TRAINING") {
        return "TRAINING";
    } else if (duty.definition.legend.toUpperCase().includes("SICK")) {
        return "ILLNESS";
    } else if (duty.definition.name === "POS") {
        return "POSITIONING";
    } else if (duty.definition.dutyDefinitionTypeEnum === "DUTY") {
        return "ON_DUTY";
    } else if (duty.definition.dutyDefinitionTypeEnum === "OFF") {
        return "OFF_DUTY_UNPAID";
    } else {
        return "FACT";
    }
}

type Props = {
    locator: NavInvoiceScreenParams,
    onWarning: (message: string) => void,
};

type State = {
    personMonthRoster: null | LeonPersonMonthRoster,
    loading: boolean,
};

function getBlockSeconds(seg: LeonSegment): number {
    if (!("flightType" in seg)) {
        return 0;
    }
    if (seg.journeyLog) {
        return (new Date(seg.journeyLog.blonUTC).getTime() - new Date(seg.journeyLog.bloffUTC).getTime()) / 1000;
    }
    return seg.duration;
}

export default class InvoiceRosterViewLeonGraphql extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            personMonthRoster: null,
            loading: true,
        };
        api.Roster.getLeonGraphqlRoster(this.props.locator)
            .then(personMonthRoster => this.setState({ personMonthRoster }))
            .catch(error => this.props.onWarning(String(error)))
            .finally(() => this.setState({ loading: false }));
    }

    private get days() {
        const result: Day[] = [];
        const yearMonth = this.props.locator.year + "-" + (this.props.locator.month.toString()).padStart(2, "0");
        for (let i = 0; i < getNumberOfDays(this.props.locator); ++i) {
            const date = yearMonth + "-" + String(i + 1).padStart(2, "0");
            result.push({
                date: date,
                personDuties: (this.state.personMonthRoster?.personDuties ?? [])
                    .filter(dut => dut.startTimeIso.startsWith(date)),
                segments: (this.state.personMonthRoster?.segments ?? [])
                    .filter(seg => seg.startDateTime.startsWith(date)),
            });
        }
        return result;
    }

    private get totalBlockHours() {
        if (!this.state.personMonthRoster) {
            return "?";
        }
        const blockSeconds = this.state.personMonthRoster.segments
            .map(getBlockSeconds)
            .reduce((a,b) => a + b, 0);
        const blockHours = Math.floor(blockSeconds / 60 / 60);
        const minutesRemainder = Math.min(blockSeconds % 3600 / 60);
        return blockHours + ":" + String(minutesRemainder).padStart(2, "0");
    }

    private get rosterRetrievalTimeFormatted() {
        return !this.state.personMonthRoster ? "" : `retrieved on ${this.state.personMonthRoster.retrievalTime.replace(/\.\d+Z$/, "")}`;
    }

    render() {
        const journalRecords = this.state.personMonthRoster?.journalRecords;
        return <div className="invoice-roster-view-leon-graphql">
            <h3>
                <span style={{ flex: 1 }}>{this.state.personMonthRoster?.leonEntry.code}</span>
                <span>Roster from Leon</span>
                <span className="retrieved-on-badge">{this.rosterRetrievalTimeFormatted}</span>
            </h3>
            <div className="invoice-roster-view-table-wrapper">
                <table className="leon-graphql-roster-table generic-roster-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th colSpan={2}>Activity</th>
                            <th colSpan={2}>Departure</th>
                            <th colSpan={2}>Arrival</th>
                            <th>Block</th>
                            <th>Position</th>
                        </tr>
                    </thead>
                    {this.state.loading ? <tbody>
                    <tr>
                        <td className="status-loading-animated-ellipsis" colSpan={999}>Loading</td>
                    </tr>
                    </tbody> : !this.state.personMonthRoster ? <tbody>
                        <tr>
                            <td className="content-encountered-errors" colSpan={999}>Encountered errors during Roster data retrieval:</td>
                        </tr>
                    </tbody> : this.days.map((day, dayNumber) => {
                        const weekDayNumber = new Date(day.date).getDay();
                        const trs: React.ReactElement[] = [];
                        const rowsInDay = day.personDuties.length + day.segments.length;
                        let dateCellTaken = false;
                        const takeDateCell = () => {
                            if (dateCellTaken) {
                                return null;
                            } else {
                                dateCellTaken = true;
                                return <td className="alpha-numeric-field start-date-utc" data-field="StartDateUtc" rowSpan={rowsInDay}>{dayNumber + 1} {getMonthName(this.props.locator.month)}</td>;
                            }
                        };
                        for (const seg of day.segments) {
                            const isDutyFlight = "flightType" in seg;
                            const category = isDutyFlight ? "ON_DUTY" : "POSITIONING";
                            const activityType = isDutyFlight ? "Flight" : "Deadhead";
                            const position = "flightNo" in seg
                                ? seg.crewList.find(c => this.state.personMonthRoster && c.login.code === this.state.personMonthRoster.leonEntry.code)?.position
                                : null;
                            trs.push(<tr key={JSON.stringify(seg)} data-activity-type={activityType} data-ground-activity-category={category} data-week-day-number={weekDayNumber}>
                                {takeDateCell()}
                                <td><ActivityIcon activityType={activityType} category={category}/></td>
                                <td className="alpha-numeric-field">{"flightNo" in seg ? seg.flightNo : null}</td>
                                <td className="alpha-numeric-field">{seg.startAirport.code.iata}</td>
                                <td className="alpha-numeric-field">{seg.startDateTime.slice(11, 16)}</td>
                                <td className="alpha-numeric-field">{seg.endAirport.code.iata}</td>
                                <td className="alpha-numeric-field">{seg.endDateTime.slice(11, 16)}</td>
                                <td className="alpha-numeric-field" data-field="BlockTime">{formatBlockHours(getBlockSeconds(seg))}</td>
                                <td title={!position ? undefined : JSON.stringify(position, null, 4)}>{position?.name}</td>
                                <td className="pilot-note"></td>
                            </tr>);
                        }
                        for (const duty of day.personDuties) {
                            const category = duty && getGroundActivityCategory(duty);
                            trs.push(<tr data-activity-type="Ground" data-ground-activity-category={category} data-week-day-number={weekDayNumber}>
                                {takeDateCell()}
                                <td><ActivityIcon activityType="Ground" category={category}/></td>
                                <td>{duty ? duty.definition.name + (duty.definition.name === duty.definition.legend ? "" : " | " + duty.definition.legend) : null}</td>
                                <td className="alpha-numeric-field">{duty?.startAirport?.code.iata}</td>
                                <td className="alpha-numeric-field">{duty ? duty.startTimeIso.slice(11, 16) : null}</td>
                                <td className="alpha-numeric-field">{duty?.endAirport?.code.iata}</td>
                                <td className="alpha-numeric-field">{duty ? duty.endTimeIso.slice(11, 16) : null}</td>
                                <td></td>
                                <td></td>
                                <td className="pilot-note">{duty?.pilotNote ?? ""}</td>
                            </tr>);
                        }
                        return <tbody key={dayNumber} data-week-day-number={weekDayNumber}>{trs}</tbody>;
                    })}
                    <tfoot>
                        <tr>
                            <td colSpan={8} style={{ textAlign: "right" }}>Total Block Hours: </td>
                            <td className="alpha-numeric-field" data-field="BlockTime">{this.totalBlockHours}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            {!journalRecords || journalRecords.length === 0 ? undefined : <div className="preliminary-salary-journal-from-roster">
                <h4 style={{ textAlign: "center" }}>Preliminary fees calculated from Leon Roster</h4>
                <NavJournalRecordsTable calculatedPeople={[{
                    person: { navEntry: { No_: this.props.locator.employeeCode } },
                    journalRecords: journalRecords.map(jr => ({ ...jr, crew_code: this.props.locator.employeeCode })),
                }]} COMPANYNAME={toCOMPANYNAMEUC(this.props.locator.company)}/>
            </div>}
        </div>;
    }
}