import * as React from "react";
import { getMonthFullName } from "../utils/dates";

type YearAndMonthFilterProps = {
    year: number,
    month: number,
    onYearChange: (e:number) => void,
    onMonthChange: (e:number) => void,
};

export function YearAndMonthFilter(props: YearAndMonthFilterProps) {
    return <>
        <label>
            <span>Year:</span>
            <input
                className="form-element"
                type="number" step="1" min="2017" max={new Date().getUTCFullYear()}
                value={props.year}
                onChange={(e) => {
                    props.onYearChange(+e.target.value);
                }}
            />
        </label>
        <label>
            <span>Month:</span>
            <select className="form-element" value={props.month} onChange={(e) => {
                props.onMonthChange(+e.target.value);
            }}>
                {[...Array(12).keys()].map(i => <option value={i + 1} key={i}>
                    {getMonthFullName(i + 1)}
                </option>)}
            </select>
        </label>
    </>;
}