import type { CellBase,Matrix } from "react-spreadsheet";
import type { RosterWithExtraData } from "./aaiMtxXlsxWorkInvoiceFiller";
import { getIsMtx1 } from "./aaiMtxXlsxWorkInvoiceFiller";
import { fromEntries } from "@mhc/utils/src/typing";
import type { FlyingHoursStatus, AaiMtxManualInvoiceDayFromSheet } from "../../types/Api/Invoice";
import type { BaseRowData } from "./aaiMtxRosterToSheetData";
import { prepareDayRowsData } from "./aaiMtxRosterToSheetData";
import { normalizeRecordValues } from "./sheetUtils";

export function getMtx1Fields() {
    return [
        "Base",
        "TimeIn1",
        "TimeOut1",
        "DailyHours",
        "OverTime",
        "AdditionalDay",
        "FlyingBelow10h",
        "FlyingOver10h",
        "PaymentOnOff",
    ] as const;
}

export function getNormalFields() {
    return [
        "Base",
        "TimeIn1",
        "TimeOut1",
        "TimeIn2",
        "TimeOut2",
        "DailyHours",
        "OverTime",
        "AdditionalDay",
        "Flying",
        "PaymentOnOff",
    ] as const;
}

export type NormalFields = ReturnType<typeof getNormalFields>;
export type Mtx1Fields = ReturnType<typeof getMtx1Fields>;

function fillNormalRow(rosterRowData: AaiMtxManualInvoiceDayFromSheet): PlainCell[] {
    const filledRow: PlainCell[] = [];
    for (const field of getNormalFields()) {
        const rosterValue = rosterRowData[field];
        const value = rosterValue === true
            ? "X" : rosterValue || "";
        filledRow.push({ value, className: "field-name--" + field });
    }
    return filledRow;
}

function fillMtx1Row(rosterRowData: AaiMtxManualInvoiceDayFromSheet): PlainCell[] {
    const filledRow = [];
    for (const field of getMtx1Fields()) {
        let value;
        if (field === "FlyingBelow10h") {
            value = rosterRowData.FlyingHoursStatus === "OVER_10_HOURS" ? "X" : "";
        } else if (field === "FlyingOver10h") {
            value = rosterRowData.FlyingHoursStatus === "HAS_HOURS" ? "X" : "";
        } else {
            const rosterValue = rosterRowData[field];
            value = rosterValue === true
                ? "X" : rosterValue || "";
        }
        filledRow.push({ value, className: "field-name--" + field });
    }
    return filledRow;
}

function refillNormalRow(
    existingRow: (PlainCell | undefined)[],
    rosterRowData: BaseRowData
) {
    const filledRow: PlainCell[] = [];
    let i = -1;
    for (const field of getNormalFields()) {
        ++i;
        const existingValue = existingRow[i]?.value ?? "";
        let value;
        if (existingValue) {
            value = existingValue;
        } else if (!rosterRowData.PaymentOnOff) {
            value = field === "Base" ? rosterRowData.Base : "";
        } else if (field === "OverTime") {
            value = "";
        } else if (field === "Flying") {
            value = rosterRowData.BlockHours > 0 ? "X" : "";
        } else {
            const rosterValue = rosterRowData[field];
            value = rosterValue === true
                ? "X" : rosterValue || "";
        }
        filledRow.push({ value, className: "field-name--" + field });
    }
    return filledRow;
}

function refillMtx1Row(
    existingRow: (PlainCell | undefined)[],
    rosterRowData: BaseRowData
) {
    const filledRow: PlainCell[] = [];
    let i = -1;
    for (const field of getMtx1Fields()) {
        ++i;
        const existingValue = existingRow[i]?.value ?? "";
        let value;
        if (existingValue) {
            value = existingValue;
        } else if (!rosterRowData.PaymentOnOff) {
            value = field === "Base" ? rosterRowData.Base : "";
        } else if (field === "OverTime") {
            value = "";
        } else if (field === "FlyingBelow10h") {
            value = rosterRowData.BlockHours > 0 && rosterRowData.BlockHours <= 10 ? "X" : "";
        } else if (field === "FlyingOver10h") {
            value = rosterRowData.BlockHours > 10 ? "X" : "";
        } else {
            const rosterValue = rosterRowData[field];
            value = rosterValue === true
                ? "X" : rosterValue || "";
        }
        filledRow.push({ value, className: "field-name--" + field });
    }
    return filledRow;
}

export type PlainCell = CellBase<string> & { DataEditor?: undefined, DataViewer?: undefined };
export type PlainMatrix = Matrix<PlainCell>;

export function fillRowsFromRoster(dayRows: PlainMatrix, rosterWithExtraData: RosterWithExtraData): PlainMatrix {
    const isMtx1 = getIsMtx1(rosterWithExtraData.profileNav);
    const rowsData = prepareDayRowsData(rosterWithExtraData);
    const resultingRows = [...dayRows];
    for (let i = 0; i < rowsData.length; ++i) {
        if (i >= dayRows.length) {
            break;
        }
        const dayRow = dayRows[i];
        const resultingRow = isMtx1
            ? refillMtx1Row(dayRow, rowsData[i])
            : refillNormalRow(dayRow, rowsData[i]);
        resultingRows[i] = resultingRow;
    }
    return resultingRows;
}

export function fillRowFromData(details: AaiMtxManualInvoiceDayFromSheet, isMtx1: boolean): PlainCell[] {
    return isMtx1
        ? fillMtx1Row(details)
        : fillNormalRow(details);
}

export function parseRow(
    dayRow: (CellBase | undefined)[],
    fields: NormalFields | Mtx1Fields,
    ActivityDate: string
): AaiMtxManualInvoiceDayFromSheet {
    const existingRow: Record<(NormalFields | Mtx1Fields)[number], string> = fromEntries(
        fields.map((field, i) => [field, String(dayRow[i]?.value ?? "")])
    );
    const { FlyingOver10h, FlyingBelow10h, Flying, ...rest } = existingRow;
    let FlyingHoursStatus: FlyingHoursStatus;
    if (FlyingOver10h) {
        FlyingHoursStatus = "OVER_10_HOURS";
    } else if (FlyingBelow10h) {
        FlyingHoursStatus = "HAS_HOURS";
    } else if (Flying) {
        FlyingHoursStatus = "HAS_HOURS";
    } else {
        FlyingHoursStatus = "NO_HOURS";
    }
    const record = normalizeRecordValues(rest);
    return {
        ...record,
        Flying: FlyingHoursStatus !== "NO_HOURS",
        FlyingHoursStatus: FlyingHoursStatus,
        ActivityDate: ActivityDate,
    };
}