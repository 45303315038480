import type { ClaimAction } from "../types/Claim";
import { getDiffDays } from "../utils/Helper";

export function getSoeClaimStatusClass(status: ClaimAction["Status"]) {
    let claimStatusClass = "label ";
    switch (status) {
        case 0 :
            claimStatusClass += "label-primary";
            break;
        case 1 :
            claimStatusClass += "label-success";
            break;
        case 2 :
            claimStatusClass += "label-danger";
            break;
        case 3 :
            claimStatusClass += "label-info";
            break;
        case 6 :
            claimStatusClass += "label-success";
            break;
        default:
            claimStatusClass += "label-uncategorized";
            break;
    }
    return claimStatusClass;
}

export function getLatestComment(claimAction: ClaimAction): string | null {
    const comments = claimAction.Comments || claimAction.Description;
    if (comments !== null) {
        const splitComments = comments.split("\n");
        if (splitComments.length > 1) {
            return splitComments[0];
        }
        return comments;
    }

    return null;
}

export function getClaimDateClass(date: string): string | undefined {
    let claimDateClass;

    if (getDiffDays(new Date(), new Date(date)) > 90) {
        claimDateClass = "label label-warning";
    }

    return claimDateClass;
}

type ClaimStatusData = {
    Status: ClaimAction["Status"],
    StatusStr: ClaimAction["StatusStr"],
};

export function isApproved(claimAction: ClaimStatusData) {
    return claimAction.Status === 1
        || claimAction.Status === 4
        || claimAction.Status === 5
        || claimAction.Status === 6;
}

export function normalizeSoeStatusFor3rdParty(claimAction: ClaimStatusData) {
    if (isApproved(claimAction)) {
        // make all stages after approval appear as "Approved" to approvers
        // since they don't need to bother about the SoE from that point
        // and contractors only get confused when we show them AddedToPayroll
        claimAction.Status = 1;
        claimAction.StatusStr = "Approved";
    }
}