
import {loadProfile} from "../features/Profile";

let React = require('react');
import {connect, Provider} from "react-redux";

let _ = require('lodash');

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadProfile: (payload) => dispatch(loadProfile(payload)),
  }
};

class Profile extends React.Component {
  //displayName: 'Profile',

  constructor(props) {
    super(props);
    this.state = {
      madeFirstCall: false
    };
  };


  componentDidMount() {
    const payload = {
      id: this.props.employeeId,
      code: this.props.code
    }
    this.props.loadProfile(payload).unwrap()
      .then(() => {
        this.setState({loading: false});
      })
      .catch(() => {
      })

    if (!this.state.madeFirstCall)
      this.setState({
        madeFirstCall: true
      });
  };

  _getField(value, label) {
    const type = this._getFieldType(label);
    if (this._isVisibleField(label)) {
      switch (type) {
        // case "image": return this._getPicture(value, label); //TODO: Tók út á meðan ekki er vitað virkina
        case "text":
          return this._getTextSpanField(value, label);
        case "date":
          return this._getTextSpanField(this._getDateStr(value), label);
        // case "text": return this._getTextInputField(value, label);
        // case "date": return this._getTextInputField(this._getDateStr(value), label);
        default:
          return null;
      }
    }
    return null;
  };

  _getDateStr(value) {
    const date = new Date(Date.parse(value));
    return !this._isDefaultDate(date) ? date.toLocaleDateString("is-IS") : "";
  };

  _isDefaultDate(value) {
    return value.getFullYear() == 1753 && value.getMonth() == 0 && value.getDate() == 1;
  };

  _getFieldType(property) {
    const match = _.find(this._getFieldsDictionary(), {property: property});
    if (match) return match.type;
    return "text";
  };

  _isVisibleField(property) {
    const match = _.find(this._getFieldsDictionary(), {property: property});
    if (match) return match.visible;
    return false;
  };

  _getTextInputField(value, label) {

    return (<div className="form-group">
      <label className="col-sm-3 control-label" htmlFor={"txt" + label}>{this._mapPropertyToLabel(label)}</label>
      <div className="col-sm-9">
        <input type="text" className="form-control" id={"txt" + label} key={"txt" + label} value={value ? value : ""}/>
      </div>
    </div>);
  };

  _getTextSpanField(value, label) {

    return (<div className="form-group">
      <label className="col-sm-3 control-label" htmlFor={"txt" + label}>{this._mapPropertyToLabel(label)}</label>
      <div className="col-sm-9">
        <span type="text" className="form-control noBorder" id={"txt" + label} key={"txt" + label}
              value={value ? value : ""}>{value}</span>
      </div>
    </div>);
  };

  _mapPropertyToLabel(property) {
    const match = _.find(this._getFieldsDictionary(), {property: property});
    if (match) return match.label;
    return property;
  };

  _getSectionName(property) {
    const match = _.find(this._getFieldsDictionary(), {property: property});
    if (match) return match.section;
    return null;
  };

  _getSectionMarkup(controls, sectionName) {
    return (<div className="section" key={sectionName}>
      <div className="title">{sectionName}</div>
      <div>{controls}</div>
    </div>);
  };

  _getSections(profile) {
    const sectionNames = _.map(_.uniqBy(this._getFieldsDictionary(), "section"), "section");

    const that = this;

    const sections = [];

    _.forEach(sectionNames, function (sectionName) {
      const controls = [];

      _.forOwn(profile, function (value, key) {
        if (that._getSectionName(key) == sectionName) {
          const control = that._getField(value, key);
          if (control != null) controls.push(control);
        }
      });
      if (sectionName != '')
        sections.push(that._getSectionMarkup(controls, sectionName));
    });

    return sections;
  };

  _getFieldsDictionary() {
    return [
      {property: "Picture", type: "image", section: "Personal information", visible: true, label: "Picture"},

      {property: "Username", type: "text", section: "", visible: false, label: "Username"},
      {property: "Password", type: "text", section: "", visible: false, label: "Password"},

      {property: "No_", type: "text", section: "Personal information", visible: true, label: "3LT Code"},
      {property: "FirstName", type: "text", section: "Personal information", visible: true, label: "First Name"},
      {property: "LocalName", type: "text", section: "Personal information", visible: true, label: "First Name"},
      {property: "MiddleName", type: "text", section: "Personal information", visible: true, label: "Middle Name"},
      {property: "LastName", type: "text", section: "Personal information", visible: true, label: "Last Name"},
      {property: "FullName", type: "text", section: "Personal information", visible: true, label: "Full Name"},
      {property: "BirhtDate", type: "date", section: "Personal information", visible: true, label: "Birth Date"},
      {property: "Gender", type: "radio", section: "Personal information", visible: true, label: "Gender"},
      {property: "JobTitle", type: "text", section: "Personal information", visible: true, label: "Job Title"},
      {property: "CompanyDisplayName", type: "text", section: "Personal information", visible: true, label: "Company"},
      {
        property: "EmplymtContractCode2",
        type: "text",
        section: "Personal information",
        visible: true,
        label: "Employment Contract Code (alt)"
      },
      {property: "Address", type: "text", section: "Personal information", visible: true, label: "Address"},
      {property: "Address2", type: "text", section: "Personal information", visible: true, label: "Address (cont)"},
      {property: "City", type: "text", section: "Personal information", visible: true, label: "City"},
      {property: "PostCode", type: "text", section: "Personal information", visible: true, label: "Postal code"},
      {property: "County", type: "text", section: "Personal information", visible: true, label: "County"},
      {property: "PhoneNo", type: "phone", section: "Personal information", visible: true, label: "Phone Number"},
      {
        property: "MobilePhoneno",
        type: "phone",
        section: "Personal information",
        visible: true,
        label: "Mobile Phone Number"
      },
      {property: "EMail", type: "email", section: "Personal information", visible: true, label: "Email"},
      {
        property: "AltAddressCode",
        type: "text",
        section: "Personal information",
        visible: true,
        label: "Alt. Address Code"
      },
      {
        property: "AltAddressStartDate",
        type: "date",
        section: "Personal information",
        visible: true,
        label: "Alt. Address Start Date"
      },
      {
        property: "AltAddressEndDate",
        type: "date",
        section: "Personal information",
        visible: true,
        label: "Alt. Address End Date"
      },
      {property: "CountryCode", type: "ddl", section: "Personal information", visible: true, label: "Country Code"},
      {property: "SkypeAddress", type: "text", section: "Personal information", visible: true, label: "Skype ID"},
      {
        property: "MobilePhone2",
        type: "phone",
        section: "Personal information",
        visible: true,
        label: "Mobile Phone (alt)"
      },
      {property: "EMail2", type: "email", section: "Personal information", visible: true, label: "Email (alt)"},

      {
        property: "EmploymentDate",
        type: "date",
        section: "Employment information",
        visible: true,
        label: "Employment Date"
      },
      {property: "Status", type: "text", section: "Employment information", visible: true, label: "Status"},
      {
        property: "InactiveDate",
        type: "date",
        section: "Employment information",
        visible: true,
        label: "Inactive Date"
      },
      {
        property: "CompanyEMail",
        type: "email",
        section: "Employment information",
        visible: true,
        label: "Company Email"
      },
      {property: "IdNumber", type: "text", section: "Employment information", visible: true, label: "ID Number"},
      {
        property: "NearestIntAirport",
        type: "text",
        section: "Employment information",
        visible: true,
        label: "Nearest Intl Airport"
      },

      {property: "Nationality", type: "ddl", section: "Passport details", visible: true, label: "Nationality"},
      {property: "PassportNumber", type: "text", section: "Passport details", visible: true, label: "Passport Number"},
      {property: "DateOfIssue", type: "date", section: "Passport details", visible: true, label: "Date of Issue"},
      {property: "PlaceOfIssue", type: "text", section: "Passport details", visible: true, label: "Place of Issue"},
      {property: "Expires", type: "date", section: "Passport details", visible: true, label: "Expires"},
      {property: "PlaceOfBirth", type: "text", section: "Passport details", visible: true, label: "Place of Birth"},
      {property: "Religion", type: "text", section: "Passport details", visible: true, label: "Religion"},
      {
        property: "SecondNationality",
        type: "ddl",
        section: "Passport details",
        visible: true,
        label: "Second Nationality"
      },
      {property: "PassportNo2", type: "text", section: "Passport details", visible: true, label: "Passport (alt)"},
      {
        property: "DateOfIssue2",
        type: "date",
        section: "Passport details",
        visible: true,
        label: "Date of Issue (alt)"
      },
      {
        property: "PlaceOfIssue2",
        type: "text",
        section: "Passport details",
        visible: true,
        label: "Place of Issue (alt)"
      },
      {property: "Expires2", type: "date", section: "Passport details", visible: true, label: "Expires (alt)"},

      {
        property: "EmergencyContact",
        type: "text",
        section: "Emergency contact information",
        visible: true,
        label: "Emergency Contact"
      },
      {
        property: "EmergenceAddress",
        type: "text",
        section: "Emergency contact information",
        visible: true,
        label: "Emergency Address"
      },
      {
        property: "EmergencyPhone",
        type: "phone",
        section: "Emergency contact information",
        visible: true,
        label: "Emergency Phone"
      },
      {
        property: "EmergencyEMail",
        type: "email",
        section: "Emergency contact information",
        visible: true,
        label: "Emergency Email"
      },
      {
        property: "EmergencyMobilePhoneNo",
        type: "phone",
        section: "Emergency contact information",
        visible: true,
        label: "Emergency Mobile Phone Number"
      },
      {
        property: "PermanentAddressComment",
        type: "text",
        section: "Emergency contact information",
        visible: true,
        label: "Permanent Address Comment"
      },
      {
        property: "EmergencyContactInfoComment",
        type: "text",
        section: "Emergency contact information",
        visible: true,
        label: "Emergency Contact Info Comment"
      },

      {property: "Id", type: "text", section: "", visible: false, label: "ID"}
    ];
  };

  render() {
    const profile = this.props.profile.profile;
    let result;

    if (!this.state.madeFirstCall) {
      result = (<h1 className="loading status-loading-animated-ellipsis">Loading profile data</h1>);
    } else if (!_.isEmpty(profile)) {
      const sections = this._getSections(profile);

      const style = {display: "none"};

      result = (<form className="form-horizontal employee-details">
        {sections}
        <div style={style}>
          <div className="accept-terms">
            <input type="checkbox" id="cbAccept"/>
            <label htmlFor="cbAccept">I accept that this information will be given to client's travel agent</label>
          </div>
          <div>
            <a className="btn btn-default">Cancel</a>
            <a className="btn btn-primary">Save</a>
          </div>
        </div>
      </form>);
    } else {
      result = (<div>No data</div>);
    }

    return (<div className="profile-wrapper">
      <div className="profile-header">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h1>My profile</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            {result}
          </div>
        </div>
      </div>
    </div>);
  }
}

const wrapper = (props) => {
  return <Provider store={window.store}>{<Profile {...props}/>}</Provider>;
}
module.exports = connect(
  mapStateToProps,
  mapDispatchToProps
)(wrapper);
