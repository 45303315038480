import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getJson, postForSuccessStatus } from "./httpUtils";

export const getAllDeadlineDays = createAsyncThunk(
  "invoices/getAllDeadlineDays",
  async (companyId) => {
    const deadlineDays = await getJson("/api/invoice/getalldeadlinedays?companyId=" + companyId);
    return deadlineDays;
  }
);

export const addNewDeadline = createAsyncThunk(
  "invoices/addNewDeadline",
  async (deadline) => {
      await postForSuccessStatus("/api/invoice/addnewdeadline", deadline);
  }
);

export const updateDeadlines = createAsyncThunk(
  "invoices/updateDeadlines",
  async (deadlines) => {
      await postForSuccessStatus("/api/invoice/updatedeadlines?deadlines=" + deadlines, deadlines);
  }
);

export const invoiceSlice = createSlice({
  name: "invoice",
  /** @type {InvoiceReducerState} */
  initialState: {
    invoice: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
      builder.addCase(getAllDeadlineDays.pending, (state, action) => {
        state.loading = true;
      }).addCase(getAllDeadlineDays.fulfilled, (state, action) => {
        state.loading = false;
      }).addCase(getAllDeadlineDays.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export default invoiceSlice.reducer;
