import React from "react";
import ManualInvoiceDetailsTable from "../ManualInvoiceDetailsTable";
import type { ManualInvoiceSheetDataOut, NavInvoiceScreenParams } from "../../features/Api";
import api from "../../features/Api";
import type InvoiceComment from "../../types/Api/InvoiceComment";
import StatusComment from "../StatusComment";
import NavJournalRecordsTable from "../NavJournalRecordsTable";
import FeeStatementDownloadReportButton from "../../components/fee-statement-invoice-confirmation-components/FeeStatementDownloadReportButton";
import { toCOMPANYNAMEUC } from "@mhc/utils/types/nav";

type Props = {
    locator: NavInvoiceScreenParams,
};

type State = {
    /** relevant for AAI MTX who submits rosters as .xlsx sheets */
    sheetData: ManualInvoiceSheetDataOut | null,
    statusComments: InvoiceComment[] | null,
};

export default class FeeStatementInvoiceConfirmationSheetDataView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            sheetData: null,
            statusComments: null,
        };
        api.Invoice.GetManualInvoiceByNavLocator(this.props.locator)
            .then(async sheetData => {
                if (!sheetData) {
                    return;
                }
                this.setState({ sheetData });
                const statusComments = await api.Invoice.GetInvoiceStatusHistory({ id: sheetData.InvoiceId });
                this.setState({ statusComments });
            });
    }

    render() {
        return this.state.sheetData && (this.state.sheetData.InvoiceDays.length > 0 || this.state.sheetData.CommentBox) && <div>
            <h3>Submitted Work Report Sheet</h3>
            <FeeStatementDownloadReportButton
                commentBox={this.state.sheetData.CommentBox}
                rosterData={this.state.sheetData.InvoiceDays}
                locator={this.props.locator}
            />
            {this.state.sheetData.CommentBox && <div className="invoice-message-panel">
                <div className="invoice-message-label">Invoice Message Box</div>
                <div className="invoice-message-value">{this.state.sheetData.CommentBox}</div>
            </div>}
            <ManualInvoiceDetailsTable
                canEdit={false}
                rosterData={this.state.sheetData.InvoiceDays}
                month={this.props.locator.month}
                year={this.props.locator.year}
                displayedFieldsList={[]}
                editRow={() => alert("Read Only!")}
            />
            <div className="status-comments-list">
                {(this.state.statusComments ?? []).map(comment => <StatusComment comment={comment} key={comment.Id}/>)}
            </div>
            {this.state.sheetData.CalculatedJournalRecords && <div className="preliminary-salary-journal-from-roster">
                <h3>Preliminary Fees Calculation (Informational)</h3>
                <NavJournalRecordsTable calculatedPeople={[{
                    person: {
                        navEntry: {
                            No_: this.props.locator.employeeCode,
                        },
                    },
                    journalRecords: this.state.sheetData.CalculatedJournalRecords,
                }]} COMPANYNAME={toCOMPANYNAMEUC(this.props.locator.company)}/>
            </div>}
        </div>;
    }
}