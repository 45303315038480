
import * as React from "react";

export default class ErrorPage extends React.Component {
  constructor(props: {}) {
    super(props);
    this.state = {};
  };

   render() {
      return  <div className="errorPageContainer"><img className="forbidden" src="/img/forbiddenicon.png" alt="forbidden"/>
        <div className="passwordRequestParagraph">You are not authorized <br/> <br/> </div><div className="passwordRequestParagraph">It seems like you don't have permission to view this page. <br/> Please sign in with a different account. <br/> <br/> </div> <a className="errorPageParagraph" href="/Home/ManualInvoice">Go to front page</a></div>;
   }
}