import React, { useEffect, useState } from "react";
import type { ManualInvoiceDetails } from "../../types/Api/Invoice";
import { getFormattedDate } from "../../utils/feeStatementInvoiceUtils";
import { getFieldTitle } from "../ManualInvoiceDetailsTable";
import { getFieldsToDisplay, isEmptyValue } from "../../utils/manualInvoiceUtils";
import type { NavInvoiceLocator } from "../../features/Api";
import type { Column, Style } from "exceljs";
import { IsolatedReact } from "../../utils/IsolatedReact";

type FeeStatementDownloadReportButtonType = {
    commentBox: string,
    rosterData: ManualInvoiceDetails[],
    locator: NavInvoiceLocator,
};

function getDisplayedFieldsList(rosterData: ManualInvoiceDetails[]) {
    return getFieldsToDisplay(rosterData);
}

export default function FeeStatementDownloadReportButton({ rosterData, commentBox, locator }: FeeStatementDownloadReportButtonType) {
    const [downloadLink, setDownloadLink] = useState<string>();

    useEffect(() => {
        getManualInvoiceFeeStatementDownloadLink(rosterData).then(link => {
            setDownloadLink(link);
        });
    }, [rosterData]);

    const displayedFieldsList = IsolatedReact.useMemo(
        getDisplayedFieldsList, [rosterData]
    );

    const formatValue = (r: ManualInvoiceDetails, field: keyof ManualInvoiceDetails) => {
        if (isEmptyValue(r, field)) {
            return "";
        } else if (field === "AddonFee") {
            return r[field].replaceAll(";", ", ");
        } else if (r[field] == true) {
            return "x";
        } else {
            return r[field];
        }
    };

    const getColumnWidth = (field: keyof ManualInvoiceDetails) => {
        if (field === "Base") {
            return 33;
        } else if (field === "FlyingHoursStatus") {
            return 19;
        } else {
            return 12;
        }
    };

    const getColumnStyle = (field: keyof ManualInvoiceDetails): Partial<Style> => {
        return { alignment: { horizontal: field === "Base" ? "left" : "center" } };
    };

    const getManualInvoiceFeeStatementDownloadLink = async (records: ManualInvoiceDetails[]) => {
        const ExcelJS = await window.WHEN_EXCELJS;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Submitted Work Report Sheet");

        const workSheetColumns: Partial<Column>[] = [];
        workSheetColumns.push({ header: "Activity Date", key: "ActivityDate", width: 26, style: getColumnStyle("ActivityDate") });
        for (const displayedField of displayedFieldsList) {
            workSheetColumns.push({ header: getFieldTitle(displayedField), key: displayedField, width: getColumnWidth(displayedField), style: getColumnStyle(displayedField) });
        }
        worksheet.columns = workSheetColumns;

        for (const record of records) {
            let row = { ActivityDate: getFormattedDate(new Date(record.ActivityDate)) };
            for (const displayField of displayedFieldsList) {
                row = {
                    ...row,
                    [displayField]: formatValue(record, displayField),
                };
            }
            worksheet.addRow(row);
        }

        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true };
        });
        worksheet.eachRow((row, rowNumber) => {
            if (rowNumber > 1 && rowNumber % 2 === 0) {
                row.eachCell((cell) => {
                    cell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "ffedf0f5" }
                    };
                });
            }
        });

        if (commentBox) {
            const endColumnIdx = worksheet.columnCount;

            const commentBoxTitleRowIndex = worksheet.rowCount + 1;
            const commentBoxTitleCell = worksheet.getCell(commentBoxTitleRowIndex, 1);
            worksheet.mergeCells(commentBoxTitleRowIndex, 1, (commentBoxTitleRowIndex), endColumnIdx);
            commentBoxTitleCell.value = "Invoice Message Box";

            const commentBoxTextRowIndex = worksheet.rowCount + 2;
            const commentBoxCell = worksheet.getCell(commentBoxTextRowIndex, 1);
            worksheet.mergeCells(commentBoxTextRowIndex, 1, (commentBoxTextRowIndex + 1), endColumnIdx);
            commentBoxCell.alignment = { vertical: "middle", wrapText: true };
            commentBoxCell.value = commentBox;
        }

        const filled = await workbook.xlsx.writeBuffer();
        const blob = new Blob([filled], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        return URL.createObjectURL(blob);
    };

    return <div className="download-link-wrapper">
        <a href={downloadLink} download={`Work Report Sheet ${locator.employeeCode} ${locator.company} ${locator.month}-${locator.year}`}>Download</a>
    </div>;
}