import * as React from "react";
import { stringifyError } from "../utils/errorHandling";
import type EmployeeInfo from "../types/EmployeeInfo";
import type { InvoiceAction, ManualInvoiceDetails } from "../types/Api/Invoice";
import api from "../features/Api";
import StatusComment from "./StatusComment";
import type InvoiceComment from "../types/Api/InvoiceComment";
import { getMonthFullName } from "@mhc/utils/src/dates";
import { CreateHeader, DisplayEmployeeInfoSmall, GetInvoiceStatusClass } from "../utils/Helper";

export type ViewWorkInvoiceProps = {
    invoice: InvoiceAction,
    isDeadlineNotPassed: boolean,
    onStatusSubmit: () => void,
    onRequestClose: () => void,
};

type ViewWorkInvoiceState = {
    rejectClass: string,
    rejectText: string,
    loading: boolean,
    codeToActivityName: Record<string, string>,
    rosterData?: ManualInvoiceDetails[],
    profileTimesheet?: EmployeeInfo,
    StatusHistory?: InvoiceComment[],
};

export default class ApproveWorkInvoice extends React.Component<ViewWorkInvoiceProps, ViewWorkInvoiceState> {
    constructor(props: ViewWorkInvoiceProps) {
        super(props);

        this.state = {
            rejectClass: "",
            rejectText: "",
            loading: true,
            codeToActivityName: {},
        };

        this.updateRoasterData();

        api.Employee.GetEmployeeById({ employeeId: this.props.invoice.EmployeeId })
            .then(originalPromiseResult => {
                this.setState({
                    profileTimesheet: {
                        ...originalPromiseResult,
                        JobTitle: this.props.invoice.JobTitle,
                    },
                });
            });

        api.Invoice.GetInvoiceStatusHistory({ id: this.props.invoice.InvoiceId })
            .then((originalPromiseResult: InvoiceComment[]) => {
                this.setState({
                    StatusHistory: originalPromiseResult,
                    loading: false,

                });
            }).catch((error: unknown) => {
            this.setState({
                loading: false,
            });
        });

        api.Activity.GetActivitiesWise().then(entries => this.setState({
            codeToActivityName: Object.fromEntries(entries.map(e => [e.Code, e.Name])),
        }));
    }

    updateRoasterData() {
        api.Invoice.GetManualInvoiceDetailsById({
            invoiceId: this.props.invoice.InvoiceId,
        }).then(rosterData => this.setState({ rosterData }));
    }

    hasBeenApproved() {
        return this.props.invoice.Status === 4;
    }

    async handleActivityReject(e: React.ChangeEvent<HTMLInputElement>, id: number) {
        const isChecked = e.target.checked;
        const payload = {
            id: id,
            rejected: isChecked
        };
        await api.Invoice.ChangeManualInvoiceLineRejected(payload);
        this.updateRoasterData();
    }

    handleRejectTextChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        if (e.target.value !== "") {
            this.setState({
                rejectClass: ""
            });
        }
        this.setState({
            rejectText: e.target.value
        });
    };

    async changeInvoiceStatus(id: number, status: number, text: string) {
        const payload = {
            invoiceId: id,
            invoiceStatus: status,
            text: text
        };
        await api.Invoice.ChangeInvoiceStatus(payload);
        this.setState({ loading: false, rejectText: "", rejectClass: "" });
        status === 3 ? this.toastReject() : this.toastApprove();

        this.props.onStatusSubmit();
    }

    async submitApprove() {
        const id = this.props.invoice.InvoiceId;
        const employeeId = this.props.invoice.EmployeeId;
        const month = this.props.invoice.Month;
        const year = this.props.invoice.Year;
        const payload = {
            employeeId: employeeId,
            month: month,
            year: year,
        };
        this.setState({ loading: true });
        try {
            await api.Invoice.SubmitManualInvoiceToNav(payload);
            await this.changeInvoiceStatus(id, 4, this.state.rejectText !== "" ? this.state.rejectText : "Approved");
        } catch (error) {
            window.toastr.error(stringifyError(error));
        } finally {
            this.setState({ loading: false });
        }
    };

    async submitReject() {
        if (this.state.rejectText === "") {
            this.setState({ rejectClass: "missing" });
            return;
        }
        const id = this.props.invoice.InvoiceId;
        this.setState({ loading: true });
        try {
            await this.changeInvoiceStatus(id, 3, this.state.rejectText);
        } finally {
            this.setState({ loading: false });
        }
    };

    toastApprove() {
        toastr.success("Work invoice approved.", "", {
            timeOut: 5000,
        });
    };

    toastReject() {
        toastr.warning("Work invoice rejected.", "", {
            timeOut: 5000,
        });
    };

    render() {
        const statusComments = this.state.StatusHistory && this.state.StatusHistory.length > 0 ? this.state.StatusHistory.map((comment, index) => {
            return <StatusComment comment={comment} key={index}/>;
        }) : [];

        if (!this.state.rosterData || !this.state.profileTimesheet) {
            return "Loading...";
        }

        let list: string[] = [];
        if (this.state.profileTimesheet.Fields) {
            list = this.state.profileTimesheet.Fields.fields.split("|");
        }
        const header = CreateHeader(list);

        let rowNum = 0;
        const records = this.state.rosterData.map((r) => {
            const id = r.Id;
            let lineStyle = "";
            if (r.Status === 1) {
                lineStyle = "yellowInvoiceDetailLine";
            } else if (r.Rejected == true) {
                lineStyle = "redInvoiceDetailLine";
            } else {
                lineStyle = (Date.parse(r.ActivityDate) < new Date(Number(this.props.invoice.Year), Number(this.props.invoice.Month) - 1, 1).getTime()) ? "blueInvoiceDetailLine" : "";
            }
            rowNum += 1;
            return (<tr key={r.Id} className={lineStyle/*r.AddedInReject !== null ? r.AddedInReject : false*/}>
                <td className="numberColumn">{rowNum}<a className={" row-toggler"}/></td>
                <td>{r.ActivityDateStr ? r.ActivityDateStr : ""}</td>
                <td className={(list.indexOf("Activity") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Activity</span>{r.Activity ? this.state.codeToActivityName[r.Activity] ?? r.Activity : ""}</td>
                <td className={(list.indexOf("AddonFee") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Activity 2</span>{r.AddonFee ? this.state.codeToActivityName[r.AddonFee] ?? r.AddonFee : ""}</td>
                <td className={(list.indexOf("Base") > -1) ? "" : "hideMe"}><span
                    className="mobile-only ">Base</span>{r.Base ? r.Base : ""}</td>
                <td className={(list.indexOf("Project") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Project</span>{r.Project ? r.Project : ""}</td>
                <td className={(list.indexOf("TimeIn") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Time In</span>{r.TimeIn1 ? r.TimeIn1 : ""}</td>
                <td className={(list.indexOf("TimeOut") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Time Out</span>{r.TimeOut1 ? r.TimeOut1 : ""}</td>
                <td className={(list.indexOf("TimeIn2") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Time In 2</span>{r.TimeIn2 ? r.TimeIn2 : ""}</td>
                <td className={(list.indexOf("TimeOut2") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Time Out 2</span>{r.TimeOut2 ? r.TimeOut2 : ""}</td>
                <td className={(list.indexOf("FlightNumber") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Flight number</span>{r.FlightNumber ? r.FlightNumber : ""}</td>
                <td className={(list.indexOf("AircraftReg") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Aircraft Reg</span>{r.AircraftReg ? r.AircraftReg : ""}</td>
                <td className={(list.indexOf("DailyHours") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Daily Hours</span>{r.DailyHours ? r.DailyHours : ""}</td>
                <td className={(list.indexOf("Over12Hours") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Over 12Hours</span>{r.Over12Hours ? r.Over12Hours : ""}</td>
                <td className={(list.indexOf("OverTime") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Over Time</span>{r.OverTime ? r.OverTime : ""}</td>
                <td className={(list.indexOf("BlockHours") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Block Hours</span>{r.BlockHours ? r.BlockHours : ""}</td>
                <td className={(list.indexOf("AddDay") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">ADD. Day</span>{r.AdditionalDay ? <img src="/img/check-green.png"/> : ""}</td>
                <td className={(list.indexOf("PerDiem") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Per Diem</span>{r.PerDiem ? <img src="/img/check-green.png"/> : ""}</td>
                <td className={(list.indexOf("PaymentONOFF") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Payment ON/OFF</span>{r.PaymentOnOff ? <img src="/img/check-green.png"/> : ""}
                </td>
                <td className={(list.indexOf("PositioningHours") > -1) ? "" : "hideMe"}><span className="mobile-only">Position hours</span>{r.PositioningHours ? r.PositioningHours : ""}
                </td>
                <td className={(list.indexOf("Flying") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Flying</span>{r.Flying ? <img src="/img/check-green.png"/> : ""}</td>
                <td className={(list.indexOf("SoldOffDay") > -1) ? "" : "hideMe"}><span
                    className="mobile-only">Sold off day</span>{r.SoldOffDay ? <img src="/img/check-green.png"/> : ""}</td>
                <td>
                    <input type="checkbox" checked={r.Rejected !== null ? r.Rejected : false} disabled={this.hasBeenApproved()}
                           onChange={(r) => this.handleActivityReject(r, id)}/>
                </td>
            </tr>);
        });
        const invoiceStatusClass = GetInvoiceStatusClass(this.props.invoice.Status);
        return (<>
            <div className="specific-work-report-approval-page-header">
                <a onClick={this.props.onRequestClose}>
                    <img className="back" src="/img/back.png" alt={"back"}/>
                </a>
                {DisplayEmployeeInfoSmall(this.state.profileTimesheet)}
                <div className="date">
                    {getMonthFullName(this.props.invoice.Month)} {this.props.invoice.Year}
                </div>
                <span className={invoiceStatusClass}>{this.props.invoice.StatusStr}</span>
            </div>
            <div className="table-responsive">
                <table className="manual invoices table table-striped table-condensed">
                    <thead>
                    {header}
                    </thead>
                    <tbody>
                    {records}
                    </tbody>
                </table>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                <div className="rejectTextComment">
                    <div>
                        <h4>Comments with work invoice</h4>
                        <div>
                          <textarea className={"form-control " + this.state.rejectClass}
                                    onChange={(e) => this.handleRejectTextChange(e)}
                                    value={this.state.rejectText}/>
                        </div>
                    </div>
                </div>
                {this.props.isDeadlineNotPassed && !this.hasBeenApproved() ?
                    <div className="buttons-list">
                        <button disabled={this.state.loading} className={"btn approveBtn"}
                                onClick={() => this.submitApprove()}>Approve
                        </button>
                        <button disabled={this.state.loading} className={"btn rejectBtn"}
                                onClick={() => this.submitReject()}>Reject
                        </button>
                    </div> : null}
            </div>
            <div className="currentfilters">
                {statusComments}
            </div>
        </>);
    }
}
