import * as React from "react";

import InvoiceStatuses from "../components/InvoiceStatuses";
import type { Dispatch, Store } from "redux";
import ManualInvoiceDetails from "../components/ManualInvoiceDetails";
import type { EmployeeInfoWise } from "../types/EmployeeInfo";
import api from "../features/Api";
import type { InvoiceStatusStr } from "../features/payrollUtils";

type Props = {
  dispatch: Dispatch,
  store: Store,
  provider: unknown,

  monthsAgo: string, // monthsAgo,
  year: number, // invoiceYear,
  month: number, //  @(Model.Invoice?.Month ?? DateTime.Today.Month),
  isOpen: unknown, // monthsAgo == '',
  DeadlineDay: number, // @(Model.DeadlineDay),
  ExtraDays: number, // @(Model.ExtraDays),
  LastMonthExtraDays: number, // @(Model.LastMonthExtraDays),
  code: string, // '@(Model.Employee != null ? Model.Employee.No_ : "")',
  id: number, // '@(Model.Employee?.Id ?? 0)',
  invoiceId: string, // invoiceId != "" ? invoiceId : '@(Model.Invoice != null ? Model.Invoice.Id.ToString() : "")',
  workingDay: number, // @Convert.ToInt32(ConfigurationManager.AppSettings["WorkingDay"]),
  commenter: string, // '@Model.Commenter',
  isAdmin: boolean, // @(Request.RequestContext.HttpContext.User.Identity == null ? "false" : UserHelper.IsAdmin(employee).ToString().ToLower()),
  isPeriodOpen: boolean, // @(Model.Invoice != null ? Model.Invoice.IsOpen.ToString().ToLower() : "false"),
  wasSubmitted: boolean, // @(Model.Invoice != null ? (Model.Invoice.Status == InvoiceStatus.PendingApproval).ToString().ToLower() : "false"),
  invoiceStatus: InvoiceStatusStr, // '@(Model.Invoice != null ? Model.Invoice.StatusStr : "")',
  invoiceStatusInfo: string, // '@(Model.Invoice != null ? Model.Invoice.InvoiceStatusInfo : "")',
  invoiceMessage?: string,
  dateSubmitted: string, // '@(Model.Invoice != null ? Model.Invoice.DateSubmitted.ToString() : "")'
};

type State = {
    profile: EmployeeInfoWise | null,
};

export class ManualInvoice extends React.Component<Props, State> {
  constructor(props: Props) {
    require("./ManualInvoice.css");
    super(props);
    this.state = {
        profile: null,
    };
  };

  componentDidMount() {
    api.Employee.LoadProfile({ employeeId: this.props.id })
        .then(profile => this.setState({ profile }));
  };

  _createManualInvoiceDetails(displayActions: boolean) {
      if (!this.state.profile) {
          return <h1 className="loading status-loading-animated-ellipsis">Profile Data Loading</h1>;
      } else if (this.state.profile.No_.toUpperCase() === "XXXY") {
          return <h1 className="loading">This screen is intended for contractors. To approve work invoices follow <a href="/Home/CurrentTimesheets?approveType=1">this</a> link.</h1>;
      } else {
          return (<ManualInvoiceDetails code={this.props.code}
                                    profile={this.state.profile}
                                    monthsAgo={this.props.monthsAgo}
                                    invoiceId={this.props.invoiceId}
                                    DeadlineDay={this.props.DeadlineDay}
                                    ExtraDays={this.props.ExtraDays}
                                    dispatch={this.props.dispatch}
                                    LastMonthExtraDays={this.props.LastMonthExtraDays}
                                    displayActions={displayActions}
                                    year={this.props.year}
                                    month={this.props.month}
                                    store={this.props.store}
                                    provider={this.props.provider}
                                    isOpen={this.props.isPeriodOpen}
                                    dateSubmitted={this.props.dateSubmitted}
                                    invoiceMessage={this.props.invoiceMessage}
                                    invoiceStatusInfo={this.props.invoiceStatusInfo}
                                    invoiceStatus={this.props.invoiceStatus}/>);
      }
  };

  _createInvoiceStatuses() {
    return (<InvoiceStatuses invoiceId={Number(this.props.invoiceId)}/>);
  };

  _getNthWorkingDay(day: Date, nthWorkingDay: number) {
    const firstDayOfTheMonth = new Date(day.getFullYear(), day.getMonth(), 1);

    while ((firstDayOfTheMonth.getDay() == 0 || firstDayOfTheMonth.getDay() == 6) || nthWorkingDay != 0) {
      firstDayOfTheMonth.setDate(firstDayOfTheMonth.getDate() + 1);

      if (firstDayOfTheMonth.getDay() != 0 && firstDayOfTheMonth.getDay() != 6)
        nthWorkingDay--;
    }

    return firstDayOfTheMonth;
  };

  render() {
      const invoiceStatueses = this._createInvoiceStatuses();

      const today = new Date();
      const nthWorkingDayThisMonth = this._getNthWorkingDay(new Date(this.props.year, this.props.month), this.props.workingDay);
      const firstSubmissionDayOfThisMonth = new Date(this.props.year, this.props.month, 1);

//TODO: Tók aðeins út útaf timasetningunum setti inn aftur
// let manualInvoiceDetails = this._createManualInvoiceDetails(true);
      const manualInvoiceDetails = this._createManualInvoiceDetails(((today <= nthWorkingDayThisMonth && today >= firstSubmissionDayOfThisMonth && !this.props.wasSubmitted) ||
        this.props.isPeriodOpen) && !this.props.isAdmin);

      return (
        <div className="invoice-wrapper">
            <div className="invoice-content">
              <div className="manualDetails">
                {manualInvoiceDetails}
              </div>
              <div>
                {invoiceStatueses}
              </div>
            </div>
        </div>
      );
    }
}