import { getJson, getOrReuseJson, getResponse } from "./httpUtils";
import type { RosterItem, RotationData } from "./roster/air_atlanta/Roster";
import type { AbsoluteMonth } from "@mhc/utils/src/dates";
import JsonResponseAsyncIterator from "../utils/JsonResponseAsyncIterator";
import type { CompanyName } from "@mhc/utils/types/nav";
import type  { Brand,EmailAddress,IataAirport,IsoDate,IsoDateTime } from "@mhc/utils/types/utility";
import type  { IsoLikeDateTimeLocal } from "../types/utility";
import { brand } from "@mhc/utils/src/typing";
import type { Crew, RosterCrew } from "@mhc/utils/types/integrations/raido/api";
import type { CrewCode } from "@mhc/utils/types/nav";
import type { RaidoActivityExtension } from "./roster/air_atlanta/RotationDaysGrouper";

export type NavContractorBase = {
    "NearestIntAirport": IataAirport,
    "JobTitle": "CABIN CREW MEMBER" | "SENIOR CABIN CREW MEMBER" | "SENIOR CABIN CREW GRADE 2" | "CABIN INSTRUCTOR" | "CRUISE RELIEF FIRST OFFICER" | "CAPTAIN" | "FIRST OFFICER" | "SENIOR FIRST OFFICER" | string,
    "No_": CrewCode,
};

const ContractCode = Symbol("ContractCode");
export type ContractCode = Brand<"FOAAI" | string, typeof ContractCode>;

const ScaleCode = Symbol("ScaleCode");
export type ScaleCode = Brand<"FO AAI" | string, typeof ScaleCode>;

const Group = Symbol("Group");
export type Group = Brand<"DFEE" | "OT" | "PD" | "EXTRA5" | string, typeof Group>;

const Level = Symbol("Level");
export type Level = Brand<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | number, typeof Level>;

const FeeEntryTypeTag = Symbol("FeeEntryType");
export type FeeEntryType = Brand<100 | 200 | 4005 | 5006 | number, typeof FeeEntryTypeTag>;
export const FeeEntryType = (value: number) => brand<FeeEntryType>(value);

const Rate = Symbol("Rate");
export type Rate = Brand<250.00 | number, typeof Rate>;

export type NavContractor = NavContractorBase & {
    "FirstName": "Ancuta Alexandra" | string,
    "LastName": "Dragomir" | string,
    "MiddleName": "",
    "Status": 0,
    "EMail": EmailAddress,
    /** in Legend, ETF, Air Atlanta it's Department code */
    GlobalDimension1Code: "610" | "410",
    /** in AAI D2W it's aircraft */
    GlobalDimension2Code: "747-400",
    /** in Legend, ETF it's job Type */
    ShortcutDimension3Code: "CABIN",
    /** in Legend, ETF it's job Subtype */
    ShortcutDimension4Code: "CCM" | "SCCM" | "INS",
    ShortcutDimension5Code: string,
    ShortcutDimension7Code: string,
    ShortcutDimension8Code: string,
};

export type ImpersonalSalaryJournalRecord = {
    description         : "Monthly Fee pending" | string, // [Faersluheiti]
    aircraft?           : "A343 YR-LRC"   | string, // [Launavidd2]
    units               : "1"             | `${number}`, // [Einingar]
    service_start_time  : IsoDate,                  // [Timabil fra (Dagpeningar)]
    service_end_time    : IsoDate,                  // [Timabil til (Dagpeningar)]
    base?               : IataAirport,              // [Launavidd6]
    country?            : ""              | string, // [Launavidd6]
    fee_entry_type      : FeeEntryType,             // [Faerslutegund]
    payment_per_unit    : "0.01"          | `${number}`, // [Upphaed taxta]
};

export type CodedSalaryJournalRecord = ImpersonalSalaryJournalRecord & {
    crew_code          : CrewCode, // [Nr_]
};

export type UnorderedSalaryJournalRecord = CodedSalaryJournalRecord & {
    batch_name         : "LAL_LEON"            | string, // [Heiti bokarkeyrslu]
    position_code?     : "CCM"                 | string, // [Launavidd4]
};

export type SalaryJournalRecord = UnorderedSalaryJournalRecord & {
    id: 5000 | number, // [Rununumer]
};

export type StoredSalaryJournalRecord = SalaryJournalRecord & {
    created_on: IsoDate | IsoDateTime,
};

export type RdbWorkHistoryRecord = {
    WorkHistoryId: 9315 | number,
    FromDate: "2023-04-27 00:00:00" | IsoLikeDateTimeLocal,
    ToDate: "2024-04-26 00:00:00" | IsoLikeDateTimeLocal,
    Notes?: "HELO contract_AAI_CCM_AAA: updated automatically from signed contract #267519 end time changed from 2024-03-31 00:00:00" | string,
};

type RdbNearestAirport = IataAirport | "RIX" | "VNO, SFO,JED" | string | null;

export type RdbContractor = {
    "ApplicantID": 18440 | number,
    "Nearest_Airport": RdbNearestAirport,
    "workHistoryRecords": RdbWorkHistoryRecord[],
};

export type RdbContractRecord = RdbWorkHistoryRecord & {
    ApplicantID: 18440 | number,
    Email: EmailAddress,
    PersonName: "Jaquelin" | string,
    Surname: "Nita Mosquera" | string,
    PayrollNo: CrewCode,
    Nearest_Airport: RdbNearestAirport,
    ClientID?: 429 | number,
    Company?: number, // number??? not string? is not the name of the Client?
    Position: "Cabin Crew" | "Operations" | "Flight Deck" | null,
    Status: "Placed" | "Contract - Ended" | string | null,
    StatusId: number | null,
    LastContractSentTime: IsoLikeDateTimeLocal | null,
    LastContractStatus: string | null,
    LastContractName: string | null,
    LastContractDueDate: IsoLikeDateTimeLocal | null,
};

export type NavContractorForBulkCalculation = {
    "No_": CrewCode,
    GlobalDimension1Code: string,
};

export type PersonMatch<TNavContractor extends NavContractorForBulkCalculation = NavContractor> = {
    navEntry: TNavContractor,
    rdbEntry?: RdbContractor,
};

export type PersonMonthRosterContent = {
    roster_complete: RotationData | null, // VARBINARY
    roster_incomplete: RosterItem[] | null, // NVARCHAR
    roster_complete_error?: string,
    roster_incomplete_error?: string,
};

export type PersonMonthRoster = AbsoluteMonth & PersonMonthRosterContent & {
    crew_code: CrewCode, // VARCHAR
    retrieved_time: string, // DATETIMEOFFSET
};

export type RaidoPersonMonthRoster = PersonMonthRoster & {
    roster_complete: RotationData<RaidoActivityExtension>,
};

export type CompanyInvoice = {
    Kennitala: CrewCode,
    "Amount to Pay I": "0.000" | string | number,
    "Amount to Pay II": "0.000" | string | number,
    COMPANYNAME: CompanyName,
};

export type FeeEntryTypeRecord = {
    fee_entry_type       : FeeEntryType, // [Faerslutegund]
    description          :          string, // [Faersluheiti]
    fee_entry_type_group : Group,
    payment_per_unit?    : Rate, // [Upphaed taxta]
    portal_activity_type?: string,
};

export type WageContract = {
    wage_contract: ContractCode,
    description: string | null,
    inactive: boolean,
};

export type WageContractFixedLine = {
    wage_contract: ContractCode,
    line_number: number,
    fee_entry_type: FeeEntryType,
    units: number,
};

export type WageContractFeeEntryScale = {
    wage_contract: ContractCode,
    scale: ScaleCode,
    fee_entry_type_group: Group,
    description: string,
    payment_per_unit_levels: Rate[],
};

export type SalaryJournalWageContractRecord = FeeEntryTypeRecord & {
    units                : 1      | number, // [Einingar]
    crew_code            : CrewCode, // [Nr_]
    COMPANYNAME: CompanyName,
};

export type HrComment = {
    No_: CrewCode,
    COMPANYNAME: CompanyName,
    Date: IsoDateTime,
    Code: string,
    Comment: string,
};

const ENDPOINT_BASE = "https://backoffice.mhcaviation.com";
// const ENDPOINT_BASE = "http://127.0.0.1:52710";

const externalRostersApi = {
    nav: {
        getAllActivePeopleComments: () => getResponse(
            ENDPOINT_BASE + "/api/nav/getAllActivePeopleComments"
        ).then(response => JsonResponseAsyncIterator<HrComment>(response)),
        getAllCompaniesPayroll: (params: AbsoluteMonth) => getResponse(
            ENDPOINT_BASE + "/api/nav/getAllCompaniesPayroll", params
        ).then(response => JsonResponseAsyncIterator<CompanyInvoice>(response)),
        getAllCompaniesWageContractEntries: () => getResponse(
            ENDPOINT_BASE + "/api/nav/getAllCompaniesWageContractEntries"
        ).then(response => JsonResponseAsyncIterator<SalaryJournalWageContractRecord>(response)),
    },
    getActiveMhcPeople: (params: { COMPANYNAME: string, clientId: number }) => getJson<PersonMatch[]>(
        ENDPOINT_BASE + "/api/getActiveMhcPeople", params
    ),
    /**
     * @throws {HttpResponseError}
     *     404 - if person does not exist in the system or is not accessible by our account or has no roster
     */
    getAirAtlantaRaidoRoster: (params: {
        crew_code: CrewCode,
        year: number,
        month: number,
    }) => {
        const get = () => getOrReuseJson<RosterCrew>(
            ENDPOINT_BASE + "/api/getAirAtlantaRaidoRoster", params
        );
        return get();
    },
    getAirAtlantaRaidoRosters: (params: AbsoluteMonth) => getOrReuseJson<RosterCrew[]>(
        ENDPOINT_BASE + "/api/getAirAtlantaRaidoRosters", params
    ),
    getAirAtlantaRaidoPeople: () => getOrReuseJson<Crew[]>(
        ENDPOINT_BASE + "/api/getAirAtlantaRaidoPeople"
    ),
};

export default externalRostersApi;
