import type { NavContractRecord } from "../types/EmployeeInfo";
import type { PersonRow } from "../views/WageContractsDashboard";
import * as React from "react";
import type { ContractCode, FeeEntryType, Group,Level,Rate,ScaleCode,
    WageContractFeeEntryScale } from "../features/ExternalRostersApi";
import type {
    FeeEntryTypeRecord,WageContract,
    WageContractFixedLine
} from "../features/ExternalRostersApi";
import { useEffect, useState } from "react";
import {
    getNavCompanyFeeEntryTypes,
    getNavCompanyWageContractFixedLines,
    getNavCompanyWageContractFeeEntryScales,getNavCompanyWageContracts
} from "../features/StaticData";
import { brand, entries, fromEntries } from "@mhc/utils/src/typing";
import { toCOMPANYNAMEUC } from "@mhc/utils/types/nav";
import { IsolatedReact } from "../utils/IsolatedReact";
import _ from "lodash";
import { isSameSet } from "../utils/fp";
import type { RDeepMap } from "../utils/Tensor";
import Tensor  from "../utils/Tensor";

type TypelessFeeEntryRate = {
    fee_entry_type_group : Group,
    payment_per_unit?: Rate | string, // [Upphaed taxta]
};

type FeeEntryRate = TypelessFeeEntryRate & {
    fee_entry_type   : FeeEntryType, // [Faerslutegund]
};

type Props = {
    personRow: PersonRow & { NavPerson: NavContractRecord },
    wageContractEntries: FeeEntryRate[],
    onRequestClose: () => void,
};

function assertInteger(numericString: string) {
    if (numericString.match(/^\d+$/)) {
        return Number(numericString);
    } else {
        return null;
    }
}

function assertDecimal(numericString: string) {
    if (numericString.match(/^\d+(\.\d+)?$/)) {
        return Number(numericString);
    } else {
        return null;
    }
}

function assertFeeEntryType(numericString: string) {
    const asInt = assertInteger(numericString);
    if (asInt !== null) {
        return brand<FeeEntryType>(asInt);
    } else {
        return null;
    }
}

function assertRate(numericString: string) {
    const asDecimal = assertDecimal(numericString);
    if (asDecimal !== null) {
        return brand<Rate>(asDecimal);
    } else {
        return null;
    }
}

function matchesFormFeeTypes(
    fixedLines: WageContractFixedLine[],
    wageContractEntries: ReadonlyMap<Group, FeeEntryRate>
) {
    const contractTypes = new Set(fixedLines.map(fl => fl.fee_entry_type));
    const formTypes = new Set([...wageContractEntries.values()].map(t => t.fee_entry_type));
    return isSameSet(contractTypes, formTypes);
}

function computeWageContractOptions(
    allWageContractFixedLines: Record<ContractCode, WageContractFixedLine[]> | undefined,
    wageContractEntries: ReadonlyMap<Group, FeeEntryRate>
) {
    if (!allWageContractFixedLines) {
        return undefined;
    }
    return entries(allWageContractFixedLines)
        .flatMap(([wageContract, fixedLines]) => {
            if (matchesFormFeeTypes(fixedLines, wageContractEntries)) {
                return [wageContract];
            } else {
                return [];
            }
        });
}

function areRatesCompatible(
    formFeeEntries: ReadonlyMap<Group, TypelessFeeEntryRate>,
    levelFeeEntries: ReadonlyMap<Group, Rate>
): boolean {
    const formGroups = new Set(formFeeEntries.keys());
    const levelGroups = new Set(levelFeeEntries.keys());
    if (!isSameSet(formGroups, levelGroups)) {
        return false;
    }
    for (const feeEntryRate of formFeeEntries.values()) {
        if (!feeEntryRate.payment_per_unit) {
            continue; // if rate is not entered, then it can be anything
        }
        const scaleRate = levelFeeEntries.get(feeEntryRate.fee_entry_type_group) ?? 0;
        if (scaleRate !== Number(feeEntryRate.payment_per_unit)) {
            return false;
        }
    }
    return true;
}

function computeCompatibleScaleLevels(
    wageContractName: string,
    wageContractOptions: string[] | undefined,
    wageContractEntries: ReadonlyMap<Group, FeeEntryRate>,
    allWageContractFeeEntryScales: WageContractFeeEntryScale[] | undefined
): Tensor<[Group, ContractCode, ScaleCode, Level], Rate> | undefined {
    if (!allWageContractFeeEntryScales) {
        return undefined;
    }
    const wageContractOptionsSet = new Set(
        wageContractName ? [wageContractName] : wageContractOptions
    );
    const levels = new Tensor<[ContractCode, ScaleCode, Level, Group], Rate>();
    for (const fes of allWageContractFeeEntryScales) {
        if (!wageContractOptionsSet.has(fes.wage_contract)) {
            continue;
        }
        for (let i = 0; i < fes.payment_per_unit_levels.length; ++i) {
            const rate = fes.payment_per_unit_levels[i];
            const level = brand<Level>(i + 1);
            levels.set([fes.wage_contract, fes.scale, level, fes.fee_entry_type_group], rate);
        }
    }
    const results = new Tensor<[Group, ContractCode, ScaleCode, Level], Rate>();
    for (const [WageContract, scales] of levels.getRoot()) {
        for (const [Scale, levels] of scales) {
            for (const [WageContractLevel, rates] of levels) {
                for (const [Group, rate] of rates) {
                    const checkEntries = new Map<Group, TypelessFeeEntryRate>(wageContractEntries);
                    checkEntries.set(Group, {
                        fee_entry_type_group: Group,
                        // ignore this group's entered rate in compatibility checks to get meaningful completion options list
                        payment_per_unit: "",
                    });
                    if (areRatesCompatible(checkEntries, rates)) {
                        results.set([Group, WageContract, Scale, WageContractLevel], rate);
                    }
                }
            }
        }
    }
    return results;
}

function computeGroupRateOptions(
    compatibleScaleLevels: Tensor<[Group, ContractCode, ScaleCode, Level], Rate> | undefined
): RDeepMap<[Group, Rate, ContractCode, ScaleCode, Level], void> | undefined {
    if (!compatibleScaleLevels) {
        return undefined;
    }
    const results = new Tensor<[Group, Rate, ContractCode, ScaleCode, Level], void>();
    for (const [Group, contracts] of compatibleScaleLevels.getRoot()) {
        for (const [ContractCode, scales] of contracts) {
            for (const [ScaleCode, levels] of scales) {
                for (const [WageContractLevel, Rate] of levels) {
                    if (Rate === 0) {
                        continue;
                    }
                    results.set([Group, Rate, ContractCode, ScaleCode, WageContractLevel], undefined);
                }
            }
        }
    }
    return new Map([...results.getRoot()].map(([group, rates]) => {
        return [group, new Map([...rates].sort((a, b) => {
            return a[0] - b[0];
        }))];
    }));
}

function makeRateDescription(contracts: RDeepMap<[ContractCode, ScaleCode, Level], void>) {
    const showContract = contracts.size > 1;
    return [...contracts].flatMap(
        ([ContractCode, scales]) => [...scales].flatMap(
            ([ScaleCode, levels]) => [...levels.keys()].map(
                (Level) => (showContract ? ContractCode + "." : "") + ScaleCode + "[" + Level + "]"
            )
        )
    ).join(" ");
}

export default function WageContractEditForm(props: Props) {
    const [wageContractEntries, setWageContractEntries] = useState<ReadonlyMap<Group, FeeEntryRate>>(new Map(
        props.wageContractEntries.map(wce => [wce.fee_entry_type_group, wce])
    ));
    const [wageContractName, setWageContractName] = useState(brand<ContractCode>(props.personRow.NavPerson.WageContract ?? ""));
    const [wageContractScale, setWageContractScale] = useState(props.personRow.NavPerson.Scale ?? "");
    const [wageContractLevel, setWageContractLevel] = useState(String(props.personRow.NavPerson.WageContractLevel ?? ""));

    const [newFeeEntryTypeRaw, setNewFeeEntryTypeRaw] = useState<string>("");
    const [newFeeEntryRateRaw, setNewFeeEntryRateRaw] = useState<string>("");

    const newFeeEntryType = IsolatedReact.useMemo(assertFeeEntryType, [newFeeEntryTypeRaw]);
    const newFeeEntryRate = IsolatedReact.useMemo(assertRate, [newFeeEntryRateRaw]);

    const [allFeeEntries, setAllFeeEntries] = useState<Record<FeeEntryType, FeeEntryTypeRecord>>();
    const [allWageContracts, setAllWageContracts] = useState<Record<ContractCode, WageContract>>();
    const [allWageContractFixedLines, setAllWageContractFixedLines] = useState<Record<ContractCode, WageContractFixedLine[]>>();
    const [allWageContractFeeEntryScales, setAllWageContractFeeEntryScales] = useState<WageContractFeeEntryScale[]>();

    const wageContractOptions = IsolatedReact.useMemo(computeWageContractOptions, [allWageContractFixedLines, wageContractEntries]);
    const compatibleScaleLevels = IsolatedReact.useMemo(computeCompatibleScaleLevels, [
        wageContractName, wageContractOptions, wageContractEntries, allWageContractFeeEntryScales
    ]);
    const groupRateOptions = IsolatedReact.useMemo(computeGroupRateOptions, [compatibleScaleLevels]);

    useEffect(() => {
        const COMPANYNAME = toCOMPANYNAMEUC(props.personRow.NavPerson.CompanyName);
        getNavCompanyFeeEntryTypes(COMPANYNAME).then(
            records => setAllFeeEntries(fromEntries(records.map(tr => [tr.fee_entry_type, tr])))
        );
        getNavCompanyWageContracts(COMPANYNAME).then(
            records => setAllWageContracts(Object.fromEntries(records.map(r => [r.wage_contract, r])))
        );
        getNavCompanyWageContractFixedLines(COMPANYNAME).then(
            records => setAllWageContractFixedLines(_.groupBy(records, rec => rec.wage_contract))
        );
        getNavCompanyWageContractFeeEntryScales(COMPANYNAME).then(setAllWageContractFeeEntryScales);
    }, []);

    const resetScaleId = () => {
        setWageContractScale("");
        setWageContractLevel("");
    };

    const resetWageContractId = () => {
        setWageContractName(brand<ContractCode>(""));
        resetScaleId();
    };

    const addNewFeeEntry = () => {
        if (!newFeeEntryTypeRaw) {
            toastr.error("Fee Entry Type must be entered");
        } else if (!newFeeEntryType) {
            toastr.error("Fee Entry Type must be a valid number");
        } else if (newFeeEntryRateRaw && !newFeeEntryRate) {
            toastr.error("Rate must be a valid decimal");
        } else if (!allFeeEntries) {
            toastr.error("Fee Entry Types loading, please try again in few seconds");
        } else if (!allFeeEntries[newFeeEntryType]) {
            toastr.error("Fee Entry Type #" + newFeeEntryType + " is not defined in NAV " + props.personRow.NavPerson.CompanyName);
        } else if (wageContractEntries.get(allFeeEntries[newFeeEntryType].fee_entry_type_group)) {
            toastr.error("Fee Entry Type #" + allFeeEntries[newFeeEntryType].fee_entry_type_group + " is already present in this wage contract");
        } else {
            setNewFeeEntryTypeRaw("");
            setNewFeeEntryRateRaw("");
            resetWageContractId();
            const group = allFeeEntries[newFeeEntryType].fee_entry_type_group;
            const newState = new Map(wageContractEntries);
            newState.set(group, {
                fee_entry_type_group: allFeeEntries[newFeeEntryType].fee_entry_type_group,
                fee_entry_type: newFeeEntryType,
                payment_per_unit: newFeeEntryRate ?? brand<Rate>(0),
            });
            setWageContractEntries(newState);
        }
    };

    let focusTaken = false;
    const takeFocus = () => {
        const wasTaken = focusTaken;
        focusTaken = true;
        return !wasTaken;
    };

    let lastFocusIndex = 0;
    const takeFocusIndex = () => ++lastFocusIndex;

    return <form
        className="wage-contract-edit-view"
        onSubmit={(e) => { e.preventDefault(); alert("Not Implemented Yet"); return false; }}
        onKeyDown={event => {
            if (event.key === "Escape") {
                event.preventDefault();
                event.stopPropagation();
                props.onRequestClose();
            }
        }}
    >
        <section>
            <header>
                <div>{props.personRow.No_}</div>
                <div>{props.personRow.FirstName} {props.personRow.LastName}</div>
            </header>
            <main>
                <table>
                    <tbody>
                        <tr>
                            <td colSpan={3}></td>
                            <td data-field-name="payment_per_unit">
                                <button tabIndex={takeFocusIndex()} type="button" onClick={() => {
                                    resetScaleId();
                                    setWageContractEntries(new Map([...wageContractEntries.entries()]
                                        .map(e => [e[0], { ...e[1], payment_per_unit: "" }])));
                                }}>Clear</button>
                            </td>
                        </tr>
                        {[...wageContractEntries.values()].map((wce) => <tr key={wce.fee_entry_type}>
                            <td data-field-name="fee_entry_type">{wce.fee_entry_type}</td>
                            <td data-field-name="fee_entry_type_group">
                                {!allFeeEntries
                                    ? <span className="status-loading-animated-ellipsis"></span>
                                    : <span>{allFeeEntries[wce.fee_entry_type]?.fee_entry_type_group}</span>}
                            </td>
                            <td data-field-name="description">
                                {!allFeeEntries
                                    ? <span className="status-loading-animated-ellipsis"></span>
                                    : <span>{allFeeEntries[wce.fee_entry_type]?.description}</span>}
                            </td>
                            <td data-field-name="payment_per_unit">
                                <input
                                    autoFocus={takeFocus()}
                                    tabIndex={takeFocusIndex()}
                                    type="text"
                                    required={true}
                                    pattern={/^\d+(\.\d+)?$/.source}
                                    title="Must be a valid number"
                                    placeholder={"Rate per Unit "}
                                    list={allFeeEntries?.[wce.fee_entry_type] && "wage-contract-edit-view-rate-options--" + allFeeEntries[wce.fee_entry_type].fee_entry_type_group}
                                    value={typeof wce.payment_per_unit === "number" ? wce.payment_per_unit.toFixed(2) : wce.payment_per_unit ?? ""}
                                    onChange={e => {
                                        resetScaleId();
                                        const newState = new Map(wageContractEntries);
                                        newState.set(wce.fee_entry_type_group, {
                                            ...wce,
                                            payment_per_unit: e.target.value,
                                        });
                                        setWageContractEntries(newState);
                                    }}
                                />
                            </td>
                            <td>
                                <button type="button" onClick={() => {
                                    const newState = new Map(wageContractEntries.entries());
                                    newState.delete(wce.fee_entry_type_group);
                                    setWageContractEntries(newState);
                                    resetWageContractId();
                                }}>Remove</button>
                            </td>
                        </tr>)}
                        <tr>
                            <td data-field-name="fee_entry_type">
                                <input
                                    autoFocus={takeFocus()}
                                    tabIndex={takeFocusIndex()}
                                    type="text"
                                    value={newFeeEntryTypeRaw}
                                    placeholder={"Entry Type "}
                                    list="wage-contract-edit-view-fee-entry-type-options"
                                    onChange={e => setNewFeeEntryTypeRaw(e.target.value)}
                                />
                                <datalist id="wage-contract-edit-view-fee-entry-type-options">
                                    {!allFeeEntries
                                        ? <option label="Fee Entry Types Loading..." value=""></option>
                                        : Object.values(allFeeEntries).map(afe => {
                                            return <option
                                                label={afe.fee_entry_type_group + " " + afe.description}
                                                key={afe.fee_entry_type}
                                                value={afe.fee_entry_type}
                                            ></option>;
                                        })}
                                </datalist>
                            </td>
                            <td data-field-name="fee_entry_type_group">
                                {!allFeeEntries
                                    ? <span className="status-loading-animated-ellipsis"></span>
                                    : <span>{newFeeEntryType && allFeeEntries[newFeeEntryType]?.fee_entry_type_group}</span>}
                            </td>
                            <td data-field-name="description">
                                {!allFeeEntries
                                    ? <span className="status-loading-animated-ellipsis"></span>
                                    : <span>{newFeeEntryType && allFeeEntries[newFeeEntryType]?.description}</span>}
                            </td>
                            <td data-field-name="payment_per_unit">
                                <input
                                    type="number"
                                    step="0.01"
                                    tabIndex={takeFocusIndex()}
                                    placeholder={"Rate per Unit "}
                                    value={newFeeEntryRateRaw}
                                    onChange={e => setNewFeeEntryRateRaw(e.target.value)}
                                />
                            </td>
                            <td>
                                <button tabIndex={takeFocusIndex()} type="button" onClick={addNewFeeEntry}>Add️</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="wage-contract-identification-panel">
                    <fieldset>
                        <label>
                            <span>Wage Contract</span>
                            <input
                                type="text"
                                tabIndex={takeFocusIndex()}
                                size={10}
                                value={wageContractName}
                                onChange={e => setWageContractName(brand<ContractCode>(e.target.value))} list="wage-contract-edit-view-wage-contract-options"
                            />
                            <datalist id="wage-contract-edit-view-wage-contract-options">
                                {!wageContractOptions
                                    ? <option label="Wage Contracts Loading..." value=""></option>
                                    : wageContractOptions.map(wageContract => {
                                        return <option
                                            key={wageContract}
                                            value={wageContract}
                                            label={allWageContracts && allWageContracts[wageContract] && (allWageContracts[wageContract].description + (allWageContracts[wageContract].inactive ? " (inactive)" : ""))}
                                        ></option>;
                                    })}
                            </datalist>
                        </label>
                        <label>
                            <span>Description</span>
                            <input
                                type="text"
                                tabIndex={takeFocusIndex()}
                                readOnly={true}
                                value={wageContractName && allWageContracts && allWageContracts[wageContractName] && (allWageContracts[wageContractName].description + (allWageContracts[wageContractName].inactive ? " (inactive)" : "")) || ""}
                            />
                        </label>
                    </fieldset>
                    <fieldset>
                        <label>
                            <span>Scale</span>
                            <input readOnly={true} type="text" size={10} value={wageContractScale} onChange={e => setWageContractScale(e.target.value)}/>
                        </label>
                        <label>
                            <span>Level</span>
                            <input readOnly={true} type="number" min={1} max={11} value={wageContractLevel} onChange={e => setWageContractLevel(e.target.value)}/>
                        </label>
                    </fieldset>
                </div>
            </main>
            <footer>
                <button tabIndex={takeFocusIndex()} type="button" onClick={props.onRequestClose}>Cancel</button>
                <button tabIndex={takeFocusIndex()} type="submit">Save</button>
            </footer>
        </section>
        {groupRateOptions && [...groupRateOptions].map(([group, rates]) => <datalist
            key={group}
            id={"wage-contract-edit-view-rate-options--" + group}
        >
            {[...rates].flatMap(([rate, contracts]) => {
                return <option
                    key={rate}
                    value={rate}
                    label={makeRateDescription(contracts)}
                ></option>;
            })}
        </datalist>)}
    </form>;
}