import type { IsoAlpha2Country, IsoDate } from "../../../types/utility";
import type { AbsoluteMonth } from "../../../utils/dates";
import  { getMonthDate,getNumberOfDays } from "../../../utils/dates";
import  { pad2 } from "../../../utils/dates";
import  { getDatePart } from "../../../utils/dates";
import { getMonthEndDateObj } from "../../../utils/dates";
import type { PersonMonthRosterContent } from "../../ExternalRostersApi";
import type    { BlankActivity, RealActivity, RosterItem, RotationActivity, RotationData, RotationItem } from "./Roster";
import type { RosterActivity, RosterCrew } from "@mhc/utils/types/integrations/raido/api";
import aaiRaidoActivityTypes from "./aaiRaidoActivityTypes";

/**
 * Air Atlanta sometimes includes the Z mark and sometimes does not
 * "2024-01-04T00:00:00Z" -> new Date("2024-01-04T00:00:00Z")
 * "2024-01-05T14:45:00"  -> new Date("2024-01-05T14:45:00Z")
 * "2024-06-15 08:53"  -> new Date("2024-06-15 08:53:00Z")
 */
export function asUtc(timeStr: string) {
    if (timeStr.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/)) {
        timeStr += ":00"; // ATA/ATD in Air Atlanta are passed as ISO string without seconds
    }
    const datePart = timeStr.slice(0, 10); // 2024-01-04
    const timePart = timeStr.slice(11);
    if (!timePart) {
        // according to W3C date without time is to be automatically interpreted as UTC 00:00:00
        return new Date(datePart);
    }
    const isoCompliant = datePart + "T" + timePart;
    return new Date(isoCompliant.slice(0, "2024-01-04T00:00:00".length) + "Z");
}

export type DutyKind = "OFF_DUTY" | "TRAVEL" | "DAY_SHIFT_TRAVEL_START" | "DAY_SHIFT_TRAVEL_END" | "NIA_GROUND_DUTY";

export type RotationDay = {
    MonthDayIndex: number,
    Date: IsoDate,
    /** @deprecated - not relevant anymore after the migration from RM to N-OC */
    DayNumberInRotaion: number,
    Activities: RosterItem[],
    FullRotationItem?: RotationItem,
};

export type DutiedRotationDay = RotationDay & {
    DutyKind: DutyKind | null,
};

export function makeBlankActivity(dayNumberInRotation: number, dateStr: IsoDate): BlankActivity {
    return {
        ActivityDate: `${dateStr}T00:00:00`,
        DayNumberInRotaion: dayNumberInRotation,
        ActivityType: "BLANK",
    } as const;
}

export function groupRotationDays(records: RosterItem[]) {
    const endDateObj = getMonthEndDateObj(records[0].ActivityDate);
    const daysInMonth = endDateObj.getUTCDate();

    let monthDayIndex = -1;
    const rotationDays: RotationDay[] = [];
    for (let i = 0; i < records.length; ++i) {
        let date = null;
        if (monthDayIndex > -1) {
            date = new Date(endDateObj.getTime());
            date.setDate(monthDayIndex + 1);
        }
        const lastDay = rotationDays.length > 0 ? rotationDays[rotationDays.length - 1] : null;
        const record = records[i];
        // off day activities start at 00:00 LT and the rotation days distinction for
        // them is defined by LT contrary to duty rotation days that start at 00:00 UTC
        // to somehow compensate for that, I assume, the API adds an extra BLANK activity
        // in the end of vacation to make it so that every UTC date had an entry
        const isSameUtcOffDay = record.DayNumberInRotaion === -1 &&
            date && asUtc(record.ActivityDate).getTime() - date.getTime() < 48 * 60 * 60 * 1000;
        const followsNight = lastDay && asUtc(
            lastDay.Activities.slice(-1)[0].ActivityDate
        ).toISOString().slice(0, 10) > lastDay.Date;

        const isSameDay = lastDay && lastDay.DayNumberInRotaion > -1 && (
            record.DayNumberInRotaion === lastDay.DayNumberInRotaion ||
            isSameUtcOffDay && !followsNight
        );
        if (isSameDay) {
            lastDay.Activities.push(record);
        } else {
            ++monthDayIndex;
            const dateObj = new Date(endDateObj.getTime());
            dateObj.setUTCDate(monthDayIndex + 1);
            rotationDays.push({
                MonthDayIndex: monthDayIndex,
                Date: getDatePart(dateObj),
                DayNumberInRotaion: record.DayNumberInRotaion,
                Activities: [record],
            });
        }
    }
    let lastDayNumber = rotationDays.length > 0 ? rotationDays[rotationDays.length - 1].DayNumberInRotaion : -1;
    let endsPrematurely = false;
    if (lastDayNumber > -1) {
        for (let i = monthDayIndex + 1; i < daysInMonth; ++i) {
            endsPrematurely = true;
            const dayNumber = ++lastDayNumber;
            const dateObj = new Date(endDateObj.getTime());
            dateObj.setUTCDate(i + 1);
            rotationDays.push({
                MonthDayIndex: i,
                Date: getDatePart(dateObj),
                DayNumberInRotaion: dayNumber,
                Activities: [makeBlankActivity(dayNumber, getDatePart(dateObj))],
            });
        }
    }
    return {
        endsPrematurely,
        rotationDays,
    };
}

const SABRE_FULL_ACTIVITY_FILL = {
    ATA: "0001-01-01T00:00:00",
    ATD: "0001-01-01T00:00:00",
    ActivePosHours: null,
    ActivePosMinutes: null,
    ActivePosTime: null,
    AirportFromCountry: "XX" as IsoAlpha2Country,
    AirportToCountry: "XX" as IsoAlpha2Country,
    LANDING: "", // System.DateTime
    LANDING_UTC: "",
    PosHours: "",
    PosMinutes: "",
    PosTime: "",
    STA: "", // System.DateTime
    STA_UTC: "",
    STD: "", // System.DateTime
    STD_UTC: "",
    TAKEOFF: "", // System.DateTime
    TAKEOFF_UTC: "",
} as const;


function groupRotationDaysFromRaido(raidoRosterCrew: RosterCrew, yearMonth: AbsoluteMonth): RotationItem[] {
    const dateToActivities = new Map<IsoDate, RosterActivity[]>();
    for (const raidoActivity of raidoRosterCrew.RosterActivities) {
        const date = getDatePart(raidoActivity.Start);
        let datActivities = dateToActivities.get(date);
        if (!datActivities) {
            datActivities = [];
            dateToActivities.set(date, datActivities);
        }
        datActivities.push(raidoActivity);
    }
    const results: RotationItem[] = [];
    for (let i = 0; i < getNumberOfDays(yearMonth); ++i) {
        const date = getMonthDate(yearMonth, i + 1);
        const raidoActivities = dateToActivities.get(date) ?? [];
        const blankDateTime = new Date(date).toISOString();
        const blankFallback: RotationActivity = {
            ...SABRE_FULL_ACTIVITY_FILL,
            ATD_UTC: blankDateTime,
            ATA_UTC: blankDateTime,
            ActivityEndDate: blankDateTime,
            DutyDesignators: "",
            ...makeBlankActivity(1, date),
        };
        const sabreActivities: RotationActivity[] = raidoActivities.length > 0
            ? raidoActivities.map(raidoToRmFullActivity)
            : [blankFallback];
        results.push({
            ActivePosHours_TotalFromStartOfRotation: 0,
            ActivePosMinutes_TotalFromStartOfRotation: 0,
            Activities: sabreActivities,
            Base: raidoRosterCrew.Base,
            BlockHours_TotalFromStartOfRotation: 0,
            BlockMinutes_TotalFromStartOfRotation: 0,
            Comments: [],
            CrwCat: "",
            Date: raidoActivities.length > 0 ? raidoActivities[0].Start : blankDateTime,
            DayNumberInRotaion: 1,
            DeadheadHours_TotalFromStartOfRotation: 0,
            DeadheadMinutes_TotalFromStartOfRotation: 0,
            Id: 0,
            PayTypes: [],
            PayTypesString: null,
            PosHours_TotalFromStartOfRotation: 0,
            PosMinutes_TotalFromStartOfRotation: 0,
            Project: "",
            Sign: raidoRosterCrew.Number,
            SpecFuncs: "",
            idempno: 0,
        });
    }
    return results;
}

/**
 * We can roughly estimate the start time of the activity by subtracting block hours from
 * the end time for informational purposes, but sometimes such heuristic incorrectly results
 * in day shift, creating an inconsistency with rotation date.
 *
 * Rotation date is not based on imprecise time data, so in such inconsistency situations
 * rotation date should be considered correct and estimated time should be capped to match that.
 *
 * 2024-05-29T23:58:00 + 2024-05-30 -> 2024-05-30T00:00:00
 * 2024-05-29T23:58:00 + 2024-05-29 -> 2024-05-29T23:58:00
 * 2024-05-30T00:07:00 + 2024-05-29 -> 2024-05-29T23:59:59
 * 2024-05-30T00:07:00 + 2024-05-30 -> 2024-05-30T00:07:00
 */
function capAtDay(approximateDt: Date, exactDay: RotationDay): Date {
    const approximateDate = approximateDt.toISOString().slice(0, 10);
    if (approximateDate < exactDay.Date) {
        const correctedDt = new Date(exactDay.Date);
        correctedDt.setUTCHours(0);
        correctedDt.setUTCMinutes(0);
        correctedDt.setUTCSeconds(0);
        return correctedDt;
    } else if (approximateDate > exactDay.Date) {
        const correctedDt = new Date(exactDay.Date);
        correctedDt.setUTCHours(23);
        correctedDt.setUTCMinutes(59);
        correctedDt.setUTCSeconds(59);
        return correctedDt;
    } else {
        return approximateDt;
    }
}

function is24hActivity(record: RosterItem) {
    if (record.ActivityType !== "Ground") {
        return false;
    }
    return record.GNDACTCODETITLE === "OFF" // DAY OFF HOMEBASE
        || record.GNDACTCODETITLE === "VAU" // VACATION UNPAID
        || record.GNDACTCODETITLE === "ULV" // UNPAID LEAVE
        || record.GNDACTCODETITLE === "OFO" // DAY OFF OUTSTATION
        || record.GNDACTCODETITLE === "RTC" // ROTATION CABIN CREW
        || record.GNDACTCODETITLE?.match(/^VA\d+/) // 3/2 ROTATION
        ;
}

function getExactStartTime(record: RosterItem): Date | null {
    const utc = asUtc(record.ActivityDate);
    if (record.ActivityType === "BLANK") {
        return utc;
    } else if (record.FullRotationActivity) {
        return asUtc(record.FullRotationActivity.ActivityDate);
    } else if (is24hActivity(record)) {
        utc.setUTCDate(utc.getUTCDate() - 1);
        return utc;
    }
    return null;
}

export function estimateStartDateTime(record: RosterItem, rotationDay: RotationDay): Date | null {
    const exact = getExactStartTime(record);
    if (exact) {
        return exact;
    }
    const utc = asUtc(record.ActivityDate);
    const rmActivityInfo = record.ActivityType === "Ground" &&
        record.GroundActivityInfo || null;
    let match;
    if (record.ActivityType === "Flight") {
        utc.setUTCHours(utc.getUTCHours() - +record.BlockHours);
        utc.setUTCMinutes(utc.getUTCMinutes() - +record.BlockMinutes);
        return capAtDay(utc, rotationDay);
    } else if (record.ActivityType === "Deadhead") {
        utc.setUTCHours(utc.getUTCHours() - +record.DeadheadHours);
        utc.setUTCMinutes(utc.getUTCMinutes() - +record.DeadHeadMinutes);
        return capAtDay(utc, rotationDay);
    } else if (match = rmActivityInfo?.match(/([0-2]\d):?([0-5]\d|)\s*(?:[Ll][Tt])?\s*(?:-|¿)\s*([0-2]\d):?([0-5]\d|)/)) {
        let [, fromHour, fromMinute, toHour, toMinute] = match;
        fromMinute = fromMinute || "00";
        toMinute = toMinute || "00";
        const toHourNum = +fromHour >= +toHour ? +toHour + 24 : +toHour;
        const fromMinutes = +fromHour * 60 + +fromMinute;
        const toMinutes = toHourNum * 60 + +toMinute;
        const durationMinutes = toMinutes - fromMinutes;
        utc.setUTCMinutes(utc.getUTCMinutes() - durationMinutes);
        return utc;
    } else if (match = rmActivityInfo?.match(/(\d+)\s*(HRS?|NIGHT SHIFT|DAY SHIFT)/i)) {
        const [, hours] = match;
        utc.setUTCHours(utc.getUTCHours() - +hours);
        return utc;
    } else {
        return null;
    }
}

function isSameActivity(incompleteActivity: RosterItem, fullActivity: RotationActivity) {
    if (incompleteActivity.FlightId && fullActivity.FlightId &&
        incompleteActivity.FlightId === fullActivity.FlightId
    ) {
        return true;
    }
    if (fullActivity.ActivityType === "BLANK") {
        return incompleteActivity.ActivityType === "BLANK"
            && incompleteActivity.ActivityDate === fullActivity.ActivityDate;
    } else if (fullActivity.ActivityType === "Ground") {
        return incompleteActivity.ActivityType === "Ground"
            && incompleteActivity.ActivityDate === fullActivity.ActivityEndDate
            && fullActivity.GNDACTCODETITLE === incompleteActivity.GNDACTCODETITLE;
    } else {
        return incompleteActivity.ActivityType === fullActivity.ActivityType
            && incompleteActivity.ActivityDate === fullActivity.ActivityEndDate;
    }
}

function findFullRotationItem(rotationDay: RotationDay, fullRoster: RotationData): RotationItem | null {
    for (const fullRotationItem of fullRoster.RotationItems) {
        for (const fullActivity of fullRotationItem.Activities) {
            for (const incompleteActivity of rotationDay.Activities) {
                if (isSameActivity(incompleteActivity, fullActivity)) {
                    return fullRotationItem;
                }
            }
        }
    }
    return null;
}

function getFullActivity(fullRotationItem: RotationItem, record: RosterItem, i: number): RotationActivity | null {
    return fullRotationItem.Activities.find(full => isSameActivity(record, full))
        ?? fullRotationItem.Activities[i]
        ?? null;
}

/**
 * @param incompleteActivity - inferior in all aspects except for day number in rotation
 */
function toIncompleteActivity(rotation: RotationItem, fullActivity: RotationActivity, incompleteActivity?: RosterItem): RosterItem {
    if (fullActivity.ActivityType === "BLANK") {
        const blankActivity: BlankActivity = {
            ...fullActivity,
            ActivityDate: fullActivity.ActivityDate,
            DayNumberInRotaion: incompleteActivity?.DayNumberInRotaion ?? (rotation.DayNumberInRotaion || -1),
            FullRotationActivity: fullActivity,
        };
        return blankActivity;
    } else {
        const realActivity: RealActivity = {
            ...fullActivity,
            // RosterItem does not have start date and the ActivityDate holds the end date
            ActivityDate: fullActivity.ActivityEndDate,
            DayNumberInRotaion: incompleteActivity?.DayNumberInRotaion ?? (rotation.DayNumberInRotaion || -1),
            FullRotationActivity: fullActivity,
        };
        return realActivity;
    }
}

function raidoToRmFullActivity(raidoActivity: RosterActivity): RotationActivity {
    const referenceActivity = aaiRaidoActivityTypes.get(raidoActivity.RefUniqueId) ?? null;
    const GroundActivityInfo = referenceActivity?.Name ?? raidoActivity.ActivitySubType;

    const base = {
        ...SABRE_FULL_ACTIVITY_FILL,
        ATA_UTC: raidoActivity.End,
        ATD_UTC: raidoActivity.Start,
        ActivityDate: raidoActivity.Start,
        ActivityEndDate: raidoActivity.End,
        GroundActivityInfo: GroundActivityInfo.toUpperCase(),
        DutyDesignators: raidoActivity.RosterDesignator?.split(",").filter(d => d).map(d => `[${d}]`).join(","),
        FullRaidoActivity: raidoActivity,
    } as const;

    const isDeadhead = raidoActivity.Code === "CFD" ||
        raidoActivity.ActivitySubType === "Transport" &&
        raidoActivity.ActivityCode !== raidoActivity.Code &&
        raidoActivity.ActivityCode.match(/^[A-Z][A-Z0-9]\d+$/); // flight number
    if (raidoActivity.Code === "FLT" ||
        raidoActivity.ActivityType === "FLIGHT" ||
        isDeadhead
    ) {
        const airActivityBase = {
            AircraftType: "",
            AirportFrom: raidoActivity.StartAirportCode,
            AirportTo: raidoActivity.EndAirportCode,
            /** it's actually ID of _activity_, it's not specific to only flights */
            FlightId: raidoActivity.UniqueId,
            FlightNumber: raidoActivity.ActivityCode,
            ATA_UTC: raidoActivity.Times.filter(t => t.Type === "ActualEnd").map(t => t.DateTime)[0] ?? null,
            ATD_UTC: raidoActivity.Times.filter(t => t.Type === "ActualStart").map(t => t.DateTime)[0] ?? null,
            FlightNumberCode: 0,
            FlightNumberPrefix: "",
            GNDACTCODETITLE: null,
            GroundActivityCode: "0",
            GroundActivityInfo: null,
        } as const;
        const blockMs =
            asUtc(airActivityBase.ATA_UTC ?? raidoActivity.End).getTime() -
            asUtc(airActivityBase.ATD_UTC ?? raidoActivity.Start).getTime();
        const blockMinutes = blockMs / 1000 / 60;
        const blockWholeHours = Math.floor(blockMinutes / 60);
        const blockRemainderMinutes = Math.floor(blockMinutes % 60);
        if (raidoActivity.Code === "FLT" ||
            raidoActivity.ActivityType === "FLIGHT"
        ) {
            return {
                ...base,
                ...airActivityBase,
                ActivityType: "Flight",
                AircraftNumber: raidoActivity.EquipmentType,
                BlockHours: `${blockWholeHours}`,
                BlockMinutes: `${blockRemainderMinutes}`,
                BlockTime: `${blockWholeHours}:${pad2(blockRemainderMinutes)}`,
                DeadHeadMinutes: null,
                DeadheadHours: null,
                DeadheadBlockTime: null,
            };
        } else {
            return {
                ...base,
                ...airActivityBase,
                ActivityType: "Deadhead",
                AircraftNumber: null,
                BlockHours: null,
                BlockMinutes: null,
                BlockTime: null,
                DeadHeadMinutes: `${blockWholeHours}`,
                DeadheadHours: `${blockRemainderMinutes}`,
                DeadheadBlockTime: `${blockWholeHours}:${pad2(blockRemainderMinutes)}`,
            };
        }
    } else {
        return {
            ...base,
            ActivityType: "Ground",
            DeadHeadMinutes: null,
            DeadheadHours: null,
            DeadheadBlockTime: null,
            AircraftNumber: null,
            BlockHours: null,
            BlockMinutes: null,
            BlockTime: null,
            AircraftType: null,
            FlightId: raidoActivity.UniqueId,
            FlightNumberCode: 0,
            FlightNumberPrefix: null,
            GroundActivityCode: `${raidoActivity.RefUniqueId}`,
            AirportFrom: raidoActivity.StartAirportCode,
            AirportTo: raidoActivity.EndAirportCode,
            FlightNumber: "",
            GNDACTCODETITLE: raidoActivity.ActivityCode,
            GroundActivityInfo: referenceActivity?.Name ?? null,
        };
    }
}

export function raidoToRm(raidoRosterCrew: RosterCrew, yearMonth: AbsoluteMonth): RotationData {
    return {
        EmployeeBases: [],
        EmployeeCategories: [],
        EmployeeInfo: {
            Base: raidoRosterCrew.Base,
            Birthdate: raidoRosterCrew.DateOfBirth,
            Lettercode: raidoRosterCrew.Number,
            Firstname: raidoRosterCrew.Firstname,
            Lastname: raidoRosterCrew.Lastname,
            CrewCategoryMain: raidoRosterCrew.Rank,
            Empdate: raidoRosterCrew.DateOfEmployment, // ISO
            JobTitle: "",
            Homebase: "",
            Fullname: [
                raidoRosterCrew.Firstname,
                raidoRosterCrew.Middlename,
                raidoRosterCrew.Lastname,
            ].filter(n => n).join(" "),
            ContractCode: "",
            ContractFrom: "", // ISO
            ContractInfo: null,
            ContractTo: "", // ISO
            ContractType: "",
            CrewCategory: "",
            DepartmentCode: "N/A",
            DepartmentName: "",
        },
        EmployeeSpecFuncs: [],
        HajjPeriod: {},
        ResultMessage: "OK",
        ResultStatus: "SUCCESS",
        RotationItems: groupRotationDaysFromRaido(raidoRosterCrew, yearMonth),
        RotationItemsBefore: [],
        RotationPeriodItems: [],

    };
}

export function getRotationDaysFromFull(
    payrollMonth: AbsoluteMonth,
    fullRotationData: RotationData,
    incomplete: RosterItem[] | null
) {
    const yearMonth = payrollMonth.year + "-" + String(payrollMonth.month).padStart(2, "0");
    return fullRotationData.RotationItems.filter(d => d.Date.slice(0, 7) === yearMonth).map(ri => {
        const Activities = ri.Activities.map(a => {
            const incompleteActivity = incomplete?.find(ia => isSameActivity(ia, a));
            return toIncompleteActivity(ri, a, incompleteActivity);
        });
        return {
            MonthDayIndex: asUtc(ri.Date).getUTCDate() - 1,
            Date: getDatePart(ri.Date),
            DayNumberInRotaion: Activities[0]?.DayNumberInRotaion ?? (ri.DayNumberInRotaion || -1),
            Activities: Activities,
            FullRotationItem: ri,
        };
    });
}

export function getRotationDays(record: AbsoluteMonth & PersonMonthRosterContent): RotationDay[] | null {
    if (record.roster_complete && record.roster_complete.ResultStatus === "SUCCESS") {
        return getRotationDaysFromFull(record, record.roster_complete, record.roster_incomplete);
    } else if (record.roster_incomplete) {
        const { rotationDays } = groupRotationDays(record.roster_incomplete);
        if (record.roster_complete) {
            for (const rotationDay of rotationDays) {
                rotationDay.FullRotationItem = findFullRotationItem(rotationDay, record.roster_complete) ?? undefined;
                if (rotationDay.FullRotationItem) {
                    for (let i = 0; i < rotationDay.Activities.length; ++i) {
                        rotationDay.Activities[i].FullRotationActivity = getFullActivity(
                            rotationDay.FullRotationItem, rotationDay.Activities[i], i
                        ) ?? undefined;
                    }
                }
            }
        }
        return rotationDays;
    } else {
        return null;
    }
}