import * as React from "react";
import type { ManualInvoiceSubmissionFormProps } from "../features/manual-work-invoices/sheetUtils";
import { initializeEmptyRows } from "../features/manual-work-invoices/sheetUtils";
import { sheetRowToRecord } from "../features/manual-work-invoices/sheetUtils";
import type { CellBase } from "react-spreadsheet";
import type { AddManualInvoiceFromSheetRequest } from "../features/Api";
import api from "../features/Api";
import { getMonthDate } from "@mhc/utils/src/dates";
import ManualInvoiceAaiMtxSubmissionFormInputTable from "./ManualInvoiceAaiMtxSubmissionFormInputTable";
import type { PlainMatrix } from "../features/manual-work-invoices/aaiMtxIntegratedSheetInput";

type State = {
    submitting: boolean,
    hasUnsavedChanges: boolean,
    commentBoxText: string,
    resetDayRowsKey: Symbol,
    resetDayRows: PlainMatrix,
};

const FIELDS = [
    "Base",
    "OverTime",
    "BlockHours",
    "PaymentOnOff",
] as const;

type HiJetManualInvoiceDayFromSheet = {
    Base: string,
    OverTime: string,
    BlockHours: string,
    PaymentOnOff: boolean,
};

type Props = ManualInvoiceSubmissionFormProps;

function parseRow(dayRow: (CellBase | undefined)[]) {
    return sheetRowToRecord(dayRow, FIELDS);
}

export default class ManualInvoiceFlyJetSubmissionForm extends React.Component<Props, State> {
    private latestDayRows: PlainMatrix;

    constructor(props: Props) {
        require("./ManualInvoiceAaiMtxSubmissionForm.css");
        super(props);
        this.latestDayRows = initializeEmptyRows(
            FIELDS, this.props
        );
        this.state = {
            submitting: false,
            hasUnsavedChanges: false,
            commentBoxText: "",
            resetDayRowsKey: Symbol(),
            resetDayRows: this.latestDayRows,
        };

        window.addEventListener("beforeunload", event => {
            if (this.state.hasUnsavedChanges) {
                (async () => {
                    const submitButton = document.getElementById("submit-entered-sheet-data-btn");
                    if (submitButton) {
                        submitButton.focus();
                        submitButton.scrollIntoView();
                    }
                })();
                event.preventDefault();
                event.returnValue = "dontcloseplizzzz";
                return "dontcloseplizzzz";
            }
        });
    }

    private getSheetRequestData(): AddManualInvoiceFromSheetRequest<HiJetManualInvoiceDayFromSheet> {
        return {
            CommentBox: this.state.commentBoxText,
            InvoiceDays: this.latestDayRows.map((row, i) => {
                const ActivityDate = getMonthDate(this.props, i + 1);
                const parsed = parseRow(row);
                return { ...parsed, ActivityDate };
            }),
            InvoiceId: this.props.invoiceId,
        };
    }

    private async submitEnteredData() {
        const sheetRequestData = this.getSheetRequestData();
        this.setState({ submitting: true });
        try {
            await api.Invoice
                 .AddManualInvoiceFromSheet(sheetRequestData);
        } finally {
            this.setState({ submitting: false });
        }
        this.props.onSubmitted(sheetRequestData);
        this.setState({ hasUnsavedChanges: false });
    }

    public render() {
        return <div className="manual-invoice-aai-mtx-submission-form">
            <div className="manual-invoice-aai-mtx-submission-form-card">
                <div className="manual-invoice-aai-mtx-submission-form-body">
                    <div className="sheet-input-header-part">
                        <div className="informational-part">
                            <div className="please-enter-data-message">{
                                "Please, fill the work report table below and Submit"
                            }</div>

                            {this.state.submitting && <div className="status-loading-animated-ellipsis">Submitting the Work Report</div>}
                        </div>
                        <div className="comment-box-part">
                            <div className="comment-box-title-bar">
                                <strong>COMMENT BOX</strong>
                                <span>-for online fill-in-</span>
                            </div>
                            <textarea
                                rows={16} cols={42}
                                value={this.state.commentBoxText}
                                onChange={async event => {
                                    this.setState({
                                        commentBoxText: event.target.value,
                                        hasUnsavedChanges: true,
                                    });
                                }}
                            ></textarea>
                        </div>
                    </div>
                    <ManualInvoiceAaiMtxSubmissionFormInputTable
                        year={this.props.year}
                        month={this.props.month}
                        rowLabels={{
                            OverTime: "Over 12h HH:MM",
                            PaymentOnOff: "On Duty",
                        }}
                        invoiceDayFields={FIELDS}
                        resetDayRowsKey={this.state.resetDayRowsKey}
                        resetDayRows={this.state.resetDayRows}
                        onExplicitChange={(rows) => {
                            this.latestDayRows = rows;
                            this.setState({ hasUnsavedChanges: true, resetDayRows: rows });
                        }}
                        onImplicitChange={(rows) => {
                            this.latestDayRows = rows;
                            this.setState({ hasUnsavedChanges: true });
                        }}
                    />
                </div>
                <div className="buttons-list">
                    <div className="flex-remainder"></div>
                    <button
                        id="submit-entered-sheet-data-btn"
                        disabled={this.state.submitting}
                        type="button"
                        className={this.state.hasUnsavedChanges ? "has-user-input" : undefined}
                        onClick={() => this.submitEnteredData()}
                    >Submit</button>
                </div>
            </div>
        </div>;
    }
}