import type { Last } from "../types/utility";
import { toUpperCase } from "@mhc/utils/types/nav";

export {
    /** probably need to put it in a different module, not in nav.ts */
    toUpperCase,
};

export function keyof<T extends {}>(key: string, obj: T): null | keyof T {
    if (key in obj) {
        return key as keyof T;
    } else {
        return null;
    }
}

export function last<TTuple extends [unknown, ...unknown[]]>(tuple: TTuple): Last<TTuple> {
    return tuple[tuple.length - 1] as Last<TTuple>;
}

export function getTypeName(value: unknown) {
    if (value === null) {
        return "null";
    }
    if (typeof value === "object") {
        return String(value.constructor?.name) || "object";
    } else {
        return typeof value;
    }
}