
import * as React from "react";
import type { FullPayslip, NavCompanyData, PayEntry, Payslip } from "../types/NavisionPayslip";
import { getCurrencyCode, getCurrencySymbol } from "../utils/currency";
import CustomFields from "../utils/CompanySpecificFields";
import { formatNavDate, isEmptyNavDate,isSoePayEntry, NAV_EPOCH_START } from "../features/payrollUtils";
import { getInvoiceTotal } from "../utils/feeStatementInvoiceUtils.ts";

type Props = {
    id?: string,
    navCompanyData: NavCompanyData,
    fullPayslip: FullPayslip,
};

export default class ContractorMonthlyPayslipInvoice extends React.Component<Props, {}> {
    private get yearMonth() {
        return this.props.fullPayslip.payslip.Dagsetning.replace(/-\d{2} 00:00:00$/, "");
    }

    private isDifferentMonth(isoStr: string) {
        return isoStr
            && isoStr !== NAV_EPOCH_START
            && !isoStr.startsWith(this.yearMonth);
    }

    private getCustomFields(source: PayEntry | Payslip) {
        return new CustomFields(this.props.navCompanyData, source);
    }

    private get departmentCode() {
        return this.getCustomFields(this.props.fullPayslip.payslip).departmentCode;
    }

    private getBase(source: PayEntry) {
        return this.getCustomFields(source).base;
    }

    private get hasBases() {
        return this.props.fullPayslip.transfers.some(t => this.getBase(t));
    }

    private get currencyPrefix() {
        const currencyCode = getCurrencyCode(this.props.navCompanyData.glSetup["LCY Code"]);
        return getCurrencySymbol(currencyCode) + " ";
    }

    private get totalBeforeDeduction() {
        return this.props.fullPayslip.transfers
            .map(t => +t.Upphaed)
            .filter(a => a > 0)
            .reduce((a,b) => a + b, 0);
    }

    private get deducted() {
        return this.props.fullPayslip.transfers
            .filter(t => t.Tegund === 2)
            .map(t => +t.Upphaed)
            .filter(a => a < 0)
            .reduce((a,b) => a + b, 0);
    }

    private get invoicedAddressSameAsCurrent() {
        return this.props.fullPayslip.payslip.CurrentAddress?.includes(this.props.fullPayslip.payslip.Heimili)
            || this.props.fullPayslip.payslip.Heimili?.includes(this.props.fullPayslip.payslip.CurrentAddress)
            || this.props.fullPayslip.payslip.Postnumer?.includes(this.props.fullPayslip.payslip.CurrentPostCode)
            || this.props.fullPayslip.payslip.CurrentPostCode?.includes(this.props.fullPayslip.payslip.Postnumer);
    }

    private get personalAddressSecondLine() {
        if (this.invoicedAddressSameAsCurrent) {
            // some of the address details, like country, are not recorded in invoice hard copies - we can
            // take them from employee card, but only if we are certain that it's same address as in invoice
            return this.props.fullPayslip.payslip["Address 2"] + " "
                + this.props.fullPayslip.payslip.City + " "
                + this.props.fullPayslip.payslip["Country_Region Code"];
        } else {
            return this.props.fullPayslip.payslip["Baer"];
        }
    }

    render() {
        const periodTotal = getInvoiceTotal(this.props.fullPayslip).toFixed(2);
        return (
            <div className="contractorMonthlyPayslipInvoice" id={this.props.id}>
                <div className="head-section-split">
                    <div>
                        {this.props.fullPayslip.payslip.VendorName ? <div>
                            <div><b>{this.props.fullPayslip.payslip.VendorName}</b></div>
                            <div>VAT {this.props.fullPayslip.payslip.VendorVatRegistrationNumber || this.props.fullPayslip.payslip.VendorRegistrationNumber}</div>
                            <div>{this.props.fullPayslip.payslip.VendorAddress}</div>
                            <div>{this.props.fullPayslip.payslip.VendorAddress2} {this.props.fullPayslip.payslip.VendorCity} {this.props.fullPayslip.payslip.VendorCountry} {this.props.fullPayslip.payslip.VendorPostCode}</div>
                            <div>Email: {this.props.fullPayslip.payslip.VendorEmail}</div>

                        </div> : <div>
                            <div>{this.props.fullPayslip.payslip["First Name"]}{!this.props.fullPayslip.payslip["Middle Name"] ? "" : " " + this.props.fullPayslip.payslip["Middle Name"]} {this.props.fullPayslip.payslip["Last Name"]}</div>
                            <div>{this.props.fullPayslip.payslip.Heimili}</div>
                            <div>{this.personalAddressSecondLine} {this.props.fullPayslip.payslip.Postnumer}</div>
                        </div>}
                        <div className="invoicingCompanyInformation">
                            <div>To:</div>
                            <div><b>{this.props.navCompanyData.company.Name}</b></div>
                            <div>VAT {this.props.navCompanyData.company["VAT Registration No_"]}</div>
                            <div>{this.props.navCompanyData.company.Address}</div>
                            <div>{this.props.navCompanyData.company["Address 2"]} {this.props.navCompanyData.company["Post Code"]}</div>
                            <div>Email: {this.props.navCompanyData.company["E-Mail"]}</div>
                        </div>
                    </div>
                    <div className="head-section-split-right-part">
                        <h3>Invoice/Fee Statement</h3>
                        <table>
                            <tbody>
                            <tr>
                                <td>Period</td>
                                <td>{this.yearMonth}</td>
                            </tr>
                            <tr>
                                <td>Pay Run No.</td>
                                <td>{this.props.fullPayslip.payslip.Utborgunarnumer}</td>
                            </tr>
                            <tr>
                                <td>Letter code</td>
                                <td>{this.props.fullPayslip.payslip.Kennitala}</td>
                            </tr>
                            <tr>
                                <td>Department</td>
                                <td>{this.departmentCode}</td>
                            </tr>
                            {!this.deducted ? [] : [
                                <tr key="TOTAL_CALCULATED">
                                    <td>Total Calculated</td>
                                    <td>{this.currencyPrefix}{this.totalBeforeDeduction.toFixed(2)}</td>
                                </tr>,
                                <tr key="DEDUCTIONS_OR_PAID_EARLIER">
                                    <td>Deductions or Paid Earlier</td>
                                    <td>{this.currencyPrefix}{this.deducted.toFixed(2)}</td>
                                </tr>,
                            ]}
                            <tr>
                                <td>Fees to be paid</td>
                                <td>{periodTotal}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="transfersTableContainer">
                    <table className="transfersList">
                        <thead>
                            <tr>
                                <th className="plainTextField">Description</th>
                                {this.hasBases ? <th>Base</th> : undefined}
                                <th>Start</th>
                                <th>End</th>
                                <th className="number-field">Units</th>
                                <th className="number-field">{this.currencyPrefix}Rate</th>
                                <th className="number-field">{this.currencyPrefix}Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.fullPayslip.transfers.map(transfer => {
                                const dateCells = transfer.Tegund === 1 || isEmptyNavDate(transfer["Dagsetning taxta"]) ? <>
                                    <td className={"date-field" + (this.isDifferentMonth(transfer["Timabil fra (Dagpeningar)"]) ? " different-month" : "")}>{formatNavDate(transfer["Timabil fra (Dagpeningar)"])}</td>
                                    <td className={"date-field" + (this.isDifferentMonth(transfer["Timabil til (Dagpeningar)"]) ? " different-month" : "")}>{formatNavDate(transfer["Timabil til (Dagpeningar)"])}</td>
                                </> : <td colSpan={2} className={"date-field" + (this.isDifferentMonth(transfer["Dagsetning taxta"]) ? " different-month" : "")}>
                                    {formatNavDate(transfer["Dagsetning taxta"])}
                                </td>;
                                const isSoe = isSoePayEntry(transfer.Faerslutegund);
                                return (
                                    <tr key={transfer.Rununumer} className={isSoe ? "soe-pay-entry" : ""} title={isSoe ? "Approved SoE" : undefined}>
                                        <td className="plainTextField">{transfer["Texti"] || transfer["Texti a launasedli"]}</td>
                                        {this.hasBases ? <td>{this.getBase(transfer)}</td> : undefined}
                                        {dateCells}
                                        <td className="number-field">{(+transfer.Einingar).toFixed(2)}</td>
                                        <td className="number-field">{(+transfer["Upphaed taxta"]).toFixed(2)}</td>
                                        <td className={"number-field total-amount-column" + (+transfer.Upphaed < 0 ? " negative" : "")}>{(+transfer.Upphaed).toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                            <tr className="periodTotalLine">
                                <td className="periodTotalLabel" colSpan={this.hasBases ? 6 : 5}>Period total</td>
                                <td className="number-field">{periodTotal}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
